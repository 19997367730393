import React from "react";

const ImageBanner = ({ src }) => {
  return (
    <div className="element_hug mt_6">
      <div className="element_container">
        <img src={src} alt="error" height={"100%"} width={"100%"}/>
      </div>
    </div>
  );
};

export default ImageBanner;
