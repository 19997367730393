import { createSlice } from "@reduxjs/toolkit";

export const quickBuyProductsSlice = createSlice({
  name: "quickBuyProducts",
  initialState: {
    loading: false,
    quickBuyProducts: [],
    totalPages: 0,
    quickBuyProductsCount: 0,
    currentPage: 0,
    message: null,
    status: null,
  },
  reducers: {
    quickBuyProductsRequest(state, payload) {
      return {
        ...state,
        loading: true,
      };
    },
    quickBuyProductsSuccess(state, action) {
      return {
        ...state,
        loading: false,
        quickBuyProducts: action.payload.data.products,
        totalPages: action.payload.data.totalPages,
        quickBuyProductsCount: action.payload.data.productsCount,
        currentPage: action.payload.data.currentPage,
        message: action.payload.message,
        status: "success",
      };
    },
    quickBuyProductsFail(state, action) {
      return {
        ...state,
        loading: false,
        message: action.payload.message,
        status: "fail",
      };
    },
    clearQuickBuyProducts(state) {
      return {
        ...state,
        loading: false,
        quickBuyProducts: [],
        totalPages: 0,
        quickBuyProductsCount: 0,
        currentPage: 0,
        message: null,
        status: null,
      };
    },
  },
});

const { actions, reducer } = quickBuyProductsSlice;

export const {
  quickBuyProductsRequest,
  quickBuyProductsFail,
  quickBuyProductsSuccess,
  clearQuickBuyProducts,
} = actions;

export default reducer;
