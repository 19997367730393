import {
  toastsFail,
  toastsRequest,
  toastsSuccess,
} from "../slices/toastsSlice";

export const updateToasts = (toasts) => async (dispatch) => {
  try {
    dispatch(toastsRequest());

    dispatch(toastsSuccess(toasts));
  } catch (error) {
    dispatch(toastsFail(error));
  }
};
