import React from "react";

const RefundPolicies = () => {
  return (
    <div>
      <h1 className="policies_title">Refund Policy</h1>
      <h2 class="section_title">
        Our refund policy covers the following scenarios:
      </h2>
      <h2 class="section_title">1. Damaged or Broken Product</h2>
      <p class="section_content">
        If your product is delivered in a damaged or broken condition, you are
        entitled to a full refund. We kindly ask that you notify us within 48
        hours of delivery to initiate the refund process. Ensure that you retain
        the original packaging until the issue is resolved.
      </p>
      <h2 class="section_title">2. Incorrect Product or Misplaced Delivery</h2>
      <p class="section_content">
        In the event you receive an incorrect product or your package is
        misplaced during delivery, we will offer a full refund. Please contact
        us as soon as you notice the issue so we can address it immediately.
      </p>
      <h2 class="section_title">3. Conditions for Refund</h2>
      <p class="section_content">
        To ensure a smooth and efficient refund process, we require an unboxing
        video of the delivered product. This video serves as proof and allows us
        to verify any damage, incorrect item, or other delivery issues. Without
        an unboxing video, we may be unable to process the refund request.
      </p>
      <h2 class="section_title">4. Refund Process</h2>
      <p class="section_content">
        Once your refund request is approved, we will process the refund within
        7 business days. The refund will be credited to your original method of
        payment. If you have any questions during this process, our customer
        support team is available to assist you.
      </p>
      <h2 class="section_title">Note: </h2>
      <p class="section_content">
        Note: Refund requests made after 7 days of delivery will not be eligible
        for consideration. We recommend inspecting your order upon receipt and
        reporting any issues promptly.
      </p>
    </div>
  );
};

export default RefundPolicies;
