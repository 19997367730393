import React, { useEffect, useState } from "react";
import InfinityLoader from "../../assets/imgs/loaders/InfinityLoader";

const BackdropLoader = ({ isLoading }) => {
  const [showDot, setShowDot] = useState(true);
  const [loadingStatus, setLoadingStatus] = useState(false);
  useEffect(() => {
    const interval = setInterval(() => {
      setShowDot((prev) => !prev);
    }, 500);
    return () => clearInterval(interval);
  }, []);
  useEffect(() => {
    if (isLoading) {
      setLoadingStatus(true);
    }
  }, [isLoading]);
  useEffect(() => {
    if (!isLoading) {
      setTimeout(() => {
        setLoadingStatus(false);
      }, 1000);
    }
  }, [isLoading]);
  if (loadingStatus)
    return (
      <div className="backdrop_loader">
        <div
          className={`backdrop_loader_container ${
            isLoading ? "loading_active" : "default_active"
          }`}
        >
          <div className="loader_container">
            <InfinityLoader height={100} />
            <div className="text cmac_default_fill_text">Loading..{showDot ? "." : ""}</div>
          </div>
        </div>
      </div>
    );
};

export default BackdropLoader;
