import React, { useEffect } from "react";
import Topbanner from "./layouts/TopBanner";
import ProductMultiCarousalLanding from "./layouts/ProductMultiCarousalLanding";
import OfferBanner from "./layouts/OfferBanner";
import CategoryMultiCarousal from "./layouts/CategoryMultiCarousal";
import banner01 from "../../../assets/imgs/landing-page/cmac_img_id_17.png";
import ImageBanner from "./layouts/ImageBanner";
import { useDispatch, useSelector } from "react-redux";
import {
  getPopularProducts,
  getRecentProducts,
  getRecommendedProducts,
} from "../../../redux/actions/productsAction";
import { performCrypto } from "../../../helpers/performCrypto";

const LandingPage = () => {
  const dispatch = useDispatch();
  const crypto = performCrypto();
  const { recentProducts } = useSelector((state) => state.recentProductsState);
  const { products } = useSelector((state) => state.productsState);
  const { popularProducts } = useSelector(
    (state) => state.popularProductsState
  );
  const { recommendedProducts } = useSelector(
    (state) => state.recommendedProductsState
  );
  useEffect(() => {
    const productIds = crypto.get("recent_items").decryptedData;
    dispatch(getRecentProducts(`product_ids=${JSON.stringify(productIds)}`));
    dispatch(getPopularProducts());
    dispatch(getRecommendedProducts());
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [products]);
  return (
    <div className="landing_page">
      <Topbanner />
      {recentProducts?.length > 0 && (
        <ProductMultiCarousalLanding
          heading={"Recently Viewed"}
          productsFeed={recentProducts}
        />
      )}
      {recentProducts?.length > 0 && <div className="mt_3"></div>}
      <CategoryMultiCarousal heading={"Categories"} />
      <div className="mt_3"></div>
      {popularProducts?.length > 0 && (
        <ProductMultiCarousalLanding
          heading={"Popular Products"}
          productsFeed={popularProducts}
        />
      )}
      <OfferBanner />
      <div className="mt_3"></div>
      {recommendedProducts?.length > 0 && (
        <ProductMultiCarousalLanding
          heading={"Recommended Products"}
          productsFeed={recommendedProducts}
        />
      )}
      <ImageBanner src={banner01} />
    </div>
  );
};

export default LandingPage;
