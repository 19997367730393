import { createSlice } from "@reduxjs/toolkit";

export const similarProductsSlice = createSlice({
  name: "similarProducts",
  initialState: {
    loading: false,
    similarProducts: [],
    message: null,
    status: null,
  },
  reducers: {
    similarProductsRequest(state, payload) {
      return {
        ...state,
        loading: true,
      };
    },
    similarProductsSuccess(state, action) {
      return {
        ...state,
        loading: false,
        similarProducts: action.payload.data.products,
        message: action.payload.message,
        status: "success",
      };
    },
    similarProductsFail(state, action) {
      return {
        ...state,
        loading: false,
        message: action.payload.message,
        status: "fail",
      };
    },
    clearSimilarProducts(state) {
      return {
        ...state,
        loading: false,
        similarProducts: [],
        message: null,
        status: null,
      };
    },
  },
});

const { actions, reducer } = similarProductsSlice;

export const {
  similarProductsRequest,
  similarProductsFail,
  similarProductsSuccess,
  clearSimilarProducts,
} = actions;

export default reducer;
