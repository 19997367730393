import { createSlice } from "@reduxjs/toolkit";

export const recommendedProductsSlice = createSlice({
  name: "recommendedProducts",
  initialState: {
    loading: false,
    recommendedProducts: [],
    message: null,
    status: null,
  },
  reducers: {
    recommendedProductsRequest(state, payload) {
      return {
        ...state,
        loading: true,
      };
    },
    recommendedProductsSuccess(state, action) {
      return {
        ...state,
        loading: false,
        recommendedProducts: action.payload.data.products,
        message: action.payload.message,
        status: "success",
      };
    },
    recommendedProductsFail(state, action) {
      return {
        ...state,
        loading: false,
        message: action.payload.message,
        status: "fail",
      };
    },
    clearRecommendedProducts(state) {
      return {
        ...state,
        loading: false,
        recommendedProducts: [],
        message: null,
        status: null,
      };
    },
  },
});

const { actions, reducer } = recommendedProductsSlice;

export const {
  recommendedProductsRequest,
  recommendedProductsFail,
  recommendedProductsSuccess,
  clearRecommendedProducts,
} = actions;

export default reducer;
