import { createSlice } from "@reduxjs/toolkit";

export const orderSlice = createSlice({
  name: "order",
  initialState: {
    loading: false,
    order: {},
    orderInitiatedData: {},
    message: null,
    status: null,
    allowToast: false,
    actionStatus: null,
  },
  reducers: {
    orderRequest(state, payload) {
      return {
        ...state,
        loading: true,
        allowToast: false,
      };
    },
    orderSuccess(state, action) {
      return {
        ...state,
        loading: false,
        order: action.payload.data.orderDetails,
        message: action.payload.message,
        status: "success",
        actionStatus: "get-success",
      };
    },
    orderFail(state, action) {
      return {
        ...state,
        loading: false,
        message: action.payload.message,
        status: "fail",
        actionStatus: "get-fail",
      };
    },
    orderDetailsRequest(state, payload) {
      return {
        ...state,
        loading: true,
        allowToast: false,
      };
    },
    orderDetailsSuccess(state, action) {
      return {
        ...state,
        loading: false,
        order: action.payload.data.orderDetails,
        message: action.payload.message,
        status: "success",
        actionStatus: "get-details-success",
      };
    },
    orderDetailsFail(state, action) {
      return {
        ...state,
        loading: false,
        message: action.payload.message,
        status: "fail",
        actionStatus: "get-details-success",
      };
    },
    orderCreateRequest(state, payload) {
      return {
        ...state,
        loading: true,
        allowToast: false,
      };
    },
    orderCreateSuccess(state, action) {
      return {
        ...state,
        loading: false,
        orderInitiatedData: action.payload.data,
        message: action.payload.message,
        status: "success",
        actionStatus: "create-success",
      };
    },
    orderCreateFail(state, action) {
      return {
        ...state,
        loading: false,
        message: action.payload.message,
        status: "fail",
        actionStatus: "create-fail",
      };
    },
    orderPaymentStatusRequest(state, payload) {
      return {
        ...state,
        loading: true,
        allowToast: false,
      };
    },
    orderPaymentStatusSuccess(state, action) {
      return {
        ...state,
        loading: false,
        order: action.payload.data.orderDetails,
        message: action.payload.message,
        status: "success",
        actionStatus: "order-payment-status-success",
      };
    },
    orderPaymentStatusFail(state, action) {
      return {
        ...state,
        loading: false,
        message: action.payload.message,
        status: "fail",
        actionStatus: "order-payment-status-fail",
      };
    },
    clearOrderActionStatus(state) {
      return {
        ...state,
        loading: false,
        status: null,
        allowToast: false,
        actionStatus: null,
      };
    },
    clearInitiatedOrderActionStatus(state) {
      return {
        ...state,
        loading: false,
        status: null,
        allowToast: false,
        actionStatus: null,
        orderInitiatedData: {},
      };
    },
  },
});

const { actions, reducer } = orderSlice;

export const {
  orderRequest,
  orderFail,
  orderSuccess,
  orderDetailsRequest,
  orderDetailsFail,
  orderDetailsSuccess,
  orderCreateRequest,
  orderCreateFail,
  orderCreateSuccess,
  orderPaymentStatusRequest,
  orderPaymentStatusFail,
  orderPaymentStatusSuccess,
  clearOrderActionStatus,
  clearInitiatedOrderActionStatus,
} = actions;

export default reducer;
