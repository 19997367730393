import React, { useEffect, useMemo, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useSelector } from "react-redux";
import CmacModal from "../../../plugins/cmac-plugins/CmacModal";
import { IoIosClose } from "react-icons/io";

const ProductImage = ({ documentDimensions }) => {
  const { product, loading } = useSelector((state) => state.productState);
  const [targetImageUrl, setTargetImageUrl] = useState("");
  const [openImage, setOpenImage] = useState(false);
  useEffect(() => {
    if (product?.productImages?.length > 0) {
      setTargetImageUrl(product?.productImages[0]?.url);
    }
  }, [product]);

  useEffect(() => {
    if (documentDimensions?.width <= 992) {
      setOpenImage(false);
    }
  }, [documentDimensions]);
  return (
    <div className="product_image">
      <div
        style={{
          position: "sticky",
          top: "160px",
        }}
      >
        <div>
          <div className="cmac_default_shade_fill_bg active_img_container border_rad_10 overflow_hidden">
            {loading ? (
              <Skeleton
                height={"100%"}
                width={"100%"}
                baseColor="var(--cmac-default-shade-fill)"
                highlightColor="var(--cmac-default-fill)"
                duration={0.8}
              />
            ) : (
              <img
                className="active_img cursor_pointer"
                src={targetImageUrl}
                alt={targetImageUrl}
                onClick={() => documentDimensions?.width >= 992 && setOpenImage(true)}
              />
            )}
          </div>
          <div className="d_flex align_items_center gap_3 collection_imgs_container">
            <div className="d_flex align_items_center gap_2">
              {loading ? (
                <Skeleton
                  height={"80px"}
                  width={"80px"}
                  baseColor="var(--cmac-default-shade-fill)"
                  highlightColor="var(--cmac-default-fill)"
                  duration={0.8}
                />
              ) : (
                product?.productImages?.length > 0 &&
                product?.productImages.map((data) => {
                  return (
                    <img
                      className="collection_img cmac_default_shade_fill_bg border_rad_10 cursor_pointer"
                      src={data?.url}
                      alt={data?.url}
                      onClick={() => setTargetImageUrl(data?.url)}
                      style={{
                        border: "2px solid #fff",
                        boxShadow: `0 0 0px 1px ${
                          data?.url === targetImageUrl
                            ? "var(--cmac-primary-color)"
                            : "transparent"
                        }`,
                        transition: "0.3s ease-in-out",
                      }}
                    />
                  );
                })
              )}
            </div>
          </div>
        </div>
      </div>
      <CmacModal
        active={openImage}
        portalUsage={true}
        style={{
          backgroundColor: "#f2f2f2",
          padding: "unset",
          overflow: "hidden",
          boxShadow: "0px 0px 20px 1px #00000019",
        }}
        onClickOutsider={() => setOpenImage(false)}
      >
        <div
          className="d_flex align_items_center justify_content_flex_end"
          style={{
            height: "0",
            position: "relative",
            top: "30px",
            right: "10px",
          }}
        >
          <div
            className="d_flex align_items_center justify_content_center cursor_pointer"
            style={{
              height: "40px",
              width: "40px",
              background: "var(--cmac-default-fill)",
              color: "var(--cmac-dark-fill)",
              borderRadius: "50%",
            }}
            onClick={() => setOpenImage(false)}
          >
            <IoIosClose size={30} />
          </div>
        </div>
        <img
          src={targetImageUrl}
          alt={targetImageUrl}
          style={{
            height: "70vh",
            width: "70vh",
            objectFit: "contain",
          }}
        />
      </CmacModal>
    </div>
  );
};

export default ProductImage;
