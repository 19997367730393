export const endPoints = {
  secret_code: {
    send: "send/secret_code",
  },
  user: {
    login: "login",
    register: "create/user",
    get_profile: "get/profile",
    update_profile: "update/profile",
  },
  products: {
    get: "get/products",
  },
  category_products: {
    get: "get/category/products",
  },
  recent_products: {
    get: "get/recent/products",
  },
  similar_products: {
    get: "get/similar/products",
  },
  popular_products: {
    get: "get/popular/products",
  },
  recommended_products: {
    get: "get/recommended/products",
  },
  test_questions: {
    create: "create/testquestions",
    get: "get/testquestions",
    get_data: "get/testquestions/data",
  },
  address: {
    get: "get/addresses",
    create: "create/address",
    delete: "delete/address",
    update: "update/address"
  },
  review: {
    get: "get/reviews",
    create: "create/review",
    delete: "delete/review",
    update: "update/review"
  },
  product: {
    get: "get/product",
    create: "create/product",
    update: "update/product",
    delete: "delete/product",
  },
  cart: {
    get: "get/cart",
    get_temp: "get/cart/temp",
    update: "update/cart/items",
    add: "add/cart",
    add_temp: "add/cart/temp",
  },
  order_details: {
    get: "get/order_details",
  },
  order: {
    create: "create/order",
    payment_status: "order/payment/status",
    create_payment: "create/order/payment",
    get: "get/order",
    update_status: "update/orderstatus"
  },
  orders: {
    get: "get/orders",
    get_all: "get/all/orders",
    print_order_invoice: "/print/orderinvoice"
  },
};
