import React, { useEffect, useState } from "react";
import { IoIosArrowForward, IoIosCheckmarkCircle } from "react-icons/io";
import { BsClockFill } from "react-icons/bs";
import { testQuestions } from "./questions";
import { useSelector } from "react-redux";

const Sidebar = ({
  onLinkChange,
  onShrinkContainerChange,
  selectedLink: selectedLinkValue,
  selectedSubLink,
  formIndex,
}) => {
  const { testQuestions: testQuestionsValue } = useSelector(
    (state) => state.testQuestionsState
  );
  const [selectedLink, setSelectedLink] = useState([]);
  const [visitedLinks, setVisitedLinks] = useState([]);
  const [isSidebarShrinked, setIsSidebarShrinked] = useState(false);
  const links = [
    {
      id: 1,
      link: "About You",
      shortLinks: [
        {
          reduxDataId: 11,
          id: 1,
          shortLink: "Let's make it easy",
        },
        {
          reduxDataId: 12,
          id: 2,
          shortLink: "Mobile Number",
        },
        {
          reduxDataId: 13,
          id: 3,
          shortLink: "Email",
        },
        {
          reduxDataId: 14,
          id: 4,
          shortLink: "How old are you?",
        },
        {
          reduxDataId: 15,
          id: 5,
          shortLink: "Gender",
        },
      ],
    },
    ...testQuestions[formIndex].questions.map((data) => {
      return {
        id: data.id,
        link: data?.category,
        shortLinks: data.qs.map((qsData) => {
          return {
            reduxDataId: Number(data.id.toString() + qsData.id.toString()),
            id: qsData.id,
            shortLink: qsData.subHeading,
          };
        }),
      };
    }),
  ];
  const handleSelectLink = (e) => {
    const linkFound = selectedLink.some((data) => data?.id === e?.id);
    const removeFoundLink = selectedLink.filter((data) => data?.id !== e?.id);
    if (linkFound) {
      setSelectedLink(removeFoundLink);
    } else {
      setSelectedLink([...removeFoundLink, e]);
    }
  };
  const handleSelectLinkPropagation = () => {
    setSelectedLink(selectedLink);
  };
  useEffect(() => {
    setSelectedLink([{ id: selectedLinkValue }]);
  }, [selectedLinkValue]);
  useEffect(() => {
    const newLink = selectedLinkValue.toString() + selectedSubLink.toString();
    if (!visitedLinks.includes(newLink)) {
      setVisitedLinks([...visitedLinks, newLink]);
    }
  }, [selectedLinkValue, selectedSubLink, visitedLinks]);

  return (
    <div
      className="test_questions_sidebar"
      style={{
        width: isSidebarShrinked && "0px",
        left: isSidebarShrinked && "-400px",
      }}
    >
      <div
        className="header_container d_flex align_items_center justify_content_space_between"
        style={{
          borderColor: isSidebarShrinked && "transparent",
          transition: "0.3s",
        }}
      >
        <div
          className={`f_weight_1 opacity_0_8 pull_back_class ${
            isSidebarShrinked && "pull_back_class_active"
          }`}
        >
          Test Questions
        </div>
        <div
          className={`d_flex align_items_center justify_content_center close_sidebar_ic cursor_pointer ${
            isSidebarShrinked && "res_close_sidebar_ic"
          }`}
          onClick={() => {
            setIsSidebarShrinked(!isSidebarShrinked);
            onShrinkContainerChange(!isSidebarShrinked);
          }}
          style={{
            position: "relative",
            left: isSidebarShrinked ? "-88px" : "0px",
            transition: "0.3s",
          }}
        >
          <IoIosArrowForward
            size={20}
            style={{
              transition: "0.3s",
              transform: isSidebarShrinked && "rotate(-180deg)",
            }}
          />
        </div>
      </div>
      <div
        className={`links_accordian_container pull_back_class ${
          isSidebarShrinked ? "pull_back_class_active d_flex" : "d_flex"
        }`}
      >
        {links?.map((data, _i) => {
          const isChecked = selectedLink.some(
            (selectedLinkData) => selectedLinkData?.id === data?.id
          );
          const isNotDisabledOverall = () => {
            if (
              data?.shortLinks.some(
                (data) => testQuestionsValue[data.reduxDataId]?.value
              ) ||
              visitedLinks?.some(
                (visitedLinkData) =>
                  Number(visitedLinkData?.split("")[0]) === data?.id
              )
            ) {
              return true;
            } else return false;
          };
          const isNotDisabledOverallValue = () => {
            if (
              data?.shortLinks.every(
                (data) => testQuestionsValue[data.reduxDataId]?.value
              )
            ) {
              return true;
            } else return false;
          };
          return (
            <div
              className={`link_accordian cursor_pointer ${
                isChecked ? "open_drawer" : "close_drawer"
              }`}
              onClick={() => isNotDisabledOverall() && handleSelectLink(data)}
              style={{
                opacity: !isNotDisabledOverall() && "0.3",
                cursor: !isNotDisabledOverall() && "default",
                transition: "all 0.5s ease-in-out",
              }}
            >
              <div>
                <div
                  className={`d_flex align_items_center justify_content_space_between parent ${
                    isChecked && "opened"
                  }`}
                >
                  <div className="f_weight_1 d_flex align_items_center gap_2 opacity_0_8">
                    <IoIosArrowForward
                      size={24}
                      style={{
                        transform: isChecked && "rotate(90deg)",
                        transition: "0.25s ease-in-out",
                      }}
                    />
                    <div>{data?.link}</div>
                  </div>
                  <div className="d_flex align_items_center">
                    {data.id === 4 ? (
                      <BsClockFill size={17} color="#ff6a008f" />
                    ) : isNotDisabledOverallValue() ? (
                      <IoIosCheckmarkCircle
                        size={21}
                        color="var(--cmac-primary-color)"
                      />
                    ) : (
                      <BsClockFill size={17} color="#ff6a008f" />
                    )}
                  </div>
                </div>
                {isChecked && (
                  <div className="d_flex flex_direction_col p_2">
                    {data?.shortLinks.map((shortLinkData, i) => {
                      const isNotDisabled = () => {
                        if (
                          testQuestionsValue[shortLinkData.reduxDataId]
                            ?.value ||
                          visitedLinks?.some(
                            (visitedLinkData) =>
                              Number(visitedLinkData) ===
                              Number(
                                data.id.toString() +
                                  shortLinkData?.id.toString()
                              )
                          )
                        ) {
                          return true;
                        } else return false;
                      };
                      return (
                        <div
                          className="d"
                          onClick={(e) => {
                            e.stopPropagation();
                            if (isNotDisabled()) {
                              onLinkChange(data?.id, shortLinkData?.id);
                              handleSelectLinkPropagation();
                            }
                          }}
                          style={{
                            paddingTop: "10px",
                            paddingBottom: "10px",
                            opacity:
                              !isNotDisabled() &&
                              isNotDisabledOverall() &&
                              "0.2",
                            cursor:
                              !isNotDisabled() &&
                              isNotDisabledOverall() &&
                              "default",
                            transition: "all 0.5s ease-in-out",
                          }}
                        >
                          <div>
                            {i + 1}. {shortLinkData?.shortLink}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Sidebar;
