import brand_src from "../../assets/imgs/brand/revive.png";
import { useState } from "react";
import CartList from "./layouts/CartList";
import { isNoShowRoute } from "../../helpers/isNoShowRoute";
import QuickBuy from "./layouts/QuickBuy";
import CmacSideDragger from "../plugins/cmac-plugins/cmac-side-dragger/CmacSideDragger";
import { PiShoppingCartLight } from "react-icons/pi";
import { IoPersonOutline } from "react-icons/io5";
import { Link, useNavigate } from "react-router-dom";
import {
  ACCOUNT_TABS_PAGE,
  AUTH_PAGE,
  LANDING_PAGE,
  SHOP_PAGE,
} from "../../helpers/paths";
import { useSelector } from "react-redux";
import { Input } from "../utils/Elements";
import HamburgerMenu from "../plugins/HamburgerMenu";
import CategorybarSideDragger from "./layouts/CategorybarSideDragger";
import { HiOutlineSearch } from "react-icons/hi";
import { getQueryParam } from "../../helpers/getQueryParams";

const Header = ({ isSearching }) => {
  const navigate = useNavigate();
  const searchActive = getQueryParam("q");
  const { cart } = useSelector((state) => state.cartState);
  const { isAuthenticated } = useSelector((state) => state.userState);
  const [categoryBarSideDraggerOpen, setCategoryBarSideDraggerOpen] =
    useState(false);
  const [cartDraggerOpen, setCartDraggerOpen] = useState(false);
  const [quickBuyDraggerOpen, setQuickBuyDraggerOpen] = useState(false);
  const [responsiveNav, setResponsiveNav] = useState(false);
  if (isNoShowRoute()) {
    return <></>;
  }
  return (
    <div className="header d_flex align_items_center">
      <div className="element_hug">
        <div className="element_container">
          <div className="d_flex align_items_center justify_content_space_between w_fill">
            <Link
              to={LANDING_PAGE}
              style={{
                width: "114px",
              }}
            >
              <img src={brand_src} height={50} className="brand" />
            </Link>
            <div className="res-max-768px-d-none">
              <Input
                style={{
                  width: "400px",
                  borderRadius: "3px",
                }}
                mode={"search"}
                className="search_input_class font_lato"
                placeholder={"Search for Products"}
                onChange={(e) => {
                  const searchPayload = e.target.value;
                  navigate(`${SHOP_PAGE}?s=${searchPayload}`);
                  isSearching(true);
                  setTimeout(() => {
                    isSearching(false);
                  }, 1000);
                }}
              />
            </div>
            <div
              className="d_flex align_items_center gap_6 ic_links"
              style={{
                width: "114px",
              }}
            >
              <HiOutlineSearch
                size={30}
                color="var(--cmac-dark-fill)"
                className="res-min-768px-d-none"
                onClick={() => {
                  if (searchActive === "search") {
                    navigate(`${SHOP_PAGE}`);
                  } else {
                    navigate(`${SHOP_PAGE}?q=search`);
                  }
                }}
              />
              <IoPersonOutline
                className="cursor_pointer"
                size={28}
                onClick={() => navigate(ACCOUNT_TABS_PAGE)}
              />
              <div
                className="cursor_pointer"
                onClick={() => {
                  // isAuthenticated
                  //   ? 
                    setCartDraggerOpen(true)
                    // : window.location.replace(AUTH_PAGE);
                }}
              >
                <div className="cart_count">
                  {cart?.productsIdsInCart?.length || 0}
                </div>
                <PiShoppingCartLight size={33} />
              </div>
              <HamburgerMenu
                className={"res-min-768px-d-none"}
                checked={categoryBarSideDraggerOpen}
                onClick={() => setCategoryBarSideDraggerOpen(true)}
              />
            </div>
          </div>
        </div>
      </div>
      <CategorybarSideDragger
        sideDraggerOpen={categoryBarSideDraggerOpen}
        onSideDraggerClose={() => setCategoryBarSideDraggerOpen(false)}
      />
      <CartList
        cartDraggerOpen={cartDraggerOpen}
        onCloseCartDragger={() => setCartDraggerOpen(false)}
      />
      <QuickBuy
        quickBuyDraggerOpen={quickBuyDraggerOpen}
        onCloseQuickBuyDragger={() => setQuickBuyDraggerOpen(false)}
      />
      <CmacSideDragger
        open={responsiveNav}
        onClose={() => setResponsiveNav(false)}
        overlayBackground={"transparent"}
        style={{
          boxShadow: "#0000007d 0px 0px 50px 1px",
        }}
        className={"font_lato"}
        dragPosition={"left"}
        background={"var(--cmac-secondary-color)"}
      >
        <div
          style={{
            height: "100%",
            display: "flex",
            justifyContent: "center",
            padding: "40px",
            textDecoration: "none",
          }}
        >
          <div
            style={{
              position: "absolute",
              bottom: "20px",
              fontSize: "14px",
              fontWeight: 600,
            }}
          >
            ReviveRoots - © All Rights Reserved - 2024
          </div>
        </div>
      </CmacSideDragger>
    </div>
  );
};

export default Header;
