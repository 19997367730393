import { createSlice } from "@reduxjs/toolkit";

export const testQuestionsSlice = createSlice({
  name: "testQuestions",
  initialState: {
    loading: false,
    testQuestions: {},
    testQuestionsData: {},
    testQuestionsList: [],
    message: null,
    status: null,
    allowToast: false,
    reqFrom: null,
    statusAction: null,
  },
  reducers: {
    addTestQuestionsRequest(state) {
      return {
        ...state,
        loading: true,
      };
    },
    addTestQuestionsSuccess(state, action) {
      return {
        ...state,
        loading: false,
        message: action.payload.message,
        allowToast: action.payload.allowToast,
        status: "add-test-success",
      };
    },
    addTestQuestionsFail(state, action) {
      return {
        ...state,
        loading: false,
        message: action.payload.message,
        allowToast: action.payload.allowToast,
        status: "add-test-fail",
      };
    },
    addTemporaryTestQuestionsRequest(state) {
      return {
        ...state,
        loading: true,
      };
    },
    addTemporaryTestQuestionsSuccess(state, action) {
      return {
        ...state,
        loading: false,
        testQuestions: action.payload,
        message: false,
        allowToast: false,
        status: "success",
      };
    },
    addTemporaryTestQuestionsFail(state, action) {
      return {
        ...state,
        loading: false,
        message: false,
        allowToast: false,
        status: "fail",
      };
    },
    getTestQuestionsRequest(state) {
      return {
        ...state,
        loading: true,
      };
    },
    getTestQuestionsSuccess(state, action) {
      return {
        ...state,
        loading: false,
        message: action.payload.message,
        testQuestionsList: action.payload.data.testQuestions,
        status: "success",
      };
    },
    getTestQuestionsFail(state, action) {
      return {
        ...state,
        loading: false,
        message: action.payload.message,
        allowToast: action.payload.allowToast,
        status: "fail",
      };
    },
    getTestQuestionsDataRequest(state) {
      return {
        ...state,
        loading: true,
      };
    },
    getTestQuestionsDataSuccess(state, action) {
      return {
        ...state,
        loading: false,
        message: action.payload.message,
        testQuestionsData: action.payload.data.testQuestionsData,
        status: "success",
      };
    },
    getTestQuestionsDataFail(state, action) {
      return {
        ...state,
        loading: false,
        message: action.payload.message,
        allowToast: action.payload.allowToast,
        status: "fail",
      };
    },
    clearTestQuestions(state) {
      return {
        ...state,
        loading: false,
        testQuestions: {},
        testQuestionsList: [],
        message: null,
        status: null,
        reqFrom: null,
        statusAction: null,
      };
    },
    clearTestQuestionsActionStatus(state) {
      return {
        ...state,
        loading: false,
        status: null,
        allowToast: false,
        reqFrom: null,
        statusAction: null,
      };
    },
  },
});

const { actions, reducer } = testQuestionsSlice;

export const {
  addTestQuestionsRequest,
  addTestQuestionsFail,
  addTestQuestionsSuccess,
  addTemporaryTestQuestionsRequest,
  addTemporaryTestQuestionsFail,
  addTemporaryTestQuestionsSuccess,
  getTestQuestionsRequest,
  getTestQuestionsFail,
  getTestQuestionsSuccess,
  getTestQuestionsDataRequest,
  getTestQuestionsDataFail,
  getTestQuestionsDataSuccess,
  clearTestQuestions,
  clearTestQuestionsActionStatus,
} = actions;

export default reducer;
