import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getOrder } from "../../../../redux/actions/orderAction";
import { getQueryParam } from "../../../../helpers/getQueryParams";
import { LANDING_PAGE } from "../../../../helpers/paths";
import { currencyFormatter } from "../../../../helpers/currencyFormatter";

const OrderInvoiceTemplate = React.forwardRef(
  ({ scale, documentDimensions }, ref) => {
    console.log("scalescalescale: ", scale);

    const dispatch = useDispatch();
    const order_id = getQueryParam("order_id");
    const { order } = useSelector((state) => state.orderState);
    const { user } = useSelector((state) => state.userState);
    const [currentTime, setCurrentTime] = useState("");

    useEffect(() => {
      if (order_id) {
        dispatch(getOrder(order_id));
      } else {
        window.location.replace(LANDING_PAGE);
      }
    }, [order_id]);

    useEffect(() => {
      const updateCurrentTime = () => {
        const now = new Date();
        setCurrentTime(`Issued on: ${now.toLocaleString()}`);
      };

      updateCurrentTime();
      const timer = setInterval(updateCurrentTime, 1000);

      return () => clearInterval(timer);
    }, []);

    return (
      <div
        style={{
          width: "750px",
          background: "#fff",
          scale: scale.toString(),
          // marginTop: scale !== 1 && -(1 * (scale === 0.665 ? 0 : 120 / scale)),
        }}
      >
        <div
          ref={ref}
          style={{
            letterSpacing: "1px",
            fontFamily: "Lato",
            padding: "30px",
          }}
        >
          <div
            style={{ borderBottom: "5px solid black", paddingBottom: "20px" }}
          >
            <div style={{ height: "60px", width: "155px" }}>
              <img
                src="https://thereviveroots.com/static/media/revive.c533a1d7edc6cdb2606c.png"
                alt="logo"
                style={{ width: "135px", height: "55px" }}
              />
            </div>
            <div
              style={{
                paddingTop: "20px",
                fontSize: "14px",
                color: "rgba(128, 128, 128, 0.55)",
              }}
            >
              {currentTime}
            </div>
          </div>
          <div
            style={{
              marginTop: "20px",
              fontSize: "14px",
              paddingBottom: "20px",
              borderBottom: "2px solid rgba(128, 128, 128, 0.227)",
            }}
          >
            <div>
              <span style={{ fontWeight: "600" }}>Order ID:</span>
              <span>{order?._id}</span>
            </div>
            <div>
              <span style={{ fontWeight: "600" }}>User ID:</span>
              <span>{user?._id}</span>
            </div>
          </div>
          <table
            style={{
              marginTop: "20px",
              width: "100%",
              // borderCollapse: "collapse",
            }}
          >
            <thead>
              <tr>
                <th
                  style={{
                    borderBottom: "1px solid rgba(0, 0, 0, 0.082)",
                    padding: "20px 8px",
                    textAlign: "left",
                    backgroundColor: "#f2f2f2",
                  }}
                >
                  #
                </th>
                <th
                  style={{
                    borderBottom: "1px solid rgba(0, 0, 0, 0.082)",
                    padding: "20px 8px",
                    textAlign: "left",
                    backgroundColor: "#f2f2f2",
                  }}
                >
                  Item
                </th>
                <th
                  style={{
                    borderBottom: "1px solid rgba(0, 0, 0, 0.082)",
                    padding: "20px 8px",
                    textAlign: "right",
                    backgroundColor: "#f2f2f2",
                  }}
                >
                  Unit Price
                </th>
                <th
                  style={{
                    borderBottom: "1px solid rgba(0, 0, 0, 0.082)",
                    padding: "20px 8px",
                    textAlign: "right",
                    backgroundColor: "#f2f2f2",
                  }}
                >
                  Quantity
                </th>
                <th
                  style={{
                    borderBottom: "1px solid rgba(0, 0, 0, 0.082)",
                    padding: "20px 8px",
                    textAlign: "right",
                    backgroundColor: "#f2f2f2",
                  }}
                >
                  Item Total
                </th>
              </tr>
            </thead>
            <tbody>
              {order?.productsInOrder?.map((item, index) => (
                <tr key={index}>
                  <td>
                    <img
                      style={{
                        height: "80px",
                        width: "80px",
                        borderRadius: "10px",
                        objectFit: "contain",
                        scale: "0.8",
                      }}
                      src={item.productImages[0].url}
                      alt={item.productName}
                    />
                  </td>
                  <td
                    style={{
                      textAlign: "left",
                      fontWeight: "600",
                      width: "30%",
                      fontSize: "13px",
                      padding: "10px",
                    }}
                  >
                    {item.productName}
                  </td>
                  <td
                    style={{
                      width: "20%",
                      fontSize: "14px",
                      textAlign: "right",
                      padding: "10px",
                    }}
                  >
                    ₹{item.onAddSalePrice}
                  </td>
                  <td
                    style={{
                      width: "20%",
                      fontSize: "14px",
                      textAlign: "right",
                      padding: "10px",
                    }}
                  >
                    {item.quantity}
                  </td>
                  <td
                    style={{
                      width: "20%",
                      fontSize: "14px",
                      textAlign: "right",
                      padding: "10px",
                    }}
                  >
                    ₹{item.onAddSalePrice * item.quantity}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div
            style={{
              display: "flex",
              alignItems: "flex-end",
              flexDirection: "column",
              justifyContent: "flex-end",
            }}
          >
            <div
              style={{
                padding: "20px 0px",
                width: "40%",
                borderBottom: "1px solid rgba(0, 0, 0, 0.082)",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div style={{ fontSize: "16px", fontWeight: "600" }}>
                Sub Total
              </div>
              <div>
                Rs.{" "}
                {currencyFormatter(
                  Number(order?.orderSummary?.totalPrice),
                  "INR"
                )}
              </div>
            </div>
            <div
              style={{
                padding: "20px 0px",
                borderBottom: "1px solid rgba(0, 0, 0, 0.082)",
                width: "40%",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div style={{ fontSize: "16px", fontWeight: "600" }}>
                Shipping Charge
              </div>
              <div>
                Rs.{" "}
                {currencyFormatter(
                  Number(order?.orderSummary?.shippingCharge),
                  "INR"
                )}
              </div>
            </div>
            <div
              style={{
                padding: "20px 0px",
                borderBottom: "2px solid black",
                width: "40%",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div style={{ fontSize: "16px", fontWeight: "600" }}>GST</div>
              <div>
                Rs.{" "}
                {currencyFormatter(
                  Number(order?.orderSummary?.gstPrice),
                  "INR"
                )}
              </div>
            </div>
            <div
              style={{
                padding: "20px 0px",
                width: "40%",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div style={{ fontSize: "16px", fontWeight: "600" }}>Total</div>
              <div
                style={{
                  fontWeight: 600,
                }}
              >
                Rs.{" "}
                {currencyFormatter(
                  Number(order?.orderSummary?.totalAmountToPay),
                  "INR"
                )}
              </div>
            </div>
          </div>
          <div
            style={{
              textAlign: "center",
              fontSize: "14px",
              width: "100%",
              marginTop: "60px",
            }}
          >
            © 2024 Reviveroots, All rights reserved.
          </div>
        </div>
      </div>
    );
  }
);

export default OrderInvoiceTemplate;
