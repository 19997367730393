import { createSlice } from "@reduxjs/toolkit";

export const recentProductsSlice = createSlice({
  name: "recentProducts",
  initialState: {
    loading: false,
    recentProducts: [],
    message: null,
    status: null,
  },
  reducers: {
    recentProductsRequest(state, payload) {
      return {
        ...state,
        loading: true,
      };
    },
    recentProductsSuccess(state, action) {
      return {
        ...state,
        loading: false,
        recentProducts: action.payload.data.products,
        message: action.payload.message,
        status: "success",
      };
    },
    recentProductsFail(state, action) {
      return {
        ...state,
        loading: false,
        message: action.payload.message,
        status: "fail",
      };
    },
    clearRecentProducts(state) {
      return {
        ...state,
        loading: false,
        recentProducts: [],
        message: null,
        status: null,
      };
    },
  },
});

const { actions, reducer } = recentProductsSlice;

export const {
  recentProductsRequest,
  recentProductsFail,
  recentProductsSuccess,
  clearRecentProducts,
} = actions;

export default reducer;
