import React from "react";
import CmacCardSlider from "../../../plugins/cmac-plugins/cmac-card-slider/CmacCardSlider.jsx";
import { dummyProducts as productsData } from "../../landing-page/layouts/productsData.js";
import ProductContainer from "../../landing-page/layouts/ProductContainer.jsx";

const ProductsMultiCarousal = ({ heading, productsFeed }) => {
  const productsDataVal = productsFeed ? productsFeed : productsData;
  const products = productsDataVal?.map((data) => {
    return (
      <ProductContainer
        product={data}
        minWidth={250}
        imgHeight={250}
        imgWidth={250}
      />
    );
  });
  return (
    <div className="element_hug">
      <div className="element_container">
        <div className="f_18 f_weight_2 mt_2">{heading}</div>
        <CmacCardSlider style={{ marginTop: "20px" }} cardGap={20}>
          {products}
        </CmacCardSlider>
      </div>
    </div>
  );
};

export default ProductsMultiCarousal;
