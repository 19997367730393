import React from "react";
import { HiOutlineShoppingBag } from "react-icons/hi";
import { Link } from "react-router-dom";
import { AUTH_SERVICE_PAGE, SHOP_PAGE, TEST_QUESTIONS_PAGE } from "../../helpers/paths";
import { isNoShowRoute } from "../../helpers/isNoShowRoute";
import { useSelector } from "react-redux";
import { TbTestPipe } from "react-icons/tb";

const Topbar = () => {
  const { isAuthenticated } = useSelector((state) => state.userState);
  if (isNoShowRoute()) {
    return <></>;
  }
  return (
    <div className="topbar d_flex align_items_center">
      <div className="element_hug">
        <div className="element_container">
          <div className="d_flex align_items_center justify_content_space_between f_14 w_fill f_weight_2">
            <div className="d_flex align_items_center gap_6">
              {/* <div className="text_decoration_unset cmac_dark_fill_text opacity_0_4">
                About Us
              </div> */}
              <Link
                className="d_flex align_items_center gap_1 text_decoration_unset"
                to={SHOP_PAGE}
              >
                <HiOutlineShoppingBag
                  color="var(--cmac-primary-color)"
                  size={20}
                />
                <div className="cmac_dark_fill_text opacity_0_8">Shop</div>
              </Link>
              <a
                className="d_flex align_items_center gap_1 text_decoration_unset"
                href={TEST_QUESTIONS_PAGE}
              >
                <TbTestPipe
                  color="var(--cmac-primary-color)"
                  size={20}
                />
                <div className="cmac_dark_fill_text opacity_0_8">Hair Test</div>
              </a>
            </div>
            {!isAuthenticated && (
              <a
                className="text_decoration_unset cmac_dark_fill_text opacity_0_8"
                href={AUTH_SERVICE_PAGE}
              >
                Join Us
              </a>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Topbar;
