import React, { useEffect, useRef, useState } from "react";
import delivery_man from "../../../../assets/imgs/vectors/delivery_man.png";
import map from "../../../../assets/imgs/vectors/map.png";
import user_png from "../../../../assets/imgs/vectors/user.png";
import moment from "moment";
import { Link } from "react-router-dom";
import {
  ADDRESS_PAGE,
  ORDERS_PAGE,
  PROFILE_PAGE,
} from "../../../../helpers/paths";
import { useSelector } from "react-redux";

const AccountTabs = () => {
  const { user } = useSelector((state) => state.userState);
  const [productHeight, setProductHeight] = useState(0);
  const productRef = useRef()
  const tabs = [
    {
      id: 1,
      ic_img: user_png,
      tabName: "Profile",
      linkTo: PROFILE_PAGE,
      lastUpdated: moment(user?.updatedAt).format("MMM DD, YYYY, h:mm A"),
    },
    {
      id: 2,
      ic_img: delivery_man,
      tabName: "Orders",
      linkTo: ORDERS_PAGE,
      lastUpdated: moment().format("MMM DD, YYYY, h:mm A"),
    },
    {
      id: 3,
      ic_img: map,
      tabName: "Address",
      linkTo: ADDRESS_PAGE,
      lastUpdated: moment().format("MMM DD, YYYY, h:mm A"),
    },
  ];
  useEffect(() => {
    const updateHeight = () => {
      if (productRef.current) {
        setProductHeight(productRef.current.offsetWidth);
      }
    };
    const timeoutId = setTimeout(updateHeight, 0);
    window.addEventListener("resize", updateHeight);
    return () => {
      clearTimeout(timeoutId);
      window.removeEventListener("resize", updateHeight);
    };
  }, []);
  return (
    <div className="account_tabs">
      <div className="element_hug font_lato">
        <div className="element_container">
          <div className="d_flex align_items_center justify_content_center gap_2 flex_direction_col w_fill top_bottom_margin">
            <div className="f_20 f_weight_2">
              Welcome Back, {user?.firstName || ""}
            </div>
            <div className="f_14 opacity_0_4 f_weight_2 text_align_center">
              Your profile is your gateway to a personalized shopping
              experience.
            </div>
            <div className="mt_2 f_weight_2 primary_color text_align_center">
              Here, you can track your orders and manage your profile
              preferences
            </div>
          </div>
        </div>
      </div>
      <div className="tabs_section">
        <div className="element_hug font_lato">
          <div className="element_container d_flex align_items_center justify_content_center">
            <div className="tabs_content">
              {tabs?.map((data) => {
                return (
                  <Link
                    className="tab_content"
                    ref={productRef}
                    to={data?.linkTo}
                    style={{
                      height: productHeight,
                    }}
                  >
                    <div className="tab_ic_img_container">
                      <img
                        src={data?.ic_img}
                        alt={data?.tabName}
                        height={50}
                        width={50}
                      />
                    </div>
                    <div className="f_weight_2 text_align_center">
                      {data?.tabName}
                    </div>
                    <div className="f_10 f_weight_2 opacity_0_6 text_align_center">
                      Last Updated: {data?.lastUpdated}
                    </div>
                  </Link>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountTabs;
