import React from "react";
import CmacCardSlider from "../../../plugins/cmac-plugins/cmac-card-slider/CmacCardSlider.jsx";
import categoryImg01 from "../../../../assets/imgs/store-room/category_img_id_01.jpg";
import categoryImg02 from "../../../../assets/imgs/store-room/category_img_id_02.jpg";
import categoryImg03 from "../../../../assets/imgs/store-room/category_img_id_03.jpg";
import categoryImg04 from "../../../../assets/imgs/store-room/category_img_id_04.jpg";
import categoryImg05 from "../../../../assets/imgs/store-room/category_img_id_05.jpg";
import categoryImg06 from "../../../../assets/imgs/store-room/category_img_id_06.jpg";
import CmacSliderCardContainer from "../../../plugins/cmac-plugins/cmac-card-slider/CmacSliderCard.jsx";
import { useNavigate } from "react-router-dom";
import { SHOP_PAGE } from "../../../../helpers/paths.js";
const CategoryMultiCarousal = ({ heading }) => {
  const navigate = useNavigate();
  const categoriesData = [
    {
      id: 1,
      category: "Hair Serum",
      desc: "Nourishing hair serums designed to add shine and smoothness while protecting against damage.",
      img: categoryImg01,
    },
    {
      id: 2,
      category: "Hair Oil",
      desc: "Luxurious hair oils that deeply moisturize and strengthen hair from root to tip.",
      img: categoryImg02,
    },
    {
      id: 4,
      category: "Shampoo",
      desc: "Gentle, sulfate-free shampoos that cleanse while maintaining natural moisture balance.",
      img: categoryImg03,
    },
    {
      id: 5,
      category: "Hair Butter",
      desc: "Rich and creamy hair butters that provide intense hydration and nourishment for dry, damaged hair.",
      img: categoryImg04,
    },
    {
      id: 6,
      category: "Hair Cream",
      desc: "Lightweight hair creams that tame frizz, define curls, and provide flexible hold.",
      img: categoryImg05,
    },
    {
      id: 7,
      category: "Skin Care Essentials",
      desc: "Essential skincare products designed to rejuvenate, hydrate, and protect your skin.",
      img: categoryImg06,
    },
  ];

  const categories = categoriesData?.map((data) => {
    return (
      <CmacSliderCardContainer className={"cursor_pointer"}>
        <div
          onClick={() => navigate(`${SHOP_PAGE}?c=${data?.category}`)}
          className="d_flex align_items_center justify_content_center flex_direction_col gap_3"
          style={{
            minWidth: "300px",
          }}
        >
          <img
            src={data.img}
            alt={data.category}
            height={200}
            width={300}
            style={{
              borderBottom: "4px solid var(--cmac-primary-color)",
            }}
          />
          <div className="f_18 f_weight_2 text_transform_uppercase">
            {data.category}
          </div>
          <div
            className="text_align_center f_14 f_weight2 opacity_0_6"
            style={{ height: "60px" }}
          >
            {data.desc}
          </div>
        </div>
      </CmacSliderCardContainer>
    );
  });
  return (
    <div className="element_hug">
      <div className="element_container">
        <div className="f_20 f_weight_2 mt_2">{heading}</div>
        <CmacCardSlider style={{ marginTop: "20px" }} cardGap={20}>
          {categories}
        </CmacCardSlider>
      </div>
    </div>
  );
};

export default CategoryMultiCarousal;
