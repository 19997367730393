import React from "react";
import NestedRoute from "./layouts/NestedRoute";

const Account = () => {
  return (
    <div className="account">
      <NestedRoute />
    </div>
  );
};

export default Account;
