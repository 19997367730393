import { createSlice } from "@reduxjs/toolkit";

export const secretCodeSlice = createSlice({
  name: "secretCode",
  initialState: {
    loading: false,
    message: null,
    status: null,
    allowToast: false,
    info: {},
  },
  reducers: {
    secretCodeRequest(state, payload) {
      return {
        ...state,
        loading: true,
        allowToast: false,
      };
    },
    secretCodeSuccess(state, action) {
      return {
        ...state,
        loading: false,
        message: action.payload.message,
        info: action.payload.info,
        status: "success",
        allowToast: action.payload.allowToast,
      };
    },
    secretCodeFail(state, action) {
      return {
        ...state,
        loading: false,
        message: action.payload.message,
        status: "fail",
        allowToast: action.payload.allowToast,
      };
    },
    clearSecretCodeActionStatus(state) {
      return {
        ...state,
        loading: false,
        status: null,
        allowToast: false,
      };
    },
  },
});

const { actions, reducer } = secretCodeSlice;

export const {
  secretCodeRequest,
  secretCodeFail,
  secretCodeSuccess,
  clearSecretCodeActionStatus,
} = actions;

export default reducer;
