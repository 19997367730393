import React, { useEffect } from "react";
import Profile from "./Profile";
import { Outlet, useNavigate } from "react-router-dom";
import { ACCOUNT_TABS_PAGE, PROFILE_PAGE } from "../../../../helpers/paths";

const NestedRoute = () => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate(ACCOUNT_TABS_PAGE);
  }, []);
  return (
    <div className="outlet">
      <Outlet />
    </div>
  );
};

export default NestedRoute;
