import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
  name: "user",
  initialState: {
    isAuthenticated: false,
    loading: false,
    dataFetchInitiated: true,
    user: [],
    message: null,
    status: null,
    allowToast: false,
  },
  reducers: {
    userRequest(state, payload) {
      return {
        ...state,
        loading: true,
        allowToast: false,
      };
    },
    userSuccess(state, action) {
      return {
        ...state,
        loading: false,
        user: action.payload.user,
        message: action.payload.message,
        status: "success",
        allowToast: action.payload.allowToast,
        isAuthenticated: true,
        dataFetchInitiated: false,
      };
    },
    userFail(state, action) {
      return {
        ...state,
        loading: false,
        message: action.payload.message,
        status: "fail",
        allowToast: action.payload.allowToast,
        isAuthenticated: false,
        dataFetchInitiated: false,
      };
    },
    updateProfileRequest(state, payload) {
      return {
        ...state,
        loading: true,
        allowToast: false,
      };
    },
    updateProfileSuccess(state, action) {
      return {
        ...state,
        loading: false,
        user: action.payload.user,
        message: action.payload.message,
        status: "success",
        allowToast: action.payload.allowToast,
        isAuthenticated: true,
        dataFetchInitiated: false,
      };
    },
    updateProfileFail(state, action) {
      return {
        ...state,
        loading: false,
        message: action.payload.message,
        status: "fail",
        allowToast: action.payload.allowToast,
        isAuthenticated: false,
        dataFetchInitiated: false,
      };
    },
    clearUser(state) {
      return {
        ...state,
        loading: false,
        user: [],
        message: null,
        status: null,
        dataFetchInitiated: true,
      };
    },
    clearUserActionStatus(state) {
      return {
        ...state,
        loading: false,
        status: null,
        allowToast: false,
        dataFetchInitiated: false,
      };
    },
  },
});

const { actions, reducer } = userSlice;

export const {
  userRequest,
  userFail,
  userSuccess,
  updateProfileRequest,
  updateProfileFail,
  updateProfileSuccess,
  addUserRequest,
  addUserFail,
  addUserSuccess,
  clearUser,
  clearUserActionStatus,
} = actions;

export default reducer;
