import { combineReducers, configureStore } from "@reduxjs/toolkit";
import thunk from "redux-thunk";
import toastsReducer from "../redux/slices/toastsSlice";
import secretCodeReducer from "../redux/slices/secretCodeSlice";
import userReducer from "../redux/slices/userSlice";
import productReducer from "../redux/slices/productSlice";
import categoryProductsReducer from "../redux/slices/categoryProductsSlice";
import recentProductsReducer from "../redux/slices/recentProductsSlice";
import similarProductsReducer from "../redux/slices/similarProductsSlice";
import popularProductsReducer from "../redux/slices/popularProductsSlice";
import recommendedProductsReducer from "../redux/slices/recommendedProductsSlice";
import productsReducer from "../redux/slices/productsSlice";
import quickBuyProductsReducer from "../redux/slices/quickBuyProductsSlice";
import cartReducer from "../redux/slices/cartSlice";
import orderReducer from "../redux/slices/orderSlice";
import ordersReducer from "../redux/slices/ordersSlice";
import postalReducer from "../redux/slices/postalSlice";
import addressReducer from "../redux/slices/addressSlice";
import reviewReducer from "../redux/slices/reviewSlice";
import testQuestionsReducer from "../redux/slices/testQuestionsSlice";
import printOrderInvoiceReducer from "../redux/slices/printOrderInvoiceSlice";

const reducer = combineReducers({
  toastsState: toastsReducer,
  secretCodeState: secretCodeReducer,
  userState: userReducer,
  productState: productReducer,
  productsState: productsReducer,
  categoryProductsState: categoryProductsReducer,
  recentProductsState: recentProductsReducer,
  similarProductsState: similarProductsReducer,
  popularProductsState: popularProductsReducer,
  recommendedProductsState: recommendedProductsReducer,
  quickBuyProductsState: quickBuyProductsReducer,
  cartState: cartReducer,
  orderState: orderReducer,
  ordersState: ordersReducer,
  postalState: postalReducer,
  addressState: addressReducer,
  reviewState: reviewReducer,
  testQuestionsState: testQuestionsReducer,
  printOrderInvoiceState: printOrderInvoiceReducer,
});

const store = configureStore({
  reducer,
  middleware: [thunk],
});

export default store;
