import styled from "styled-components";

export const StyledButton = styled.button`
  height: 100%;
  position: relative;
  display: inline-block;
  color: #fff;
  background-color: var(--cmac-secondary-color);
  border-radius: 0.3rem;
  font-size: 1rem;
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.144);
  border: 2px solid transparent;
  overflow: hidden;
  outline: none;
  cursor: pointer;
`;
