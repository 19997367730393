import React, { useEffect, useRef, useState } from "react";
import { AUTH_PAGE, PRODUCT_DETAILS_PAGE } from "../../../helpers/paths";
import { Link } from "react-router-dom";
import { currencyFormatter } from "../../../helpers/currencyFormatter";
import { useDispatch, useSelector } from "react-redux";
import { getProduct } from "../../../redux/actions/productAction";
import { addCart, addTempCart } from "../../../redux/actions/cartAction";
import { clearCartActionStatus } from "../../../redux/slices/cartSlice";
import { PiWarningCircleFill } from "react-icons/pi";
import { IoCheckmarkCircle } from "react-icons/io5";
import { useToast } from "../../plugins/toaster/useToast";
import InfinityLoader from "../../../assets/imgs/loaders/InfinityLoader";

const ProductCategoryList = ({
  handleProductCategoryListShow,
  productCategoryListData,
}) => {
  const productRef = useRef();
  const dispatch = useDispatch();
  const { isAuthenticated } = useSelector((state) => state.userState);
  const {
    loading: cartLoading,
    reqFrom,
    allowToast,
    message,
    status: cartStatus,
  } = useSelector((state) => state.cartState);
  const toast = useToast();
  const [addCartItem, setAddCartItem] = useState("");
  const handleCartCreation = (product) => {
    setAddCartItem(product);
    const payload = {
      productId: product._id,
      quantity: 1,
      selectedSize: product?.availableSizes[0],
    };
    if (isAuthenticated) {
      dispatch(addCart(payload, true, "productInfoPage"));
    } else {
      dispatch(addTempCart(payload, true, "productInfoPage"));
    }
  };
  const displayToast = (icon, toastMessage) => {
    return (
      <div className="add_cart_toast height-30 display-flex">
        <div className="display-flex align-items-center">{icon}</div>
        <div className="toast_message">{toastMessage}</div>
      </div>
    );
  };
  useEffect(() => {
    switch (cartStatus) {
      case "success": {
        if (allowToast) {
          toast.open(
            displayToast(
              <IoCheckmarkCircle color="#37cd00" size={30} />,
              message
            ),
            {
              toastWidth: "fit-content",
              toastMsgWidth: "fit-content",
            }
          );
        }
        dispatch(clearCartActionStatus());
        break;
      }
      case "fail": {
        if (allowToast) {
          toast.open(
            displayToast(
              <PiWarningCircleFill color="#f00" size={30} />,
              message
            ),
            {
              toastWidth: "fit-content",
              toastMsgWidth: "fit-content",
            }
          );
        }
        dispatch(clearCartActionStatus());
        break;
      }
      default: {
        // dispatch(clearCartActionStatus());
        break;
      }
    }
  }, [cartStatus]);
  return (
    <div className="product_category_list">
      <div className="element_hug">
        <div className="element_container">
          <div className="pt_2 pb_2 d_flex gap_6">
            <div className="sub_categories">
              {productCategoryListData?.products?.map((data) => {
                return (
                  <Link
                    to={`${PRODUCT_DETAILS_PAGE}?product_id=${data?._id}`}
                    className="sub_category cursor_pointer cmac_dark_fill_text text_decoration_unset"
                    onClick={() => {
                      handleProductCategoryListShow();
                      dispatch(getProduct({ productId: data?._id }));
                      window.scrollTo({
                        top: 0,
                        behavior: "smooth",
                      });
                    }}
                  >
                    {data?.alternateName}
                  </Link>
                );
              })}
            </div>
            <div className="seperator_line"></div>
            <div className="products_list">
              {productCategoryListData?.products?.map((product, i) => {
                if (i <= 2)
                  return (
                    <Link
                      to={`${PRODUCT_DETAILS_PAGE}?product_id=${product?._id}`}
                      className="_item"
                      style={{
                        textDecoration: "none",
                        height: "500px",
                      }}
                      ref={productRef}
                      onClick={() => {
                        handleProductCategoryListShow();
                        dispatch(getProduct({ productId: product?._id }));
                        window.scrollTo({
                          top: 0,
                          behavior: "smooth",
                        });
                      }}
                    >
                      {(product?.isDiscountedProduct === "true" ||
                        product?.isDiscountedProduct === true) && (
                        <div
                          style={{
                            height: 0,
                          }}
                        >
                          <div
                            className="pos_relative z_index_1 d_flex align_items_center justify_content_center flex_direction_col f_weight_2 f_14 border_rad_50_cover cmac_primary_color_25_bg"
                            style={{
                              height: 50,
                              width: 50,
                              top: "10px",
                              left: "10px",
                              color: "#000",
                            }}
                          >
                            <div>{product?.discountPercentage}%</div>
                            <div className="f_10">OFF</div>
                          </div>
                        </div>
                      )}
                      <div
                        className="_img_container mb_2"
                        style={{
                          height: "250px",
                          backgroundImage: "unset",
                          background: "#ebebeb",
                        }}
                      >
                        <img
                          src={product?.productImages[0]?.url}
                          alt={product._id}
                        />
                      </div>
                      <p>{product.productName}</p>
                      <div
                        className="d_flex align_items_center mt_1 price"
                        style={{
                          color: "var(--cmac-ternary-color-dark)",
                        }}
                      >
                        <div
                          className="d_flex align_items_center gap_2"
                          style={{
                            fontSize: "14px",
                            fontWeight: "600",
                          }}
                        >
                          <div>
                            Rs. {currencyFormatter(product.salePrice, "INR")}
                          </div>
                          {(product?.isDiscountedProduct === "true" ||
                            product?.isDiscountedProduct === true) && (
                            <div className="text_decoration_line_through opacity_0_4 f_12">
                              Rs. {currencyFormatter(product.salePrice, "INR")}
                            </div>
                          )}
                        </div>
                      </div>
                      <button
                        className="add_cart_btn mt_2"
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          handleCartCreation(product);
                        }}
                        disabled={cartLoading}
                        style={{
                          opacity:
                            cartLoading && reqFrom === "productInfoPage"
                              ? "0.5"
                              : "1",
                          cursor:
                            cartLoading && reqFrom === "productInfoPage"
                              ? "default"
                              : "pointer",
                        }}
                      >
                        {cartLoading &&
                        reqFrom === "productInfoPage" &&
                        addCartItem._id === product._id ? (
                          <div className="d_flex align_items_center justify_content_center gap_3">
                            <InfinityLoader
                              height={35}
                              width={35}
                              stroke="var(--cmac-default-shade-fill)"
                            />
                            <div>Please Wait!</div>
                          </div>
                        ) : (
                          "Add To Cart"
                        )}
                      </button>
                    </Link>
                  );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductCategoryList;
