import { useDispatch, useSelector } from "react-redux";
import { updateToasts } from "../../../redux/actions/toastsAction";

export const useToast = () => {
  const dispatch = useDispatch();
  const { toasts } = useSelector((state) => state.toastsState);

  const open = (
    message,
    {
      toastWidth = "330px",
      toastMsgWidth = "298px",
      backgroundColor = "var(--cmac-default-shade-fill)",
      timeoutSeconds = 3000,
    } = {}
  ) => {
    const newToast = {
      id: toasts?.length + 1,
      message,
      toastWidth,
      toastMsgWidth,
      backgroundColor,
      timeoutSeconds,
    };

    dispatch(updateToasts([newToast, ...toasts]));
    return { id: newToast.id, message };
  };

  const close = (id) => {
    const newToasts = toasts.filter((toast) => toast.id !== id);
    dispatch(updateToasts(newToasts));
  };
  return { open, close };
};
