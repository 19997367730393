import React from "react";
import { LANDING_PAGE } from "../../../../helpers/paths";
import brand from "../../../../assets/imgs/brand/revive.png";

const Topbar = () => {
  return (
    <div className="test_questions_topbar">
      <div
        className="d_flex"
        style={{
          height: "100%",
          paddingLeft: "20px",
          paddingRight: "20px",
        }}
      >
        <a
          href={LANDING_PAGE}
          className="d_flex align_items_center cursor_pointer _brand_container"
          style={{
            width: "fit-content",
            textDecoration: "none",
            fontWeight: 600,
            display: "flex",
            alignItems: "center",
            gap: "13px",
          }}
        >
          <img src={brand} alt="brand" height={45} />
        </a>
        <div className="element_hug start">
          <div className="element_container"></div>
        </div>
      </div>
    </div>
  );
};

export default Topbar;
