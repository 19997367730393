import React from "react";
import CombainedForms from "./layouts/CombainedForms";
import Topbar from "./layouts/Topbar";

const TestQuestionsPage = () => {
  return (
    <div className="test_questions_page">
      <Topbar />
      <CombainedForms />
    </div>
  );
};

export default TestQuestionsPage;
