import React, { useEffect, useState } from "react";
import { getQueryParam } from "../../../../helpers/getQueryParams";
import { clearProduct } from "../../../../redux/slices/productSlice";
import { getProduct } from "../../../../redux/actions/productAction";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  ORDER_MANAGEMENT_PAGE,
  PRODUCT_DETAILS_PAGE,
  PRODUCT_OVERVIEW_PAGE,
} from "../../../../helpers/paths";
import moment from "moment";
import {
  getOrder,
  updateOrderStatus,
} from "../../../../redux/actions/orderAction";
import DataTable from "react-data-table-component";
import EmptyDataState from "../../../plugins/EmptyDataState";
import { currencyFormatter } from "../../../../helpers/currencyFormatter";
import CmacModal from "../../../plugins/cmac-plugins/CmacModal";
const OrderOverview = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const orderId = getQueryParam("order_id");
  const currentDate = new Date().toISOString().split("T")[0];
  const [shippmentStatusModel, setShippmentStatusModel] = useState(false);
  const [trackingId, setTrackingId] = useState("");
  const [transistName, setTransistName] = useState("");
  const [currentLocation, setCurrentLocation] = useState("");
  const [estimatedDelivery, setEstimatedDelivery] = useState(currentDate);
  const { order } = useSelector((state) => state.orderState);
  const orderDetailsColumns = [
    {
      name: "Product",
      cell: (row) => {
        return (
          <div
            className="d_flex align_items_center gap_3"
            onClick={() =>
              navigate(`${PRODUCT_OVERVIEW_PAGE}?product_id=${row.productId}`)
            }
          >
            <img
              src={row?.productImages[0]?.url}
              alt={row?.productId}
              height={50}
              width={50}
              style={{
                backgroundColor: "var(--cmac-management-outer-color)",
                padding: "5px",
                objectFit: "contain",
                borderRadius: "8px",
              }}
            />
            <div className="f_12 f_weight_1">
              <div className="multi_line_2">{row?.productName}</div>
              <div
                className="multi_line_2 f_10 opacity_0_8"
                style={{
                  marginTop: "2px",
                }}
              >
                {row?.productCategory}
              </div>
            </div>
          </div>
        );
      },
      minWidth: "350px",
    },
    {
      name: "Size",
      selector: (row) => (
        <div
          onClick={() =>
            navigate(`${PRODUCT_OVERVIEW_PAGE}?product_id=${row.productId}`)
          }
          className="f_12 f_weight_1"
        >
          {row?.selectedSize}
        </div>
      ),
    },
    {
      name: "Price",
      selector: (row) => (
        <div
          onClick={() =>
            navigate(`${PRODUCT_OVERVIEW_PAGE}?product_id=${row.productId}`)
          }
          className="f_12 f_weight_1"
        >
          {currencyFormatter(row?.onAddSalePrice, "INR")} INR
        </div>
      ),
    },
    {
      name: "Qty",
      selector: (row) => (
        <div
          className="f_12 f_weight_1"
          onClick={() =>
            navigate(`${PRODUCT_OVERVIEW_PAGE}?product_id=${row.productId}`)
          }
        >
          {row?.quantity} Item(s)
        </div>
      ),
    },
  ];
  const priceDetailsColumns = [
    {
      name: "Price (Without GST)",
      cell: (row) => {
        return (
          <div className="f_weight_1">
            {currencyFormatter(row?.totalPrice, "INR")} INR
          </div>
        );
      },
    },
    {
      name: "GST",
      cell: (row) => {
        return (
          <div className="f_weight_1">
            {currencyFormatter(row?.gstPrice, "INR")} INR
          </div>
        );
      },
    },
    {
      name: "Shipping Charge",
      cell: (row) => {
        return (
          <div className="f_weight_1">
            {currencyFormatter(row?.shippingCharge, "INR")} INR
          </div>
        );
      },
    },
    {
      name: "Total Price",
      cell: (row) => {
        return (
          <div className="f_weight_1 f_16">
            {currencyFormatter(row?.totalAmountToPay, "INR")} INR
          </div>
        );
      },
    },
  ];
  const handleShippmentStatus = (e) => {
    if (e === "shipped") {
      order?.shippingAddress?.trackingId &&
        setTrackingId(order?.shippingAddress?.trackingId);
      order?.shippingAddress?.transistName &&
        setTransistName(order?.shippingAddress?.transistName);
      order?.shippingAddress?.currentLocation &&
        setCurrentLocation(order?.shippingAddress?.currentLocation);
      order?.estimatedDeliveryOn &&
        setEstimatedDelivery(
          new Date(order?.estimatedDeliveryOn)?.toISOString().split("T")[0]
        );
      setShippmentStatusModel(true);
    } else {
      // dispatch()
    }
  };
  const handleShippmentStatusSave = () => {
    const payload = {
      trackingId,
      currentLocation,
      transistName,
      estimatedDelivery,
      orderId,
      shipmentStatus: "shipped"
    };
    dispatch(updateOrderStatus(payload));
    setShippmentStatusModel(false);
  };
  useEffect(() => {
    if (!orderId) {
      navigate(ORDER_MANAGEMENT_PAGE);
    }
    dispatch(clearProduct());
    dispatch(getOrder(orderId));
  }, []);
  return (
    <div className="product_overview">
      <div className="product_overview_header ">
        <div className="w_fill">
          <div className="d_flex align_items_center justify_content_space_between">
            <div>
              <div className="heading">ORDER #{order?._id}</div>
              <div className="d_flex align_items_center gap_2 f_12 mt_1">
                <div>Placed On:</div>
                <div className="f_weight_1">
                  {moment(order?.createdAt).format("DD, MMM YYYY HH:MM A")}
                </div>
              </div>
            </div>
            <div>
              <div className="status_input_container">
                <div>Status:</div>
                <select onChange={(e) => handleShippmentStatus(e.target.value)}>
                  <option disabled>Processed</option>
                  <option value={"shipped"}>Shipped</option>
                  <option value={"completed"}>Completed</option>
                  <option value={"canceled"}>Canceled</option>
                </select>
              </div>
            </div>
          </div>
          <div className="order_details_table_flex mt_5">
            <div className="order_details_table_container">
              <div className="heading f_18 mb_2">Order Details</div>
              <DataTable
                data={order?.productsInOrder}
                columns={orderDetailsColumns}
                noDataComponent={
                  <EmptyDataState message={"There are no records to display"} />
                }
                onRowClicked={(e) =>
                  window.location.replace(
                    `${PRODUCT_OVERVIEW_PAGE}?product_id=${e.productId}`
                  )
                }
                fixedHeader={true}
                fixedHeaderScrollHeight="40vh"
                className="data_table_comp"
              />
              <div className="heading f_18 mt_3 mb_2">Price Details</div>
              <DataTable
                data={
                  Object.keys(order)?.length > 0 ? [order?.orderSummary] : []
                }
                columns={priceDetailsColumns}
                noDataComponent={
                  <EmptyDataState message={"There are no records to display"} />
                }
                fixedHeader={true}
                fixedHeaderScrollHeight="40vh"
                className="data_table_comp"
              />
            </div>
            <div className="customer_details_container">
              <div className="heading f_18 mb_2">Customer Details</div>
              <div className="details_container">
                <div>
                  <div className="f_12 opacity_0_6">Name</div>
                  <div
                    className="f_weight_2"
                    style={{
                      marginTop: "3px",
                      fontSize: "13px",
                    }}
                  >
                    {order?.shippingAddress?.firstName}{" "}
                    {order?.shippingAddress?.lastName}
                  </div>
                </div>
                <div>
                  <div className="f_12 opacity_0_6">Email</div>
                  <div
                    className="f_weight_2"
                    style={{
                      marginTop: "3px",
                      fontSize: "13px",
                    }}
                  >
                    {order?.shippingAddress?.email}
                  </div>
                </div>
                <div>
                  <div className="f_12 opacity_0_6">Mobile Number</div>
                  <div
                    className="f_weight_2"
                    style={{
                      marginTop: "3px",
                      fontSize: "13px",
                    }}
                  >
                    {order?.shippingAddress?.mobileNumber}
                  </div>
                </div>
                <div>
                  <div className="f_12 opacity_0_6">Shipping Address</div>
                  <div
                    className="f_weight_2"
                    style={{
                      marginTop: "3px",
                      fontSize: "13px",
                    }}
                  >
                    {order?.shippingAddress?.addressLine1},{" "}
                    {order?.shippingAddress?.location},{" "}
                    {order?.shippingAddress?.district},{" "}
                    {order?.shippingAddress?.state},{" "}
                    {order?.shippingAddress?.country}{" "}
                    {order?.shippingAddress?.postalCode}
                  </div>
                </div>
                {order?.shippingAddress?.addressLine2 && (
                  <div>
                    <div className="f_12 opacity_0_6">
                      Additional Shipping Address
                    </div>
                    <div
                      className="f_weight_2"
                      style={{
                        marginTop: "3px",
                        fontSize: "13px",
                      }}
                    >
                      {order?.shippingAddress?.addressLine2}
                    </div>
                  </div>
                )}
                <div>
                  <div className="f_12 opacity_0_6">Payment Method</div>
                  <div
                    className="f_weight_2"
                    style={{
                      marginTop: "3px",
                      fontSize: "13px",
                    }}
                  >
                    {order?.paymentInfo?.paymentMethod}
                  </div>
                </div>
                <div>
                  <div className="f_12 opacity_0_6">Payment Status</div>
                  <div
                    className="f_weight_2"
                    style={{
                      marginTop: "3px",
                      fontSize: "13px",
                      textTransform: "capitalize",
                    }}
                  >
                    {order?.paymentInfo?.paymentStatus}
                  </div>
                </div>
                {order?.shippingAddress?.trackingId && (
                  <div>
                    <div className="f_12 opacity_0_6">Tracking Id</div>
                    <div
                      className="f_weight_2"
                      style={{
                        marginTop: "3px",
                        fontSize: "13px",
                        textTransform: "capitalize",
                      }}
                    >
                      {order?.shippingAddress?.trackingId}
                    </div>
                  </div>
                )}
                {order?.shippingAddress?.transistName && (
                  <div>
                    <div className="f_12 opacity_0_6">Transist Name</div>
                    <div
                      className="f_weight_2"
                      style={{
                        marginTop: "3px",
                        fontSize: "13px",
                        textTransform: "capitalize",
                      }}
                    >
                      {order?.shippingAddress?.transistName}
                    </div>
                  </div>
                )}
                {order?.shippingAddress?.currentLocation && (
                  <div>
                    <div className="f_12 opacity_0_6">Current Location</div>
                    <div
                      className="f_weight_2"
                      style={{
                        marginTop: "3px",
                        fontSize: "13px",
                        textTransform: "capitalize",
                      }}
                    >
                      {order?.shippingAddress?.currentLocation}
                    </div>
                  </div>
                )}
                {order?.estimatedDeliveryOn && (
                  <div>
                    <div className="f_12 opacity_0_6">
                      Estimated Delivery Name
                    </div>
                    <div
                      className="f_weight_2"
                      style={{
                        marginTop: "3px",
                        fontSize: "13px",
                        textTransform: "capitalize",
                      }}
                    >
                      {moment(order?.estimatedDeliveryOn).format(
                        "DD MMM, YYYY"
                      )}{" "}
                      9:00PM
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <CmacModal
        active={shippmentStatusModel}
        onClickOutsider={() => {
          setShippmentStatusModel(false);
        }}
        portalUsage={true}
        blurStrength="stronger"
        style={{
          width: "650px",
          color: "var(--cmac-dark-fill)",
          backgroundColor: "var(--cmac-default-shade-fill)",
        }}
        className={"shipping_status_inputs_container"}
      >
        <div className="f_20 f_weight_2">Shipping Status</div>
        <div className="d_flex gap_2 mt_3">
          <div className="w_fill">
            <div className="label">Tracking Id</div>
            <input
              placeholder="Enter Tracking Id"
              value={trackingId}
              onChange={(e) => {
                const value = e.target.value;
                setTrackingId(value);
              }}
            />
          </div>
          <div className="w_fill">
            <div className="label">Transist Name</div>
            <input
              placeholder="Enter Transist Name"
              value={transistName}
              onChange={(e) => {
                const value = e.target.value;
                setTransistName(value);
              }}
            />
          </div>
        </div>
        <div className="d_flex gap_2 mt_1">
          <div className="w_fill">
            <div className="label">Current Location</div>
            <input
              placeholder="Enter Current Location"
              value={currentLocation}
              onChange={(e) => {
                const value = e.target.value;
                setCurrentLocation(value);
              }}
            />
          </div>
          <div className="w_fill">
            <div className="label">Estimated Delivery</div>
            <input
              min={currentDate}
              type="date"
              placeholder="Enter Transist Name"
              value={estimatedDelivery}
              onChange={(e) => {
                const value = e.target.value;
                setEstimatedDelivery(value);
              }}
            />
          </div>
        </div>
        <div className="d_flex align_items_center justify_content_flex_end gap_2 mt_1">
          <button
            onClick={() => {
              setShippmentStatusModel(false);
            }}
            style={{
              background: "unset",
            }}
          >
            Cancel
          </button>
          <button onClick={() => handleShippmentStatusSave()}>Save</button>
        </div>
      </CmacModal>
    </div>
  );
};

export default OrderOverview;
