import { createSlice } from "@reduxjs/toolkit";

export const printOrderInvoiceSlice = createSlice({
  name: "printOrderInvoice",
  initialState: {
    loading: false,
    message: null,
    status: null,
    allowToast: false,
    reqFrom: null,
    statusAction: null,
  },
  reducers: {
    printOrderInvoiceRequest(state) {
      return {
        ...state,
        loading: true,
        statusAction: "get-printOrderInvoice-req",
      };
    },
    printOrderInvoiceSuccess(state, action) {
      return {
        ...state,
        loading: false,
        message: action.payload.message,
        status: "success",
        statusAction: "get-printOrderInvoice-success",
      };
    },
    printOrderInvoiceFail(state, action) {
      return {
        ...state,
        loading: false,
        message: action.payload.message,
        status: "fail",
        statusAction: "get-printOrderInvoice-fail",
      };
    },
    clearPrintOrderInvoice(state) {
      return {
        ...state,
        loading: false,
        message: null,
        status: null,
        reqFrom: null,
        statusAction: null,
      };
    },
    clearPrintOrderInvoiceActionStatus(state) {
      return {
        ...state,
        loading: false,
        status: null,
        allowToast: false,
        reqFrom: null,
        statusAction: null,
      };
    },
  },
});

const { actions, reducer } = printOrderInvoiceSlice;

export const {
  printOrderInvoiceRequest,
  printOrderInvoiceFail,
  printOrderInvoiceSuccess,
  clearPrintOrderInvoice,
  clearPrintOrderInvoiceActionStatus,
} = actions;

export default reducer;
