import React, { useEffect, useRef, useState } from "react";
import { FaAngleDown, FaPlus } from "react-icons/fa";

const CustomDropdown = ({
  value,
  placeholder,
  onSelect,
  className,
  options,
  onAddOption,
  noOptionContent,
  addOptionValue,
  disabled,
}) => {

  const [isOpen, setIsOpen] = useState(false);
  const [optionInput, setOptionInput] = useState("");
  const [filteredOptions, setFilteredOptions] = useState([]);
  const dropdownRef = useRef(null);
  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleSelect = (option) => {
    setOptionInput(option);
    onSelect(option);
    setIsOpen(false);
  };

  const handleInputChange = (e) => {
    setOptionInput(e.target.value);
    if (String(e.target.value) === "") {
      setFilteredOptions(options);
    } else {
      const filteredOptionValues = options?.filter((option) => {
        const optionValues = String(option).toLowerCase();
        return optionValues?.includes(e.target.value?.toLowerCase());
      });
      setFilteredOptions(filteredOptionValues);
    }
  };
  const addOption = () => {
    const trimmedOption = optionInput.trim();
    if (trimmedOption && !options.includes(trimmedOption) && addOptionValue) {
      onAddOption && onAddOption([...options, trimmedOption]);
      setFilteredOptions([...options, trimmedOption]);
      setOptionInput("");
    }
  };
  const handleKeyDown = (e) => {
    if (e.key === "Enter" || e.key === ",") {
      e.preventDefault();
      addOption();
    }
  };
  useEffect(() => {
    setOptionInput(value);
    if (value) {
      const trimmedOption = value.trim();
      if (trimmedOption && !options.includes(trimmedOption)) {
        onAddOption && onAddOption([...options, trimmedOption]);
        setFilteredOptions([...options, trimmedOption]);
      }
    }
  }, [value]);
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current?.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);
  useEffect(() => {
    setFilteredOptions(options);
  }, [options]);
  return (
    <div
      className="custom-dropdown"
      ref={dropdownRef}
    >
      {addOptionValue && optionInput && (
        <div className="res-add-key">
          <div className="icon-container">
            <FaPlus className="ic-plus" onClick={addOption} />
          </div>
        </div>
      )}
      {!addOptionValue && (
        <div className="res-dropdown-ic">
          <div className="icon-container">
            <FaAngleDown
              className={`ic ${isOpen && "dropdown-open"}`}
              onClick={handleToggle}
              style={{
                opacity: disabled ? "0.8" : "1",
              }}
            />
          </div>
        </div>
      )}
      <input
        value={optionInput}
        className={`dropdown-toggle ${className}`}
        placeholder={placeholder}
        onClick={handleToggle}
        onKeyDown={handleKeyDown}
        onChange={handleInputChange}
        disabled={disabled ? true : false}
        style={{
          cursor: disabled ? "default" : "pointer",
        }}
      />
      {isOpen && (
        <ul className="dropdown-menu">
          {filteredOptions.length > 0 ? (
            filteredOptions?.map((option) => {
              return <li onClick={() => handleSelect(option)}>{option}</li>;
            })
          ) : (
            <li>{noOptionContent ? noOptionContent : "No options found"}</li>
          )}
        </ul>
      )}
    </div>
  );
};

export default CustomDropdown;
