import { createSlice } from "@reduxjs/toolkit";

export const cartSlice = createSlice({
  name: "cart",
  initialState: {
    loading: false,
    cart: [],
    message: null,
    status: null,
    allowToast: false,
    reqFrom: null,
    statusAction: null,
  },
  reducers: {
    cartRequest(state, action) {
      return {
        ...state,
        loading: true,
        allowToast: false,
        reqFrom: action.payload.reqFrom,
        statusAction: "get-cart-req",
      };
    },
    cartSuccess(state, action) {
      return {
        ...state,
        loading: false,
        cart: action.payload.data.cart,
        message: action.payload.message,
        status: "success",
        statusAction: "get-cart-success",
      };
    },
    cartFail(state, action) {
      return {
        ...state,
        loading: false,
        message: action.payload.message,
        status: "fail",
        statusAction: "get-cart-fail",
      };
    },
    addCartRequest(state, action) {
      return {
        ...state,
        loading: true,
        reqFrom: action.payload.reqFrom,
      };
    },
    addCartSuccess(state, action) {
      return {
        ...state,
        loading: false,
        cart: action.payload.data.cart,
        message: action.payload.message,
        allowToast: action.payload.allowToast,
        status: "success",
      };
    },
    addCartFail(state, action) {
      return {
        ...state,
        loading: false,
        message: action.payload.message,
        allowToast: action.payload.allowToast,
        status: "fail",
      };
    },
    quickBuyAddCartRequest(state, action) {
      return {
        ...state,
        loading: true,
        reqFrom: action.payload.reqFrom,
        statusAction: "quick-buy-cart-req",
      };
    },
    quickBuyAddCartSuccess(state, action) {
      return {
        ...state,
        loading: false,
        status: "success",
        statusAction: "quick-buy-cart-success",
      };
    },
    quickBuyAddCartFail(state, action) {
      return {
        ...state,
        loading: false,
        status: "fail",
        statusAction: "quick-buy-cart-fail",
      };
    },
    clearCart(state) {
      return {
        ...state,
        loading: false,
        cart: [],
        message: null,
        status: null,
        reqFrom: null,
        statusAction: null,
      };
    },
    clearCartActionStatus(state) {
      return {
        ...state,
        loading: false,
        status: null,
        allowToast: false,
        reqFrom: null,
        statusAction: null,
      };
    },
  },
});

const { actions, reducer } = cartSlice;

export const {
  cartRequest,
  cartFail,
  cartSuccess,
  addCartRequest,
  addCartFail,
  addCartSuccess,
  quickBuyAddCartRequest,
  quickBuyAddCartFail,
  quickBuyAddCartSuccess,
  clearCart,
  clearCartActionStatus,
} = actions;

export default reducer;
