import React from "react";
import OrderInvoiceTemplate from "./layouts/OrderInvoiceTemplate";
import PrintPDF from "../../plugins/PtintPdf";

const OrderInvoicePrintPage = ({documentDimensions}) => {
  return (
    <div>
      <PrintPDF
        documentDimensions={documentDimensions}
        printDoc={(ref, scale) => (
          <OrderInvoiceTemplate ref={ref} scale={scale} documentDimensions={documentDimensions}/>
        )}
      />
    </div>
  );
};

export default OrderInvoicePrintPage;
