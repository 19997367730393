import { createSlice } from "@reduxjs/toolkit";

export const productsSlice = createSlice({
  name: "products",
  initialState: {
    loading: false,
    products: [],
    totalPages: 0,
    productsCount: 0,
    currentPage: 0,
    message: null,
    status: null,
  },
  reducers: {
    productsRequest(state, payload) {
      return {
        ...state,
        loading: true,
      };
    },
    productsSuccess(state, action) {
      return {
        ...state,
        loading: false,
        products: action.payload.data.products,
        totalPages: action.payload.data.totalPages,
        productsCount: action.payload.data.productsCount,
        currentPage: action.payload.data.currentPage,
        message: action.payload.message,
        status: "success",
      };
    },
    productsFail(state, action) {
      return {
        ...state,
        loading: false,
        message: action.payload.message,
        status: "fail",
      };
    },
    clearProducts(state) {
      return {
        ...state,
        loading: false,
        products: [],
        totalPages: 0,
        productsCount: 0,
        currentPage: 0,
        message: null,
        status: null,
      };
    },
  },
});

const { actions, reducer } = productsSlice;

export const {
  productsRequest,
  productsFail,
  productsSuccess,
  clearProducts,
} = actions;

export default reducer;
