import {
  AUTH_SECRET_CODE_PAGE,
  AUTH_PAGE,
  AUTH_SERVICE_PAGE,
  CHECKOUT_PAGE,
  MANAGEMENT_PAGE,
  PRODUCT_MANAGEMENT_PAGE,
  PRODUCT_OVERVIEW_PAGE,
  ORDER_MANAGEMENT_PAGE,
  ORDER_OVERVIEW_PAGE,
  TEST_QUESTIONS_PAGE,
  PRINT_ORDER_INVOICE_PAGE,
  TEST_MANAGEMENT_PAGE,
  TEST_OVERVIEW_PAGE,
} from "./paths";

const noShowRoutes = [
  CHECKOUT_PAGE,
  MANAGEMENT_PAGE,
  PRODUCT_MANAGEMENT_PAGE,
  PRODUCT_OVERVIEW_PAGE,
  ORDER_MANAGEMENT_PAGE,
  ORDER_OVERVIEW_PAGE,
  AUTH_PAGE,
  AUTH_SERVICE_PAGE,
  AUTH_SECRET_CODE_PAGE,
  TEST_QUESTIONS_PAGE,
];
const pathname = window.location.pathname;
export const isNoShowRoute = () => {
  if (
    noShowRoutes?.length > 0 &&
    noShowRoutes?.some((data) => data === pathname)
  ) {
    return true;
  } else {
    return false;
  }
};
