import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { isNoShowRoute } from "../../helpers/isNoShowRoute";
import ProductCategoryList from "./layouts/ProductCategoryList";
import { useDispatch, useSelector } from "react-redux";
import { getCategoryProducts } from "../../redux/actions/productsAction";

const Categorybar = () => {
  const dispatch = useDispatch();
  const { categoryProducts } = useSelector(
    (state) => state.categoryProductsState
  );

  const formatProductsByCategory = () => {
    const formattedData = {};
  
    // Group products by category
    categoryProducts.forEach((product) => {
      const category = product.productCategory;
      const productDetails = {
        ...product,
      };
      if (formattedData[category]) {
        formattedData[category].push(productDetails);
      } else {
        formattedData[category] = [productDetails];
      }
    });
  
    const result = Object.keys(formattedData).reduce(
      (acc, category) => {
        if (formattedData[category]?.length === 1) {
          acc.moreProducts.push(...formattedData[category]);
        } else {
          acc.categories.push({
            category,
            products: formattedData[category],
          });
        }
        return acc;
      },
      { categories: [], moreProducts: [] }
    );
  
    if (result.moreProducts.length > 0) {
      result.categories.push({
        category: "More Products",
        products: result.moreProducts,
      });
    }
  
    return result.categories;
  };
  

  const categorybarRef = useRef(null);
  const [productCategoryListData, setProductCategoryListData] = useState("");
  const [productCategoryListShow, setProductCategoryListShow] = useState(false);

  useEffect(() => {
    dispatch(getCategoryProducts());
  }, []);

  if (isNoShowRoute()) {
    return <></>;
  }
  return (
    <div className="categorybar res-max-768px-d-none">
      <div className="product_category_list_down">
        <div
          className={`elementor_container ${
            productCategoryListShow ? "visible" : "default"
          }`}
          onMouseLeave={() => {
            setProductCategoryListData("");
            setProductCategoryListShow(false);
          }}
        >
          <ProductCategoryList
            handleProductCategoryListShow={() => {
              setProductCategoryListData("");
              setProductCategoryListShow(false);
            }}
            productCategoryListData={productCategoryListData}
          />
        </div>
      </div>
      <div className="d_flex align_items_center links_elementor_container">
        <div className="element_hug ">
          <div className="element_container">
            <div
              className="d_flex justify_content_center gap_9 f_14 text_wrap_nowrap links_container"
              ref={categorybarRef}
            >
              {formatProductsByCategory()?.map((data) => {
                return (
                  <Link
                    key={data?.category}
                    className="cmac_default_fill_text text_decoration_unset text_transform_uppercase link"
                    onMouseOver={() => {
                      setProductCategoryListData(data);
                      setProductCategoryListShow(true);
                    }}
                  >
                    {data?.category}
                    {productCategoryListData?.category === data?.category && (
                      <div className="active_indicator"></div>
                    )}
                  </Link>
                );
              })}
              {/* <div className="pos_absolute vertical_menu_dots">
              <HiOutlineDotsVertical />
            </div> */}
            </div>
            {/* <div className="dropdown_pos">
            <div className="dropdown_container">
              {overflowLinks.map((data) => {
                if (data)
                  return (
                    <Link className="text_decoration_unset text_transform_uppercase">
                      {data}
                    </Link>
                  );
              })}
            </div>
          </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Categorybar;
