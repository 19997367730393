import { StyledInput } from "./styled-components/input/input.styles";
import { StyledButton } from "./styled-components/button/button.styles";
import "../utils/styles/cmac.styles.utils.css";
import { FaHeart, FaSearch } from "react-icons/fa";

/*** Element Exports ***/

/* Input */
export const Input = ({
  containerStyle,
  style,
  className,
  id,
  mode,
  onChange,
  onClick,
  onKeyDown,
  onKeyUp,
  onKeyDownCapture,
  onKeyUpCapture,
  placeholder,
  placeholderColor,
  value,
  type,
  disabled,
}) => {
  const handleClick = (e) => {
    onClick && onClick(e);
  };
  return (
    <div className="cm-aTjKpL" style={{ ...containerStyle }}>
      <StyledInput
        id={id}
        className={`${className ? `${className}` : ""} ${placeholderColor}`}
        value={value}
        style={{ ...style }}
        onChange={(e) => onChange && onChange(e)}
        onClick={(e) => handleClick(e)}
        onKeyDown={(e) => onKeyDown && onKeyDown(e)}
        onKeyUp={(e) => onKeyUp && onKeyUp(e)}
        onKeyDownCapture={(e) => onKeyDownCapture && onKeyDownCapture(e)}
        onKeyUpCapture={(e) => onKeyUpCapture && onKeyUpCapture(e)}
        placeholder={placeholder}
        type={type}
        disabled={disabled}
      />
      {mode === "search" ? (
        <div className="cm-pqOpQj">
          <div className="d_flex align_items_center justify_content_center _ic_search_container">
            <FaSearch size={20} color="var(--cmac-dark-fill)"/>
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

/******************************************/

/* Checkbox Input  */

export const CheckboxInput = ({
  containerStyle,
  style,
  className,
  id,
  isChecked,
  children,
  onChange,
  onClick,
  onKeyDown,
  onKeyUp,
  onKeyDownCapture,
  onKeyUpCapture,
  placeholder,
  placeholderColor,
  value,
  disabled,
}) => {
  const handleClick = (e) => {
    onClick && onClick(e);
  };
  return (
    <div className="cm-aEoKqL" style={{ ...containerStyle }}>
      <input
        type="checkbox"
        checked={isChecked}
        id={id}
        className={`${className ? `${className}` : ""} ${placeholderColor}`}
        value={value}
        style={{ ...style }}
        onChange={(e) => onChange && onChange(e)}
        onClick={(e) => handleClick(e)}
        onKeyDown={(e) => onKeyDown && onKeyDown(e)}
        onKeyUp={(e) => onKeyUp && onKeyUp(e)}
        onKeyDownCapture={(e) => onKeyDownCapture && onKeyDownCapture(e)}
        onKeyUpCapture={(e) => onKeyUpCapture && onKeyUpCapture(e)}
        placeholder={placeholder}
        disabled={disabled}
      />
      {children}
    </div>
  );
};

/******************************************/

/* Error */

export const Error = () => {
  return (
    <div className="cm-zRmXnK">
      <div className="_error"></div>
    </div>
  );
};
/******************************************/

/* Heading Design */

export const HeadingDesign = ({
  title,
  fontSize,
  fontColor,
  lineColor,
  icColor,
  lineWidth,
  lineHeight,
  fontWeigth,
}) => {
  return (
    <div className="cm-zRmGtK">
      <div className="_heading">
        <h3
          style={{
            fontSize: fontSize && fontSize,
            color: fontColor ? fontColor : "black",
            fontWeigth: fontWeigth ? fontWeigth : "400",
          }}
        >
          {title && title}
        </h3>
        <div className="_line_hug">
          <div
            style={{
              backgroundColor: lineColor ? lineColor : "blue",
              width: lineWidth ? lineWidth : "50px",
              height: lineHeight ? lineHeight : "2.5px",
            }}
          ></div>
          <div className="_ic_container">
            <FaHeart className="_ic" color={icColor ? icColor : "black"} />
          </div>
          <div
            style={{
              backgroundColor: lineColor ? lineColor : "blue",
              width: lineWidth ? lineWidth : "50px",
              height: lineHeight ? lineHeight : "2.5px",
            }}
          ></div>
        </div>
      </div>
    </div>
  );
};

/******************************************/

/* Button */
export const Button = ({
  children,
  containerStyle,
  style,
  className,
  id,
  onChange,
  onClick,
  onKeyDown,
  onKeyUp,
  onKeyDownCapture,
  onKeyUpCapture,
  type,
  disabled,
}) => {
  const handleBtnClick = (e) => {
    onClick && onClick(e);
    let overlay = document.createElement("span");
    overlay.classList.add("_f1e1439d");
    e.target.appendChild(overlay);
    let xValue = e.clientX - e.target.offsetLeft;
    let yValue = e.clientY - e.target.offsetTop;
    overlay.style.left = xValue + "px";
    overlay.style.top = yValue + "px";
    setTimeout(() => {
      overlay.remove();
    }, 700);
  };
  return (
    <div className="cm-yPqLnW" style={{ ...containerStyle }}>
      <StyledButton
        id={id}
        className={`${className ? `${className}` : ""}`}
        style={{ ...style }}
        onChange={(e) => onChange && onChange(e)}
        onClick={(e) => handleBtnClick(e)}
        onKeyDown={(e) => onKeyDown && onKeyDown(e)}
        onKeyUp={(e) => onKeyUp && onKeyUp(e)}
        onKeyDownCapture={(e) => onKeyDownCapture && onKeyDownCapture(e)}
        onKeyUpCapture={(e) => onKeyUpCapture && onKeyUpCapture(e)}
        type={type}
        disabled={disabled}
      >
        {children}
      </StyledButton>
    </div>
  );
};
