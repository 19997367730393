import React, { useEffect, useState } from "react";
import { Input } from "../../../utils/Elements";
import { useDispatch, useSelector } from "react-redux";
import { createTemporaryTestQuestions } from "../../../../redux/actions/testQuestionsAction";

const FormGamma = ({ selectedLink, selectedSubLink, handleSelectChange }) => {
  const [email, setEmail] = useState("");
  const { testQuestions } = useSelector((state) => state.testQuestionsState);
  const dispatch = useDispatch();
  const onNext = () => {
    const payload = {
      ...testQuestions,
      13: { link: 1, sublink: 3, value: email, key: "email" },
    };
    dispatch(createTemporaryTestQuestions(payload));
  };
  useEffect(() => {
    if (testQuestions[13] && testQuestions[3]["value"]) {
      setEmail(testQuestions[13]["value"]);
    }
  }, [testQuestions]);
  return (
    <div className="form_alpha">
      <div className="heading f_weight_1">Email</div>
      <div className="interactions_container">
        <div className="input_container">
          <div className="label">Could you share your Email?</div>
          <Input
            className={"font_lato mt_2"}
            placeholder={"Your Email"}
            containerStyle={{
              width: "-webkit-fill-available",
            }}
            style={{
              width: "-webkit-fill-available",
            }}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
                email && onNext();
                email && handleSelectChange(selectedLink, selectedSubLink + 1);
              }
            }}
          />
        </div>
        <div className="mt_2 interactions_btn_container">
          <button
            className="interaction_btn_alpha"
            onClick={() =>
              handleSelectChange(selectedLink, selectedSubLink - 1)
            }
          >
            Previous
          </button>
          <button
            className="interaction_btn_beta"
            onClick={() => {
              email && onNext();
              email && handleSelectChange(selectedLink, selectedSubLink + 1);
            }}
            style={{
              transition: "all 0.5s ease-in-out",
              opacity: !email && "0.2",
            }}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default FormGamma;
