import { createSlice } from "@reduxjs/toolkit";

export const postalSlice = createSlice({
  name: "postal",
  initialState: {
    loading: false,
    postalData: [],
    message: null,
    status: null,
    allowToast: false,
  },
  reducers: {
    postalDataRequest(state, payload) {
      return {
        ...state,
        loading: true,
        allowToast: false
      };
    },
    postalDataSuccess(state, action) {
      return {
        ...state,
        loading: false,
        postalData: action.payload.PostOffice,
        message: action.payload.message,
        status: "success",
      };
    },
    postalDataFail(state, action) {
      return {
        ...state,
        loading: false,
        message: action.payload.message,
        status: "fail",
      };
    },
  },
});

const { actions, reducer } = postalSlice;

export const {
  postalDataRequest,
  postalDataFail,
  postalDataSuccess,
} = actions;

export default reducer;
