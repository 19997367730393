const testQuestions = [
  {
    id: 1,
    gender: "male",
    questions: [
      {
        id: 2,
        category: "Hair Health",
        qs: [
          {
            id: 1,
            q: "Do you have a family history of hair loss?",
            subHeading: "Do you have a family history of hair loss?",
            options: ["Father / Mother", "⁠Both", "⁠None"],
          },
          {
            id: 2,
            q: "Have you experienced any of the below?",
            subHeading: "Have you experienced any of the below?",
            options: [
              "⁠None",
              "⁠Have u hospitalized for injury / illness  / surgery ?",
              "Weight loss/  Weight gain",
              "Are you using commercial hair dye ?",
            ],
          },
          {
            id: 3,
            q: "Do you have dandruff?",
            subHeading: "Do you have dandruff?",
            options: [
              "None",
              "Yes mild that comes and goes",
              "Yes heavy dandruff that stices to the scalp",
              "I have psoriasis",
              "I have seborrheic dermatitis",
            ],
          },
        ],
      },
      {
        id: 3,
        category: "Your Lifestyle",
        qs: [
          {
            id: 1,
            q: "How well do you sleep?",
            subHeading: "How well do you sleep?",
            options: [
              "Very peacefully for 6 to 8 hours",
              "Disturbed sleep i wake up at least one time during the day",
              "⁠Have difficulty falling asleep",
            ],
          },
          {
            id: 2,
            q: "How stressed are you?",
            subHeading: "How stressed are you?",
            options: [
              "⁠None",
              "Low",
              "Moderate ( work family etc )",
              "High (loss of close one , separation, home , illness )",
            ],
          },
          {
            id: 3,
            q: "Are you currently dealing with any of these health conditions?",
            subHeading:
              "Are you currently dealing with any of these health conditions?",
            options: [
              "None",
              "Anemia (low iron , haemoglobin)",
              "For sleep",
              "Stress or depression",
              "Asthma / sinus",
            ],
          },
          {
            id: 4,
            q: "Do you feel constipated?",
            subHeading: "Do you feel constipated?",
            options: [
              "No / Rarely",
              "Yes",
              "Unsatisfactory bowel movements",
              "Suffering from ibs ( irritable bowel syndrome) / dysentery",
            ],
          },
          {
            id: 5,
            q: "How are you enery level?",
            subHeading: "How are you enery level?",
            options: [
              "Always high",
              "Low when i wake up , but gradually increase",
              "⁠Very low in afternoon",
              "Low by evening/ night",
              "⁠Always low",
            ],
          },
          {
            id: 6,
            q: "Do you have gas , acidity or bloating?",
            subHeading: "Do you have gas , acidity or bloating?",
            options: ["Yes", "No"],
          },
          {
            id: 7,
            q: "Are you currently taking any supplements or vitamins for hair?",
            subHeading:
              "Are you currently taking any supplements or vitamins for hair?",
            options: ["Yes", "No", "Not sure"],
          },
        ],
      },
      {
        id: 4,
        category: "Scalp Assessment",
        qs: [
          {
            id: 1,
            q: "Upload scalp photo which will be used by doctor after you purchase the plan.",
            subHeading: "Upload scalp photo which will be used by doctor.",
            options: ["Nill"],
            external: "Upload Photo",
          },
        ],
      },
    ],
  },
  {
    id: 2,
    gender: "female",
    questions: [
      {
        id: 2,
        category: "Hair Health",
        qs: [
          {
            id: 1,
            q: "What does your hair look like naturally?",
            subHeading: "What does your hair look like naturally?",
            options: ["Straight", "Wavy", "Curly"],
          },
          {
            id: 2,
            q: "What is your most important goal currently?",
            subHeading: "What is your most important goal currently?",
            options: [
              "Control hairfall",
              "Regrow hair",
              "⁠Improve hair Quality",
              "Hair well being",
              "Control dandruff / itching",
            ],
          },
          {
            id: 3,
            q: "Do you feel like you’re facing hair fall more than usual?",
            subHeading:
              "Do you feel like you’re facing hair fall more than usual?",
            options: [
              "Yes extreme hair fall",
              "⁠Mild hairfall",
              "No hair fall",
            ],
          },
          {
            id: 4,
            q: "Describe your hair quality.",
            subHeading: "Describe your hair quality.",
            options: [
              "Good hair Quality",
              "Hair breakage-Split ends",
              "Hair thinning",
            ],
          },
          {
            id: 5,
            q: "How would you describe your hair damage?",
            subHeading: "How would you describe your hair damage?",
            options: [
              "Dull hair",
              "⁠Frizzy hair",
              "Tangles easily with knots",
              "None",
            ],
          },
          {
            id: 6,
            q: "What does a single strand of your hair feel like ?",
            subHeading: "What does a single strand of your hair feel like ?",
            options: ["Thin", "⁠Medium", "Thick"],
          },
          {
            id: 7,
            q: "Is hair loss a genetric issue in your family?",
            subHeading: "Is hair loss a genetric issue in your family?",
            options: ["Mother / Father", "Both", "None"],
          },
          {
            id: 8,
            q: "How long after hair wash does your hair start facing issue?",
            subHeading:
              "How long after hair wash does your hair start facing issue?",
            options: ["Within 24 hours", "⁠2-3 days", "⁠More than 4 days"],
          },
          {
            id: 9,
            q: "Describe your dandruff.",
            subHeading: "Describe your dandruff.",
            options: [
              "No",
              "Yes mild that comes & goes",
              "Yes heavy dandruff that sticks to the scalp",
              "⁠I have psoriasis",
              "⁠I have seborrheic deratitis",
            ],
          },
        ],
      },
      {
        id: 3,
        category: "Your Lifestyle",
        qs: [
          {
            id: 1,
            q: "Are you going through any of these stages?",
            subHeading: "Are you going through any of these stages?",
            options: [
              "None",
              "⁠Planning to get pregnant",
              "Pregnant",
              "⁠Post pregnancy ( baby is less than 2 years old",
              "Menopause",
            ],
          },
          {
            id: 2,
            q: "Have you experienced any of these below in last 1 year?",
            subHeading:
              "Have you experienced any of these below in last 1 year?",
            options: [
              "None",
              "⁠Severe illness / surgery / medication",
              "Weight loss or sudden weight gain",
              "Surgery or on heavy medication",
            ],
          },
          {
            id: 3,
            q: "Are you going through any of the below?",
            subHeading: "Are you going through any of the below?",
            options: ["None", "Anemia ( low haemoglobin )", "Thyroid / pcod"],
          },
          {
            id: 4,
            q: "Are u taking tablets for any reason?",
            subHeading: "Are u taking tablets for any reason?",
            options: ["Thyroid / pcod", "Sinus / asthma", "Or for any reasons"],
          },
          {
            id: 5,
            q: "How well do you sleep?",
            subHeading: "How well do you sleep?",
            options: [
              "Very peacefully for 6 to 8 hours",
              "Disturbed sleep i wake up at least one time during the day",
              "⁠Have difficulty falling asleep",
            ],
          },
          {
            id: 6,
            q: "How stressed are you?",
            subHeading: "How stressed are you?",
            options: [
              "⁠None",
              "Low",
              "Moderate ( work family etc )",
              "High (loss of close one , separation, home , illness )",
            ],
          },
          {
            id: 7,
            q: "Do you feel constipated?",
            subHeading:
              "Do you feel constipated?",
            options: [
              "None",
              "Yes",
              "Ii have ibs"
            ],
          },
          {
            id: 8,
            q: "Do you have gas , acidity or bloating?",
            subHeading: "Do you have gas , acidity or bloating?",
            options: ["Yes", "No"],
          },
          {
            id: 9,
            q: "Describe you energy levels.",
            subHeading: "Describe you energy levels.",
            options: [
              "Always high",
              "Low when i wake up , but gradually increase",
              "⁠Very low in afternoon",
              "Low by evening/ night",
              "⁠Always low",
            ],
          },
        ],
      },
      {
        id: 4,
        category: "Scalp Assessment",
        qs: [
          {
            id: 1,
            q: "Upload scalp photo which will be used by doctor after you purchase the plan.",
            subHeading: "Upload scalp photo which will be used by doctor.",
            options: ["Nill"],
            external: "Upload Photo",
          },
        ],
      },
    ],
  },
];

export { testQuestions };
