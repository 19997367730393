import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { proceedTrigger } from "../../../../../redux/slices/resCartSlice";
import { FaShoppingBasket } from "react-icons/fa";
import { FaTruck } from "react-icons/fa";
import { BsFillBoxSeamFill } from "react-icons/bs";
import { IoChatbubbles, IoLocation } from "react-icons/io5";
// import { getOrder } from "../../../../../redux/actions/orderAction";
import moment from "moment";
import { RxCross2 } from "react-icons/rx";
import { PiCurrencyInrBold } from "react-icons/pi";
import { useNavigate } from "react-router-dom";
// import { LOCKED_CLOTH_PAGE } from "../../../../../helpers/route-paths/paths";
import { IoPrint } from "react-icons/io5";
import { currencyFormatter } from "../../../helpers/currencyFormatter";
import { getQueryParam } from "../../../helpers/getQueryParams";
// import { printInvoice } from "../../../../../redux/actions/printInvoiceAction";
// import Loader from "react-js-loader";
import {
  getOrder,
  printOrderInvoice,
} from "../../../redux/actions/orderAction";
import { Input } from "../../utils/Elements";
import PrintPDF from "../../plugins/PtintPdf";
import OrderInvoiceTemplate from "./layouts/OrderInvoiceTemplate";
import { PRINT_ORDER_INVOICE_PAGE } from "../../../helpers/paths";
const OrderTrakingPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const trigger = getQueryParam("proceed");
  const order_id = getQueryParam("order_id");
  const { loading } = useSelector((state) => state.printOrderInvoiceState);
  const { user } = useSelector((state) => state.userState);
  // const { proceed } = useSelector((state) => state.resCartState);
  // const { products } = useSelector((state) => state.productsState);
  const { order } = useSelector((state) => state.orderState);
  const [orderStatusValues, setOrderStatusValues] = useState(["3", "3", "3"]);
  const order_status_values = [
    {
      status: "processing",
      label: "Order Placed",
      bg: "#fe2d5a29",
      color: "#fe2d5a",
    },
    {
      status: "shipped",
      label: "In Transist",
      bg: "#feb70126",
      color: "#feb701",
    },
    {
      status: "delivered",
      label: "Out For Delivery",
      bg: "#00a86b26",
      color: "#00A86B",
    },
  ];
  const getOrderStatus = (statusFor) => {
    switch (statusFor) {
      case "o-p":
        if (orderStatusValues[0] === "1") {
          return "passed";
        } else if (orderStatusValues[0] === "2") {
          return "active";
        } else if (orderStatusValues[0] === "3") {
          return "pending";
        }
        return "unknown";

      case "i-t":
        if (orderStatusValues[1] === "1") {
          return "passed";
        } else if (orderStatusValues[1] === "2") {
          return "active";
        } else if (orderStatusValues[1] === "3") {
          return "pending";
        }
        return "unknown";
      case "o-f-d":
        if (orderStatusValues[2] === "1") {
          return "passed delivered";
        } else if (orderStatusValues[2] === "2") {
          return "active";
        } else if (orderStatusValues[2] === "3") {
          return "pending";
        }
        return "unknown";
      default:
        return "unknown";
    }
  };
  // useEffect(() => {
  //   dispatch(proceedTrigger(trigger));
  // }, [trigger]);
  useEffect(() => {
    if (order_id) {
      dispatch(getOrder(order_id));
    }
  }, []);
  useEffect(() => {
    switch (order?.shippingAddress?.shipmentStatus) {
      case "processing":
        setOrderStatusValues(["1", "2", "3"]);
        break;
      case "shipped":
        setOrderStatusValues(["1", "1", "2"]);
        break;
      case "delivered":
        setOrderStatusValues(["1", "1", "1"]);
        break;
      default:
        setOrderStatusValues(["3", "3", "3"]);
        break;
    }
  }, [order]);

  return (
    <div className="order-status font_lato">
      <div className="element_hug">
        <div className="order-status-container element_container">
          <div className="mt_1 mb_5">
            <div className="f_size_3 f_weight_1">Track Your Package</div>
            <div className="d_flex align_items_center gap_2 mt_2">
              <div className="f_14 f_weight_1">Estimated Delivery:</div>
              <div className="f_14">
                {moment(order?.estimatedDeliveryOn).format("MMM DD, YYYY")}
              </div>
            </div>
            {order?.shippingAddress?.trackingId && (
              <div className="d_flex align_items_center gap_2 mt_1">
                <div className="f_14 f_weight_1">Tracking Id:</div>
                <div className="f_14">{order?.shippingAddress?.trackingId}</div>
              </div>
            )}
            {order?.shippingAddress?.currentLocation && (
              <div className="d_flex align_items_center gap_2 mt_1 mb_2">
                <div className="f_14 f_weight_1">Current Location:</div>
                <div className="f_14">
                  {order?.shippingAddress?.currentLocation}
                </div>
              </div>
            )}
            <div className="d_flex align_items_center justify_content_space_between">
              <div
                className="order-status-value"
                style={{
                  background: order_status_values.find(
                    (value) =>
                      value?.status === order?.shippingAddress?.shipmentStatus
                  )?.bg,
                  color: order_status_values.find(
                    (value) =>
                      value?.status === order?.shippingAddress?.shipmentStatus
                  )?.color,
                }}
              >
                {order?.shippingAddress?.shipmentStatus}
              </div>
              <div className="f_12 primary-color cursor_pointer f_weight_1 text_decoration_underline">
                How will my item be delivered?
              </div>
            </div>
          </div>
          <div className="order-status-info d_flex align_items_center justify_content_center">
            <div className="order-status-info-content d_flex align_items_center justify_content_space_between w_fill">
              <div className="status">
                <div className="d_flex align_items_center justify_content_center">
                  <div className={`icon ${getOrderStatus("o-p")}`}>
                    <FaShoppingBasket />
                  </div>
                </div>
                <div className="f_12 mt_2 text_align_center f_weight_1">
                  Order Placed
                </div>
                <div className="f_12 mt_1 text_align_center">
                  {moment(order?.createdAt).format("MMM DD, YYYY")}
                </div>
              </div>
              <div
                className={`status-bar ${getOrderStatus("i-t")} in-progress
              `}
              ></div>
              <div className="status d_flex align_items_center flex_direction_col">
                <div className="d_flex align_items_center justify_content_center">
                  <div className={`icon ${getOrderStatus("i-t")}`}>
                    <FaTruck />
                  </div>
                </div>
                <div className="f_12 mt_2 text_align_center f_weight_1">
                  In Transist
                </div>
                <div className="f_12 mt_1 text_align_center f_weight_1">
                  {order?.shippingAddress?.transistName
                    ? order?.shippingAddress?.transistName
                    : "N/A"}
                </div>
                {order?.shippingAddress?.currentLocation && (
                  <div className="f_12 mt_1 text_align_center d_flex align_items_center gap_1 f_weight_1">
                    <IoLocation size={18} />
                    {order?.shippingAddress?.currentLocation
                      ? order?.shippingAddress?.currentLocation
                      : "N/A"}
                  </div>
                )}
              </div>
              <div
                className={`status-bar ${getOrderStatus("o-f-d")} in-progress
              `}
              ></div>
              <div className="status ">
                <div className="d_flex align_items_center justify_content_center">
                  <div className={`icon ${getOrderStatus("o-f-d")}`}>
                    <BsFillBoxSeamFill />
                  </div>
                </div>
                <div className="f_12 mt_2 text_align_center f_weight_1">
                  Out For Delivery
                </div>
                <div className="f_12 mt_1 text_align_center">
                  Estimated:{" "}
                  {moment(order?.estimatedDeliveryOn).format("MMM DD, YYYY")}
                </div>
              </div>
            </div>
          </div>
          <div className="order-info">
            <div className="order-product-details">
              <div className="order-details">
                <div className="order-details-heading">Order Details</div>
                <div className="p_3 d_flex align_items_center justify_content_space_between res-max-576px-f-direction-c res-max-576px-align-items-s gap_3">
                  <div>
                    <div className="d_flex align_items_center gap_2">
                      <div className="f_14 res-font f_weight_1">
                        Date Orderd:
                      </div>
                      <div className="f_14 res-font">
                        {moment(order?.createdAt).format("MMM DD, YYYY")}
                      </div>
                    </div>
                    <div className="d_flex align_items_center gap_2">
                      <div className="f_14 res-font f_weight_1">Order Id:</div>
                      <div className="f_14 res-font">{order?._id}</div>
                    </div>
                  </div>
                  <div>
                    <a
                      style={{
                        background: "var(--cmac-default-shade-fill)",
                        color: false ? "#fff" : "#000",
                        width: false && "200px",
                        transition: "width ease 0.5s",
                        opacity: false ? 0.5 : 1,
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        border: "1px solid #00000010",
                      }}
                      className="print-inv-btn res-font d_flex align_items_center justify_content_center gap_2"
                      href={`${PRINT_ORDER_INVOICE_PAGE}?order_id=${order?._id}`}
                    >
                      <div
                        className="d_flex align_items_center justify_content_center"
                        style={{ marginTop: 2 }}
                      >
                        <IoPrint size={20} />
                      </div>
                      <div>Print Invoice</div>
                    </a>
                  </div>
                </div>
              </div>
              <div className="delivery-details">
                <div className="delivery-details-heading">Delivery Details</div>
                <div className="p_3">
                  <div>
                    <div className="d_flex align_items_center gap_2">
                      <div className="f_14 f_weight_1">Delivery Address: </div>
                      <div className="f_12">
                        {order?.shippingAddress?.firstName},{" "}
                        {order?.shippingAddress?.addressLine1} -{" "}
                        {order?.shippingAddress?.postalCode}, Phone:{" "}
                        {order?.shippingAddress?.mobileNumber}
                      </div>
                    </div>
                  </div>
                  <div className="mt_4 order-items-content">
                    {order?.productsInOrder?.map((data, index) => {
                      return (
                        <div
                          className={`order-item ${
                            index < order?.productsInOrder?.length - 1
                              ? ""
                              : "end"
                          }`}
                        >
                          <div className="d_flex gap_3 w_fill">
                            <div
                              className="product-img cursor-pointer"
                              onClick={
                                () => navigate()
                                // `${LOCKED_CLOTH_PAGE}?type=men&product_id=${order_item?.product_id}`
                              }
                            >
                              <img
                                src={
                                  data?.productImages?.length > 0 &&
                                  data?.productImages[0]?.url
                                }
                                alt="image_1"
                              />
                            </div>
                            <div className="product-info cursor-pointer w_fill">
                              <div
                                className="font_size-1-h f_weight_1"
                                style={{ color: "gray" }}
                              >
                                {data?.productCategory}
                              </div>
                              <div
                                className="product-title"
                                onClick={
                                  () => navigate()
                                  // `${LOCKED_CLOTH_PAGE}?type=men&product_id=${order_item?.product_id}`
                                }
                              >
                                {data?.productName}
                              </div>
                              <div className="d_flex justify-content-flex-end res-max-576px-align-items-s res-max-576px-f-direction-c w_fill gap_3 product-price-details mt_2 mb_2">
                                <div className="d_flex align_items_center gap_2 product-price-qty">
                                  <div>{data?.quantity}</div>
                                  <RxCross2 />
                                  <div className="d_flex align_items_center">
                                    <div>
                                      Rs.{" "}
                                      {currencyFormatter(
                                        Number(data?.onAddSalePrice),
                                        "INR"
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <div className="d_flex align_items_center price">
                                  <div>
                                    Rs.{" "}
                                    {currencyFormatter(
                                      Number(data?.quantity) *
                                        Number(data?.onAddSalePrice)
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className="selected-product-features">
                                <div className="d_flex align_items_center gap_3">
                                  {/* <div className="f_14 f_weight_1 selected-size">
                                    {order_item?.selected_size}
                                  </div>
                                  <div className="custom-vr"></div>
                                  <div
                                    className="target-color"
                                    style={{
                                      backgroundColor:
                                        order_item?.target_color_code,
                                    }}
                                  ></div> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
            <div className="order-payment-details">
              <div className="payment-details">
                <div className="order-payment-heading">Order Total</div>
                <div className="p_3">
                  <div>
                    <div className="d_flex align_items_center justify_content_space_between">
                      <div className="f_14">Subtotal</div>
                      <div className="d_flex align_items_center f_14">
                        <div>
                          Rs.{" "}
                          {currencyFormatter(
                            Number(order?.orderSummary?.totalPrice),
                            "INR"
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="d_flex align_items_center justify_content_space_between mt_2">
                      <div className="f_14">Shipping</div>
                      <div className="d_flex align_items_center f_14">
                        <div>
                          Rs.{" "}
                          {currencyFormatter(
                            Number(order?.orderSummary?.shippingCharge),
                            "INR"
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="d_flex align_items_center justify_content_space_between mt_2">
                      <div className="f_14">GST</div>
                      <div className="d_flex align_items_center f_14">
                        <div>
                          Rs.{" "}
                          {currencyFormatter(
                            Number(order?.orderSummary?.gstPrice),
                            "INR"
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="custom-hr mt_2"></div>
                    <div className="d_flex align_items_center justify_content_space_between mt_2">
                      <div className="f_14 f_weight_1">Total</div>
                      <div className="d_flex align_items_center f_14">
                        <div
                          style={{
                            fontWeight: 600,
                          }}
                        >
                          Rs.{" "}
                          {currencyFormatter(
                            Number(order?.orderSummary?.totalAmountToPay),
                            "INR"
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="get-msg-details"
                style={{
                  opacity: 0.5,
                  cursor: "default",
                }}
              >
                <div className="p_3">
                  <div className="d_flex align_items_center gap_2">
                    <div className="d_flex align_items_center justify_content_center">
                      <IoChatbubbles className="d_flex align_items_center justify_content_center f_size_3" />
                    </div>
                    <div className="f_weight_1">Get text msg notifications</div>
                  </div>
                  <div className="f_12 mt_2">
                    Get instant updates on your order status, including shipping
                    confirmation and estimated delivery time, ensuring you're
                    always in the loop with your package's journey.
                  </div>
                  <div className="phone-number-input">
                    <div className="label">Phone Number</div>
                    <Input
                      className="font_lato"
                      style={{
                        height: "0.7rem",
                        marginTop: "5px",
                        background: "var(--cmac-default-shade-fill)",
                        letterSpacing: "1px",
                        border: "1px solid #0000002a",
                      }}
                      disabled={true}
                      type={"number"}
                    />
                  </div>
                  <div className="f_12 d_flex align_items_center mt_2 gap_1">
                    <input
                      type="checkbox"
                      style={{
                        accentColor: "var(--cmac-primary-color)",
                      }}
                      disabled={true}
                    />{" "}
                    I agree to terms and conditions
                  </div>
                  <button
                    className="font_lato"
                    style={{
                      background: "var(--cmac-primary-color)",
                      color: "#fff",
                      border: "unset",
                      outline: "unset",
                      letterSpacing: "1px",
                      fontSize: "14px",
                    }}
                    disabled={true}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderTrakingPage;
