import React, { useEffect, useState } from "react";
import ProductConnections from "./ProductConnections";
import { useDispatch, useSelector } from "react-redux";
import { getQueryParam } from "../../../../helpers/getQueryParams";
import {
  createReview,
  deleteReview,
  getProduct,
  updateReview,
} from "../../../../redux/actions/productAction";
import { clearProduct } from "../../../../redux/slices/productSlice";
import { IoIosStar, IoMdAlert, IoMdImages } from "react-icons/io";
import { BiSolidStar } from "react-icons/bi";
import { LiaStarSolid } from "react-icons/lia";
import { Input } from "../../../utils/Elements";
import { generateUUID } from "../../../../helpers/generateUUID";
import { RxCross2 } from "react-icons/rx";
import { FaPlus } from "react-icons/fa";
import { IoCheckmarkCircle } from "react-icons/io5";
import { PiWarningCircleFill } from "react-icons/pi";
import { useNavigate } from "react-router-dom";
import { PRODUCT_DETAILS_PAGE } from "../../../../helpers/paths";
import { clearReviewActionStatus } from "../../../../redux/slices/reviewSlice";
import { useToast } from "../../../plugins/toaster/useToast";
import { FaTrashCan } from "react-icons/fa6";
import CmacModal from "../../../plugins/cmac-plugins/CmacModal";
import InfinityLoader from "../../../../assets/imgs/loaders/InfinityLoader";
import Skeleton from "react-loading-skeleton";
import appConstatnts from "../../../../helpers/appConstants";

const ProductReviewPage = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const { product, loading: productLoading } = useSelector(
    (state) => state.productState
  );
  const { user } = useSelector((state) => state.userState);
  const {
    review,
    loading: reviewLoading,
    reviewsList,
    status: reviewStatus,
    message,
    allowToast,
    statusAction,
  } = useSelector((state) => state.reviewState);
  const dispatch = useDispatch();
  const productId = getQueryParam("product_id");
  const isEdit = getQueryParam("isEdit");
  const [ratingValue, setRatingValue] = useState(5);
  const [reviewTitle, setReviewTitle] = useState("");
  const [reviewUserName, setReviewUserName] = useState("");
  const [isVerifiedUser, setIsVerifiedUser] = useState(false);
  const [reviewImages, setReviewImages] = useState([]);
  const [reviewDetails, setReviewDetails] = useState("");
  const [deleteReviewModal, setDeleteReviewModal] = useState(false);
  const [purchasedSize, setPurchasedSize] = useState("");
  const handleProductImage = (e) => {
    const newImages = Array?.from(e.target.files)?.map((image) => {
      return {
        id: generateUUID(),
        file: image,
        url: URL.createObjectURL(image),
      };
    });
    setReviewImages([...reviewImages, ...newImages]);
  };
  const handleRemoveImage = (image_url) => {
    const updated_images = reviewImages?.filter(
      (image) => image?.url !== image_url
    );
    setReviewImages(updated_images);
  };
  const handleDeleteReview = () => {
    setDeleteReviewModal(true);
  };
  const handleSubmitReview = () => {
    const payload = {
      productId: product?._id,
      reviewTitle,
      reviewDetails,
      ratingValue,
      reviewImages,
      purchasedSize:
        purchasedSize && isVerifiedUser
          ? purchasedSize
          : product?.verifiedPurchaseUsers?.find(
              (data) => data?.userId === user?._id
            )?.purchasedSize,
      ...(user.role === appConstatnts.ROLES.SUPREME_ADMIN &&
        reviewUserName && {
          reviewUserName,
        }),
      ...(user.role === appConstatnts.ROLES.SUPREME_ADMIN &&
        isVerifiedUser && {
          isVerifiedUser,
        }),
    };
    if (isEdit === true || isEdit === "true") {
      dispatch(updateReview({ ...payload, reviewId: review?._id }));
    } else {
      dispatch(createReview(payload));
    }
  };
  const displayToast = (icon, toastMessage) => {
    return (
      <div className="add_cart_toast height-30 display-flex">
        <div className="display-flex align-items-center">{icon}</div>
        <div className="toast_message">{toastMessage}</div>
      </div>
    );
  };
  useEffect(() => {
    if (isEdit === true || isEdit === "true") {
      const foundReview = reviewsList?.find(
        (data) => data?.userId === user?._id
      );
      setRatingValue(Number(foundReview?.ratingValue));
      setReviewTitle(foundReview?.reviewTitle);
      setReviewImages(foundReview?.reviewImages);
      setReviewDetails(foundReview?.reviewDetails);
    } else {
      setReviewTitle("");
    }
  }, [isEdit]);
  useEffect(() => {
    const payload = {
      productId,
    };
    dispatch(clearProduct());
    dispatch(getProduct(payload));
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  useEffect(() => {
    switch (reviewStatus) {
      case "success": {
        if (allowToast) {
          toast.open(
            displayToast(
              <IoCheckmarkCircle color="#37cd00" size={30} />,
              message
            ),
            {
              toastWidth: "fit-content",
              toastMsgWidth: "fit-content",
            }
          );
        }
        if (statusAction === "add-review-success") {
          setTimeout(() => {
            navigate(`${PRODUCT_DETAILS_PAGE}?product_id=${product?._id}`);
          }, 500);
        }
        dispatch(clearReviewActionStatus());
        break;
      }
      case "fail": {
        if (allowToast) {
          toast.open(
            displayToast(
              <PiWarningCircleFill color="#f00" size={30} />,
              message
            ),
            {
              toastWidth: "fit-content",
              toastMsgWidth: "fit-content",
            }
          );
        }
        dispatch(clearReviewActionStatus());
        break;
      }
      default: {
        // dispatch(clearCartActionStatus());
        break;
      }
    }
  }, [reviewStatus]);
  useEffect(() => {
    if (product) {
      if (product?.availableSizes?.length > 0) {
        setPurchasedSize(product?.availableSizes[0]);
      }
    }
  }, [product]);
  return (
    <div className="product_review_page">
      <div className="element_hug">
        <div className="element_container mt_4 d_flex align_items_center gap_3 flex_direction_col">
          <div className="review_page_container">
            <div className="f_size_3 f_weight_2">Add Review</div>
            <div className="d_flex align_items_center gap_5 mt_2">
              {productLoading ? (
                <Skeleton
                  height={80}
                  width={80}
                  baseColor="var(--cmac-default-shade-fill)"
                  highlightColor="var(--cmac-default-fill)"
                  duration={0.8}
                />
              ) : (
                <img
                  src={
                    product?.productImages?.length > 0 &&
                    product?.productImages[0]?.url
                  }
                  height={100}
                  width={100}
                />
              )}
              <div className="f_14">{product?.productName}</div>
            </div>
            <div
              className="mt_2 mb_2"
              style={{
                width: "-webkit-fill-available",
                border: "1px dashed #e5e5e5",
              }}
            ></div>
            <div>
              <div className="f_20 f_weight_2">Rating</div>
              <div
                className="d_flex align_items_center f_size_6 mt_2"
                style={{
                  color: "#ffc249",
                }}
              >
                {Array.from({ length: ratingValue }).map((_, i) => {
                  return (
                    <LiaStarSolid
                      key={`filled-${i}`}
                      onClick={() => setRatingValue(i + 1)}
                      style={{ cursor: "pointer", color: "#ffc249" }}
                    />
                  );
                })}
                {Array.from({ length: 5 - ratingValue }).map((_, i) => {
                  return (
                    <LiaStarSolid
                      key={`unfilled-${i}`}
                      onClick={() => setRatingValue(ratingValue + i + 1)}
                      style={{ cursor: "pointer", color: "#dcdcdc" }}
                    />
                  );
                })}
              </div>
              <div
                className="f_12 opacity_0_6"
                style={{
                  position: "relative",
                  left: "8px",
                  top: "5px",
                }}
              >
                Click the star to rate
              </div>
            </div>
            <div
              className="mt_2 mb_2"
              style={{
                width: "-webkit-fill-available",
                border: "1px dashed #e5e5e5",
              }}
            ></div>
            {user?.role === appConstatnts.ROLES.SUPREME_ADMIN && (
              <div>
                <div className="f_20 f_weight_2">Add a User Name</div>
                <div className="f_10">
                  This input shows only if you are an admin
                </div>
                <Input
                  className={"font_lato mt_2 f_14"}
                  style={{
                    width: "-webkit-fill-available",
                    backgroundColor: "var(--cmac-default-shade-fill)",
                  }}
                  value={reviewUserName}
                  onChange={(e) => setReviewUserName(e.target.value)}
                />
                <div className="mt_2 f_14 d_flex align_items_center gap_2">
                  <input
                    type="checkbox"
                    value={isVerifiedUser}
                    onChange={() => setIsVerifiedUser(!isVerifiedUser)}
                    checked={isVerifiedUser}
                    style={{
                      accentColor: "var(--cmac-primary-color)",
                    }}
                  />
                  <div>Is Verified Purchased User?</div>
                </div>
                <div className="d_flex align_items_center gap_2 mt_2">
                  <div className="f_weight_2">Purchased Size:</div>
                  <select
                    value={purchasedSize}
                    className="cursor_pointer"
                    onChange={(e) => setPurchasedSize(e.target.value)}
                    style={{
                      height: "50px",
                      width: "80px",
                      paddingLeft: "5px",
                      outline: "none",
                      border: "1px solid #c9c9c9",
                      borderRadius: "5px",
                    }}
                  >
                    {product?.availableSizes?.map((data, i) => {
                      return (
                        <option value={data} key={i}>
                          {data}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            )}
            <div
              className="mt_2 mb_2"
              style={{
                width: "-webkit-fill-available",
                border: "1px dashed #e5e5e5",
              }}
            ></div>
            <div>
              <div className="f_20 f_weight_2">Add a Title</div>
              <Input
                className={"font_lato mt_2 f_14"}
                style={{
                  width: "-webkit-fill-available",
                  backgroundColor: "var(--cmac-default-shade-fill)",
                }}
                value={reviewTitle}
                onChange={(e) => setReviewTitle(e.target.value)}
              />
            </div>
            <div
              className="mt_2 mb_2"
              style={{
                width: "-webkit-fill-available",
                border: "1px dashed #e5e5e5",
              }}
            ></div>
            <div>
              <div className="f_20 f_weight_2">Add Photos</div>
              <div className="mt_1 f_12">
                Visual content such as images tend to be more effective for
                shoppers than relying solely on text.
              </div>
              <div className="uploaded_images_container mt_2">
                <input
                  className="d_none"
                  id="product_imgs"
                  type="file"
                  onChange={(e) => handleProductImage(e)}
                />
                <div className="images_container">
                  {reviewImages?.length !== 0 &&
                    reviewImages?.map((image) => (
                      <div className="image_content">
                        <div className="remove_image_container">
                          <div
                            className="remove_image"
                            onClick={() => handleRemoveImage(image?.url)}
                          >
                            <RxCross2 />
                          </div>
                        </div>
                        <div className="image">
                          <img
                            key={image?.id}
                            src={
                              image?.file instanceof Blob
                                ? URL.createObjectURL(image?.file)
                                : image?.url
                            }
                            alt={`Product Image ${image?.id}`}
                          />
                        </div>
                      </div>
                    ))}
                  <label
                    className="upload_img_container cursor_pointer"
                    htmlFor="product_imgs"
                  >
                    <div className="upload_img">
                      <IoMdImages className="d_flex align_items_center justify_content_center" />
                    </div>
                  </label>
                </div>
              </div>
              <div
                className="mt_2 mb_2"
                style={{
                  width: "-webkit-fill-available",
                  border: "1px dashed #e5e5e5",
                }}
              ></div>
              <div className="add_review_text_area">
                <div className="f_20 f_weight_2">Add Review</div>
                <textarea
                  rows={4}
                  className={"font_lato mt_2 f_14"}
                  style={{
                    width: "-webkit-fill-available",
                    backgroundColor: "var(--cmac-default-shade-fill)",
                  }}
                  value={reviewDetails}
                  onChange={(e) => setReviewDetails(e.target.value)}
                />
              </div>
            </div>
            <div
              className="mt_2 mb_2"
              style={{
                width: "-webkit-fill-available",
                border: "1px dashed #e5e5e5",
              }}
            ></div>
            <div className="d_flex align_items_center w_fill justify_content_flex_end gap_2">
              {isEdit === true ||
                (isEdit === "true" && (
                  <button
                    className="submit_review_btn cursor_pointer d_flex align_items_center justify_content_center gap_2"
                    onClick={handleDeleteReview}
                    style={{
                      background: "var(--cmac-dark-fill)",
                    }}
                  >
                    <FaTrashCan color="#f00" size={18} /> Delete Review
                  </button>
                ))}
              <button
                className="submit_review_btn cursor_pointer"
                onClick={handleSubmitReview}
                disabled={reviewLoading}
                style={{
                  opacity: reviewLoading ? "0.5" : "1",
                  cursor: reviewLoading ? "default" : "pointer",
                }}
              >
                {reviewLoading ? (
                  <div className="d_flex align_items_center justify_content_center gap_3">
                    <InfinityLoader
                      height={35}
                      width={35}
                      stroke="var(--cmac-default-shade-fill)"
                    />
                    <div>Please Wait!</div>
                  </div>
                ) : isEdit === true || isEdit === "true" ? (
                  "Update Review"
                ) : (
                  "Submit Review"
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
      <CmacModal
        active={deleteReviewModal}
        onClickOutsider={() => setDeleteReviewModal(false)}
        className="auth-modal"
        portalUsage={true}
        blurStrength="stronger"
        style={{
          width: "450px",
          background: "var(--cmac-default-shade-fill)",
          color: "var(--cmac-dark-fill)",
        }}
      >
        <div className="font_lato">
          <div className="d_flex gap_3">
            <div>
              <IoMdAlert color="#f00" size={60} />
            </div>
            <div>
              <div
                style={{
                  fontSize: "20px",
                  fontWeight: 600,
                }}
              >
                Delete Review!
              </div>
              <div
                className="mt_1"
                style={{
                  fontSize: "14px",
                }}
              >
                Are you sure you want to delete the review? This action cannot
                be undone! Please note that once the review is deleted, you will
                no longer be able to add or update reviews for this product.
              </div>
            </div>
          </div>
          <div className="mt_2 d_flex justify_content_flex_end gap_2">
            <button
              className="alert_btn_1"
              onClick={() => setDeleteReviewModal(false)}
              style={{
                background: "var(--cmac-dark-fill)",
                color: "var(--cmac-default-fill)",
              }}
            >
              Cancel
            </button>
            <button
              className="alert_btn_2"
              onClick={() => {
                dispatch(deleteReview(productId));
                setDeleteReviewModal(false);
              }}
              style={{
                background: "var(--cmac-primary-color)",
                color: "var(--cmac-default-fill)",
              }}
            >
              Yes! Delete!
            </button>
          </div>
        </div>
      </CmacModal>
      <ProductConnections />
    </div>
  );
};

export default ProductReviewPage;
