export const generateUUID = () => {
    const cryptoObj = window.crypto;
    if (cryptoObj) {
      const array = new Uint32Array(4);
      cryptoObj.getRandomValues(array);
      return (
        array[0].toString(16).padStart(8, "0") +
        array[1].toString(16).padStart(8, "0") +
        "-" +
        array[2].toString(16).padStart(8, "0") +
        array[3].toString(16).padStart(8, "0")
      );
    } else {
      return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
        /[xy]/g,
        function (c) {
          const r = (Math.random() * 16) | 0;
          const v = c === "x" ? r : (r & 0x3) | 0x8;
          return v.toString(16);
        }
      );
    }
  };