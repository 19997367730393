import React, { useEffect, useState } from "react";
import { Input } from "../../../utils/Elements";
import { useDispatch, useSelector } from "react-redux";
import { createTemporaryTestQuestions } from "../../../../redux/actions/testQuestionsAction";

const FormAlpha = ({ selectedLink, selectedSubLink, handleSelectChange }) => {
  const [userName, setUserName] = useState("");
  const { testQuestions } = useSelector((state) => state.testQuestionsState);
  const dispatch = useDispatch();
  const onNext = () => {
    const payload = {
      ...testQuestions,
      11: { link: 1, sublink: 1, value: userName, key: "userName" },
    };
    dispatch(createTemporaryTestQuestions(payload));
  };

  useEffect(() => {
    if (testQuestions[11] && testQuestions[11]["value"]) {
      setUserName(testQuestions[11]["value"]);
    }
  }, [testQuestions]);
  return (
    <div className="form_alpha">
      <div className="heading f_weight_1">Let's make it easy</div>
      <div className="interactions_container">
        <div className="input_container">
          <div className="label">Could you share your Name?</div>
          <Input
            className={"font_lato mt_2"}
            placeholder={"Your Name"}
            containerStyle={{
              width: "-webkit-fill-available",
            }}
            style={{
              width: "-webkit-fill-available",
            }}
            value={userName}
            onChange={(e) => setUserName(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
                userName &&
                  handleSelectChange(selectedLink, selectedSubLink + 1);
                userName && onNext();
              }
            }}
          />
        </div>
        <div className="mt_2 interactions_btn_container">
          <button className="interaction_btn_alpha" disabled></button>
          <button
            className="interaction_btn_beta"
            onClick={() => {
              userName && handleSelectChange(selectedLink, selectedSubLink + 1);
              userName && onNext();
            }}
            style={{
              transition: "all 0.5s ease-in-out",
              opacity: !userName && "0.2",
            }}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default FormAlpha;
