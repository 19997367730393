import productImage1 from "../../../../assets/imgs/store-room/product_id_01.png";
import productImage2 from "../../../../assets/imgs/store-room/product_id_02.png";
import productImage3 from "../../../../assets/imgs/store-room/product_id_03.png";
import productImage4 from "../../../../assets/imgs/store-room/product_id_04.png";
import productImage5 from "../../../../assets/imgs/store-room/product_id_05.png";
import productImage6 from "../../../../assets/imgs/store-room/product_id_06.png";

const dummyProducts = [
  {
    _id: "serum001",
    productName: "Radiance Boosting Face Serum for Glowing and Youthful Skin",
    salePrice: 1499,
    productImages: [
      {
        url: productImage1,
      },
    ],
    isDiscountedProduct: true,
    productDescription:
      "Experience a radiant glow with our advanced formula. This serum revitalizes and nourishes your skin, leaving it soft and youthful. Infused with potent antioxidants, it helps to reduce the appearance of fine lines and wrinkles. Lightweight and fast-absorbing, it delivers intense hydration without any greasy residue. Suitable for all skin types, it promotes a more even skin tone and enhances your skin's natural radiance.",
    additionalDescription:
      "Our Radiance Boosting Face Serum is crafted with a blend of natural ingredients designed to penetrate deep into the skin. Use daily for best results. Free from parabens, sulfates, and artificial fragrances, making it gentle on sensitive skin. Dermatologically tested and cruelty-free.",
  },
  {
    _id: "serum002",
    productName: "Hydrating Hyaluronic Acid Serum",
    salePrice: 1999,
    productImages: [
      {
        url: productImage2,
      },
    ],
  },
  {
    _id: "serum003",
    productName: "Vitamin C Brightening Serum",
    salePrice: 1299,
    productImages: [
      {
        url: productImage3,
      },
    ],
  },
  {
    _id: "serum004",
    productName: "Anti-Aging Retinol Serum",
    salePrice: 2499,
    productImages: [
      {
        url: productImage4,
      },
    ],
    isDiscountedProduct: true,
    discountPercentage: "12",
  },
  {
    _id: "serum005",
    productName: "Acne Control Salicylic Acid Serum",
    salePrice: 999,
    productImages: [
      {
        url: productImage5,
      },
    ],
  },
  {
    _id: "serum006",
    productName: "Nourishing Night Repair Serum",
    salePrice: 1799,
    productImages: [
      {
        url: productImage6,
      },
    ],
    isDiscountedProduct: true,
    discountPercentage: "25",
  },
  {
    _id: "serum001",
    productName: "Radiance Boosting Face Serum",
    salePrice: 1499,
    productImages: [
      {
        url: productImage1,
      },
    ],
    isDiscountedProduct: true,
    discountPercentage: "20",
  },
  {
    _id: "serum002",
    productName: "Hydrating Hyaluronic Acid Serum",
    salePrice: 1999,
    productImages: [
      {
        url: productImage2,
      },
    ],
  },
  {
    _id: "serum003",
    productName: "Vitamin C Brightening Serum",
    salePrice: 1299,
    productImages: [
      {
        url: productImage3,
      },
    ],
  },
  {
    _id: "serum004",
    productName: "Anti-Aging Retinol Serum",
    salePrice: 2499,
    productImages: [
      {
        url: productImage4,
      },
    ],
    isDiscountedProduct: true,
    discountPercentage: "12",
  },
  {
    _id: "serum005",
    productName: "Acne Control Salicylic Acid Serum",
    salePrice: 999,
    productImages: [
      {
        url: productImage5,
      },
    ],
  },
  {
    _id: "serum006",
    productName: "Nourishing Night Repair Serum",
    salePrice: 1799,
    productImages: [
      {
        url: productImage6,
      },
    ],
    isDiscountedProduct: true,
    discountPercentage: "25",
  },
];
const dummyProductsCategoryList = [
  {
    _id: "serum001",
    productName: "Radiance Boosting Face Serum for Glowing and Youthful Skin",
    salePrice: 1499,
    productImages: [
      {
        url: productImage1,
      },
    ],
    isDiscountedProduct: true,
    productDescription:
      "Experience a radiant glow with our advanced formula. This serum revitalizes and nourishes your skin, leaving it soft and youthful. Infused with potent antioxidants, it helps to reduce the appearance of fine lines and wrinkles. Lightweight and fast-absorbing, it delivers intense hydration without any greasy residue. Suitable for all skin types, it promotes a more even skin tone and enhances your skin's natural radiance.",
    additionalDescription:
      "Our Radiance Boosting Face Serum is crafted with a blend of natural ingredients designed to penetrate deep into the skin. Use daily for best results. Free from parabens, sulfates, and artificial fragrances, making it gentle on sensitive skin. Dermatologically tested and cruelty-free.",
  },
  {
    _id: "serum002",
    productName: "Hydrating Hyaluronic Acid Serum",
    salePrice: 1999,
    productImages: [
      {
        url: productImage2,
      },
    ],
  },
  {
    _id: "serum003",
    productName: "Vitamin C Brightening Serum",
    salePrice: 1299,
    productImages: [
      {
        url: productImage3,
      },
    ],
  },
  {
    _id: "serum004",
    productName: "Anti-Aging Retinol Serum",
    salePrice: 2499,
    productImages: [
      {
        url: productImage4,
      },
    ],
    isDiscountedProduct: true,
    discountPercentage: "12",
  },
  {
    _id: "serum005",
    productName: "Acne Control Salicylic Acid Serum",
    salePrice: 999,
    productImages: [
      {
        url: productImage5,
      },
    ],
  },
  {
    _id: "serum006",
    productName: "Nourishing Night Repair Serum",
    salePrice: 1799,
    productImages: [
      {
        url: productImage6,
      },
    ],
    isDiscountedProduct: true,
    discountPercentage: "25",
  },
  {
    _id: "serum001",
    productName: "Radiance Boosting Face Serum",
    salePrice: 1499,
    productImages: [
      {
        url: productImage1,
      },
    ],
    isDiscountedProduct: true,
    discountPercentage: "20",
  },
  {
    _id: "serum002",
    productName: "Hydrating Hyaluronic Acid Serum",
    salePrice: 1999,
    productImages: [
      {
        url: productImage2,
      },
    ],
  },
  {
    _id: "serum003",
    productName: "Vitamin C Brightening Serum",
    salePrice: 1299,
    productImages: [
      {
        url: productImage3,
      },
    ],
  },
  {
    _id: "serum004",
    productName: "Anti-Aging Retinol Serum",
    salePrice: 2499,
    productImages: [
      {
        url: productImage4,
      },
    ],
    isDiscountedProduct: true,
    discountPercentage: "12",
  },
  {
    _id: "serum005",
    productName: "Acne Control Salicylic Acid Serum",
    salePrice: 999,
    productImages: [
      {
        url: productImage5,
      },
    ],
  },
  {
    _id: "serum006",
    productName: "Nourishing Night Repair Serum",
    salePrice: 1799,
    productImages: [
      {
        url: productImage6,
      },
    ],
    isDiscountedProduct: true,
    discountPercentage: "25",
  },
];

export { dummyProducts, dummyProductsCategoryList };
