import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getQueryParam } from "../../../../helpers/getQueryParams";
import moment from "moment";
import { getTestQuestionsData } from "../../../../redux/actions/testQuestionsAction";
import { TEST_MANAGEMENT_PAGE } from "../../../../helpers/paths";

const TestOverview = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const reportId = getQueryParam("test_report_id");
  const { testQuestionsData } = useSelector(
    (state) => state.testQuestionsState
  );
  useEffect(() => {
    if (!reportId) {
      navigate(TEST_MANAGEMENT_PAGE);
    }
    dispatch(getTestQuestionsData(reportId));
  }, []);
  return (
    <div className="product_overview">
      <div className="product_overview_header ">
        <div className="w_fill">
          <div className="d_flex align_items_center justify_content_space_between">
            <div>
              <div className="heading">REPORT ID #{testQuestionsData?._id}</div>
              <div className="d_flex align_items_center gap_2 f_12 mt_1">
                <div>Created On:</div>
                <div className="f_weight_1">
                  {moment(testQuestionsData?.createdAt).format(
                    "DD, MMM YYYY HH:MM A"
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="d_flex gap_10 mt_4">
            <div
              className="p_3"
              style={{
                width: "600px",
                maxHeight: "calc(var(--cmac-doc-height) - 290px)",
                overflow: "auto",
                background: "var(--cmac-management-outer-color)",
                borderRadius: "30px",
              }}
            >
              <div className="d_flex flex_direction_col gap_4">
                {testQuestionsData?.testQuestions
                  ?.filter((data) => data?.value)
                  ?.map((data, i) => {
                    return (
                      <div>
                        <div
                          className="f_14"
                          style={{
                            color: "#00c3ff",
                          }}
                        >
                          {i + 1}. {data?.q}
                        </div>
                        <div className="p_2">
                          {data?.options?.map((option) => {
                            return <div className="f_12">• {option}</div>;
                          })}
                        </div>
                        <div className="f_14 d_flex align_items_center gap_2">
                          <div
                            style={{
                              color: "#ff0082",
                            }}
                          >
                            Response:{" "}
                          </div>
                          <div className="f_12 f_weight_2">{data?.value}</div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
            <div>
              <div className="d_flex align_items_center gap_2 f_14 f_weight_2">
                <div
                  style={{
                    color: "#00c3ff",
                  }}
                >
                  Patient Name:{" "}
                </div>
                <div
                  style={{
                    color: "#ff0082",
                  }}
                >
                  {testQuestionsData?.userName}
                </div>
              </div>
              <div className="d_flex align_items_center gap_2 f_14 f_weight_2 mt_1">
                <div
                  style={{
                    color: "#00c3ff",
                  }}
                >
                  Patient Email:{" "}
                </div>
                <div
                  style={{
                    color: "#ff0082",
                  }}
                >
                  {testQuestionsData?.email}
                </div>
              </div>
              <div className="d_flex align_items_center gap_2 f_14 f_weight_2 mt_1">
                <div
                  style={{
                    color: "#00c3ff",
                  }}
                >
                  Mobile Number:{" "}
                </div>
                <div
                  style={{
                    color: "#ff0082",
                  }}
                >
                  {testQuestionsData?.mobileNumber}
                </div>
              </div>
              <div className="d_flex align_items_center gap_2 f_14 f_weight_2 mt_1">
                <div
                  style={{
                    color: "#00c3ff",
                  }}
                >
                  Age:{" "}
                </div>
                <div
                  style={{
                    color: "#ff0082",
                  }}
                >
                  {testQuestionsData?.age}
                </div>
              </div>
              <div className="d_flex align_items_center gap_2 f_14 f_weight_2 mt_1">
                <div
                  style={{
                    color: "#00c3ff",
                  }}
                >
                  Gender:{" "}
                </div>
                <div
                  style={{
                    color: "#ff0082",
                  }}
                >
                  {testQuestionsData?.gender}
                </div>
              </div>
              <div className="f_14 f_weight_2 mt_3">
                <div
                  style={{
                    color: "#00c3ff",
                  }}
                >
                  Scalp Sample Image:
                </div>
                <div
                  className="mt_1"
                  style={{
                    color: "#ff0082",
                  }}
                >
                  <img
                    src={
                      testQuestionsData?.testQuestions?.length > 0 &&
                      testQuestionsData?.testQuestions[
                        testQuestionsData?.testQuestions?.length - 1
                      ].url
                    }
                    alt="testQuestionsData url"
                    height={300}
                    width={300}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TestOverview;
