import React, { useEffect, useState } from "react";
import { IoIosArrowDown, IoIosArrowForward } from "react-icons/io";
import { MANAGEMENT_PAGE } from "../../../../../helpers/paths";
import { RiMoonClearLine, RiSettingsFill, RiSunLine } from "react-icons/ri";
import { FaBell } from "react-icons/fa";
import { performCrypto } from "../../../../../helpers/performCrypto";

const Header = ({ currentTheme }) => {
  const [theme, setTheme] = useState(0);
  const cmacManagementStyles = [
    {
      "--theme-id": "0",
      "--cmac-management-font-color-1": "#cecece",
      "--cmac-management-font-color-2": "#ffffff",
      "--cmac-management-inner-color": "#202020",
      "--cmac-management-outer-color": "#2b2c2e",
      "--cmac-management-border-color": "#3e3e3e",
      "--cmac-management-linear-gradient":
        "linear-gradient(to right, #ff0082, #00c3ff)",
    },
    {
      "--theme-id": "1",
      "--cmac-management-font-color-1": "#545764",
      "--cmac-management-font-color-2": "#000",
      "--cmac-management-outer-color": "#f7f7f7",
      "--cmac-management-inner-color": "#fffefe",
      "--cmac-management-border-color": "#e1e1e1",
      "--cmac-management-linear-gradient":
        "linear-gradient(to right, #ff0082, #0045ff)",
    },
  ];
  const handleTheme = (index) => {
    const findThemeId = cmacManagementStyles[index];
    for (let [key, value] of Object.entries(findThemeId)) {
      document.documentElement.style.setProperty(key, value);
    }
  };
  useEffect(() => {
    handleTheme(theme);
    currentTheme(theme);
  }, [theme]);
  return (
    <div className="management_header">
      <div className="d_flex align_items_center gap_3 bread_crumbs">
        <a href={MANAGEMENT_PAGE} className="cursor_pointer">
          MANAGEMENT
        </a>
        <div className="d_flex align_items_center">
          <IoIosArrowForward size={14} />
        </div>
        <div className="active">PRODUCTS</div>
      </div>
      <div
        className="d_flex align_items_center justify_content_flex_end w_fill"
        style={{ gap: "20px" }}
      >
        <div className="d_flex align_items_center justify_content_center cursor_pointer">
          {theme === 0 ? (
            <RiSunLine
              size={24}
              onClick={() => {
                setTheme(1);
              }}
            />
          ) : (
            <RiMoonClearLine
              size={24}
              onClick={() => {
                setTheme(0);
              }}
            />
          )}
        </div>
        <div className="ic_container d_flex align_items_center justify_content_center">
          <div className="ic_follow"></div>
          <FaBell size={24} className="ic" />
        </div>
        <div className="profile_menu_container d_flex align_items_center">
          <div className="img_container">
            <img
              src="https://images.unsplash.com/photo-1529626455594-4ff0802cfb7e?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
              alt="profile_img"
            />
          </div>
          <div className="ic">
            <IoIosArrowDown />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
