import { createSlice } from "@reduxjs/toolkit";

export const categoryProductsSlice = createSlice({
  name: "categoryProducts",
  initialState: {
    loading: false,
    categoryProducts: [],
    message: null,
    status: null,
  },
  reducers: {
    categoryProductsRequest(state, payload) {
      return {
        ...state,
        loading: true,
      };
    },
    categoryProductsSuccess(state, action) {
      return {
        ...state,
        loading: false,
        categoryProducts: action.payload.data.products,
        message: action.payload.message,
        status: "success",
      };
    },
    categoryProductsFail(state, action) {
      return {
        ...state,
        loading: false,
        message: action.payload.message,
        status: "fail",
      };
    },
    clearCategoryProducts(state) {
      return {
        ...state,
        loading: false,
        categoryProducts: [],
        message: null,
        status: null,
      };
    },
  },
});

const { actions, reducer } = categoryProductsSlice;

export const {
  categoryProductsRequest,
  categoryProductsFail,
  categoryProductsSuccess,
  clearCategoryProducts,
} = actions;

export default reducer;
