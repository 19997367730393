const PAYMENT_METHODS = {
  COD: "COD",
  UPI: "UPI",
  CARD: "CARD-PAYMENT",
};
const PAYMENT_STATUS = {
  PENDING: "pending",
};
const STATUS = {
  ACTIVE: "active",
  INACTIVE: "inActive",
  PENDING: "pending",
};
const SHIPMENT_STATUS = {
  PROCESSING: "processing",
  SHIPPED: "shipped",
  DELIVERED: "delivered",
};

const ROLES = {
  USER: "user",
  SUPERVISOR: "supervisor",
  MANAGER: "manager",
  ADMIN: "admin",
  SUPREME_ADMIN: "supreme_admin",
};

const appConstatnts = {
  PAYMENT_METHODS,
  PAYMENT_STATUS,
  STATUS,
  SHIPMENT_STATUS,
  ROLES,
};

export default appConstatnts;
