import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { currencyFormatter } from "../../../../helpers/currencyFormatter";
import { useNavigate, useOutletContext } from "react-router-dom";
import { ORDER_OVERVIEW_PAGE } from "../../../../helpers/paths";
import { getAllOrders } from "../../../../redux/actions/orderAction";
import EmptyDataState from "../../../plugins/EmptyDataState";

const OrderManagement = () => {
  const [theme] = useOutletContext()
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { allOrders } = useSelector((state) => state.ordersState);
  const getStatusTheme = (status) => {
    switch (status) {
      case "processing": {
        if (theme === 0) {
          return {
            bg: "repeating-linear-gradient(45deg, #f5b1b156 0px, #f5b1b156 10px, #eb575752 10px, #eb575752 20px)",
            color: "#E17678",
          };
        } else if (theme === 1) {
          return {
            bg: "repeating-linear-gradient(45deg, #F5B1B1 0px, #F5B1B1 10px, #EB5757 10px, #EB5757 20px)",
            color: "#ffffff",
          };
        }
      }
      case "pending": {
        if (theme === 0) {
          return {
            bg: "repeating-linear-gradient(45deg, #f5b1b156 0px, #f5b1b156 10px, #eb575752 10px, #eb575752 20px)",
            color: "#E17678",
          };
        } else if (theme === 1) {
          return {
            bg: "repeating-linear-gradient(45deg, #F5B1B1 0px, #F5B1B1 10px, #EB5757 10px, #EB5757 20px)",
            color: "#ffffff",
          };
        }
      }
      case "shipped": {
        if (theme === 0) {
          return {
            bg: "#feb70126",
            color: "#feb701",
          };
        } else if (theme === 1) {
          return {
            bg: "#feb60171",
            color: "#8d6500",
          };
        }
      }
      case "delivered": {
        if (theme === 0) {
          return {
            bg: "#4dbe6d24",
            color: "#4DBE6D",
          };
        } else if (theme === 1) {
          return {
            bg: "#e5fdef",
            color: "#333333",
          };
        }
      }
      case "canceled": {
        if (theme === 0) {
          return {
            bg: "#ff313126",
            color: "#ff4444ad",
          };
        } else if (theme === 1) {
          return {
            bg: "#ffd8d8",
            color: "#ff1515",
          };
        }
      }
    }
  };
  const columns = [
    {
      name: "Order Id",
      cell: (row) => {
        return (
          <div
            onClick={() =>
              navigate(`${ORDER_OVERVIEW_PAGE}?order_id=${row._id}`)
            }
          >
            #{row?._id}
          </div>
        );
      },
      minWidth: "200px",
    },
    {
      name: "Created On",
      selector: (row) => (
        <div
          onClick={() => navigate(`${ORDER_OVERVIEW_PAGE}?order_id=${row._id}`)}
        >
          {moment(row?.createdAt).format("DD, MMM YYYY HH:MM A")}
        </div>
      ),
      minWidth: "200px",
    },
    {
      name: "Customer",
      cell: (row) => {
        return (
          <div
            onClick={() =>
              navigate(`${ORDER_OVERVIEW_PAGE}?order_id=${row._id}`)
            }
          >
            {row?.shippingAddress?.firstName} {row?.shippingAddress?.lastName}
          </div>
        );
      },
      width: "150px",
    },
    {
      name: "Products",
      cell: (row) => {
        return (
          <div
            onClick={() =>
              navigate(`${ORDER_OVERVIEW_PAGE}?order_id=${row._id}`)
            }
            className="d_flex flex_direction_col gap_3"
          >
            {row?.productsInOrder?.map((data) => {
              return (
                <div className="d_flex align_items_center gap_3">
                  <img
                    src={data?.productImages[0]?.url}
                    alt={data?._id}
                    height={50}
                    width={50}
                    style={{
                      backgroundColor: "var(--cmac-management-outer-color)",
                      padding: "5px",
                      objectFit: "contain",
                      borderRadius: "8px",
                    }}
                  />
                  <div className="f_12">{data?.productName}</div>
                </div>
              );
            })}
          </div>
        );
      },
      width: "400px",
    },
    {
      name: "Order Status",
      cell: (row) => {
        return (
          <div
            className="text_transform_capitalize"
            style={{
              background: getStatusTheme(row?.shippingAddress?.shipmentStatus)
                ?.bg,
              color: getStatusTheme(row?.shippingAddress?.shipmentStatus)
                ?.color,
              padding: "10px",
              paddingTop: "5px",
              paddingBottom: "5px",
              borderRadius: "50px",
              fontSize: "12px",
              fontWeight: "600"
            }}
            onClick={() =>
              navigate(`${ORDER_OVERVIEW_PAGE}?order_id=${row._id}`)
            }
          >
            {row?.shippingAddress?.shipmentStatus}
          </div>
        );
      },
      width: "150px",
    },
    {
      name: "Total Price",
      cell: (row) => {
        return (
          <div
            onClick={() =>
              navigate(`${ORDER_OVERVIEW_PAGE}?order_id=${row._id}`)
            }
          >
            {currencyFormatter(row?.orderSummary?.totalAmountToPay, "INR")} INR
          </div>
        );
      },
      width: "180px",
    },
    {
      name: "Payment Method",
      cell: (row) => {
        return (
          <div
            onClick={() =>
              navigate(`${ORDER_OVERVIEW_PAGE}?order_id=${row._id}`)
            }
          >
            {row?.paymentInfo?.paymentMethod}
          </div>
        );
      },
      width: "150px",
    },
    {
      name: "Payment Status",
      cell: (row) => {
        return (
          <div
            className="text_transform_capitalize"
            style={{
              background: getStatusTheme(row?.paymentInfo?.paymentStatus)?.bg,
              color: getStatusTheme(row?.paymentInfo?.paymentStatus)?.color,
              padding: "10px",
              paddingTop: "5px",
              paddingBottom: "5px",
              borderRadius: "50px",
              fontSize: "12px",
              fontWeight: "600"
            }}
            onClick={() =>
              navigate(`${ORDER_OVERVIEW_PAGE}?order_id=${row._id}`)
            }
          >
            {row?.paymentInfo?.paymentStatus}
          </div>
        );
      },
      width: "150px",
    },
    {
      name: "Shipping Address",
      cell: (row) => {
        return (
          <div
            onClick={() =>
              navigate(`${ORDER_OVERVIEW_PAGE}?order_id=${row._id}`)
            }
          >
            <div>{row?.shippingAddress?.addressLine1},</div>
            <div>
              {row?.shippingAddress?.location}, {row?.shippingAddress?.district}
              , {row?.shippingAddress?.state}
            </div>
          </div>
        );
      },
      minWidth: "330px",
    },
    {
      name: "Pincode",
      cell: (row) => {
        return (
          <div
            onClick={() =>
              navigate(`${ORDER_OVERVIEW_PAGE}?order_id=${row._id}`)
            }
          >
            {row?.shippingAddress?.postalCode}
          </div>
        );
      },
      width: "100px",
    },
  ];
  const data = allOrders.filter((row) => row !== null && row !== undefined);

  useEffect(() => {
    const query = `limit=100`;
    dispatch(getAllOrders(query));
  }, []);
  return (
    <div className="product_management">
      <div className="product_management_header">
        <div className="heading">ORDERS</div>
      </div>
      <div className="mt_4 data_table_comp">
        <DataTable
          columns={columns}
          data={data}
          onRowClicked={(e) =>
            navigate(`${ORDER_OVERVIEW_PAGE}?order_id=${e._id}`)
          }
          noDataComponent={
            <EmptyDataState message={"There are no records to display"} />
          }
          fixedHeader={true}
          fixedHeaderScrollHeight="70vh"
          className="data_table_comp"
        />
      </div>
    </div>
  );
};

export default OrderManagement;
