import moment from "moment";
import React, { useEffect, useState } from "react";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { Popover } from "react-tiny-popover";
import { FaCalendarDays } from "react-icons/fa6";

const CustomDatePicker = ({ onDateChange, value, className }) => {
  const [viewDatePickerPopover, setViewDatePickerPopover] = useState(false);
  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const handleSelect = (ranges) => {
    setDateRange([ranges.selection]);
    onDateChange([ranges.selection]);
  };

  useEffect(() => {
    setDateRange([
      {
        startDate: value[0]?.startDate,
        endDate: value[0]?.endDate,
        key: "selection",
      },
    ]);
  }, [value]);
  return (
    <div className={className}>
      <Popover
        isOpen={viewDatePickerPopover}
        positions={["top", "bottom", "left", "right"]}
        content={
          <div
            style={{
              borderRadius: "10px",
              zIndex: 5
            }}
          >
            <DateRangePicker
              ranges={dateRange}
              onChange={handleSelect}
              rangeColors={["#000000"]}
            />
          </div>
        }
        onClickOutside={() => setViewDatePickerPopover(!viewDatePickerPopover)}
      >
        <div
          onClick={() => setViewDatePickerPopover(!viewDatePickerPopover)}
          className="custom-date-picker"
        >
          <div className="selected-date">
            <div>{moment(dateRange[0].startDate).format("DD-MM-YYYY")}</div>
            {moment(dateRange[0].startDate).format("DD-MM-YYYY") !==
              moment(dateRange[0].endDate).format("DD-MM-YYYY") && <div>-</div>}
            {moment(dateRange[0].startDate).format("DD-MM-YYYY") !==
              moment(dateRange[0].endDate).format("DD-MM-YYYY") && (
              <div>{moment(dateRange[0].endDate).format("DD-MM-YYYY")}</div>
            )}
          </div>
          <FaCalendarDays className="ic-calendar" />
        </div>
      </Popover>
    </div>
  );
};

export default CustomDatePicker;
