import React, { useEffect, useState } from "react";
import { IoIosStar, IoIosStarOutline } from "react-icons/io";
import { currencyFormatter } from "../../../../helpers/currencyFormatter";
import Skeleton from "react-loading-skeleton";
import { GoDotFill } from "react-icons/go";
import { useSelector } from "react-redux";
import { getRandomColor } from "../../../../helpers/getRandomColors";
import CmacModal from "../../../plugins/cmac-plugins/CmacModal";
import { FaPlus } from "react-icons/fa6";
import moment from "moment";
import { Link } from "react-router-dom";
import { PRODUCT_REVIEW_PAGE } from "../../../../helpers/paths";
import { BsFillPersonFill } from "react-icons/bs";
import { LiaStarSolid } from "react-icons/lia";
import { FaCheckCircle } from "react-icons/fa";
import { PiWarningCircleFill } from "react-icons/pi";

const ProductInfo = ({ handleSelectedSize }) => {
  const { product, loading } = useSelector((state) => state.productState);
  const { user } = useSelector((state) => state.userState);
  const { reviewsList, loading: reviewLoading } = useSelector(
    (state) => state.reviewState
  );
  const [selectedProductOption, setSelectedProductOption] = useState(1);
  const [selectedSize, setSelectedSize] = useState("");
  const [reviewSelectedImg, setReviewSelectedImg] = useState("");
  const [reviewImgModal, setReviewImgModal] = useState(false);
  const productDetailedOptions = [
    {
      id: 1,
      title: "About Product",
    },
    {
      id: 2,
      title: "Reviews",
    },
  ];
  const getQuantityStatement = () => {
    return (
      <div
        className="d_flex align_items_center gap_1"
        style={{
          color: Number(product.availableQuantity) <= 10 ? "rgb(230,22,25)" : "#01B532",
          fontWeight: 600,
        }}
      >
        {Number(product.availableQuantity) <= 10 ? (
          <PiWarningCircleFill size={15} />
        ) : (
          <FaCheckCircle size={15} />
        )}
        <div
          style={{
            fontSize: "12px",
          }}
        >
          {console.log(
            "product.availableQuantity: ",
            product.availableQuantity
          )}
          {Number(product.availableQuantity) === 0
            ? "Currently Unavailable!"
            : Number(product.availableQuantity) <= 10 && Number(product.availableQuantity) >= 1
            ? `Hurry up! Only ${Number(product.availableQuantity)} left in Stock!`
            : "Available! In Stock!"}
        </div>
      </div>
    );
  };
  const getOptionDetails = () => {
    if (selectedProductOption === 1) {
      return (
        <div className="mt_2">
          <div className="f_14 f_weight_1">
            {loading ? (
              <Skeleton
                height={"18px"}
                width={"100px"}
                baseColor="var(--cmac-default-shade-fill)"
                highlightColor="var(--cmac-default-fill)"
                duration={0.8}
              />
            ) : (
              "Description:"
            )}
          </div>
          {loading ? (
            <div className="mt_1">
              <Skeleton
                height={"20px"}
                width={"100%"}
                baseColor="var(--cmac-default-shade-fill)"
                highlightColor="var(--cmac-default-fill)"
                duration={0.8}
                className="mt_1"
              />
              <Skeleton
                height={"20px"}
                width={"100%"}
                baseColor="var(--cmac-default-shade-fill)"
                highlightColor="var(--cmac-default-fill)"
                duration={0.8}
                className="mt_1"
              />
              <Skeleton
                height={"20px"}
                width={"100%"}
                baseColor="var(--cmac-default-shade-fill)"
                highlightColor="var(--cmac-default-fill)"
                duration={0.8}
                className="mt_1"
              />
              <Skeleton
                height={"20px"}
                width={"100%"}
                baseColor="var(--cmac-default-shade-fill)"
                highlightColor="var(--cmac-default-fill)"
                duration={0.8}
                className="mt_1"
              />
            </div>
          ) : (
            <div
              style={{
                fontSize: "13px",
              }}
              className="f_weight_1 mt_1 opacity_0_4"
            >
              {product?.description}
            </div>
          )}
          <div className="f_14 f_weight_1 mt_2">
            {loading ? (
              <Skeleton
                height={"18px"}
                width={"160px"}
                baseColor="var(--cmac-default-shade-fill)"
                highlightColor="var(--cmac-default-fill)"
                duration={0.8}
              />
            ) : (
              "Safe & Secure Payment:"
            )}
          </div>
          {loading ? (
            <div className="mt_1">
              <Skeleton
                height={"20px"}
                width={"100%"}
                baseColor="var(--cmac-default-shade-fill)"
                highlightColor="var(--cmac-default-fill)"
                duration={0.8}
                className="mt_1"
              />
              <Skeleton
                height={"20px"}
                width={"100%"}
                baseColor="var(--cmac-default-shade-fill)"
                highlightColor="var(--cmac-default-fill)"
                duration={0.8}
                className="mt_1"
              />
              <Skeleton
                height={"20px"}
                width={"100%"}
                baseColor="var(--cmac-default-shade-fill)"
                highlightColor="var(--cmac-default-fill)"
                duration={0.8}
                className="mt_1"
              />
              <Skeleton
                height={"20px"}
                width={"100%"}
                baseColor="var(--cmac-default-shade-fill)"
                highlightColor="var(--cmac-default-fill)"
                duration={0.8}
                className="mt_1"
              />
            </div>
          ) : (
            <div
              style={{
                fontSize: "13px",
              }}
              className="f_weight_1 mt_1 opacity_0_4"
            >
              We offer safe and secure payment options to ensure a smooth
              purchasing experience. You can pay using your credit card or
              choose from a variety of other payment methods. All prices include
              VAT, making it easy to see the total cost upfront. Our streamlined
              ordering process ensures a hassle-free experience from start to
              finish.
            </div>
          )}
          <div className="f_14 f_weight_1 mt_2">
            {loading ? (
              <Skeleton
                height={"18px"}
                width={"160px"}
                baseColor="var(--cmac-default-shade-fill)"
                highlightColor="var(--cmac-default-fill)"
                duration={0.8}
              />
            ) : (
              "Delivery Policy:"
            )}
          </div>
          {loading ? (
            <div className="mt_1">
              <Skeleton
                height={"20px"}
                width={"100%"}
                baseColor="var(--cmac-default-shade-fill)"
                highlightColor="var(--cmac-default-fill)"
                duration={0.8}
                className="mt_1"
              />
              <Skeleton
                height={"20px"}
                width={"100%"}
                baseColor="var(--cmac-default-shade-fill)"
                highlightColor="var(--cmac-default-fill)"
                duration={0.8}
                className="mt_1"
              />
              <Skeleton
                height={"20px"}
                width={"100%"}
                baseColor="var(--cmac-default-shade-fill)"
                highlightColor="var(--cmac-default-fill)"
                duration={0.8}
                className="mt_1"
              />
              <Skeleton
                height={"20px"}
                width={"100%"}
                baseColor="var(--cmac-default-shade-fill)"
                highlightColor="var(--cmac-default-fill)"
                duration={0.8}
                className="mt_1"
              />
            </div>
          ) : (
            <div
              style={{
                fontSize: "13px",
              }}
              className="f_weight_1 mt_1 opacity_0_4"
            >
              Our delivery policy ensures a smooth and efficient shipping
              process for all orders. We offer multiple shipping options,
              including standard, expedited, and overnight delivery, with
              tracking information provided for each shipment. Orders are
              processed within 1-2 business days, and delivery times vary
              depending on the chosen method and destination. To maintain
              product quality, temperature-controlled packaging may be used
              during warmer months. For any issues with delivery, our customer
              service team is available to assist and resolve any concerns
              promptly.
            </div>
          )}
        </div>
      );
    } else if (selectedProductOption === 2) {
      const isAlreadyReviewed = reviewsList?.some(
        (data) => data?.userId === user?._id
      );
      return (
        <div className="mt_2">
          <div>
            <div className="f_14 f_weight_1">
              {reviewLoading ? (
                <Skeleton
                  height={"18px"}
                  width={"80px"}
                  baseColor="var(--cmac-default-shade-fill)"
                  highlightColor="var(--cmac-default-fill)"
                  duration={0.8}
                />
              ) : (
                "Ratings:"
              )}
            </div>
            <div className="f_weight_2 f_size_3 mt_1 opacity_0_8 d_flex align_items_center gap_3">
              {reviewLoading ? (
                <Skeleton
                  height={"30px"}
                  width={"50px"}
                  baseColor="var(--cmac-default-shade-fill)"
                  highlightColor="var(--cmac-default-fill)"
                  duration={0.8}
                />
              ) : (
                `${Number(product?.averageRating).toFixed(1)}`
              )}{" "}
              {reviewLoading ? (
                <Skeleton
                  height={"30px"}
                  width={"130px"}
                  baseColor="var(--cmac-default-shade-fill)"
                  highlightColor="var(--cmac-default-fill)"
                  duration={0.8}
                />
              ) : (
                <Link
                  to={`${PRODUCT_REVIEW_PAGE}?product_id=${product?._id}${
                    isAlreadyReviewed ? `&isEdit=true` : ""
                  }`}
                  className="add_review_btn"
                >
                  {isAlreadyReviewed ? "Update Review" : "Add a Review"}
                </Link>
              )}
            </div>
            <div className="d_flex align_items_center gap_3 mt_1">
              {reviewLoading ? (
                <Skeleton
                  height={"30px"}
                  width={"130px"}
                  baseColor="var(--cmac-default-shade-fill)"
                  highlightColor="var(--cmac-default-fill)"
                  duration={0.8}
                />
              ) : (
                <div
                  className="d_flex align_items_center f_size_3"
                  style={{
                    color: "#feaa02",
                  }}
                >
                  {Array.from({ length: Number(product?.averageRating) }).map(
                    (_, i) => {
                      return (
                        <LiaStarSolid
                          key={`filled-${i}`}
                          style={{ color: "#ffc249" }}
                        />
                      );
                    }
                  )}
                  {Array.from({
                    length: 5 - Number(product?.averageRating),
                  }).map((_, i) => {
                    return (
                      <LiaStarSolid
                        key={`unfilled-${i}`}
                        style={{ color: "#dcdcdc" }}
                      />
                    );
                  })}
                </div>
              )}
              {reviewLoading ? (
                <Skeleton
                  height={"30px"}
                  width={"40px"}
                  baseColor="var(--cmac-default-shade-fill)"
                  highlightColor="var(--cmac-default-fill)"
                  duration={0.8}
                />
              ) : (
                <div className="f_weight_2">({reviewsList?.length})</div>
              )}
            </div>
          </div>
          {reviewLoading ? (
            <Skeleton
              height={"18px"}
              width={"80px"}
              baseColor="var(--cmac-default-shade-fill)"
              highlightColor="var(--cmac-default-fill)"
              duration={0.8}
              className="mt_2"
            />
          ) : (
            reviewsList?.length > 0 && (
              <div className="f_14 f_weight_1 mt_2">Reviews:</div>
            )
          )}
          <div className="reviews_list">
            {reviewLoading ? (
              <div className="review_content">
                <div className="avatar_container">
                  <div className="avatar">
                    <Skeleton
                      height={"40px"}
                      width={"40px"}
                      baseColor="var(--cmac-default-shade-fill)"
                      highlightColor="var(--cmac-default-fill)"
                      duration={0.8}
                    />
                  </div>
                  <div>
                    <div className="d_flex align_items_center gap_2">
                      <Skeleton
                        height={"15px"}
                        width={"90px"}
                        baseColor="var(--cmac-default-shade-fill)"
                        highlightColor="var(--cmac-default-fill)"
                        duration={0.8}
                      />
                      <Skeleton
                        height={"15px"}
                        width={"90px"}
                        baseColor="var(--cmac-default-shade-fill)"
                        highlightColor="var(--cmac-default-fill)"
                        duration={0.8}
                      />
                    </div>
                    <div className="d_flex align_items_center gap_2">
                      <Skeleton
                        height={"15px"}
                        width={"60px"}
                        baseColor="var(--cmac-default-shade-fill)"
                        highlightColor="var(--cmac-default-fill)"
                        duration={0.8}
                      />
                      <Skeleton
                        height={"15px"}
                        width={"120px"}
                        baseColor="var(--cmac-default-shade-fill)"
                        highlightColor="var(--cmac-default-fill)"
                        duration={0.8}
                      />
                    </div>
                  </div>
                </div>
                <div className="d_flex align_items_center gap_2 mt_1">
                  <div className="d_flex align_items_center f_18">
                    <Skeleton
                      height={"20px"}
                      width={"130px"}
                      baseColor="var(--cmac-default-shade-fill)"
                      highlightColor="var(--cmac-default-fill)"
                      duration={0.8}
                    />
                  </div>
                  <div>
                    <Skeleton
                      height={"20px"}
                      width={"20px"}
                      baseColor="var(--cmac-default-shade-fill)"
                      highlightColor="var(--cmac-default-fill)"
                      duration={0.8}
                    />
                  </div>
                </div>
                <div>
                  <div className="user_review_imgs">
                    {Array.from({ length: 3 })?.map((imgData) => {
                      return (
                        <Skeleton
                          height={80}
                          width={80}
                          baseColor="var(--cmac-default-shade-fill)"
                          highlightColor="var(--cmac-default-fill)"
                          duration={0.8}
                        />
                      );
                    })}
                  </div>
                  <div className="mt_1 f_weight_2">
                    <Skeleton
                      height={"20px"}
                      width={"100%"}
                      baseColor="var(--cmac-default-shade-fill)"
                      highlightColor="var(--cmac-default-fill)"
                      duration={0.8}
                    />
                  </div>
                  <div
                    style={{
                      fontSize: "13px",
                    }}
                  >
                    <Skeleton
                      height={"15px"}
                      width={"100%"}
                      baseColor="var(--cmac-default-shade-fill)"
                      highlightColor="var(--cmac-default-fill)"
                      duration={0.8}
                      className="mt_2"
                    />
                    <Skeleton
                      height={"15px"}
                      width={"100%"}
                      baseColor="var(--cmac-default-shade-fill)"
                      highlightColor="var(--cmac-default-fill)"
                      duration={0.8}
                      className="mt_1"
                    />
                    <Skeleton
                      height={"15px"}
                      width={"100%"}
                      baseColor="var(--cmac-default-shade-fill)"
                      highlightColor="var(--cmac-default-fill)"
                      duration={0.8}
                      className="mt_1"
                    />
                    <Skeleton
                      height={"15px"}
                      width={"100%"}
                      baseColor="var(--cmac-default-shade-fill)"
                      highlightColor="var(--cmac-default-fill)"
                      duration={0.8}
                      className="mt_1"
                    />
                  </div>
                </div>
              </div>
            ) : reviewsList?.length > 0 ? (
              reviewsList?.map((data, i) => {
                return (
                  <div
                    className="review_content"
                    style={{
                      borderBottom:
                        i < reviewsList?.length - 1 && "1px dashed #c1c1c1",
                    }}
                  >
                    <div className="avatar_container">
                      <div
                        className="avatar"
                        style={{
                          background: data?.user?.firstName
                            ? data?.iconColor
                            : "#d8d8d8",
                          // color: randomcolor,
                        }}
                      >
                        {data?.user?.firstName ? (
                          data?.user?.firstName?.toUpperCase().split("")[0]
                        ) : (
                          <BsFillPersonFill size={25} />
                        )}
                      </div>
                      <div>
                        <div className="f_14 f_weight_2">
                          {data?.user?.firstName ? (
                            <span>
                              {data?.user?.firstName} {data?.user?.lastName}
                            </span>
                          ) : (
                            <span
                              style={{
                                color: "#fe7302",
                              }}
                            >
                              Revive Roots User
                            </span>
                          )}
                          {" | "}
                          <span className="f_10 opacity_0_6">
                            {moment(data?.createdAt).format("DD MMM YYYY")}
                          </span>
                        </div>
                        <div
                          className="f_12 f_weight_2 opacity_0_8"
                          style={{
                            color: "gray",
                          }}
                        >
                          {data?.purchasedSize ? data?.purchasedSize : ""}
                          {data?.isVerifiedPurchasedUser === "true" ||
                          data?.isVerifiedPurchasedUser === true
                            ? " | "
                            : ""}
                          <span
                            className="f_weight_2 f_10"
                            style={{
                              color: "#fe7302",
                            }}
                          >
                            {data?.isVerifiedPurchasedUser === "true" ||
                            data?.isVerifiedPurchasedUser === true
                              ? "Verified Purchase"
                              : ""}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="d_flex align_items_center gap_2 mt_1">
                      <div
                        className="d_flex align_items_center f_18"
                        style={{
                          color: "#feaa02",
                        }}
                      >
                        {Array.from({ length: Number(data?.ratingValue) }).map(
                          (_, i) => {
                            return (
                              <LiaStarSolid
                                key={`filled-${i}`}
                                style={{ color: "#ffc249" }}
                              />
                            );
                          }
                        )}
                        {Array.from({
                          length: 5 - Number(data?.ratingValue),
                        }).map((_, i) => {
                          return (
                            <LiaStarSolid
                              key={`unfilled-${i}`}
                              style={{ color: "#dcdcdc" }}
                            />
                          );
                        })}
                      </div>
                      <div className="f_weight_2 f_14 opacity_0_6">
                        {Number(data?.ratingValue).toFixed(1)}
                      </div>
                    </div>
                    <div>
                      <div className="user_review_imgs">
                        {data?.reviewImages?.map((imgData) => {
                          return (
                            <img
                              src={imgData?.url}
                              alt={imgData?.id}
                              height={80}
                              width={80}
                              onClick={() => {
                                setReviewSelectedImg(imgData);
                                setReviewImgModal(true);
                              }}
                              className="cursor_pointer"
                            />
                          );
                        })}
                      </div>
                      <div className="mt_1 f_weight_2">{data?.reviewTitle}</div>
                      <div
                        className="mt_1 f_weight_1 mt_1 opacity_0_4"
                        style={{
                          fontSize: "13px",
                        }}
                      >
                        {data?.reviewDetails}
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <div className="w_fill text_align_center p_4 f_14 f_weight_2 opacity_0_2 text_transform_uppercase">
                Be the first to review this product!
              </div>
            )}
          </div>
          <CmacModal
            active={reviewImgModal}
            onClickOutsider={() => {
              setReviewImgModal(false);
              setReviewSelectedImg("");
            }}
            className="review_img_modal"
            portalUsage={true}
            blurStrength="stronger"
          >
            <div className="close_container">
              <div
                className="close_ic"
                onClick={() => {
                  setReviewImgModal(false);
                  setReviewSelectedImg("");
                }}
              >
                <FaPlus className="ic" />
              </div>
            </div>
            <img src={reviewSelectedImg?.url} alt={reviewSelectedImg?.id} />
          </CmacModal>
        </div>
      );
    }
  };
  useEffect(() => {
    setSelectedSize(product);
    setSelectedProductOption(1);
  }, [product]);
  useEffect(() => {
    setSelectedSize(
      product?.availableSizes?.length > 0 && product?.availableSizes[0]
    );
    handleSelectedSize(
      product?.availableSizes?.length > 0 && product?.availableSizes[0]
    );
  }, [product]);
  return (
    <div className="product_info">
      <div>
        <div
          className="f_20 f_weight_2 letter_height_1"
          style={{
            width: "95%",
          }}
        >
          {loading ? (
            <Skeleton
              height={"25px"}
              width={"100%"}
              baseColor="var(--cmac-default-shade-fill)"
              highlightColor="var(--cmac-default-fill)"
              duration={0.8}
            />
          ) : (
            product.productName
          )}{" "}
        </div>
        <div className="product_ratings d_flex align_items_center gap_2 mt_1 cursor-pointer res-max-992px-d-none mt_2">
          <div className="d_flex align_items_center gap_1">
            <div className="d_flex align_items_center gap_1">
              {!loading && (
                <div className="d_flex align_items_center">
                  <IoIosStar
                    color="#feaa02"
                    className="d_flex align_items_center"
                  />
                </div>
              )}
              {loading ? (
                <Skeleton
                  height={"18px"}
                  width={"80px"}
                  baseColor="var(--cmac-default-shade-fill)"
                  highlightColor="var(--cmac-default-fill)"
                  duration={0.8}
                />
              ) : (
                <div className="f_12 font-weight-1 rate">
                  {Number(product?.averageRating).toFixed(1)} Ratings
                </div>
              )}
            </div>
          </div>
          {!loading && (
            <div className="d_flex align_items_center dot_ic">
              <GoDotFill size={10} />
            </div>
          )}
          <div className="d_flex align_items_center gap_1">
            <div className="f_12 reviews">
              {loading ? (
                <Skeleton
                  height={18}
                  width={80}
                  baseColor="var(--cmac-default-shade-fill)"
                  highlightColor="var(--cmac-default-fill)"
                  duration={0.8}
                />
              ) : (
                `${product?.reviewedUsers?.length} Reviews`
              )}
            </div>
          </div>

          {!loading && (
            <div className="d_flex align_items_center dot_ic">
              <GoDotFill size={10} />
            </div>
          )}
          <div className="f_12 sold">
            {loading ? (
              <Skeleton
                height={18}
                width={80}
                baseColor="var(--cmac-default-shade-fill)"
                highlightColor="var(--cmac-default-fill)"
                duration={0.8}
              />
            ) : (
              `${product?.verifiedPurchaseUsers?.length} Items Sold`
            )}
          </div>
        </div>
        <div className="mt_2">
          {loading ? (
            <Skeleton
              height={"20px"}
              width={"150px"}
              baseColor="var(--cmac-default-shade-fill)"
              highlightColor="var(--cmac-default-fill)"
              duration={0.8}
            />
          ) : (
            getQuantityStatement()
          )}
        </div>
        <div className="d_flex flex_direction_col gap_3 mt_2 product_sizes">
          <div className="f_14 f_weight_1">
            {loading ? (
              <Skeleton
                height={20}
                width={90}
                baseColor="var(--cmac-default-shade-fill)"
                highlightColor="var(--cmac-default-fill)"
                duration={0.8}
              />
            ) : (
              "Select Size:"
            )}
          </div>
          <div className="grid_product_sizes">
            {product?.availableSizes?.map((data) => {
              if (loading) {
                return (
                  <Skeleton
                    height={"45px"}
                    width={"70px"}
                    baseColor="var(--cmac-default-shade-fill)"
                    highlightColor="var(--cmac-default-fill)"
                    duration={0.8}
                  />
                );
              } else {
                return (
                  <div
                    className="product_size"
                    onClick={() => {
                      handleSelectedSize(data);
                      setSelectedSize(data);
                    }}
                    style={{
                      color:
                        !loading &&
                        selectedSize === data &&
                        "var(--cmac-primary-color)",
                      borderColor:
                        !loading &&
                        selectedSize === data &&
                        "var(--cmac-primary-color)",
                      transition: "0.6s",
                    }}
                  >
                    {data}
                  </div>
                );
              }
            })}
          </div>
        </div>
        <div className="product_detailed_options mt_3">
          {!loading && (
            <div className="options">
              {productDetailedOptions?.map((data) => {
                return (
                  <div
                    className={`option ${
                      data.id == selectedProductOption && "active_option"
                    }`}
                    onClick={() => setSelectedProductOption(data?.id)}
                    key={data?.id}
                  >
                    {data.title}
                  </div>
                );
              })}
            </div>
          )}
          <div>{getOptionDetails()}</div>
        </div>
      </div>
    </div>
  );
};

export default ProductInfo;
