import React from "react";
const HamburgerMenu = ({ checked, onClick, className }) => {
  return (
    <div
      className={`hamburger-menu ${className ? className : ""}`}
      onClick={() => onClick && onClick()}
    >
      <label for="check">
        <input type="checkbox" id="check" checked={checked} />
        <span></span>
        <span></span>
        <span></span>
      </label>
    </div>
  );
};

export default HamburgerMenu;
