import React, { useEffect, useState } from "react";
import {  CheckboxInput } from "../../../utils/Elements";
import { currencyFormatter } from "../../../../helpers/currencyFormatter";
import { IoIosArrowRoundUp } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { getProducts } from "../../../../redux/actions/productsAction";
import { getQueryParam } from "../../../../helpers/getQueryParams";
import { Link } from "react-router-dom";
import { AUTH_PAGE, PRODUCT_DETAILS_PAGE, SHOP_PAGE } from "../../../../helpers/paths";
import { PiWarningCircleFill } from "react-icons/pi";
import { IoCheckmarkCircle } from "react-icons/io5";
import { useToast } from "../../../plugins/toaster/useToast";
import { addCart, addTempCart } from "../../../../redux/actions/cartAction";
import { clearCartActionStatus } from "../../../../redux/slices/cartSlice";
import { performCrypto } from "../../../../helpers/performCrypto";

const FilterBar = ({
  background = "var(--cmac-default-shade-fill)",
  setFilterValuesData,
}) => {
  const crypto = performCrypto()
  const toast = useToast();
  const dispatch = useDispatch();
  const categoriesQuery = getQueryParam("c");
  const searchQuery = getQueryParam("s");
  const [categories, setCategories] = useState([]);
  const { categoryProducts } = useSelector(
    (state) => state.categoryProductsState
  );
  const { recommendedProducts } = useSelector(
    (state) => state.recommendedProductsState
  );
  const { isAuthenticated } = useSelector((state) => state.userState);
  const {
    loading: cartLoading,
    reqFrom,
    allowToast,
    message,
    status: cartStatus,
  } = useSelector((state) => state.cartState);
  
  const handleCartCreation = (product) => {
    const payload = {
      productId: product._id,
      quantity: 1,
      selectedSize: product?.availableSizes[0],
    };
    if (isAuthenticated) {
      dispatch(addCart(payload, true, "productInfoPage"));
    } else {
      dispatch(addTempCart(payload, true, "productInfoPage"));
    }
  };
  const formatProductsByCategory = () => {
    const formattedData = {};

    categoryProducts.forEach((product) => {
      const category = product.productCategory;
      const productDetails = {
        ...product,
      };
      if (formattedData[category]) {
        formattedData[category].push(productDetails);
      } else {
        formattedData[category] = [productDetails];
      }
    });
    const result = Object.keys(formattedData).map((category) => ({
      category,
      products: formattedData[category],
    }));

    return result;
  };
  const handleSizeFilter = (e) => {
    let categories_temp = [];
    if (categories?.some((size) => size === e)) {
      categories_temp = categories.filter((size) => size !== e);
      setCategories(categories_temp);
    } else {
      categories_temp = [...categories, e];
      setCategories(categories_temp);
    }
    const queries = `product_status=active&limit=10&${
      categories_temp.length > 0 &&
      `product_category=${categories_temp.toString()}`
    }${searchQuery && `keyword=${searchQuery}`}`;
    setFilterValuesData([`product_category=${categories_temp.toString()}`]);
    dispatch(getProducts(queries));
  };
  const displayToast = (icon, toastMessage) => {
    return (
      <div className="add_cart_toast height-30 display-flex">
        <div className="display-flex align-items-center">{icon}</div>
        <div className="toast_message">{toastMessage}</div>
      </div>
    );
  };
  useEffect(() => {
    if (categoriesQuery) {
      const queries = `product_status=active&limit=10&${
        categoriesQuery && `product_category=${categoriesQuery}`
      }`;
      setCategories([...categoriesQuery.split(","), ...categories]);
      dispatch(getProducts(queries));
    }
  }, [categoriesQuery]);
  useEffect(() => {
    switch (cartStatus) {
      case "success": {
        if (allowToast) {
          toast.open(
            displayToast(
              <IoCheckmarkCircle color="#37cd00" size={30} />,
              message
            ),
            {
              toastWidth: "fit-content",
              toastMsgWidth: "fit-content",
            }
          );
        }
        dispatch(clearCartActionStatus());
        break;
      }
      case "fail": {
        if (allowToast) {
          toast.open(
            displayToast(
              <PiWarningCircleFill color="#f00" size={30} />,
              message
            ),
            {
              toastWidth: "fit-content",
              toastMsgWidth: "fit-content",
            }
          );
        }
        dispatch(clearCartActionStatus());
        break;
      }
      default: {
        // dispatch(clearCartActionStatus());
        break;
      }
    }
  }, [cartStatus]);
  return (
    <div
      className="filter_bar"
      style={{
        background: background,
      }}
    >
      <div>
        {/* <CheckboxInput isChecked={true}/> */}
        <div className="d_flex align_items_center gap_2">
          <h3>Categories</h3>
          <span className="dash_item"></span>
        </div>
        <div className="filter_item">
          {formatProductsByCategory()?.map((item) => {
            const getAppliedSizes = () => {
              if (categories?.some((data) => data === item.category)) {
                return true;
              } else {
                return false;
              }
            };
            return (
              <div className="filter_item_container">
                <CheckboxInput
                  isChecked={getAppliedSizes()}
                  onClick={() => handleSizeFilter(item.category)}
                  className="check_box_input"
                />
                {/* <CheckboxInput
                  onClick={() => handleSelectProduct(productData)}
                  style={{
                    height: "16px",
                    width: "16px",
                    position: "relative",
                    top: "1.5px",
                  }}
                  className="check_box_input"
                  isChecked={isChecked}
                /> */}
                <p>{item.category}</p>
                {/* <div className="mt_1 mb_1 filter-value">{item}</div> */}
              </div>
            );
          })}
        </div>
      </div>
      <div>
        <div className="d_flex align_items_center gap_2 mt_2">
          <h3>Price</h3>
          <span className="dash_item"></span>
        </div>
        <div className="filter_item">
          <div className="filter_item_container">
            <CheckboxInput disabled className="check_box_input" />
            <p>Rs. 100 - Rs. 250</p>
          </div>
          <div className="filter_item_container">
            <CheckboxInput disabled className="check_box_input" />
            <p>Rs. 250 - Rs. 500</p>
          </div>
          <div className="filter_item_container">
            <CheckboxInput disabled className="check_box_input" />
            <p>Rs. 500 - Rs. 1000</p>
          </div>
          <div className="filter_item_container">
            <CheckboxInput disabled className="check_box_input" />
            <p>Rs. 1000 - Rs. 2000</p>
          </div>
        </div>
      </div>
      <div>
        <div className="d_flex align_items_center gap_2 mt_2">
          <h3>Tags</h3>
          <span className="dash_item"></span>
        </div>
        <div className="filter_item tags">
          <div className="d_flex align_items_center gap_2">
            <p>Hair Serum</p>
            <p>Shampoo</p>
            <p>Hair Oil</p>
          </div>
          <div className="d_flex align_items_center gap_2 mt_1">
            <p>Rs. 200 - Rs. 500</p>
            <p>Under Rs. 500</p>
          </div>
        </div>
      </div>

      <div>
        <div className="d_flex align_items_center gap_2 mt_2">
          <h3>Suggested</h3>
          <span className="dash_item"></span>
        </div>
        <div className="filter_item suggested_items">
          {recommendedProducts.map((product, i) => {
            if (i < 2) {
              return (
                <Link
                  to={`${PRODUCT_DETAILS_PAGE}?product_id=${product?._id}`}
                  className="d_flex gap_2 mb_2 filter_item_link"
                  style={{
                    color: "var(--cmac-dark-fill)",
                  }}
                >
                  <div>
                    <img
                      src={product?.productImages[0]?.url}
                      alt={product._id}
                      height={90}
                      width={90}
                    />
                  </div>
                  <div>
                    <h5>{product.productName}</h5>
                    <div
                      className="d_flex align_items_center price mb_1"
                      style={{
                        color: "var(--cmac-dark-fill)",
                        marginTop: "5px",
                      }}
                    >
                      <div
                        style={{
                          fontSize: "12px",
                          fontWeight: "600",
                        }}
                      >
                        Rs. {currencyFormatter(product.salePrice, "INR")}
                      </div>
                    </div>
                    <div
                      className="cursor-pointer d_flex align_items_center f_weight_2"
                      style={{
                        borderBottom: "1px solid var(--cmac-primary-color)",
                        fontSize: "12px",
                        width: "fit-content",
                        color: "var(--cmac-primary-color)",
                        opacity:
                          cartLoading && reqFrom === "productInfoPage"
                            ? "0.5"
                            : "1",
                        cursor:
                          cartLoading && reqFrom === "productInfoPage"
                            ? "default"
                            : "pointer",
                      }}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        handleCartCreation(product);
                      }}
                      disabled={cartLoading}
                    >
                      Add Item <IoIosArrowRoundUp size={18} />
                    </div>
                  </div>
                </Link>
              );
            } else {
              return <></>;
            }
          })}
        </div>
      </div>
    </div>
  );
};

export default FilterBar;
