import React, { useEffect, useState } from "react";
import { Button, Input } from "../../../utils/Elements";
import brand_src from "../../../../assets/imgs/brand/revive.png";
import {
  AUTH_SERVICE_PAGE,
  CHECKOUT_PAGE,
  LANDING_PAGE,
} from "../../../../helpers/paths";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  authenticateUser,
  sendSecretCode,
} from "../../../../redux/actions/userAction";
import { clearUserActionStatus } from "../../../../redux/slices/userSlice";
import { PiWarningCircleFill } from "react-icons/pi";
import { IoCheckmarkCircle } from "react-icons/io5";
import { useToast } from "../../../plugins/toaster/useToast";
import moment from "moment";
import { clearSecretCodeActionStatus } from "../../../../redux/slices/secretCodeSlice";
import InfinityLoader from "../../../../assets/imgs/loaders/InfinityLoader";
import { performCrypto } from "../../../../helpers/performCrypto";

const AuthSecretCodeInput = () => {
  const [codeInput, setCodeInput] = useState(new Array(6).fill(""));
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const toast = useToast();
  const dispatch = useDispatch();
  const crypto = performCrypto();
  const {
    info,
    loading,
    allowToast: secretCodeAllowToast,
    status: secretCodeStatus,
    message: secretCodemessage,
  } = useSelector((state) => state.secretCodeState);
  const {
    status,
    allowToast,
    message,
    loading: userLoading,
  } = useSelector((state) => state.userState);
  const [remainingTime, setRemainingTime] = useState(0);
  const [reSendEnableTime, setReSendEnableTime] = useState(0);
  const [enableResend, setEnableResend] = useState(false);
  const handleSecretCodeChange = (e, i) => {
    let targetValue = e.target.value;
    if (isNaN(e.target.value)) return false;
    else if (e.target.value.length > 1)
      if (e.target.nextSibling) {
        targetValue = e.target.value.split("")[1];
        e.target.nextSibling.focus();
      } else {
        targetValue = e.target.value.split("")[1];
      }

    setCodeInput([
      ...codeInput.map((data, idx) => (idx === i ? targetValue : data)),
    ]);
    if (e.target.value && e.target.nextSibling) {
      e.target.nextSibling.focus();
    }
  };
  const handleResendCode = () => {
    const payload = {
      mobileNumber: info?.mobileNumber,
      reSendCode: true,
    };
    if (info?.mobileNumber) {
      dispatch(sendSecretCode(payload, true));
    }
  };
  const handleAuthenticateUser = (e) => {
    e.preventDefault();
    const payload = {
      mobileNumber: info?.mobileNumber,
      secretCodeClient: codeInput.join(""),
    };
    if (payload.secretCodeClient) {
      dispatch(authenticateUser(payload, info?.authCode, true));
    }
  };
  const displayToast = (icon, toastMessage) => {
    return (
      <div className="add_cart_toast height-30 d_flex">
        <div className="d_flex align_items_center">{icon}</div>
        <div className="toast_message">{toastMessage}</div>
      </div>
    );
  };

  const calculateRemainingTime = () => {
    const currentTime = moment();
    const expirationTime = moment(info?.expiresOn);
    const diff = expirationTime.diff(currentTime);
    const remainingSeconds = Math.max(0, Math.floor(diff / 1000));
    return remainingSeconds;
  };
  const calculateRemainingResendTime = () => {
    const currentTime = moment();
    const resendEnableTime = moment(info?.resendEnablesIn);
    const diff = resendEnableTime.diff(currentTime);
    const remainingSeconds = Math.max(0, Math.floor(diff / 1000));
    return remainingSeconds;
  };

  const formatTime = (seconds) => {
    const duration = moment.duration(seconds, "seconds");
    const minutes = Math.floor(duration.asMinutes());
    const remainingSeconds = Math.floor(duration.asSeconds()) % 60;
    return `${minutes < 10 ? "0" : ""}${minutes}:${
      remainingSeconds < 10 ? "0" : ""
    }${remainingSeconds}`;
  };
  const formatReSendTime = (seconds) => {
    const currentTime = moment();
    const duration = moment.duration(seconds, "seconds");
    const remainingSeconds = Math.floor(duration.asSeconds()) % 60;
    const resendEnableTime = moment(info?.resendEnablesIn);
    const diff = resendEnableTime.diff(currentTime);
    const remainingSecondsCheck = Math.max(0, Math.floor(diff / 1000));
    if (remainingSeconds === 0 && remainingSecondsCheck === 0) {
      setEnableResend(true);
    }
    return `Resend Code in ${remainingSeconds}s`;
  };

  useEffect(() => {
    if (!loading) {
      const interval = setInterval(() => {
        setRemainingTime(calculateRemainingTime());
        setReSendEnableTime(calculateRemainingResendTime());
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [remainingTime, reSendEnableTime, loading]);

  useEffect(() => {
    setEnableResend(false);
    if (!info?.authCode) {
      navigate(AUTH_SERVICE_PAGE);
    }
  }, []);
  useEffect(() => {
    switch (status) {
      case "success": {
        if (allowToast) {
          toast.open(
            displayToast(
              <IoCheckmarkCircle color="#37cd00" size={30} />,
              message
            ),
            {
              toastWidth: "fit-content",
              toastMsgWidth: "fit-content",
            }
          );
        }
        dispatch(clearUserActionStatus());
        const preceding_route =
          crypto.get("preceding_route")?.decryptedData || null;

        if (preceding_route?.length > 0) {
          setTimeout(() => {
            localStorage.removeItem("preceding_route");
            window.location.replace(preceding_route);
          }, 2000);
        } else {
          setTimeout(() => {
            window.location.replace(LANDING_PAGE);
          }, 2000);
        }
        break;
      }
      case "fail": {
        if (allowToast) {
          toast.open(
            displayToast(
              <PiWarningCircleFill color="#f00" size={30} />,
              message
            ),
            {
              toastWidth: "fit-content",
              toastMsgWidth: "fit-content",
            }
          );
        }
        dispatch(clearUserActionStatus());
        break;
      }
      default: {
        dispatch(clearUserActionStatus());
      }
    }
  }, [status]);
  useEffect(() => {
    switch (secretCodeStatus) {
      case "success": {
        if (secretCodeAllowToast) {
          setEnableResend(false);
          toast.open(
            displayToast(
              <IoCheckmarkCircle color="#37cd00" size={30} />,
              secretCodemessage
            ),
            {
              toastWidth: "fit-content",
              toastMsgWidth: "fit-content",
            }
          );
        }
        dispatch(clearSecretCodeActionStatus());
        break;
      }
      case "fail": {
        if (secretCodeAllowToast) {
          toast.open(
            displayToast(
              <PiWarningCircleFill color="#f00" size={30} />,
              secretCodemessage
            ),
            {
              toastWidth: "fit-content",
              toastMsgWidth: "fit-content",
            }
          );
        }
        dispatch(clearSecretCodeActionStatus());
        break;
      }
      default: {
        dispatch(clearSecretCodeActionStatus());
      }
    }
  }, [secretCodeStatus]);
  return (
    <div className="auth_input">
      <a
        href={LANDING_PAGE}
        className="d_flex align_items_center justify_content_center cursor_pointer _brand_container"
      >
        <img src={brand_src} alt="brand_src" height={"60px"} />
        {/* LOGO */}
      </a>
      <div className="email_input_title">
        Please enter the six-digit secret code you received in your email. The
        code will expire in 15 minutes.
      </div>
      <form onSubmit={handleAuthenticateUser}>
        <div className="code_area">
          {codeInput.map((data, i) => {
            return (
              <input
                type="text"
                value={data}
                onChange={(e) => handleSecretCodeChange(e, i)}
              />
            );
          })}
        </div>
        <div className="secret_code_actions mt_1 mb_3">
          <div
            className={`resend ${!enableResend && "disabled"}`}
            onClick={() => enableResend && handleResendCode()}
          >
            {enableResend ? "Resend Code?" : formatReSendTime(reSendEnableTime)}
          </div>
          <div>
            Expires in {remainingTime > 0 ? formatTime(remainingTime) : "00:00"}
          </div>
        </div>
        <Button
          className="email_continue_btn"
          type={"submit"}
          disabled={codeInput.length !== 6 || userLoading ? true : false}
        >
          {userLoading ? (
            <div className="d_flex align_items_center justify_content_center gap_2">
              <InfinityLoader height={40} width={40} />
              <span
                style={{
                  fontSize: "14px",
                }}
              >
                Validating Code
              </span>
            </div>
          ) : (
            "Continue"
          )}
        </Button>
      </form>
      {/* <div className="dash_line"></div> */}
      <div className="rights_template">
        Reviveroots - © All Rights Reserved - 2024
      </div>
    </div>
  );
};

export default AuthSecretCodeInput;
