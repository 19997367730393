import CmacSideDragger from "../../plugins/cmac-plugins/cmac-side-dragger/CmacSideDragger";
import { PiHandbagSimpleBold } from "react-icons/pi";
import { currencyFormatter } from "../../../helpers/currencyFormatter";
import {
  CmacIcCurrencyINR,
  CmacIcStoreBuy,
} from "../../../assets/icons/CmacIcons";
import {
  IoIosArrowRoundUp,
  IoIosArrowForward,
  IoIosClose,
} from "react-icons/io";
import { FaMinus, FaPlus } from "react-icons/fa6";
import { CmacVectorEmptyBag } from "../../../assets/icons/CmacVectors";
import { Link } from "react-router-dom";
import { CHECKOUT_PAGE } from "../../../helpers/paths";
import { useEffect, useState } from "react";
import { CheckboxInput, Input } from "../../utils/Elements";
import { FaSearch } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { generateUUID } from "../../../helpers/generateUUID";
import { quickBuyAddCart } from "../../../redux/actions/cartAction";
import InfinityLoader from "../../../assets/imgs/loaders/InfinityLoader";
import Skeleton from "react-loading-skeleton";

const QuickBuy = ({ quickBuyDraggerOpen, onCloseQuickBuyDragger }) => {
  const dispatch = useDispatch();
  const [showProducts, setShowProducts] = useState([0]);
  const { quickBuyProducts, loading: quickBuyProductsLoading } = useSelector(
    (state) => state.quickBuyProductsState
  );
  const { loading, reqFrom, statusAction } = useSelector(
    (state) => state.cartState
  );
  const [formattedProducts, setFormattedProducts] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const demoProducts = [
    {
      id: 1,
      category: "Dark Chocolate",
      products: [
        {
          id: 1,
          title: "HERSHEY'S Special Dark Chocolate",
        },
        {
          id: 2,
          title: "Amul Sugar Free Dark Chocolate",
        },
      ],
    },
    {
      id: 2,
      category: "White Chocolate",
      products: [
        {
          id: 1,
          title: "Royal Milk Chocolate - Made In Europe",
        },
        {
          id: 2,
          title: "Ooty Homemade Chocolate - Plain Milk Molding Chocolates",
        },
      ],
    },
    {
      id: 3,
      category: "Milk Chocolate",
      products: [
        {
          id: 1,
          title: "Royal Milk Chocolate - Made In Europe",
        },
        {
          id: 2,
          title: "Ooty Homemade Chocolate - Plain Milk Molding Chocolates",
        },
      ],
    },
  ];
  const handleSelectProduct = (e, action) => {
    const productFound = selectedProducts.some((data) => data?._id === e?._id);
    const removeFoundProduct = selectedProducts.filter(
      (data) => data?._id !== e?._id
    );
    if (productFound && action !== "q") {
      setSelectedProducts(removeFoundProduct);
    } else {
      setSelectedProducts([...removeFoundProduct, e]);
    }
  };
  const handleMoveItemsToCart = () => {
    if ((loading && reqFrom === "quickBuy") || selectedProducts.length === 0) {
      return;
    }
    const payload = selectedProducts.map((data) => {
      return {
        productId: data?._id,
        quantity: data?.quantity,
      };
    });
    dispatch(quickBuyAddCart(payload, "quickBuy"));
  };
  useEffect(() => {
    setShowProducts([1]);
  }, [quickBuyDraggerOpen]);
  useEffect(() => {
    const formatedQuickProducts = quickBuyProducts?.reduce((acc, product) => {
      const productCategory = product.productCategory;
      if (!acc[productCategory]) {
        acc[productCategory] = [];
      }
      acc[productCategory].push(product);
      return acc;
    }, {});
    const categoriesMapped = Object.entries(formatedQuickProducts).map(
      ([productCategory, products]) => {
        return {
          id: generateUUID(),
          productCategory,
          products: products?.map((data) => {
            return {
              ...data,
              quantity: 1,
            };
          }),
        };
      }
    );
    setFormattedProducts(categoriesMapped);
  }, [quickBuyProducts]);

  useEffect(() => {
    switch (statusAction) {
      case "quick-buy-cart-success": {
        onCloseQuickBuyDragger();
        break;
      }
      default: {
        // dispatch(clearCartActionStatus());
        break;
      }
    }
  }, [statusAction]);
  return (
    <CmacSideDragger
      open={quickBuyDraggerOpen}
      dragPosition={"right"}
      onClose={() => onCloseQuickBuyDragger && onCloseQuickBuyDragger(false)}
      overlayBackground={"transparent"}
      style={{
        boxShadow: "#0000007d 0px 0px 50px 1px",
      }}
      background={"var(--cmac-secondary-color)"}
      className={"font_lato"}
    >
      <div
        className="quick_buy"
        style={{
          height: "100%",
          padding: "20px",
        }}
      >
        <div
          className="display-flex align-items-center justify-content-space-between"
          style={{
            height: "50px",
            // background: 'red',
            borderBottom: "2px solid #80808038",
          }}
        >
          <div className="display-flex align-items-center justify-content-center gap-5">
            <div className="display-flex align-items-center justify-content-center">
              <CmacIcStoreBuy heightVal={25} widthVal={25} />
            </div>
            <div
              style={{
                fontSize: "18px",
                textTransform: "uppercase",
                fontWeight: "900",
              }}
            >
              Quick Buy
            </div>
          </div>
          <div
            onClick={() => onCloseQuickBuyDragger && onCloseQuickBuyDragger()}
          >
            <IoIosClose
              size={30}
              style={{
                scale: "1.3",
                cursor: "pointer",
              }}
            />
          </div>
        </div>
        <div
          className="items_container"
          style={{
            minHeight: "calc(var(--cmac-doc-height) - 160px)",
            maxHeight: "calc(var(--cmac-doc-height) - 160px)",
            overflow: "auto",
            borderBottom: "2px solid #80808038",
          }}
        >
          <div className="display-flex align-items-center mt-1">
            <input
              placeholder="Search for Products!"
              style={{
                width: "-webkit-fill-available",
                height: "10px",
                padding: "15px",
                border: "1px solid #00000026",
                outline: "none",
                background: "#8f563d23",
                borderRight: "none",
                fontSize: "15px",
                letterSpacing: "1px",
              }}
              className="search_input font_lato"
            />
            <div
              className="display-flex align-items-center justify-content-center"
              style={{
                height: "40px",
                width: "50px",
                border: "1px solid #00000026",
                outline: "none",
                background: "#8f563d23",
                borderLeft: "none",
              }}
            >
              <FaSearch />
            </div>
          </div>

          {quickBuyProductsLoading &&
            Array.from({ length: 2 }).map(() => {
              return (
                <div
                  style={{
                    border: "1px dashed #0000002c",
                    padding: "15px",
                    cursor: "pointer",
                    position: "relative",
                    top: "8px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                  className="mt-1"
                >
                  <Skeleton
                    height={18}
                    width={120}
                    baseColor="#F8E7D8"
                    highlightColor="#fff0e3"
                    duration={0.8}
                  />
                  <Skeleton
                    height={18}
                    width={18}
                    baseColor="#F8E7D8"
                    highlightColor="#fff0e3"
                    duration={0.8}
                  />
                </div>
              );
            })}
          {/* Items Listing */}

          {!quickBuyProductsLoading && (
            <div className="mt-2">
              {formattedProducts?.map((data) => {
                const foundProduct = showProducts?.some(
                  (item) => item === data?.id
                );
                return (
                  <div
                    style={{
                      border: "1px dashed #0000002c",
                      padding: "15px",
                      cursor: "pointer",
                    }}
                    className="mt-1"
                    onClick={() => {
                      if (foundProduct) {
                        setShowProducts(
                          showProducts.filter((item) => item !== data?.id)
                        );
                      } else {
                        setShowProducts([...showProducts, data?.id]);
                      }
                    }}
                  >
                    <div
                      className="display-flex align-items-center justify-content-space-between"
                      style={{
                        // height: "40px",
                        fontSize: "14px",
                        fontWeight: 600,
                      }}
                    >
                      <div>{data?.productCategory}</div>
                      <div>
                        <IoIosArrowForward
                          size={18}
                          style={{
                            transform: foundProduct
                              ? "rotate(270deg)"
                              : "rotate(90deg)",
                            transition: "transform 0.3s ease",
                          }}
                        />
                      </div>
                    </div>
                    <div
                      onClick={(e) => e.stopPropagation()}
                      style={{
                        cursor: "default",
                      }}
                    >
                      {showProducts?.length > 0 &&
                        foundProduct &&
                        data.products?.map((productData) => {
                          const isChecked = selectedProducts.some(
                            (data) => data?._id === productData?._id
                          );
                          return (
                            <div className="display-flex gap-2 mt-2">
                              <CheckboxInput
                                onClick={() => handleSelectProduct(productData)}
                                style={{
                                  height: "16px",
                                  width: "16px",
                                  position: "relative",
                                  top: "1.5px",
                                }}
                                className="check_box_input"
                                isChecked={isChecked}
                              />
                              <div>
                                <div
                                  style={{
                                    fontSize: "12px",
                                    fontWeight: 600,
                                  }}
                                >
                                  {productData?.productName}
                                </div>
                                <div
                                  className="display-flex align-items-center gap-2 mt-1"
                                  style={{
                                    fontSize: "12px",
                                    fontWeight: "600",
                                  }}
                                >
                                  <div
                                    className="display-flex align-items-center justify-content-center"
                                    style={{
                                      height: "20px",
                                      width: "20px",
                                      border: "1px solid gray",
                                      borderRadius: "5px",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      const productIndex =
                                        data.products.findIndex(
                                          (product) =>
                                            product._id === productData._id
                                        );

                                      if (productIndex !== -1) {
                                        const updatedProduct = {
                                          ...data.products[productIndex],
                                          quantity:
                                            data.products[productIndex]
                                              .quantity !== 1
                                              ? data.products[productIndex]
                                                  .quantity - 1
                                              : 1,
                                        };

                                        const updatedProducts = [
                                          ...data.products.slice(
                                            0,
                                            productIndex
                                          ),
                                          updatedProduct,
                                          ...data.products.slice(
                                            productIndex + 1
                                          ),
                                        ];

                                        const updatedCategory = {
                                          ...data,
                                          products: updatedProducts,
                                        };

                                        const updatedFormattedProducts =
                                          formattedProducts.map(
                                            (formattedProduct) =>
                                              formattedProduct.id === data.id
                                                ? updatedCategory
                                                : formattedProduct
                                          );

                                        setFormattedProducts(
                                          updatedFormattedProducts
                                        );
                                        handleSelectProduct(
                                          {
                                            ...productData,
                                            quantity:
                                              productData.quantity !== 1
                                                ? productData.quantity - 1
                                                : 1,
                                          },
                                          "q"
                                        );
                                      }
                                    }}
                                  >
                                    <FaMinus />
                                  </div>
                                  <div
                                    className="display-flex align-items-center justify-content-center"
                                    style={{
                                      height: "20px",
                                      minWidth: "15px",
                                    }}
                                  >
                                    {productData?.quantity}
                                  </div>
                                  <div
                                    className="display-flex align-items-center justify-content-center"
                                    style={{
                                      height: "20px",
                                      width: "20px",
                                      border: "1px solid gray",
                                      borderRadius: "5px",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      const productIndex =
                                        data.products.findIndex(
                                          (product) =>
                                            product._id === productData._id
                                        );

                                      if (productIndex !== -1) {
                                        const updatedProduct = {
                                          ...data.products[productIndex],
                                          quantity:
                                            data.products[productIndex]
                                              .quantity + 1,
                                        };

                                        const updatedProducts = [
                                          ...data.products.slice(
                                            0,
                                            productIndex
                                          ),
                                          updatedProduct,
                                          ...data.products.slice(
                                            productIndex + 1
                                          ),
                                        ];

                                        const updatedCategory = {
                                          ...data,
                                          products: updatedProducts,
                                        };

                                        const updatedFormattedProducts =
                                          formattedProducts.map(
                                            (formattedProduct) =>
                                              formattedProduct.id === data.id
                                                ? updatedCategory
                                                : formattedProduct
                                          );

                                        setFormattedProducts(
                                          updatedFormattedProducts
                                        );
                                        handleSelectProduct(
                                          {
                                            ...productData,
                                            quantity: productData.quantity + 1,
                                          },
                                          "q"
                                        );
                                      }
                                    }}
                                  >
                                    <FaPlus />
                                  </div>
                                </div>
                                <div className="display-flex align-items-center gap-1 mt-1">
                                  <div className="display-flex align-items-center">
                                    <CmacIcCurrencyINR
                                      heightVal={"10"}
                                      widthVal={"10"}
                                    />
                                  </div>
                                  <div
                                    style={{
                                      fontSize: "12px",
                                      fontWeight: "600",
                                    }}
                                  >
                                    {currencyFormatter(450, "INR")}
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                );
              })}
            </div>
          )}

          {/* Empty Items */}

          {/* <div
            className="display-flex align-items-center justify-content-center"
            style={{
              minHeight: "calc(var(--cmac-doc-height) - 420px)",
              maxHeight: "calc(var(--cmac-doc-height) - 500px)",
              flexDirection: "column",
              height: "100%",
            }}
          >
            <div>
              <CmacVectorEmptyBag heightVal={'120'} widthVal={'120'} />
            </div>
            <div
              style={{
                fontWeight: "600",
                color: "var(--cmac-secondary-color)",
              }}
            >
              Cart Is Empty
            </div>
          </div> */}
        </div>
        <div>
          {/* <div
            className="display-flex align-items-center justify-content-space-between"
            style={{
              fontWeight: "900",
            }}
          >
            <div>Suggested</div>
            <div>You Might Also Like</div>
          </div>
          <div
            className="display-flex justify-content-space-between gap-3 mt-2"
            style={{
              height: "130px",
              //   background: "red",
              borderBottom: "2px solid #80808038",
            }}
          >
            <img
              src="https://smoor.in/cdn/shop/files/DARKAPRICOT_1024x1024.jpg?v=1719759371"
              height={"100px"}
              width={"100px"}
              style={{
                borderRadius: "5px",
              }}
            />
            <div>
              <div
                style={{
                  fontSize: "12px",
                  textTransform: "capitalize",
                }}
              >
                True Treat - Rich Dark Chocolate Bar With Apricot
              </div>
              <div className="display-flex align-items-center gap-1 mt-1">
                <div className="display-flex align-items-center">
                  <CmacIcCurrencyINR heightVal={"12"} widthVal={"12"} />
                </div>
                <div
                  style={{
                    fontSize: "14px",
                    fontWeight: "600",
                  }}
                >
                  {currencyFormatter(250, "INR")}
                </div>
              </div>
              <div
                className="mt-1 cursor-pointer display-flex align-items-center"
                style={{
                  borderBottom: "1px solid var(--cmac-primary-color)",
                  fontSize: "12px",
                  width: "fit-content",
                  color: "var(--cmac-primary-color)",
                }}
              >
                Add Item <IoIosArrowRoundUp size={18} />
              </div>
            </div>
          </div> */}
          {/* <div
            className="display-flex align-items-center justify-content-space-between"
            style={{
              height: "70px",
              // background: 'red',
              borderBottom: "2px solid #80808038",
            }}
          >
            <div
              style={{
                fontSize: "20px",
                fontWeight: "900",
              }}
            >
              Total
            </div>
            <div className="display-flex align-items-center gap-1 mt-1">
              <div className="display-flex align-items-center">
                <CmacIcCurrencyINR heightVal={"16"} widthVal={"16"} />
              </div>
              <div
                style={{
                  fontSize: "18px",
                  fontWeight: "600",
                }}
              >
                {currencyFormatter(450, "INR")}
              </div>
            </div>
          </div> */}
          <div className="display-flex align-items-center justify-content-space-between mt-3">
            <button
              className="display-flex align-items-center justify-content-center gap-2 cursor-pointer"
              style={{
                height: "35px",
                width: "160px",
                background: "#fcd3bc",
                borderRadius: "50px",
                border: "1px solid transparent",
                fontWeight: "900",
                textTransform: "uppercase",
                fontSize: "11px",
              }}
              onClick={() => onCloseQuickBuyDragger()}
            >
              Continue Shopping
              <IoIosArrowForward
                style={{
                  scale: "1.3",
                }}
              />
            </button>
            <div
              onClick={handleMoveItemsToCart}
              className="display-flex align-items-center justify-content-center gap-2 cursor-pointer"
              style={{
                height: "35px",
                width: "150px",
                background: "#58d76a",
                borderRadius: "50px",
                border: "1px solid transparent",
                fontWeight: "900",
                textTransform: "uppercase",
                fontSize: "11px",
                textDecoration: "none",
                color: "var(--cmac-primary-color)",
                opacity:
                  (loading && reqFrom === "quickBuy") ||
                  selectedProducts.length === 0
                    ? "0.5"
                    : "1",
                cursor:
                  (loading && reqFrom === "quickBuy") ||
                  selectedProducts.length === 0
                    ? "default"
                    : "pointer",
              }}
            >
              {loading && reqFrom === "quickBuy" ? (
                <div className="display-flex align-items-center justify-content-center gap-3">
                  {/* <img src={loader_gif} alt="loader_gif" height={50} /> */}
                  <InfinityLoader height={50} width={50} />
                </div>
              ) : (
                <div className="display-flex align-items-center justify-content-center gap-2">
                  Move To Cart <IoIosArrowForward />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </CmacSideDragger>
  );
};

export default QuickBuy;
