import { useSelector } from "react-redux";
import { AUTH_PAGE, LANDING_PAGE } from "../../helpers/paths";
import { useEffect, useState } from "react";

const ProtectedRoute = ({ children, roles }) => {
  const {
    isAuthenticated,
    user,
    dataFetchInitiated = true,
  } = useSelector((state) => state.userState);

  const [showRoute, setShowRoute] = useState(false);

  useEffect(() => {
    if (!dataFetchInitiated) {
      setShowRoute(true);
    }
  }, [dataFetchInitiated]);

  if (!showRoute) {
    return "Data Fetching in Process...! Please Wait...!";
  }
  if (!isAuthenticated) {
    window.location.replace(AUTH_PAGE);
    return children;
  }
  if (roles?.length > 0 && roles?.some((role) => role !== user?.role)) {
    window.location.replace(LANDING_PAGE);
    return;
  }
  return children;
};

export default ProtectedRoute;
