import React, { useEffect, useState } from "react";
import CmacSideDragger from "../../plugins/cmac-plugins/cmac-side-dragger/CmacSideDragger";
import { useDispatch, useSelector } from "react-redux";
import { getCategoryProducts } from "../../../redux/actions/productsAction";
import { Link } from "react-router-dom";
import { FaAngleRight } from "react-icons/fa";
import { IoClose } from "react-icons/io5";
import { getProduct } from "../../../redux/actions/productAction";
import { PRODUCT_DETAILS_PAGE } from "../../../helpers/paths";

const CategorybarSideDragger = ({ sideDraggerOpen, onSideDraggerClose }) => {
  const dispatch = useDispatch();
  const { categoryProducts } = useSelector(
    (state) => state.categoryProductsState
  );
  const [productCategoryListData, setProductCategoryListData] = useState("");
  const [sideDraggerBetaOpen, setSideDraggerBetaOpen] = useState(false);
  const formatProductsByCategory = () => {
    const formattedData = {};

    // Group products by category
    categoryProducts.forEach((product) => {
      const category = product.productCategory;
      const productDetails = {
        ...product,
      };
      if (formattedData[category]) {
        formattedData[category].push(productDetails);
      } else {
        formattedData[category] = [productDetails];
      }
    });

    const result = Object.keys(formattedData).reduce(
      (acc, category) => {
        if (formattedData[category]?.length === 1) {
          acc.moreProducts.push(...formattedData[category]);
        } else {
          acc.categories.push({
            category,
            products: formattedData[category],
          });
        }
        return acc;
      },
      { categories: [], moreProducts: [] }
    );

    if (result.moreProducts.length > 0) {
      result.categories.push({
        category: "More Products",
        products: result.moreProducts,
      });
    }

    return result.categories;
  };

  useEffect(() => {
    dispatch(getCategoryProducts());
  }, []);

  return (
    <div>
      <CmacSideDragger
        open={sideDraggerOpen}
        dragPosition={"left"}
        onClose={() => onSideDraggerClose && onSideDraggerClose(false)}
        overlayBackground={"transparent"}
        style={{
          boxShadow: "#00000052 0px 0px 50px 1px",
        }}
        className={"font_lato p_2"}
        background={"var(--cmac-default-shade-fill)"}
      >
        <div
          className="d_flex align_items_center justify_content_space_between pb_2"
          style={{
            borderBottom: "2px solid #434343",
          }}
        >
          <div className="f_18 f_weight_2 text_transform_uppercase opacity_0_8">
            Quick Links
          </div>
          <IoClose size={25} onClick={() => onSideDraggerClose(false)} />
        </div>
        <div className="d_flex flex_direction_col gap_6 mt_3 opacity_0_8">
          {formatProductsByCategory()?.map((data) => {
            return (
              <div
                key={data?.category}
                className="text_decoration_unset cmac_dark_fill_text d_flex align_items_center gap_2 f_weight_2"
                onClick={() => {
                  setProductCategoryListData(data);
                  setSideDraggerBetaOpen(true);
                }}
              >
                <div>{data?.category}</div>
                <FaAngleRight
                  size={18}
                  style={{
                    position: "relative",
                    top: "2px",
                  }}
                />
              </div>
            );
          })}
        </div>
        <div
          style={{
            height: "100%",
            display: "flex",
            justifyContent: "center",
            padding: "40px",
            textDecoration: "none",
          }}
        >
          <div
            className="cmac_primary_color_text"
            style={{
              position: "absolute",
              bottom: "20px",
              fontSize: "14px",
              fontWeight: 600,
            }}
          >
            ReviveRoots - © All Rights Reserved - 2024
          </div>
        </div>
      </CmacSideDragger>
      <CmacSideDragger
        open={sideDraggerBetaOpen}
        dragPosition={"left"}
        onClose={() => setSideDraggerBetaOpen(false)}
        overlayBackground={"transparent"}
        style={{
          boxShadow: "#00000052 0px 0px 50px 1px",
        }}
        className={"font_lato p_2"}
        background={"var(--cmac-default-shade-fill)"}
      >
        <div
          className="d_flex align_items_center justify_content_space_between pb_2"
          style={{
            borderBottom: "2px solid #434343",
          }}
        >
          <div className="f_18 text_transform_uppercase f_weight_2 opacity_0_8">
            Products
          </div>
          <IoClose size={25} onClick={() => setSideDraggerBetaOpen(false)} />
        </div>
        <div className="d_flex flex_direction_col gap_6 mt_3 opacity_0_8">
          {productCategoryListData?.products?.map((data) => {
            return (
              <Link
                to={`${PRODUCT_DETAILS_PAGE}?product_id=${data?._id}`}
                className="text_decoration_underline cmac_dark_fill_text d_flex align_items_center gap_2 f_weight_2"
                onClick={() => {
                  dispatch(getProduct({ productId: data?._id }));
                  setSideDraggerBetaOpen(false);
                  onSideDraggerClose(false);
                }}
              >
                <div>{data?.productName}</div>
              </Link>
            );
          })}
        </div>
        <div
          style={{
            height: "100%",
            display: "flex",
            justifyContent: "center",
            padding: "40px",
            textDecoration: "none",
          }}
        >
          <div
            style={{
              position: "absolute",
              bottom: "20px",
              fontSize: "14px",
              fontWeight: 600,
            }}
          >
            ReviveRoots - © All Rights Reserved - 2024
          </div>
        </div>
      </CmacSideDragger>
    </div>
  );
};

export default CategorybarSideDragger;
