import axios from "axios";
import { BASE_URL, POSTAL_ADDRESS_API_URL } from "../../api/apiPath";
import { endPoints } from "../../api/endPoints";
import {
  orderDetailsFail,
  orderDetailsRequest,
  orderDetailsSuccess,
  orderCreateFail,
  orderCreateRequest,
  orderCreateSuccess,
  orderRequest,
  orderSuccess,
  orderFail,
  orderPaymentStatusRequest,
  orderPaymentStatusSuccess,
  orderPaymentStatusFail,
} from "../slices/orderSlice";
import {
  ordersRequest,
  ordersSuccess,
  ordersFail,
  allOrdersRequest,
  allOrdersSuccess,
  allOrdersFail,
} from "../slices/ordersSlice";
import { getVerificationToken } from "../../helpers/getVerificationToken";
import {
  postalDataFail,
  postalDataRequest,
  postalDataSuccess,
} from "../slices/postalSlice";
import { performCrypto } from "../../helpers/performCrypto";
import {
  printOrderInvoiceFail,
  printOrderInvoiceRequest,
  printOrderInvoiceSuccess,
} from "../slices/printOrderInvoiceSlice";

let orderStatusResDone = true;

export const getOrderDetails = () => async (dispatch) => {
  try {
    dispatch(orderDetailsRequest());
    const response = await axios.get(
      `${BASE_URL}/${endPoints.order_details.get}`,
      {
        withCredentials: true,
        headers: {
          Authorization: getVerificationToken().authToken,
          "x-verification-token": getVerificationToken().X_VERIFY_TOKEN,
        },
      }
    );
    const decryptedData = performCrypto().decode(response.data.encryptedData);
    dispatch(orderDetailsSuccess(decryptedData));
  } catch (error) {
    const decryptedData = performCrypto().decode(
      error?.response?.data?.encryptedData
    );
    dispatch(orderDetailsFail(decryptedData?.error));
  }
};

export const createOrder = (payload) => async (dispatch) => {
  try {
    dispatch(orderCreateRequest());
    const response = await axios.post(
      `${BASE_URL}/${endPoints.order.create_payment}?postal_code=${payload?.postalCode}&location=${payload?.location}`,
      payload,
      {
        withCredentials: true,
        headers: {
          Authorization: getVerificationToken().authToken,
          "x-verification-token": getVerificationToken().X_VERIFY_TOKEN,
        },
      }
    );
    const decryptedData = performCrypto().decode(response.data.encryptedData);
    dispatch(orderCreateSuccess(decryptedData));
  } catch (error) {
    const decryptedData = performCrypto().decode(
      error?.response?.data?.encryptedData
    );
    console.error("[ERROR]: ", decryptedData?.error?.message);
    dispatch(orderCreateFail(decryptedData?.error));
  }
};
export const getOrderPaymentStatus =
  (merchant_transaction_id, payload) => async (dispatch) => {
    try {
      if (orderStatusResDone) {
        orderStatusResDone = false;
        dispatch(orderPaymentStatusRequest());
        const response = await axios.post(
          `${BASE_URL}/${endPoints.order.payment_status}?merchant_transaction_id=${merchant_transaction_id}`,
          payload,
          {
            withCredentials: true,
            headers: {
              Authorization: getVerificationToken().authToken,
              "x-verification-token": getVerificationToken().X_VERIFY_TOKEN,
            },
          }
        );
        const decryptedData = performCrypto().decode(
          response.data.encryptedData
        );
        dispatch(orderPaymentStatusSuccess(decryptedData));
        orderStatusResDone = true
      }
    } catch (error) {
      const decryptedData = performCrypto().decode(
        error?.response?.data?.encryptedData
      );
      console.error("[ERROR]: ", decryptedData?.error?.message);
      dispatch(orderPaymentStatusFail(decryptedData?.error));
      orderStatusResDone = true
    }
  };

export const getPostalData = (postalCode) => async (dispatch) => {
  try {
    dispatch(postalDataRequest());
    const response = await axios.get(`${POSTAL_ADDRESS_API_URL}/${postalCode}`);
    dispatch(postalDataSuccess(response?.data[0]));
  } catch (error) {
    dispatch(postalDataFail(error));
  }
};
export const getOrder = (orderId) => async (dispatch) => {
  try {
    dispatch(orderRequest());
    const response = await axios.get(
      `${BASE_URL}/${endPoints.order.get}?order_id=${orderId}`,
      {
        withCredentials: true,
        headers: {
          Authorization: getVerificationToken().authToken,
          "x-verification-token": getVerificationToken().X_VERIFY_TOKEN,
        },
      }
    );
    const decryptedData = performCrypto().decode(response.data.encryptedData);
    dispatch(orderSuccess(decryptedData));
  } catch (error) {
    const decryptedData = performCrypto().decode(
      error?.response?.data?.encryptedData
    );
    console.error("[ERROR]: ", decryptedData?.error?.message);
    dispatch(orderFail(decryptedData?.error));
  }
};
export const getOrders = (payload) => async (dispatch) => {
  try {
    dispatch(ordersRequest());
    const response = await axios.get(
      `${BASE_URL}/${endPoints.orders.get}${payload ? payload : ""}`,
      {
        withCredentials: true,
        headers: {
          Authorization: getVerificationToken().authToken,
          "x-verification-token": getVerificationToken().X_VERIFY_TOKEN,
        },
      }
    );
    const decryptedData = performCrypto().decode(response.data.encryptedData);
    dispatch(ordersSuccess(decryptedData));
  } catch (error) {
    const decryptedData = performCrypto().decode(
      error?.response?.data?.encryptedData
    );
    dispatch(ordersFail(decryptedData?.error));
  }
};
export const getAllOrders = () => async (dispatch) => {
  try {
    dispatch(allOrdersRequest());
    const response = await axios.get(
      `${BASE_URL}/${endPoints.orders.get_all}`,
      {
        withCredentials: true,
        headers: {
          Authorization: getVerificationToken().authToken,
          "x-verification-token": getVerificationToken().X_VERIFY_TOKEN,
        },
      }
    );
    const decryptedData = performCrypto().decode(response.data.encryptedData);
    dispatch(allOrdersSuccess(decryptedData));
  } catch (error) {
    const decryptedData = performCrypto().decode(
      error?.response?.data?.encryptedData
    );
    dispatch(allOrdersFail(decryptedData?.error));
  }
};
export const printOrderInvoice = (orderId) => async (dispatch) => {
  try {
    dispatch(printOrderInvoiceRequest());
    const response = await axios.get(
      `${BASE_URL}/${endPoints.orders.print_order_invoice}?order_id=${orderId}`,
      {
        withCredentials: true,
        headers: {
          Authorization: getVerificationToken().authToken,
          "x-verification-token": getVerificationToken().X_VERIFY_TOKEN,
        },
        responseType: "arraybuffer",
      }
    );
    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "generated.pdf";
    document.body.appendChild(a);
    a.click();
    a.remove();
    window.URL.revokeObjectURL(url);

    dispatch(printOrderInvoiceSuccess());
  } catch (error) {
    dispatch(printOrderInvoiceFail(error));
  }
};
export const updateOrderStatus = (payload) => async (dispatch) => {
  try {
    dispatch(orderRequest());
    const response = await axios.put(
      `${BASE_URL}/${endPoints.order.update_status}`,
      payload,
      {
        withCredentials: true,
        headers: {
          Authorization: getVerificationToken().authToken,
          "x-verification-token": getVerificationToken().X_VERIFY_TOKEN,
        },
      }
    );
    const decryptedData = performCrypto().decode(response.data.encryptedData);
    dispatch(orderSuccess(decryptedData));
  } catch (error) {
    const decryptedData = performCrypto().decode(
      error?.response?.data?.encryptedData
    );
    console.error("[ERROR]: ", decryptedData?.error?.message);
    dispatch(orderFail(decryptedData?.error));
  }
};
