import React, { useState } from "react";
import FormAlpha from "./FormAlpha";
import Sidebar from "./Sidebar";
import FormBeta from "./FormBeta";
import FormGamma from "./FormGamma";
import FormEpsilon from "./FormEpsilon";
import FormDelta from "./FormDelta";
import FormQuestion1 from "./FormQuestion1";
import { testQuestions } from "./questions";

const CombainedForms = () => {
  const [selectedLink, setSelectedlink] = useState(1);
  const [selectedSubLink, setSelectedSublink] = useState(1);
  const [formIndex, setFormIndex] = useState(0);
  const [isSidebarShrinked, setIsSidebarShrinked] = useState(false);
  const forms = [
    {
      id: 1,
      linkId: 1,
      subLinkId: 1,
      content: () => (
        <FormAlpha
          selectedLink={selectedLink}
          selectedSubLink={selectedSubLink}
          handleSelectChange={handleSelectChange}
        />
      ),
    },
    {
      id: 1,
      linkId: 1,
      subLinkId: 2,
      content: () => (
        <FormBeta
          selectedLink={selectedLink}
          selectedSubLink={selectedSubLink}
          handleSelectChange={handleSelectChange}
        />
      ),
    },
    {
      id: 1,
      linkId: 1,
      subLinkId: 3,
      content: () => (
        <FormGamma
          selectedLink={selectedLink}
          selectedSubLink={selectedSubLink}
          handleSelectChange={handleSelectChange}
        />
      ),
    },
    {
      id: 1,
      linkId: 1,
      subLinkId: 4,
      content: () => (
        <FormDelta
          selectedLink={selectedLink}
          selectedSubLink={selectedSubLink}
          handleSelectChange={handleSelectChange}
        />
      ),
    },
    {
      id: 1,
      linkId: 1,
      subLinkId: 5,
      content: () => (
        <FormEpsilon
          selectedLink={selectedLink}
          selectedSubLink={selectedSubLink}
          handleSelectChange={handleSelectChange}
          setFormIndex={(e) => setFormIndex(e)}
        />
      ),
    },
    ...testQuestions[formIndex]?.questions
      .map((data, i) => {
        return data?.qs?.map((qsData) => {
          return {
            id: data?.id,
            linkId: data?.id,
            subLinkId: qsData?.id,
            content: () => (
              <FormQuestion1
                selectedLink={selectedLink}
                selectedSubLink={selectedSubLink}
                handleSelectChange={handleSelectChange}
                options={qsData?.options}
                questionHeading={qsData?.q}
                questionId={qsData?.id}
                external={qsData?.external}
                totalLength={data?.qs?.length}
                prevQuestions={testQuestions[0].questions[i - 1]}
                totalQuestionsLength={testQuestions[0]?.questions?.length + 1}
              />
            ),
          };
        });
      })
      .flat(),
  ];

  const getForm = (linkId, subLinkId) => {
    const foundForm = forms.find(
      (data) => data.linkId === linkId && data.subLinkId === subLinkId
    );
    if (!foundForm) {
      return {
        id: linkId + subLinkId,
        linkId: linkId,
        subLinkId: subLinkId,
        content: () => <div>No Form Found</div>,
      };
    }
    return foundForm;
  };
  const onLinkChange = (linkId, subLinkId) => {
    setSelectedlink(linkId);
    setSelectedSublink(subLinkId);
  };
  const handleSelectChange = (linkId, subLinkId) => {
    setSelectedlink(linkId);
    setSelectedSublink(subLinkId);
  };
  const onShrinkContainerChange = (e) => {
    setIsSidebarShrinked(e);
  };
  const calculateCompletionPercentage = (linkId, subLinkId) => {
    const formsData = forms;
    const totalForms = formsData.length;
    const completedForms = formsData.filter((form) => {
      if (form.linkId < linkId) {
        return true;
      } else if (form.linkId === linkId && form.subLinkId <= subLinkId) {
        return true;
      }
      return false;
    });
    const completionPercentage = (completedForms.length / totalForms) * 100;

    return completionPercentage.toFixed(2);
  };

  return (
    <div className="combained_forms">
      <div>
        <Sidebar
          onLinkChange={onLinkChange}
          onShrinkContainerChange={onShrinkContainerChange}
          selectedLink={selectedLink}
          selectedSubLink={selectedSubLink}
          formIndex={formIndex}
        />
      </div>
      <div
        className="hug_interactions_box_container"
        style={{
          width: isSidebarShrinked && "100%",
          transition: "0.3s ease",
        }}
      >
        <div className="hug_interactions_box">
          <div className="data_printed_container">
            <div className="data_printed">
              <div className="print_value">
                <div
                  className="percentage"
                  style={{
                    width: `${Number(
                      calculateCompletionPercentage(
                        selectedLink,
                        selectedSubLink
                      )
                    ).toFixed(2)}%`,
                  }}
                ></div>
              </div>
            </div>
          </div>
          {getForm(selectedLink, selectedSubLink).content()}
        </div>
      </div>
    </div>
  );
};

export default CombainedForms;
