import { createSlice } from "@reduxjs/toolkit";

export const ordersSlice = createSlice({
  name: "orders",
  initialState: {
    loading: false,
    orders: [],
    allOrders: [],
    ordersCount: 0,
    message: null,
    status: null,
    allowToast: false,
    actionStatus: null,
  },
  reducers: {
    ordersRequest(state, payload) {
      return {
        ...state,
        loading: true,
        allowToast: false,
      };
    },
    ordersSuccess(state, action) {
      return {
        ...state,
        loading: false,
        orders: action.payload.data.orders,
        ordersCount: action.payload.data.ordersCount,
        allOrders: [],
        message: action.payload.message,
        status: "success",
        actionStatus: "get-success",
      };
    },
    ordersFail(state, action) {
      return {
        ...state,
        loading: false,
        message: action.payload.message,
        status: "fail",
        actionStatus: "get-fail",
      };
    },
    allOrdersRequest(state, payload) {
      return {
        ...state,
        loading: true,
        allowToast: false,
      };
    },
    allOrdersSuccess(state, action) {
      return {
        ...state,
        loading: false,
        allOrders: action.payload.data.orders,
        ordersCount: action.payload.data.ordersCount,
        message: action.payload.message,
        status: "success",
        actionStatus: "get-success",
      };
    },
    allOrdersFail(state, action) {
      return {
        ...state,
        loading: false,
        message: action.payload.message,
        status: "fail",
        actionStatus: "get-fail",
      };
    },
    clearOrdersActionStatus(state) {
      return {
        ...state,
        loading: false,
        status: null,
        allowToast: false,
        actionStatus: null,
      };
    },
  },
});

const { actions, reducer } = ordersSlice;

export const {
  ordersRequest,
  ordersFail,
  ordersSuccess,
  allOrdersRequest,
  allOrdersFail,
  allOrdersSuccess,
  clearOrdersActionStatus,
} = actions;

export default reducer;
