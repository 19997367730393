import { createSlice } from "@reduxjs/toolkit";

export const popularProductsSlice = createSlice({
  name: "popularProducts",
  initialState: {
    loading: false,
    popularProducts: [],
    message: null,
    status: null,
  },
  reducers: {
    popularProductsRequest(state, payload) {
      return {
        ...state,
        loading: true,
      };
    },
    popularProductsSuccess(state, action) {
      return {
        ...state,
        loading: false,
        popularProducts: action.payload.data.products,
        message: action.payload.message,
        status: "success",
      };
    },
    popularProductsFail(state, action) {
      return {
        ...state,
        loading: false,
        message: action.payload.message,
        status: "fail",
      };
    },
    clearPopularProducts(state) {
      return {
        ...state,
        loading: false,
        popularProducts: [],
        message: null,
        status: null,
      };
    },
  },
});

const { actions, reducer } = popularProductsSlice;

export const {
  popularProductsRequest,
  popularProductsFail,
  popularProductsSuccess,
  clearPopularProducts,
} = actions;

export default reducer;
