import React, { useEffect, useState } from "react";
import { FaHome } from "react-icons/fa";
import { FaPlus } from "react-icons/fa6";
import CmacModal from "../../../plugins/cmac-plugins/CmacModal";
import { Button, HeadingDesign, Input } from "../../../utils/Elements";
import { useDispatch, useSelector } from "react-redux";
import { getPostalData } from "../../../../redux/actions/orderAction";
import CustomDropdown from "../../../plugins/CustomDropdown";
import { useToast } from "../../../plugins/toaster/useToast";
import {
  createAddress,
  deleteAddress,
  getAddresses,
  updateAddress,
} from "../../../../redux/actions/userAction";
import { clearAddressActionStatus } from "../../../../redux/slices/addressSlice";
import { IoCheckmarkCircle } from "react-icons/io5";
import { PiWarningCircleFill } from "react-icons/pi";
import { BsArrowLeft } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import search_location from "../../../../assets/imgs/vectors/search-location.png";

const AddressBook = () => {
  const toast = useToast();
  const dispatch = useDispatch();
  const { user, isAuthenticated } = useSelector((state) => state.userState);
  const { postalData } = useSelector((state) => state.postalState);
  const { status, allowToast, message, addressList } = useSelector(
    (state) => state.addressState
  );
  const [postalCodeEnteringInput, setPostalCodeEnteringInput] = useState(false);
  const [addAddressModal, setAddAddressModal] = useState(false);
  const [addressAction, setAddressAction] = useState("add");

  const phoneRegex = /^(\+91[\-\s]?)?[0]?(91)?[6789]\d{9}$/;
  const [addressId, setAddressId] = useState("");
  const navigate = useNavigate();

  /* Input States */

  const [addressName, setAddressName] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [addressLine1, setAddressLine1] = useState("");
  const [addressLine2, setAddressLine2] = useState("");
  const [district, setDistrict] = useState("");
  const [location, setLocation] = useState("");
  const [locationList, setLocationList] = useState([]);

  /* Input States Errors*/

  const [addressNameError, setAddressNameError] = useState(false);
  const [mobileNumberError, setMobileNumberError] = useState(false);
  const [postalCodeError, setPostalCodeError] = useState(false);
  const [firstNameError, setFirstNameError] = useState("");
  const [addressLine1Error, setAddressLine1Error] = useState("");
  const [locationError, setLocationError] = useState(false);

  /* Disable Button State */

  const [disableSubmitButton, setDisableSubmitButton] = useState(true);

  const demo_address = [
    {
      id: 1,
      addressName: "Home",
      fullName: "Sam Binu",
      mobileNumber: "+91 93841 09664",
      address: "26, Mahalakshmi Nagar, Gobichettipalayam, Tamil Nadu - 638476",
    },
    {
      id: 2,
      addressName: "Office",
      fullName: "Jhon Cena",
      mobileNumber: "+91 80894 17870",
      address:
        "8th Floor, Hanudev Infopark Pvt. Ltd, Coimbatore, Tamilnadu – 641028",
    },
  ];

  const handleLocationValue = (e) => {
    setLocation(e);
    // let locationValue = e;
    // if (!locationValue) {
    //   setLocationError(true);
    // } else {
    //   setLocationError(false);
    // }
    // if (
    //   !email ||
    //   !mobileNumber ||
    //   !postalCode ||
    //   !firstName ||
    //   !addressLine1 ||
    //   !locationValue
    // ) {
    //   setDisableSubmitButton(true);
    // } else {
    //   setDisableSubmitButton(false);
    // }
  };
  const handleInputErrors = () => {
    let isValid = true;
    if (!addressName) {
      setAddressNameError(true);
      isValid = false;
      toast.open("Please enter your Email!");
    } else {
      setAddressNameError(false);
      isValid = true;
    }
    if (!mobileNumber) {
      setMobileNumberError(true);
      isValid = false;
    } else if (!phoneRegex.test(mobileNumber)) {
      setAddressNameError("Please enter a valid Mobile Number.");
      isValid = false;
    } else {
      setMobileNumberError(false);
      isValid = true;
    }
    if (!postalCode) {
      setPostalCodeError(true);
      isValid = false;
    } else if (postalCode?.length >= 6 || postalCode?.length >= 6) {
      setPostalCodeError(true);
      isValid = false;
    } else {
      setPostalCodeError(false);
      isValid = true;
    }
    if (!firstName) {
      setFirstNameError(true);
      isValid = false;
    } else {
      setFirstNameError(false);
      isValid = true;
    }
    if (!addressLine1) {
      setAddressLine1Error(true);
      isValid = false;
    } else {
      setAddressLine1Error(false);
      isValid = true;
    }
    if (!location) {
      setLocationError(true);
      isValid = false;
    } else {
      setLocationError(false);
      isValid = true;
    }
    if (
      !addressName ||
      !mobileNumber ||
      !postalCode ||
      !firstName ||
      !addressLine1 ||
      !location
    ) {
      setDisableSubmitButton(true);
    } else {
      setDisableSubmitButton(false);
    }
    return isValid;
  };
  const handleSubmitAddress = (e) => {
    e.preventDefault();
    let isValid = handleInputErrors();

    if (isValid) {
      const payload = {
        addressName,
        mobileNumber,
        country: "India",
        state: postalData?.length > 0 && postalData[0]?.State,
        postalCode,
        firstName,
        lastName,
        addressLine1,
        addressLine2,
        district,
        location,
      };
      {
        addressAction == "edit"
          ? dispatch(updateAddress({ ...payload, addressId }))
          : dispatch(createAddress(payload));
      }
    }
  };
  const handleEditAddressData = (data) => {
    setAddressId(data?._id);
    setAddressAction("edit");
    setAddAddressModal(true);
    setAddressName(data?.addressName || "");
    setMobileNumber(data?.mobileNumber || "");
    setFirstName(data?.firstName || "");
    setLastName(data?.lastName || "");
    setAddressLine1(data?.addressLine1 || "");
    setAddressLine2(data?.addressLine2 || "");
    setPostalCode(data?.postalCode || "");
    setDistrict(data?.district || "");
    setLocation(data?.location || "");
  };
  const handleAddAddressData = () => {
    setAddressAction("add");
    setAddAddressModal(true);
    setAddressName("");
    setMobileNumber(user?.mobileNumber || "");
    setFirstName(user?.firstName || "");
    setLastName(user?.lastName || "");
    setAddressLine1("");
    setAddressLine2("");
    setPostalCode("");
    setDistrict("");
    setLocation("");
  };
  const handleDeleteAddress = (data) => {
    dispatch(deleteAddress(data._id));
  };
  const displayToast = (icon) => {
    return (
      <div className="add_cart_toast height-30 d_flex">
        <div className="d_flex align_items_center">{icon}</div>
        <div className="toast_message">{message}</div>
      </div>
    );
  };
  useEffect(() => {
    dispatch(getAddresses());
  }, []);
  useEffect(() => {
    if (isAuthenticated) {

      setFirstName(user?.firstName || "");
      setLastName(user?.lastName || "");
      setMobileNumber(user?.mobileNumber || "");
    }
  }, [user]);
  useEffect(() => {
    if (postalCodeEnteringInput) {
      setTimeout(() => {
        setPostalCodeEnteringInput(false);
      }, 1000);
    }
  }, [postalCode]);
  useEffect(() => {
    if (!postalCodeEnteringInput) {
      if (postalCode) {
        dispatch(getPostalData(postalCode));
      }
    }
  }, [postalCodeEnteringInput]);
  useEffect(() => {
    const formattedList = postalData?.map((data) => {
      return data?.Name;
    });
    if (formattedList && formattedList.length > 0) {
      setLocationList(formattedList);
      setLocation("");
      setDistrict(postalData[0].District);
    }
  }, [postalData]);
  useEffect(() => {
    switch (status) {
      case "success": {
        if (allowToast) {
          setAddAddressModal(false);
          toast.open(
            displayToast(
              <IoCheckmarkCircle color="#37cd00" size={30} />,
              message
            ),
            {
              toastWidth: "fit-content",
              toastMsgWidth: "fit-content",
            }
          );
        }
        dispatch(clearAddressActionStatus());
        break;
      }
      case "fail": {
        if (allowToast) {
          setAddAddressModal(false);
          toast.open(
            displayToast(
              <PiWarningCircleFill color="#f00" size={30} />,
              message
            ),
            {
              toastWidth: "fit-content",
              toastMsgWidth: "fit-content",
            }
          );
        }
        dispatch(clearAddressActionStatus());
        break;
      }
      default: {
        dispatch(clearAddressActionStatus());
      }
    }
  }, [status]);
  return (
    <div className="address_book">
      <div className="element_hug font_lato">
        <div className="element_container top_bottom_margin">
          <div className="d_flex align_items_center gap_2 f_14 f_weight_2">
            <div
              className="d_flex align_items_center justify_content_center ic_arrow_back"
              onClick={() => navigate(-1)}
            >
              <BsArrowLeft size={18} />
            </div>
            <div className="opacity_0_6">Back</div>
          </div>
          <div className="d_flex align_items_center justify_content_center gap_2 flex_direction_col">
            <div className="address_book_container">
              {addressList?.length > 0 && (
                <div
                  className="d_flex align_items_center justify_content_space_between mt_2 pb_2 f_size_3 f_weight_2"
                  style={{
                    borderBottom: "1px solid #ececec",
                    height: "40px"
                  }}
                >
                  <div className="d_flex align_items_center justify_content_center gap_3">
                    <FaHome />
                    <div>My Addresses</div>
                  </div>
                  <button
                    className="d_flex align_items_center justify_content_center gap_2 new_address_btn cursor_pointer"
                    onClick={() => {
                      handleAddAddressData();
                    }}
                  >
                    <FaPlus
                      size={17}
                      style={{
                        position: "relative",
                        top: "1px",
                      }}
                    />
                    New Address
                  </button>
                </div>
              )}
              <div className="address_container">
                {addressList?.length > 0 ? (
                  addressList?.map((data) => {
                    return (
                      <div className="address_content" key={data._id}>
                        <div className="address_name">{data.addressName}</div>
                        <div className="address_recipient_name">
                          {data.fullName}
                        </div>
                        <div className="address_mobile_number">
                          {data.mobileNumber}
                        </div>
                        <div className="address_value">{data.addressLine1}</div>
                        <div className="address_actions">
                          <button
                            onClick={() => {
                              handleEditAddressData(data);
                            }}
                          >
                            Edit Address
                          </button>
                          <button onClick={() => handleDeleteAddress(data)}>
                            Delete Address
                          </button>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div className="d_flex align_items_center justify_content_center mt_4 flex_direction_col gap_5 f_weight_2 f_18">
                    <div>
                      <img
                        src={search_location}
                        alt="search_location"
                        height={150}
                      />
                    </div>
                    <div className="mt_2">No Address Found</div>
                    <div
                      className="opacity_0_4 text_align_center f_14 quotes"
                      style={{
                        width: "500px",
                      }}
                    >
                      You haven't added any addresses. Please add a new address
                      to manage your addresses.
                    </div>
                    <button
                      className="d_flex align_items_center justify_content_center gap_2 new_address_btn cursor_pointer"
                      onClick={() => {
                        handleAddAddressData();
                      }}
                      style={{
                        scale: "0.8",
                      }}
                    >
                      <FaPlus
                        size={17}
                        style={{
                          position: "relative",
                          top: "1px",
                        }}
                      />
                      New Address
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <CmacModal
        active={addAddressModal}
        onClickOutsider={() => setAddAddressModal(false)}
        portalUsage={true}
        blurStrength="stronger"
        style={{
          width: "650px",
          color: "var(--cmac-dark-fill)",
          backgroundColor: "var(--cmac-default-shade-fill)",
        }}
        className={"address_inputs_container"}
      >
        <form className="font_lato">
          <div className="w_fill address_inputs">
            <div className="group_input gap_2">
              <div className="input_container w_fill">
                <div className="label primary_color">Address Name</div>
                <Input
                  className={"font_lato"}
                  style={{
                    width: "-webkit-fill-available",
                    backgroundColor: "var(--cmac-default-fill)",
                  }}
                  value={addressName}
                  onChange={(e) => setAddressName(e.target.value)}
                />
              </div>
              <div className="input_container">
                <div className="label primary_color">Mobile Number</div>
                <Input
                  className={"font_lato"}
                  style={{
                    width: "-webkit-fill-available",
                    backgroundColor: "var(--cmac-default-fill)",
                  }}
                  type={"number"}
                  value={mobileNumber}
                  onKeyDown={(evt) =>
                    (evt.key === "e" || evt.key === "+" || evt.key === "-") &&
                    evt.preventDefault()
                  }
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value.length <= 11) setMobileNumber(value);
                  }}
                />
              </div>
              <div className="input_container w_fill">
                <div className="label primary_color">Country</div>
                <Input
                  className={"font_lato"}
                  style={{
                    width: "-webkit-fill-available",
                    backgroundColor: "var(--cmac-default-fill)",
                  }}
                  value={"India"}
                  disabled={true}
                />
              </div>
            </div>
            <div className="group_input gap_2">
              <div className="input_container w_fill">
                <div className="label primary_color">First Name</div>
                <Input
                  className={"font_lato"}
                  style={{
                    width: "-webkit-fill-available",
                    backgroundColor: "var(--cmac-default-fill)",
                  }}
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </div>
              <div className="input_container w_fill">
                <div className="label primary_color">Last Name</div>
                <Input
                  className={"font_lato"}
                  style={{
                    width: "-webkit-fill-available",
                    backgroundColor: "var(--cmac-default-fill)",
                  }}
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />
              </div>
            </div>
            <div className="group_input gap_2">
              <div className="input_container w_fill">
                <div className="label primary_color">Address Line1</div>
                <Input
                  className={"font_lato"}
                  style={{
                    width: "-webkit-fill-available",
                    backgroundColor: "var(--cmac-default-fill)",
                  }}
                  value={addressLine1}
                  onChange={(e) => setAddressLine1(e.target.value)}
                />
              </div>
            </div>
            <div className="group_input gap_2">
              <div className="input_container w_fill">
                <div className="label primary_color">Address Line2</div>
                <Input
                  className={"font_lato"}
                  style={{
                    width: "-webkit-fill-available",
                    backgroundColor: "var(--cmac-default-fill)",
                  }}
                  value={addressLine2}
                  onChange={(e) => setAddressLine2(e.target.value)}
                />
              </div>
            </div>
            <div className="group_input gap_2">
              <div className="input_container w_fill">
                <div className="label primary_color">Postal Code</div>
                <Input
                  className={"font_lato"}
                  style={{
                    width: "-webkit-fill-available",
                    backgroundColor: "var(--cmac-default-fill)",
                  }}
                  type={"number"}
                  onKeyDown={(evt) =>
                    (evt.key === "e" || evt.key === "+" || evt.key === "-") &&
                    evt.preventDefault()
                  }
                  value={postalCode}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value.length <= 6) {
                      setPostalCodeEnteringInput(true);
                      setPostalCode(value);
                    }
                  }}
                />
              </div>
              <div className="input_container w_fill">
                <div className="label primary_color">District</div>
                <Input
                  className={"font_lato"}
                  style={{
                    width: "-webkit-fill-available",
                    backgroundColor: "var(--cmac-default-fill)",
                  }}
                  value={district}
                  disabled={true}
                />
              </div>
              <div className="input_container w_fill">
                <div className="label primary_color">Location</div>
                {/* <Input
                  className={"font_lato"}
                  style={{
                    width: "-webkit-fill-available",
                    backgroundColor: "var(--cmac-default-fill)",
                  }}
                  value={location}
                  disabled={true}
                /> */}
                <CustomDropdown
                  value={location}
                  placeholder="Select Location"
                  onSelect={handleLocationValue}
                  options={locationList}
                  addOptionValue={false}
                  disabled={false}
                  className={`custom_dropdown ${
                    // locationError
                    false ? "input_error" : "default"
                  }`}
                  st
                />
              </div>
            </div>
          </div>
          <div className="d_flex align_items_center justify_content_flex_end gap_3 mt_2">
            <Button
              style={{
                height: "45px",
                width: "100px",
                backgroundColor: "var(--cmac-primary-color)",
              }}
              className={"font_lato"}
              type={"button"}
              onClick={() => setAddAddressModal(false)}
            >
              Cancel
            </Button>
            <Button
              style={{
                height: "45px",
                width: "150px",
                backgroundColor: "var(--cmac-primary-color)",
              }}
              className={"font_lato"}
              type={"button"}
              onClick={handleSubmitAddress}
              // disabled={disableSubmitButton}
            >
              {addressAction === "edit" ? "Update Address" : "Add Address"}
            </Button>
          </div>
        </form>
      </CmacModal>
    </div>
  );
};

export default AddressBook;
