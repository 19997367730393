import React, { useEffect, useRef, useState } from "react";
import ReactToPrint from "react-to-print";

const PrintPDF = ({ printDoc, documentDimensions }) => {
  const componentRef = useRef();
  const [scale, setScale] = useState(1);
  const [printLoading, setIsPrintLoading] = useState(false);
  useEffect(() => {
    if (documentDimensions.width <= 800) {
      const newScale = documentDimensions.width / 800;
      setScale(newScale);
    } else {
      setScale(1);
    }
  }, [documentDimensions.width]);
  return (
    <div>
      <div
        className="d_flex align_items_center flex_direction_col gap_5"
        style={{
          background: "rgb(241 241 241)",
          paddingTop: "30px",
          paddingBottom: "30px",
        }}
      >
        {printDoc(componentRef, scale)}
        <ReactToPrint
          trigger={() => (
            <button
              disabled={printLoading}
              style={{
                height: "40px",
                width: "150px",
                borderRadius: "50px",
                cursor: "pointer",
                background: "#ffffff",
                border: "1px solid #bfbfbf",
              }}
            >
              {printLoading ? "Loading..." : "Print"}
            </button>
          )}
          content={() => componentRef.current}
          documentTitle="Order Invoice"
          onBeforeGetContent={() => {
            setIsPrintLoading(true);
          }}
          onBeforePrint={() => setIsPrintLoading(false)}
        />
      </div>
    </div>
  );
};

export default PrintPDF;
