import React, { useEffect, useState } from "react";
import { Input } from "../../../utils/Elements";
import { useDispatch, useSelector } from "react-redux";
import { updateProfile } from "../../../../redux/actions/userAction";
import {
  clearUser,
  clearUserActionStatus,
} from "../../../../redux/slices/userSlice";
import { useToast } from "../../../plugins/toaster/useToast";
import { IoCheckmarkCircle } from "react-icons/io5";
import { PiWarningCircleFill } from "react-icons/pi";
import { BsArrowLeft } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { TbLogout2 } from "react-icons/tb";
import { LANDING_PAGE } from "../../../../helpers/paths";

const Profile = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const toast = useToast();
  const { user, status, message, allowToast } = useSelector(
    (state) => state.userState
  );
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [alternateEmail, setAlternateEmail] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [alternateMobileNumber, setAlternateMobileNumber] = useState("");
  const displayToast = (icon) => {
    return (
      <div className="add_cart_toast height-30 d_flex">
        <div className="d_flex align_items_center">{icon}</div>
        <div className="toast_message">{message}</div>
      </div>
    );
  };
  const handleUpdateProfile = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (email) {
      const payload = {
        firstName,
        lastName,
        email,
        alternateEmail,
        mobileNumber,
        alternateMobileNumber,
      };
      dispatch(updateProfile(payload));
    }
  };
  const handleLogout = () => {
    localStorage.removeItem("auth_token");
    dispatch(clearUser());
    setTimeout(() => {
      window.location.replace(LANDING_PAGE);
    }, 300);
  };
  useEffect(() => {
    if (user) {
      setFirstName(user?.firstName || "");
      setLastName(user?.lastName || "");
      setEmail(user?.email || "");
      setAlternateEmail(user?.alternateEmail || "");
      setMobileNumber(user?.mobileNumber || "");
      setAlternateMobileNumber(user?.alternateMobileNumber || "");
    }
  }, [user]);
  useEffect(() => {
    switch (status) {
      case "success": {
        if (allowToast) {
          toast.open(
            displayToast(
              <IoCheckmarkCircle color="#37cd00" size={30} />,
              message
            ),
            {
              toastWidth: "fit-content",
              toastMsgWidth: "fit-content",
            }
          );
        }
        dispatch(clearUserActionStatus());
        break;
      }
      case "fail": {
        if (allowToast) {
          toast.open(
            displayToast(
              <PiWarningCircleFill color="#f00" size={30} />,
              message
            ),
            {
              toastWidth: "fit-content",
              toastMsgWidth: "fit-content",
            }
          );
        }
        dispatch(clearUserActionStatus());
        break;
      }
      default: {
        dispatch(clearUserActionStatus());
      }
    }
  }, [status]);
  return (
    <div className="profile">
      <div className="element_hug font_lato">
        <div className="element_container">
          <div className="d_flex align_items_center justify_content_space_between">
            <div className="d_flex align_items_center gap_2 f_14 f_weight_2">
              <div
                className="d_flex align_items_center justify_content_center ic_arrow_back"
                onClick={() => navigate(-1)}
              >
                <BsArrowLeft size={18} />
              </div>
              <div className="opacity_0_6">Back</div>
            </div>
            <div
              className="d_flex align_items_center gap_2 f_14 f_weight_2 cursor_pointer"
              style={{
                border: "1px solid #b5b5b5",
                padding: "10px 15px",
                borderRadius: "3px",
                background: "#f6f6f6",
              }}
              onClick={handleLogout}
            >
              <TbLogout2 size={18} color="red" />
              <div>Logout</div>
            </div>
          </div>
          <div className="d_flex align_items_center justify_content_center flex_direction_col">
            <div
              style={{
                fontWeight: 600,
                fontSize: "20px",
                marginBottom: "30px",
              }}
            >
              Edit Your Profile
            </div>
            <form onSubmit={handleUpdateProfile}>
              <div className="group_input">
                <div className="input_container w_fill">
                  <div className="label">First Name</div>
                  <Input
                    style={{
                      width: "-webkit-fill-available",
                    }}
                    containerStyle={{ width: "-webkit-fill-available" }}
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                </div>
                <div className="input_container w_fill">
                  <div className="label">Last Name</div>
                  <Input
                    style={{
                      width: "-webkit-fill-available",
                    }}
                    containerStyle={{ width: "-webkit-fill-available" }}
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                  />
                </div>
              </div>
              <div className="group_input">
                <div className="input_container w_fill">
                  <div className="label">Email</div>
                  <Input
                    style={{
                      width: "-webkit-fill-available",
                    }}
                    containerStyle={{ width: "-webkit-fill-available" }}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div className="input_container w_fill">
                  <div className="label">Alternative Email (Optional)</div>
                  <Input
                    style={{
                      width: "-webkit-fill-available",
                    }}
                    containerStyle={{ width: "-webkit-fill-available" }}
                    value={alternateEmail}
                    onChange={(e) => setAlternateEmail(e.target.value)}
                  />
                </div>
              </div>
              <div className="group_input">
                <div className="input_container w_fill">
                  <div className="label">Mobile Number</div>
                  <Input
                    style={{
                      width: "-webkit-fill-available",
                    }}
                    containerStyle={{ width: "-webkit-fill-available" }}
                    value={mobileNumber}
                    onChange={(e) => setMobileNumber(e.target.value)}
                  />
                </div>
                <div className="input_container w_fill">
                  <div className="label">
                    Alternative Mobile Number (Optional)
                  </div>
                  <Input
                    style={{
                      width: "-webkit-fill-available",
                    }}
                    containerStyle={{ width: "-webkit-fill-available" }}
                    value={alternateMobileNumber}
                    onChange={(e) => setAlternateMobileNumber(e.target.value)}
                  />
                </div>
              </div>
              <div className="w_fill d_flex align_items_center justify_content_flex_end gap_8 mt_3 f_14">
                <div
                  style={{
                    fontWeight: 600,
                    opacity: 0.2,
                    cursor: "default",
                  }}
                >
                  Reset Changes
                </div>
                <button className="save-btn" type="submit">
                  Save Changes
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
