import "../src/styles/css/App.css";
import "../src/styles/css/cmac.styles.omega.css";
import "../src/styles/css/cmac.styles.lamda.css";
import "../src/styles/css/Common.css";
import "../src/styles/scss/cmac.styles.alpha.dragon.scss";
import "../src/styles/scss/cmac.styles.alpha.dragon.res.scss";
import "../src/styles/scss/cmac.styles.alpha.panther.scss";
import "../src/components/plugins/cmac.styles.plugins.css";
import AppRouter from "./components/routes";
import "react-loading-skeleton/dist/skeleton.css";
import { GlobalStyles } from "./components/utils/styled-components/GlobalStyles";
// import DisableZoom from "./components/plugins/DisableZooming";
import { Toaster } from "./components/plugins/toaster/Toaster";
import BackdropLoader from "./components/plugins/BackdropLoader";
import { useSelector } from "react-redux";

function App() {
  const { loading: userLoading } = useSelector((state) => state.userState);
  const { loading: categoryProductsLoading } = useSelector(
    (state) => state.categoryProductsState
  );
  const {
    loading: cartLoading,
    reqFrom,
    statusAction,
  } = useSelector((state) => state.cartState);

  return (
    <div>
      {/* <DisableZoom /> */}
      <Toaster />
      <GlobalStyles />
      <AppRouter />
      <BackdropLoader
        isLoading={
          userLoading ||
          categoryProductsLoading ||
          (cartLoading &&
            reqFrom === "quickBuy" &&
            statusAction === "get-cart-req")
        }
      />
    </div>
  );
}

export default App;
