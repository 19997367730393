import React from "react";

const QuestionForm = ({ value, options, onChange, onClick, onKeyDown }) => {
  return (
    <div className="d_flex gap_2 flex_direction_col mt_2">
      {options?.map((data, i) => {
        return (
          <div
            key={i}
            className="d_flex align_items_center gap_2 cursor_pointer"
            style={{
              borderBottom: "1px solid #e8e8e8",
              height: "50px",
            }}
            onChange={(e) => onChange && onChange(e, data)}
            onClick={(e) => onClick && onClick(e, data)}
            onKeyDown={(e) => onKeyDown && onKeyDown(e, data)}
          >
            <input
              type="radio"
              value={data}
              checked={value === data}
              className="cursor_pointer"
            />
            <div>{data}</div>
          </div>
        );
      })}
    </div>
  );
};

export default QuestionForm;
