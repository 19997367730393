import axios from "axios";
import { BASE_URL } from "../../api/apiPath";
import { endPoints } from "../../api/endPoints";
import {
  secretCodeRequest,
  secretCodeFail,
  secretCodeSuccess,
} from "../slices/secretCodeSlice";
import { getVerificationToken } from "../../helpers/getVerificationToken";
import {
  updateProfileFail,
  updateProfileRequest,
  updateProfileSuccess,
  userFail,
  userRequest,
  userSuccess,
} from "../slices/userSlice";
import {
  addAddressFail,
  addAddressRequest,
  addAddressSuccess,
  addressesFail,
  addressesRequest,
  addressesSuccess,
} from "../slices/addressSlice";
import { performCrypto } from "../../helpers/performCrypto";

export const sendSecretCode = (payload, allowToast) => async (dispatch) => {
  try {
    dispatch(secretCodeRequest());
    const response = await axios.post(
      `${BASE_URL}/${endPoints.secret_code.send}`,
      payload,
      {
        withCredentials: true,
        headers: {
          "x-verification-token": getVerificationToken().X_VERIFY_TOKEN,
        },
      }
    );
    const decryptedData = performCrypto().decode(response.data.encryptedData);
    console.log("ecryptedData.data: ", decryptedData.data);
    
    dispatch(
      secretCodeSuccess({
        message: decryptedData.message,
        info: {
          authCode: decryptedData.data?.secretCode?.authCode,
          mobileNumber: payload?.mobileNumber,
          expiresOn: decryptedData.data?.secretCode?.expirationTime,
          resendEnablesIn: decryptedData.data?.secretCode?.reSendCodeEnablesIn,
        },
        allowToast,
      })
    );
  } catch (error) {
    const decryptedData = performCrypto().decode(
      error?.response?.data?.encryptedData
    );
    dispatch(
      secretCodeFail({
        message: decryptedData?.error?.message,
        allowToast,
      })
    );
  }
};
export const authenticateUser =
  (payload, authCode, allowToast) => async (dispatch) => {
    try {
      const URL =
        authCode.toString() === "1"
          ? `${BASE_URL}/${endPoints.user.register}`
          : `${BASE_URL}/${endPoints.user.login}`;

      dispatch(userRequest());
      const response = await axios.post(URL, payload, {
        withCredentials: true,
        headers: {
          "x-verification-token": getVerificationToken().X_VERIFY_TOKEN,
        },
      });
      const decryptedData = performCrypto().decode(response.data.encryptedData);
      dispatch(
        userSuccess({
          message: decryptedData.message,
          user: decryptedData?.data?.user,
          allowToast,
        })
      );
      localStorage.setItem("auth_token", decryptedData?.data?.jwt_token);
    } catch (error) {
      const decryptedData = performCrypto().decode(
        error?.response?.data?.encryptedData
      );
      dispatch(
        userFail({
          message: decryptedData?.error?.message,
          allowToast: allowToast,
        })
      );
    }
  };
export const updateProfile = (payload) => async (dispatch) => {
  try {
    dispatch(updateProfileRequest());
    const response = await axios.put(
      `${BASE_URL}/${endPoints.user.update_profile}`,
      payload,
      {
        withCredentials: true,
        headers: {
          "x-verification-token": getVerificationToken().X_VERIFY_TOKEN,
          Authorization: getVerificationToken().authToken,
        },
      }
    );
    const decryptedData = performCrypto().decode(response.data.encryptedData);
    dispatch(
      updateProfileSuccess({
        message: decryptedData.message,
        user: decryptedData.data?.user,
        allowToast: true,
      })
    );
  } catch (error) {
    const decryptedData = performCrypto().decode(
      error?.response?.data?.encryptedData
    );
    dispatch(
      updateProfileFail({
        message: decryptedData?.error?.message,
        allowToast: false,
      })
    );
  }
};
export const getProfile = () => async (dispatch) => {
  try {
    dispatch(userRequest());
    const response = await axios.get(
      `${BASE_URL}/${endPoints.user.get_profile}`,
      {
        withCredentials: true,
        headers: {
          "x-verification-token": getVerificationToken().X_VERIFY_TOKEN,
          Authorization: getVerificationToken().authToken,
        },
      }
    );
    const decryptedData = performCrypto().decode(response.data.encryptedData);
    dispatch(
      userSuccess({
        message: decryptedData.message,
        user: decryptedData?.data?.user,
        allowToast: false,
      })
    );
  } catch (error) {
    const decryptedData = performCrypto().decode(
      error?.response?.data?.encryptedData
    );
    dispatch(
      userFail({
        message: decryptedData?.error?.message,
        allowToast: false,
      })
    );
  }
};
export const createAddress = (payload) => async (dispatch) => {
  try {
    dispatch(addAddressRequest());
    const response = await axios.post(
      `${BASE_URL}/${endPoints.address.create}`,
      payload,
      {
        withCredentials: true,
        headers: {
          "x-verification-token": getVerificationToken().X_VERIFY_TOKEN,
          Authorization: getVerificationToken().authToken,
        },
      }
    );
    const decryptedData = performCrypto().decode(response.data.encryptedData);
    dispatch(
      addAddressSuccess({
        message: decryptedData.message,
        address: decryptedData.data?.address,
        allowToast: true,
      })
    );
  } catch (error) {
    const decryptedData = performCrypto().decode(
      error?.response?.data?.encryptedData
    );
    dispatch(
      addAddressFail({
        message: decryptedData?.error?.message,
        allowToast: true,
      })
    );
  }
};
export const updateAddress = (payload) => async (dispatch) => {
  try {
    dispatch(addAddressRequest());
    const response = await axios.put(
      `${BASE_URL}/${endPoints.address.update}`,
      payload,
      {
        withCredentials: true,
        headers: {
          "x-verification-token": getVerificationToken().X_VERIFY_TOKEN,
          Authorization: getVerificationToken().authToken,
        },
      }
    );
    const decryptedData = performCrypto().decode(response.data.encryptedData);
    dispatch(
      addAddressSuccess({
        message: decryptedData.message,
        address: decryptedData?.data?.address,
        allowToast: true,
      })
    );
  } catch (error) {
    const decryptedData = performCrypto().decode(
      error?.response?.data?.encryptedData
    );
    dispatch(
      addAddressFail({
        message: decryptedData?.error?.message,
        allowToast: true,
      })
    );
  }
};
export const getAddresses = () => async (dispatch) => {
  try {
    dispatch(addressesRequest());
    const response = await axios.get(`${BASE_URL}/${endPoints.address.get}`, {
      withCredentials: true,
      headers: {
        "x-verification-token": getVerificationToken().X_VERIFY_TOKEN,
        Authorization: getVerificationToken().authToken,
      },
    });
    const decryptedData = performCrypto().decode(response.data.encryptedData);
    dispatch(
      addressesSuccess({
        message: decryptedData.message,
        address: decryptedData.data?.address,
        allowToast: false,
      })
    );
  } catch (error) {
    const decryptedData = performCrypto().decode(
      error?.response?.data?.encryptedData
    );
    dispatch(
      addressesFail({
        message: decryptedData?.error?.message,
        allowToast: true,
      })
    );
  }
};
export const deleteAddress = (addressId) => async (dispatch) => {
  try {
    dispatch(addAddressRequest());
    const response = await axios.delete(
      `${BASE_URL}/${endPoints.address.delete}?address_id=${addressId}`,
      {
        withCredentials: true,
        headers: {
          "x-verification-token": getVerificationToken().X_VERIFY_TOKEN,
          Authorization: getVerificationToken().authToken,
        },
      }
    );
    const decryptedData = performCrypto().decode(response.data.encryptedData);
    dispatch(
      addAddressSuccess({
        message: decryptedData.message,
        address: decryptedData.data?.address,
        allowToast: false,
      })
    );
  } catch (error) {
    const decryptedData = performCrypto().decode(
      error?.response?.data?.encryptedData
    );
    dispatch(
      addAddressFail({
        message: decryptedData?.error?.message,
        allowToast: true,
      })
    );
  }
};
