import React, { useEffect, useState } from "react";
import { HiShare } from "react-icons/hi";
import { FiMinus, FiPlus } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { currencyFormatter } from "../../../../helpers/currencyFormatter";
import moment from "moment";
import { addCart, addTempCart } from "../../../../redux/actions/cartAction";
import { AUTH_PAGE, PRODUCT_DETAILS_PAGE } from "../../../../helpers/paths";
import InfinityLoader from "../../../../assets/imgs/loaders/InfinityLoader";
import { PiWarningCircleFill } from "react-icons/pi";
import { useToast } from "../../../plugins/toaster/useToast";
import { IoCheckmarkCircle } from "react-icons/io5";
import { clearCartActionStatus } from "../../../../redux/slices/cartSlice";
import Skeleton from "react-loading-skeleton";
import { useLocation } from "react-router-dom";
import { performCrypto } from "../../../../helpers/performCrypto";

const ProductPurchaseBox = ({ selectedSize }) => {
  const { isAuthenticated } = useSelector((state) => state.userState);
  const {
    cart,
    loading: cartLoading,
    reqFrom,
    allowToast,
    message,
    status: cartStatus,
  } = useSelector((state) => state.cartState);
  const { pathname } = useLocation();
  const crypto = performCrypto();
  const toast = useToast();
  const { product, loading } = useSelector((state) => state.productState);
  const [quantity, setQuantity] = useState(1);
  const dispatch = useDispatch();
  const shareProduct = () => {
    if (navigator.share) {
      navigator
        .share({
          title: product.productName,
          text: `Check out this amazing product: ${product.productName}`,
          url: window.location.href,
        })
        .then(() => console.log("Product shared successfully"))
        .catch((error) => console.log("Error sharing", error));
    } else {
      console.log("Web Share API not supported in this browser.");
    }
  };
  const handleCartCreation = () => {
    const tempCartId = crypto.get("temp_cart_id")?.decryptedData || null;
    const payload = {
      productId: product._id,
      quantity,
      selectedSize,
      ...(!isAuthenticated && tempCartId && { tempCartId }),
    };
    if (isAuthenticated) {
      dispatch(addCart(payload, true, "productInfoPage"));
    } else {
      dispatch(addTempCart(payload, true, "productInfoPage"));
    }
  };
  const displayToast = (icon, toastMessage) => {
    return (
      <div className="add_cart_toast height-30 display-flex">
        <div className="display-flex align-items-center">{icon}</div>
        <div className="toast_message">{toastMessage}</div>
      </div>
    );
  };
  useEffect(() => {
    setQuantity(1);
  }, [product]);
  useEffect(() => {
    switch (cartStatus) {
      case "success": {
        if (allowToast) {
          toast.open(
            displayToast(
              <IoCheckmarkCircle color="#37cd00" size={30} />,
              message
            ),
            {
              toastWidth: "fit-content",
              toastMsgWidth: "fit-content",
            }
          );
        }
        if (!isAuthenticated) {
          crypto.set("temp_cart_id", cart?._id);
        }
        dispatch(clearCartActionStatus());
        break;
      }
      case "fail": {
        if (allowToast) {
          toast.open(
            displayToast(
              <PiWarningCircleFill color="#f00" size={30} />,
              message
            ),
            {
              toastWidth: "fit-content",
              toastMsgWidth: "fit-content",
            }
          );
        }
        dispatch(clearCartActionStatus());
        break;
      }
      default: {
        // dispatch(clearCartActionStatus());
        break;
      }
    }
  }, [cartStatus]);
  return (
    <div className="product_purchase_box">
      <div
        className="box_container"
        style={{
          position: "sticky",
          top: "160px",
        }}
      >
        {(product?.isDiscountedProduct === "true" ||
          product?.isDiscountedProduct === true) && (
          <div
            className="alpha_box"
            style={{
              background: loading && "var(--cmac-default-shade-fill)",
            }}
          >
            <div>
              {loading ? (
                <Skeleton
                  height={"25px"}
                  width={"70px"}
                  baseColor="#eaeaea"
                  highlightColor="var(--cmac-default-fill)"
                  duration={0.8}
                />
              ) : (
                <div className="f_weight_1">
                  {product?.discountPercentage}% OFF
                </div>
              )}
              <div
                className="f_10"
                style={{
                  marginTop: "3px",
                }}
              >
                {loading ? (
                  <Skeleton
                    height={"12px"}
                    width={"50px"}
                    baseColor="#eaeaea"
                    highlightColor="var(--cmac-default-fill)"
                    duration={0.8}
                  />
                ) : (
                  "Save "
                )}
                {loading ? (
                  <Skeleton
                    height={"15px"}
                    width={"70px"}
                    baseColor="#eaeaea"
                    highlightColor="var(--cmac-default-fill)"
                    duration={0.8}
                  />
                ) : (
                  <span className="f_12 text_wrap_nowrap">
                    Rs. {currencyFormatter(product?.discountPrice, "INR")}
                  </span>
                )}
              </div>
            </div>
            {loading ? (
              <Skeleton
                height={"40px"}
                width={"120px"}
                baseColor="#eaeaea"
                highlightColor="var(--cmac-default-fill)"
                duration={0.8}
              />
            ) : (
              <div className="f_10 hugged_element_style text_wrap_nowrap">
                Until {moment(product?.discountEndDate).format("MMM DD, YYYY")}
              </div>
            )}
          </div>
        )}
        <div className="beta_box">
          <div className="heading_container res-max-576px-d-none">
            {loading ? (
              <Skeleton
                height={"18px"}
                width={"140px"}
                baseColor="#eaeaea"
                highlightColor="var(--cmac-default-fill)"
                duration={0.8}
              />
            ) : (
              "Purchase Product"
            )}
          </div>
          <div className="d_flex mt_2 gap_2 align_items_center res-max-992px-d-none">
            {loading ? (
              <Skeleton
                height={"76px"}
                width={"76px"}
                baseColor="#eaeaea"
                highlightColor="var(--cmac-default-fill)"
                duration={0.8}
              />
            ) : (
              <div
                className="cmac_default_fill_bg p_1"
                style={{
                  background: "#eaeaea",
                  boxShadow: "0 0 5px 1px #000000d",
                  borderRadius: "5px",
                }}
              >
                <img
                  src={
                    product?.productImages?.length > 0 &&
                    product?.productImages[0]?.url
                  }
                  alt={product?.productName}
                  height={"60px"}
                  width={"60px"}
                />
              </div>
            )}
            <div>
              <div className="f_12 f_weight_1 opacity_0_4">
                {loading ? (
                  <Skeleton
                    height={"16px"}
                    width={"100px"}
                    baseColor="#eaeaea"
                    highlightColor="var(--cmac-default-fill)"
                    duration={0.8}
                  />
                ) : (
                  "Selected Size"
                )}
              </div>
              <div
                className="f_weight_1 f_14"
                style={{
                  marginTop: "4px",
                }}
              >
                {loading ? (
                  <Skeleton
                    height={"22px"}
                    width={"70px"}
                    baseColor="#eaeaea"
                    highlightColor="var(--cmac-default-fill)"
                    duration={0.8}
                  />
                ) : (
                  selectedSize
                )}
              </div>
            </div>
          </div>
          {Number(product.availableQuantity) === 0 && !loading ? (
            <div
              className="d_flex align_items_center gap_1 mt_2 f_12 f_weight_2"
              style={{
                color: "rgb(230,22,25)",
              }}
            >
              <PiWarningCircleFill size={16} />
              <div>Out Of Stock!</div>
            </div>
          ) : (
            <div className="d_flex align_items_center gap_3 mt_2 res-max-576px-d-none">
              {loading ? (
                <Skeleton
                  height={"43px"}
                  width={"80px"}
                  baseColor="#eaeaea"
                  highlightColor="var(--cmac-default-fill)"
                  duration={0.8}
                  className="quantity_container"
                  style={{
                    border: "unset",
                  }}
                />
              ) : (
                <div className="d_flex align_items_center quantity_container">
                  <div
                    className="negative_ic"
                    onClick={() => quantity !== 1 && setQuantity(quantity - 1)}
                  >
                    <FiMinus />
                  </div>
                  <div className="qty">{quantity}</div>
                  <div
                    className="positive_ic"
                    onClick={() => {
                      product?.availableQuantity <= quantity
                        ? setQuantity(product?.availableQuantity)
                        : setQuantity(quantity + 1);
                    }}
                  >
                    <FiPlus />
                  </div>
                </div>
              )}
              {loading ? (
                <Skeleton
                  // height={"43px"}
                  // width={"100px"}
                  baseColor="#eaeaea"
                  highlightColor="var(--cmac-default-fill)"
                  duration={0.8}
                  className="jump_add"
                  style={{
                    border: "unset",
                    position: "relative",
                    top: 10,
                  }}
                />
              ) : (
                <div
                  className="jump_add"
                  onClick={() => {
                    product?.availableQuantity <= quantity + 3
                      ? setQuantity(product?.availableQuantity)
                      : setQuantity(quantity + 3);
                  }}
                >
                  <div>3</div>
                  <div className="f_size_1">Peices</div>
                </div>
              )}
              {loading ? (
                <Skeleton
                  // height={"43px"}
                  // width={"100px"}
                  baseColor="#eaeaea"
                  highlightColor="var(--cmac-default-fill)"
                  duration={0.8}
                  className="jump_add"
                  style={{
                    border: "unset",
                    position: "relative",
                    top: 10,
                  }}
                />
              ) : (
                <div
                  className="jump_add"
                  onClick={() =>
                    product?.availableQuantity <= quantity + 6
                      ? setQuantity(product?.availableQuantity)
                      : setQuantity(quantity + 6)
                  }
                >
                  <div>6</div>
                  <div className="f_size_1">Peices</div>
                </div>
              )}
            </div>
          )}
          <div className="d_flex align_items_center justify_content_space_between mt_3 mb_3 total_price_res w_fill">
            {loading ? (
              <Skeleton
                height={"20px"}
                width={"95px"}
                baseColor="#eaeaea"
                highlightColor="var(--cmac-default-fill)"
                duration={0.8}
                style={{
                  border: "unset",
                  position: "relative",
                  top: 10,
                }}
              />
            ) : (
              <div className="f_14 f_weight_1">Total Price:</div>
            )}
            {loading ? (
              <Skeleton
                count={1}
                height={"20px"}
                width={"95px"}
                baseColor="#eaeaea"
                highlightColor="var(--cmac-default-fill)"
                duration={0.8}
                style={{
                  border: "unset",
                  position: "relative",
                  top: 10,
                }}
              />
            ) : (
              <div
                className="text_align_end"
                style={{
                  minWidth: "100px",
                }}
              >
                <div className="f_18 f_weight_1">
                  Rs. {currencyFormatter(product?.salePrice)}
                </div>
                {(product?.isDiscountedProduct === "true" ||
                  product?.isDiscountedProduct === true) && (
                  <div className="f_12 f_weight_1 opacity_0_6 text_decoration_line_through">
                    Rs. {currencyFormatter(product?.grossPrice)}
                  </div>
                )}
              </div>
            )}
          </div>
          {loading ? (
            <Skeleton
              height={"40px"}
              width={"100%"}
              baseColor="#eaeaea"
              highlightColor="var(--cmac-default-fill)"
              duration={0.8}
              style={{
                border: "unset",
                position: "relative",
                top: 10,
              }}
              className="mb_2"
            />
          ) : (
            Number(product.availableQuantity) !== 0 && (
              <button
                className="btn_style_add font_lato"
                onClick={handleCartCreation}
                disabled={cartLoading}
                style={{
                  opacity:
                    cartLoading && reqFrom === "productInfoPage" ? "0.5" : "1",
                  cursor:
                    cartLoading && reqFrom === "productInfoPage"
                      ? "default"
                      : "pointer",
                }}
              >
                {cartLoading && reqFrom === "productInfoPage" ? (
                  <div className="d_flex align_items_center justify_content_center gap_3">
                    <InfinityLoader
                      height={35}
                      width={35}
                      stroke="var(--cmac-default-shade-fill)"
                    />
                    <div>Please Wait!</div>
                  </div>
                ) : (
                  "Add To Cart"
                )}
              </button>
            )
          )}
          {!loading && (
            <div className="d_flex align_items_center share_link cursor_pointer res-max-576px-d-none">
              <div className="d_flex align_items_center">
                <HiShare />
              </div>
              <div className="f_12" onClick={() => shareProduct()}>
                Share Product
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProductPurchaseBox;
