import React, { useEffect, useState } from "react";
import QuestionForm from "./QuestionForm";
import { useDispatch, useSelector } from "react-redux";
import {
  createTemporaryTestQuestions,
  sendDBTemporaryTestQuestions,
} from "../../../../redux/actions/testQuestionsAction";
import { RxCross2 } from "react-icons/rx";
import camera_ic from "../../../../assets/imgs/vectors/camera.png";
import { clearTestQuestionsActionStatus } from "../../../../redux/slices/testQuestionsSlice";
import { PiWarningCircleFill } from "react-icons/pi";
import { IoCheckmarkCircle } from "react-icons/io5";
import { useToast } from "../../../plugins/toaster/useToast";
import { LANDING_PAGE } from "../../../../helpers/paths";

const FormQuestion1 = ({
  selectedLink,
  selectedSubLink,
  handleSelectChange,
  questionHeading,
  options,
  questionId,
  totalLength,
  prevQuestions,
  external,
  totalQuestionsLength,
}) => {
  const toast = useToast()
  const [valueData, setValueData] = useState("");
  const { testQuestions, status, message } = useSelector(
    (state) => state.testQuestionsState
  );
  console.log("external: ", {
    selectedLink,
    selectedSubLink,
    handleSelectChange,
    questionHeading,
    options,
    questionId,
    totalLength,
    prevQuestions,
    external,
    totalQuestionsLength,
    testQuestions,
  });
  const dispatch = useDispatch();
  const onNext = () => {
    const payload = {
      ...testQuestions,
      [selectedLink.toString() + selectedSubLink.toString()]: {
        link: selectedLink,
        sublink: selectedSubLink,
        value: valueData,
        key: selectedLink.toString() + selectedSubLink.toString(),
        q: questionHeading,
        options,
      },
    };
    dispatch(createTemporaryTestQuestions(payload));
  };
  const handleProductImage = (e) => {
    const image = e.target.files[0];
    if (image) {
      const newImage = {
        file: image,
        url: URL?.createObjectURL(image),
      };
      setValueData(newImage);
    } else {
      setValueData(valueData);
    }
  };
  const handleRemoveImage = () => {
    setValueData({});
  };
  useEffect(() => {
    if (
      testQuestions[selectedLink.toString() + selectedSubLink.toString()] &&
      testQuestions[selectedLink.toString() + selectedSubLink.toString()][
        "value"
      ]
    ) {
      setValueData(
        testQuestions[selectedLink.toString() + selectedSubLink.toString()][
          "value"
        ]
      );
    } else {
      setValueData("");
    }
  }, [testQuestions, selectedLink, selectedSubLink]);
  const displayToast = (icon, toastMessage) => {
    return (
      <div className="add_cart_toast height-30 display-flex">
        <div className="display-flex align-items-center">{icon}</div>
        <div className="toast_message">{toastMessage}</div>
      </div>
    );
  };
  useEffect(() => {
    switch (status) {
      case "add-test-success": {
        toast.open(
          displayToast(
            <IoCheckmarkCircle color="#37cd00" size={30} />,
            message
          ),
          {
            toastWidth: "fit-content",
            toastMsgWidth: "fit-content",
          }
        );
        dispatch(clearTestQuestionsActionStatus());
        setTimeout(() => {
          window.location.replace(LANDING_PAGE)
        }, 1500)
        break;
      }
      case "add-test-fail": {
        toast.open(
          displayToast(<PiWarningCircleFill color="#f00" size={30} />, message),
          {
            toastWidth: "fit-content",
            toastMsgWidth: "fit-content",
          }
        );
        dispatch(clearTestQuestionsActionStatus());
        break;
      }
      default: {
        // dispatch(clearCartActionStatus());
        break;
      }
    }
  }, [status]);
  return (
    <div className="form_alpha">
      <div className="heading f_weight_1">{questionHeading}</div>
      <div className="interactions_container">
        {external === "Upload Photo" ? (
          <div className="images_box">
            <input
              className="d_none"
              id="product_img_solo"
              type="file"
              onChange={(e) => handleProductImage(e)}
            />
            <div className={`image_container_data`}>
              <div className="image_content">
                <div className="remove_image_container">
                  {Object.keys(valueData)?.length !== 0 && (
                    <div
                      className="remove_image"
                      onClick={() => handleRemoveImage()}
                    >
                      <RxCross2 />
                    </div>
                  )}
                </div>
                {Object.keys(valueData)?.length !== 0 ? (
                  <div className="image_box_conent">
                    <img
                      src={
                        valueData?.file instanceof Blob
                          ? URL.createObjectURL(valueData?.file)
                          : valueData?.url
                      }
                      alt={`Product Image`}
                    />
                  </div>
                ) : (
                  <label
                    className="upload_img_container_test_questions image_box_conent"
                    htmlFor="product_img_solo"
                  >
                    <img
                      src={camera_ic}
                      alt="camera_ic"
                      height={10}
                      width={10}
                    />
                  </label>
                )}
              </div>
            </div>
          </div>
        ) : (
          <QuestionForm
            value={valueData}
            options={options}
            onClick={(_, data) => setValueData(data)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
                if (questionId === totalLength) {
                  valueData && handleSelectChange(selectedLink + 1, 1);
                } else {
                  valueData &&
                    handleSelectChange(selectedLink, selectedSubLink + 1);
                }
                valueData && onNext();
              }
            }}
          />
        )}

        <div className="mt_2 interactions_btn_container">
          <button
            className="interaction_btn_alpha"
            onClick={() => {
              if (questionId === 1 && selectedLink !== 2) {
                handleSelectChange(selectedLink - 1, prevQuestions?.qs?.length);
              } else if (selectedLink === 2 && selectedSubLink === 1) {
                handleSelectChange(1, 5);
              } else {
                handleSelectChange(selectedLink, selectedSubLink - 1);
              }
            }}
          >
            Previous
          </button>
          <button
            className="interaction_btn_beta"
            onClick={() => {
              if (totalQuestionsLength === selectedLink) {
                const payload = {
                  userName: testQuestions["11"].value,
                  email: testQuestions["12"].value,
                  mobileNumber: testQuestions["13"].value,
                  age: testQuestions["14"].value,
                  gender: testQuestions["15"].value,
                  testQuestions: [
                    ...Object.values(testQuestions).map(
                      (data) => data?.q && data
                    ),
                  ].filter((data) => data !== undefined),
                  scalpImage: {
                    value: valueData,
                    key: selectedLink.toString() + selectedSubLink.toString(),
                    q: questionHeading,
                  },
                };
                console.log("ASDOPOLI: ", payload);
                dispatch(sendDBTemporaryTestQuestions(payload));
                return;
              } else if (questionId === totalLength) {
                valueData && handleSelectChange(selectedLink + 1, 1);
              } else {
                valueData &&
                  handleSelectChange(selectedLink, selectedSubLink + 1);
              }
              valueData && onNext();
            }}
            style={{
              transition: "all 0.5s ease-in-out",
              opacity: !valueData && "0.2",
            }}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default FormQuestion1;
