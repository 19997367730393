/**
 * Default Pages
 */

export const LANDING_PAGE = "/";
export const SHOP_PAGE = "/shop";
export const PRODUCT_DETAILS_PAGE = `${SHOP_PAGE}/product`;
export const PRODUCT_REVIEW_PAGE = `${PRODUCT_DETAILS_PAGE}/review`;
export const TEST_QUESTIONS_PAGE = `/test/questions`;
export const TERMS_POLICIES_PAGE = `/policies/termsandconditions`;
export const PRIVACY_POLICIES_PAGE = `/policies/privacy`;
/* Auth Pages*/

export const AUTH_PAGE = `/auth`;
export const AUTH_SERVICE_PAGE = `${AUTH_PAGE}/service`;
export const AUTH_SECRET_CODE_PAGE = `${AUTH_PAGE}/code`;

/* Protected Pages*/

export const CHECKOUT_PAGE = `/checkout`;
export const ACCOUNT_PAGE = `/account`;
export const ACCOUNT_TABS_PAGE = `/account/tabs`;
export const ORDER_TRACKING_PAGE = `/order/tracking`;
export const PRINT_ORDER_INVOICE_PAGE = `/order/tracking/print/doc/invoice`;
export const PROFILE_PAGE = `${ACCOUNT_PAGE}/profile`;
export const ADDRESS_PAGE = `${ACCOUNT_PAGE}/address`;
export const ORDERS_PAGE = `${ACCOUNT_PAGE}/orders`;

/**
 * Management Pages
 */

export const MANAGEMENT_PAGE = "/management";
export const MANAGEMENT_DASHBOARD_PAGE = `${MANAGEMENT_PAGE}/dashboard`;
export const PRODUCT_MANAGEMENT_PAGE = `${MANAGEMENT_PAGE}/products`;
export const PRODUCT_OVERVIEW_PAGE = `${PRODUCT_MANAGEMENT_PAGE}/overview`;
export const ORDER_MANAGEMENT_PAGE = `${MANAGEMENT_PAGE}/orders`;
export const ORDER_OVERVIEW_PAGE = `${ORDER_MANAGEMENT_PAGE}/overview`;
export const TEST_MANAGEMENT_PAGE = `${MANAGEMENT_PAGE}/test/reports`;
export const TEST_OVERVIEW_PAGE = `${TEST_MANAGEMENT_PAGE}/overview`;

/* Error Pages */

export const NOT_FOUND_404 = `/404-page-not-found`;
