import React from "react";
import cmac_img_id_02 from "../../../../assets/imgs/landing-page/cmac_img_id_02.png";
import cmac_img_id_01 from "../../../../assets/imgs/landing-page/cmac_img_id_01.png";

const OfferBanner = () => {
  return (
    <div className="mt_7">
      <div className="element_hug">
        <div className="element_container">
          <div className="d_flex gap_3 res-max-576px-f-direction-c">
            <img
              src={cmac_img_id_02}
              alt="cmac_img_id_02"
              height={"100%"}
              width={"50%"}
              className="res-max-576px-w_fill"
            />
            <img
              src={cmac_img_id_01}
              alt="cmac_img_id_01"
              height={"100%"}
              width={"50%"}
               className="res-max-576px-w_fill"
            />
            {/* <div>
              <div>
                <img
                  src={cmac_img_id_03}
                  alt="cmac_img_id_03"
                  height={"100%"}
                  width={"100%"}
                />
              </div>
              <div></div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OfferBanner;
