import React, { useEffect, useRef, useState } from "react";
import sorry_gif from "../../../../assets/imgs/loaders/sorry_gif.gif";
import ResponsivePagination from "react-responsive-pagination";
import "react-responsive-pagination/themes/classic.css";
import { currencyFormatter } from "../../../../helpers/currencyFormatter";
import { CmacIcCurrencyINR } from "../../../../assets/icons/CmacIcons";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import {
  getProducts,
  getRecentProducts,
} from "../../../../redux/actions/productsAction";
import { Link } from "react-router-dom";
import { PRODUCT_DETAILS_PAGE } from "../../../../helpers/paths";
import Skeleton from "react-loading-skeleton";
import { getQueryParam } from "../../../../helpers/getQueryParams";
import { dummyProducts } from "../../landing-page/layouts/productsData";
import { clearProducts } from "../../../../redux/slices/productsSlice";
import { addRecentProducts } from "../../../../helpers/addRecentProducts";
import { performCrypto } from "../../../../helpers/performCrypto";
const Products = ({ filterValues, searching }) => {
  const dispatch = useDispatch();
  const productRef = useRef();
  const { products, totalPages, loading } = useSelector(
    (state) => state.productsState
  );
  const crypto = performCrypto();
  const categoriesQueryFound = getQueryParam("categories");
  const searchQuery = getQueryParam("s");
  const [activePage, setActivePage] = useState(1);
  const [productsList, setProductsList] = useState([]);
  const [limit, setLimit] = useState(9);
  const [productHeight, setProductHeight] = useState(0);
  const handlePageChange = (e) => {
    const queries = `product_status=active&limit=${limit}&page=${e}${
      filterValues?.length > 0 ? filterValues?.map((data) => `&${data}`) : ""
    }`;
    dispatch(clearProducts());
    dispatch(getProducts(queries));
    setActivePage(e);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  useEffect(() => {
    if (searchQuery !== "") {
      if (!searching) {
        const queries = `product_status=active&limit=${limit}&page=${activePage}${
          searchQuery && `&keyword=${searchQuery}`
        }`;
        dispatch(getProducts(queries));
      }
    } else if (!categoriesQueryFound) {
      const queries = `product_status=active&limit=${limit}&page=${activePage}`;
      dispatch(getProducts(queries));
    }
  }, [searching]);
  useEffect(() => {
    if (products?.length > 0) {
      setProductsList(products);
    } else {
      setProductsList([]);
    }
  }, [products]);
  useEffect(() => {
    const updateHeight = () => {
      if (productRef.current) {
        setProductHeight(productRef.current.offsetWidth);
      }
    };
    const timeoutId = setTimeout(updateHeight, 0);
    window.addEventListener("resize", updateHeight);
    return () => {
      clearTimeout(timeoutId);
      window.removeEventListener("resize", updateHeight);
    };
  }, []);
  return (
    <div className="products">
      <div
        className={`products_container ${
          !loading && productsList.length === 0 && "products_null"
        }`}
      >
        {loading ? (
          Array.from({ length: limit }).map(() => {
            return (
              <div className="_item" ref={productRef}>
                <div
                  className="_img_container"
                  style={{
                    height: productHeight,
                  }}
                >
                  <Skeleton
                    height={"100%"}
                    width={"100%"}
                    baseColor="var(--cmac-default-shade-fill)"
                    highlightColor="var(--cmac-default-fill)"
                    duration={0.8}
                  />
                </div>
                <p>
                  <Skeleton
                    height={13}
                    width={240}
                    baseColor="var(--cmac-default-shade-fill)"
                    highlightColor="var(--cmac-default-fill)"
                    duration={0.8}
                  />
                </p>
                <div className="display-flex align-items-center mt-1 price">
                  <Skeleton
                    height={13}
                    width={100}
                    baseColor="var(--cmac-default-shade-fill)"
                    highlightColor="var(--cmac-default-fill)"
                    duration={0.8}
                  />
                </div>
              </div>
            );
          })
        ) : productsList.length === 0 ? (
          <div
            style={{
              height: "70vh",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
              gap: "20px",
            }}
          >
            <img src={sorry_gif} alt="sorry_gif" height={350} width={320} />
            <div
              className="font_lato"
              style={{
                fontSize: "20px",
                fontWeight: 600,
                opacity: "0.8",
              }}
            >
              No Products Found
            </div>
          </div>
        ) : (
          productsList.map((product) => {
            return (
              <Link
                to={`${PRODUCT_DETAILS_PAGE}?product_id=${product?._id}`}
                className="_item"
                style={{
                  textDecoration: "none",
                }}
                ref={productRef}
              >
                {(product?.isDiscountedProduct === "true" ||
                  product?.isDiscountedProduct === true) && (
                  <div
                    style={{
                      height: 0,
                    }}
                  >
                    <div
                      className="pos_relative z_index_1 d_flex align_items_center justify_content_center flex_direction_col f_weight_2 f_14 border_rad_50_cover cmac_primary_color_25_bg"
                      style={{
                        height: 50,
                        width: 50,
                        top: "10px",
                        left: "10px",
                        color: "#000",
                      }}
                    >
                      <div>{product?.discountPercentage}%</div>
                      <div className="f_10">OFF</div>
                    </div>
                  </div>
                )}
                <div
                  className="_img_container mb_2"
                  style={{
                    height: productHeight,
                  }}
                >
                  <img src={product?.productImages[0]?.url} alt={product._id} />
                </div>
                <p>{product.productName}</p>
                <div
                  className="d_flex align_items_center mt_1 price"
                  style={{
                    color: "var(--cmac-ternary-color-dark)",
                  }}
                >
                  <div
                    className="d_flex align_items_center gap_2"
                    style={{
                      fontSize: "14px",
                      fontWeight: "600",
                    }}
                  >
                    <div>Rs. {currencyFormatter(product.salePrice, "INR")}</div>
                    {(product?.isDiscountedProduct === "true" ||
                      product?.isDiscountedProduct === true) && (
                      <div className="text_decoration_line_through opacity_0_4 f_12">
                        Rs. {currencyFormatter(product.grossPrice, "INR")}
                      </div>
                    )}
                  </div>
                </div>
              </Link>
            );
          })
        )}
      </div>
      {!loading && productsList.length > 0 && (
        <div className="mt_4 mb_4">
          <ResponsivePagination
            current={activePage}
            total={totalPages}
            onPageChange={handlePageChange}
            pageItemClassName="page-item-class"
            previousLabel={
              <IoIosArrowBack
                size={20}
                color="var(--cmac-default-fill)"
                style={{ position: "relative", top: "2px" }}
              />
            }
            nextLabel={
              <IoIosArrowForward
                size={20}
                color="var(--cmac-default-fill)"
                style={{ position: "relative", top: "2px" }}
              />
            }
          />
        </div>
      )}
    </div>
  );
};

export default Products;
