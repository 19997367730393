import React, { useEffect, useState } from "react";
import { Button, CheckboxInput, Input } from "../../../utils/Elements";
import {
  CmacIcCurrencyINR,
  CmacIcDeliveryTruck,
} from "../../../../assets/icons/CmacIcons";
import { RiLock2Fill } from "react-icons/ri";
import cod from "../../../../assets/imgs/vectors/cmac_vector_id_11.png";
import upi from "../../../../assets/imgs/vectors/cmac_vector_id_7.png";
import atm_card from "../../../../assets/imgs/vectors/cmac_vector_id_8.png";
import { currencyFormatter } from "../../../../helpers/currencyFormatter";
import { BsCreditCard2Front } from "react-icons/bs";
import brand_src from "../../../../assets/imgs/brand/brand.png";
import { IoIosArrowBack, IoIosArrowForward, IoMdListBox } from "react-icons/io";
import { LANDING_PAGE, ORDER_TRACKING_PAGE } from "../../../../helpers/paths";
import { useDispatch, useSelector } from "react-redux";
import CustomDropdown from "../../../plugins/CustomDropdown";
import {
  createOrder,
  getOrderPaymentStatus,
  getPostalData,
} from "../../../../redux/actions/orderAction";
import { useToast } from "../../../plugins/toaster/useToast";
import { useNavigate } from "react-router-dom";
import { clearOrderActionStatus } from "../../../../redux/slices/orderSlice";
import InfinityLoader from "../../../../assets/imgs/loaders/InfinityLoader";
import CmacModal from "../../../plugins/cmac-plugins/CmacModal";
import { getQueryParam } from "../../../../helpers/getQueryParams";
import { performCrypto } from "../../../../helpers/performCrypto";
import DotLoader from "../../../../assets/imgs/loaders/DotLoader";

const CheckoutInputs = ({ handleCheckoutDetailsModal }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const crypto = performCrypto();
  const [paymentMethod, setPaymentMethod] = useState("COD");
  const { loading, order, orderInitiatedData, actionStatus } = useSelector(
    (state) => state.orderState
  );
  const encriptedData = getQueryParam("encripted_data");
  const { address } = useSelector((state) => state.addressState);
  const { user, isAuthenticated } = useSelector((state) => state.userState);
  const { loading: cartLoading } = useSelector((state) => state.cartState);
  const { postalData } = useSelector((state) => state.postalState);
  const [postalCodeEnteringInput, setPostalCodeEnteringInput] = useState(false);
  const [locationList, setLocationList] = useState([]);
  const toast = useToast();
  const [addresses, setAddresses] = useState();
  const [addressModal, setAddressModal] = useState(false);
  const [selectedAddressId, setSelectedAddressId] = useState("");
  const [addressData, setAddressData] = useState({});
  const [verifingPayment, setVerifingPayment] = useState(false);

  /* Regex */

  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const phoneRegex = /^(\+91[\-\s]?)?[0]?(91)?[6789]\d{9}$/;

  /* Input States */

  const [email, setEmail] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [addressLine1, setAddressLine1] = useState("");
  const [addressLine2, setAddressLine2] = useState("");
  const [district, setDistrict] = useState("");
  const [location, setLocation] = useState("");

  /* Input States Errors*/

  const [emailError, setEmailError] = useState(false);
  const [mobileNumberError, setMobileNumberError] = useState(false);
  const [postalCodeError, setPostalCodeError] = useState(false);
  const [firstNameError, setFirstNameError] = useState("");
  const [addressLine1Error, setAddressLine1Error] = useState("");
  const [locationError, setLocationError] = useState(false);

  /* Disable Button State */

  const [disableSubmitButton, setDisableSubmitButton] = useState(true);

  const handleLocationValue = (e) => {
    setLocation(e);
    let locationValue = e;
    if (!locationValue) {
      setLocationError(true);
    } else {
      setLocationError(false);
    }
    if (
      !email ||
      !mobileNumber ||
      !postalCode ||
      !firstName ||
      !addressLine1 ||
      !locationValue
    ) {
      setDisableSubmitButton(true);
    } else {
      setDisableSubmitButton(false);
    }
  };

  const handleInputErrors = () => {
    let isValid = true;
    if (!email) {
      setEmailError(true);
      isValid = false;
      toast.open("Please enter your Email!");
    } else if (!emailRegex.test(email)) {
      setEmailError("Please enter a valid email address.");
      isValid = false;
    } else {
      setEmailError(false);
      isValid = true;
    }
    if (!mobileNumber) {
      setMobileNumberError(true);
      isValid = false;
    } else if (!phoneRegex.test(mobileNumber)) {
      setEmailError("Please enter a valid Mobile Number.");
      isValid = false;
    } else {
      setMobileNumberError(false);
      isValid = true;
    }
    if (!postalCode) {
      setPostalCodeError(true);
      isValid = false;
    } else {
      setPostalCodeError(false);
      isValid = true;
    }
    if (!firstName) {
      setFirstNameError(true);
      isValid = false;
    } else {
      setFirstNameError(false);
      isValid = true;
    }
    if (!addressLine1) {
      setAddressLine1Error(true);
      isValid = false;
    } else {
      setAddressLine1Error(false);
      isValid = true;
    }
    if (!location) {
      setLocationError(true);
      isValid = false;
    } else {
      setLocationError(false);
      isValid = true;
    }
    if (
      !email ||
      !mobileNumber ||
      !postalCode ||
      !firstName ||
      !addressLine1 ||
      !location
    ) {
      setDisableSubmitButton(true);
    } else {
      setDisableSubmitButton(false);
    }
    return isValid;
  };
  const handleSubmitOrder = () => {
    let isValid = handleInputErrors();
    if (isValid) {
      const payload = {
        email,
        mobileNumber,
        country: "India",
        state: postalData?.length > 0 && postalData[0]?.State,
        postalCode,
        firstName,
        lastName,
        addressLine1,
        addressLine2,
        district,
        location,
      };
      dispatch(createOrder(payload));
    }
  };
  const handleAddressData = (data) => {
    setSelectedAddressId(data?._id);
    setAddressData(data);
  };
  const handleClearSelectedAddress = () => {
    setAddressModal(false);
  };
  const handleChangeAddress = () => {
    setFirstName(addressData?.firstName || "");
    setLastName(addressData?.lastName || "");
    setEmail(user?.email || "");
    setMobileNumber(addressData?.mobileNumber || "");
    setAddressLine1(addressData?.addressLine1 || "");
    setAddressLine2(addressData?.addressLine2 || "");
    setDistrict(addressData?.district || "");
    setPostalCode(addressData?.postalCode || "");
    setLocation(addressData?.location || "");
    setAddressModal(false);
  };
  useEffect(() => {
    if (postalCodeEnteringInput) {
      setTimeout(() => {
        setPostalCodeEnteringInput(false);
      }, 1000);
    }
  }, [postalCode]);
  useEffect(() => {
    if (!postalCodeEnteringInput) {
      if (postalCode) {
        dispatch(getPostalData(postalCode));
      }
    }
  }, [postalCodeEnteringInput]);
  useEffect(() => {
    const formattedList = postalData?.map((data) => {
      return data?.Name;
    });
    if (formattedList && formattedList.length > 0) {
      setLocationList(formattedList);
      setLocation("");
      setDistrict(postalData[0].District);
    }
  }, [postalData]);

  useEffect(() => {
    switch (actionStatus) {
      case "order-payment-status-success": {
        setTimeout(() => {
          dispatch(clearOrderActionStatus());
          navigate(ORDER_TRACKING_PAGE);
        }, 100);
        setVerifingPayment(false);
      }
      default: {
        dispatch(clearOrderActionStatus());
        setVerifingPayment(false);
      }
    }
  }, [actionStatus]);

  useEffect(() => {
    if (cartLoading || loading) {
      setDisableSubmitButton(true);
    }
  }, [cartLoading, loading]);
  useEffect(() => {
    setAddresses(address?.addressList);
    if (address?.addressList?.length > 0) {
      setSelectedAddressId(address?.addressList[0]?._id);
      setAddressData(address?.addressList[0]);
      setFirstName(address?.addressList[0]?.firstName || "");
      setLastName(address?.addressList[0]?.lastName || "");
      setEmail(user?.email || "");
      setMobileNumber(address?.addressList[0]?.mobileNumber || "");
      setAddressLine1(address?.addressList[0]?.addressLine1 || "");
      setAddressLine2(address?.addressList[0]?.addressLine2 || "");
      setDistrict(address?.addressList[0]?.district || "");
      setPostalCode(address?.addressList[0]?.postalCode || "");
      setLocation(address?.addressList[0]?.location || "");
    } else if (isAuthenticated) {
      setFirstName(user?.firstName || "");
      setLastName(user?.lastName || "");
      setEmail(user?.email || "");
      setMobileNumber(user?.mobileNumber || "");
    }
  }, [address, user]);
  useEffect(() => {
    if (orderInitiatedData?.redirectUrl) {
      window.location.href = orderInitiatedData.redirectUrl;
    }
  }, [orderInitiatedData]);
  useEffect(() => {
    if (encriptedData && !verifingPayment && isAuthenticated) {
      const decriptedData = crypto.decode(encriptedData);
      dispatch(
        getOrderPaymentStatus(
          decriptedData?.merchantTransactionId,
          decriptedData
        )
      );
      setVerifingPayment(true);
    }
  }, [isAuthenticated, dispatch]);
  return (
    <div className="checkout_inputs font_lato">
      <div className="element_hug end">
        <div className="element_container">
          <div className="d_flex align_items_center justify_content_space_between">
            <div
              className="d_flex align_items_center gap_3"
              style={{
                fontSize: "12px",
                fontWeight: 600,
              }}
            >
              <a
                href={LANDING_PAGE}
                className="cursor_pointer"
                style={{
                  borderBottom: "1px solid var(--cmac-primary-color)",
                  textDecoration: "none",
                  color: "var(--cmac-primary-color)",
                }}
              >
                SHOP
              </a>
              <div className="d_flex align_items_center">
                <IoIosArrowForward size={14} />
              </div>
              <div
                style={{
                  color: "var(--cmac-ternary-color)",
                  borderBottom: "1px solid transparent",
                }}
              >
                CHECKOUT
              </div>
            </div>
            <div
              onClick={handleCheckoutDetailsModal}
              className="checkout_details_btn gap_2 res-min-992px-d-none cursor_pointer"
            >
              <IoMdListBox size={18} /> Checkout Details
            </div>
          </div>
          <div className="mt_3">
            <div>
              <div
                style={{
                  fontSize: "14px",
                  fontWeight: 600,
                }}
              >
                CONTACT INFORMATION
              </div>
              <div className="d_flex w_fill gap_2">
                <div className="w_fill">
                  <Input
                    type={"email"}
                    style={{
                      width: "-webkit-fill-available",
                      marginTop: "10px",
                      background: "#fff7ee",
                    }}
                    className={`font_lato ${
                      emailError ? "input_error" : "default"
                    }`}
                    placeholder="Email Id"
                    value={email}
                    containerStyle={{
                      width: "-webkit-fill-available",
                    }}
                    onChange={(e) => {
                      setEmail(e.target.value);
                      let emailValue = e.target.value;
                      if (!emailValue) {
                        setEmailError(true);
                      } else if (!emailRegex.test(emailValue)) {
                        setEmailError("Please enter a valid email address.");
                      } else {
                        setEmailError(false);
                      }
                      if (
                        !emailValue ||
                        !mobileNumber ||
                        !postalCode ||
                        !firstName ||
                        !addressLine1 ||
                        !location
                      ) {
                        setDisableSubmitButton(true);
                      } else {
                        setDisableSubmitButton(false);
                      }
                    }}
                  />
                  <div className="error_text">{emailError}</div>
                </div>
                <div className="w_fill">
                  <Input
                    type={"number"}
                    style={{
                      width: "-webkit-fill-available",
                      marginTop: "10px",
                      background: "#fff7ee",
                    }}
                    className={`font_lato ${
                      mobileNumberError ? "input_error" : "default"
                    }`}
                    placeholder="Mobile Number"
                    value={mobileNumber}
                    containerStyle={{
                      width: "-webkit-fill-available",
                    }}
                    onChange={(e) => {
                      setMobileNumber(e.target.value);
                      let mobileNumberValue = e.target.value;
                      if (!mobileNumberValue) {
                        setMobileNumberError(true);
                      } else if (!phoneRegex.test(mobileNumberValue)) {
                        setMobileNumberError(
                          "Please enter a valid Mobile Number."
                        );
                      } else {
                        setMobileNumberError(false);
                      }
                      if (
                        !email ||
                        !mobileNumberValue ||
                        !postalCode ||
                        !firstName ||
                        !addressLine1 ||
                        !location
                      ) {
                        setDisableSubmitButton(true);
                      } else {
                        setDisableSubmitButton(false);
                      }
                    }}
                  />
                  <div className="error_text">{mobileNumberError}</div>
                </div>
              </div>
            </div>
            {/* <div className="d_flex align_items_center gap_2 mt_1">
_             <CheckboxInput
                style={{
                  height: 15,
                  width: 15,
                }}
              />
              <div
                style={{
                  fontSize: "12px",
                }}
              >
                Stay uptodate with exclusive offers and news. Unsubscribe any
                time!
              </div>
            </div> */}
          </div>
          <div className="mt_2">
            <div className="d_flex align_items_center justify_content_space_between">
              <div
                style={{
                  fontSize: "14px",
                  fontWeight: 600,
                }}
              >
                DELIVERY ADDRESS
              </div>
              <Button
                className={"font_lato"}
                onClick={() => setAddressModal(true)}
                style={{
                  color: "var(--cmac-default-fill)",
                  backgroundColor: "var(--cmac-primary-color)",
                  fontSize: "12px",
                  padding: "8px 15px",
                }}
              >
                Change Address
              </Button>
            </div>
            <div className="d_flex w_fill gap_2">
              <CustomDropdown
                value={"India"}
                placeholder="Select Location"
                options={["India"]}
                addOptionValue={false}
                disabled={true}
                className={"custom_dropdown"}
              />

              <Input
                containerStyle={{
                  width: "-webkit-fill-available",
                }}
                value={postalCode}
                style={{
                  width: "-webkit-fill-available",
                  marginTop: "10px",
                  background: "#fff7ee",
                }}
                className={`font_lato ${
                  postalCodeError ? "input_error" : "default"
                }`}
                placeholder="Postal Code"
                onChange={(e) => {
                  setPostalCodeEnteringInput(true);
                  setPostalCode(e.target.value);
                  let postalCodeValue = e.target.value;
                  if (!postalCodeValue) {
                    setPostalCodeError(true);
                  } else {
                    setPostalCodeError(false);
                  }
                  if (
                    !email ||
                    !mobileNumber ||
                    !postalCodeValue ||
                    !firstName ||
                    !addressLine1 ||
                    !location
                  ) {
                    setDisableSubmitButton(true);
                  } else {
                    setDisableSubmitButton(false);
                  }
                }}
              />
            </div>
            <div className="d_flex w_fill gap_2">
              <Input
                containerStyle={{
                  width: "-webkit-fill-available",
                }}
                style={{
                  width: "-webkit-fill-available",
                  marginTop: "10px",
                  background: "#fff7ee",
                }}
                className={`font_lato ${
                  firstNameError ? "input_error" : "default"
                }`}
                placeholder="First Name"
                value={firstName}
                onChange={(e) => {
                  setFirstName(e.target.value);
                  let firstNameValue = e.target.value;
                  if (!firstNameValue) {
                    setFirstNameError(true);
                  } else {
                    setFirstNameError(false);
                  }
                  if (
                    !email ||
                    !mobileNumber ||
                    !postalCode ||
                    !firstNameValue ||
                    !addressLine1 ||
                    !location
                  ) {
                    setDisableSubmitButton(true);
                  } else {
                    setDisableSubmitButton(false);
                  }
                }}
              />
              <Input
                containerStyle={{
                  width: "-webkit-fill-available",
                }}
                style={{
                  width: "-webkit-fill-available",
                  marginTop: "10px",
                  background: "#fff7ee",
                }}
                className={`font_lato`}
                placeholder="Last Name (Optional)"
                value={lastName}
                onChange={(e) => {
                  setLastName(e.target.value);
                  if (
                    !email ||
                    !mobileNumber ||
                    !postalCode ||
                    !firstName ||
                    !addressLine1 ||
                    !location
                  ) {
                    setDisableSubmitButton(true);
                  } else {
                    setDisableSubmitButton(false);
                  }
                }}
              />
            </div>
            <Input
              value={addressLine1}
              containerStyle={{
                width: "-webkit-fill-available",
              }}
              style={{
                width: "-webkit-fill-available",
                marginTop: "10px",
                background: "#fff7ee",
              }}
              className={`font_lato ${
                addressLine1Error ? "input_error" : "default"
              }`}
              placeholder="Address"
              onChange={(e) => {
                setAddressLine1(e.target.value);
                let addressLine1Value = e.target.value;
                if (!addressLine1Value) {
                  setAddressLine1Error(true);
                } else {
                  setAddressLine1Error(false);
                }
                if (
                  !email ||
                  !mobileNumber ||
                  !postalCode ||
                  !firstName ||
                  !addressLine1Value ||
                  !location
                ) {
                  setDisableSubmitButton(true);
                } else {
                  setDisableSubmitButton(false);
                }
              }}
            />
            <Input
              containerStyle={{
                width: "-webkit-fill-available",
              }}
              style={{
                width: "-webkit-fill-available",
                marginTop: "10px",
                background: "#fff7ee",
              }}
              className={`font_lato`}
              placeholder="Apartment, suite, etc. (optional)"
              value={addressLine2}
              onChange={(e) => {
                setAddressLine2(e.target.value);
                if (
                  !email ||
                  !mobileNumber ||
                  !postalCode ||
                  !firstName ||
                  !addressLine1 ||
                  !location
                ) {
                  setDisableSubmitButton(true);
                } else {
                  setDisableSubmitButton(false);
                }
              }}
            />
            <div className="d_flex w_fill gap_2">
              <Input
                value={district}
                containerStyle={{
                  width: "-webkit-fill-available",
                }}
                style={{
                  width: "-webkit-fill-available",
                  marginTop: "10px",
                  background: "#fff7ee",
                }}
                className="font_lato"
                placeholder="District"
                disabled={true}
              />
              <CustomDropdown
                value={location}
                placeholder="Select Location"
                onSelect={handleLocationValue}
                options={locationList}
                addOptionValue={false}
                disabled={false}
                className={`custom_dropdown ${
                  locationError ? "input_error" : "default"
                }`}
              />
            </div>
            {/* <div className="d_flex align_items_center gap_2 mt_1">
_             <CheckboxInput
                style={{
                  height: 15,
                  width: 15,
                }}
              />
              <div
                style={{
                  fontSize: "12px",
                }}
              >
                Save Address for Next Time
              </div>
            </div> */}
          </div>
          <div className="mt_5">
            <div
              style={{
                fontSize: "14px",
                fontWeight: 600,
              }}
            >
              PAYMENT
            </div>
            <div
              style={{
                marginTop: "10px",
                accentColor: "var(--cmac-primary-color)",
              }}
            >
              <div className="accordian">
                <div
                  className="accordian_header"
                  onClick={() => setPaymentMethod("COD")}
                >
                  <div>
                    <input
                      defaultChecked
                      type="radio"
                      checked={paymentMethod === "COD"}
                    />
                  </div>
                  <div className="d_flex align_items_center gap_4">
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <img src={cod} alt="cod" height={25} width={35} />
                    </div>
                    <div
                      style={{
                        fontSize: "14px",
                        fontWeight: 600,
                      }}
                    >
                      COD (Cash On Delivery)
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="accordian"
                style={{
                  marginTop: "10px",
                  opacity: "0.5",
                }}
              >
                <div
                  className="accordian_header"
                  // onClick={() => setPaymentMethod("UPI")}
                >
                  <div>
                    <input
                      type="radio"
                      checked={paymentMethod === "UPI"}
                      // disabled
                    />
                  </div>
                  <div className="d_flex align_items_center gap_4">
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <img src={upi} alt="upi" height={25} width={35} />
                    </div>
                    <div
                      style={{
                        fontSize: "14px",
                        fontWeight: 600,
                      }}
                    >
                      UPI
                    </div>
                  </div>
                </div>
              </div>

              {/* <div
                className={`accordian ${
                  paymentMethod == "C-D-CARD" ? "active" : ""
                }`}
                style={{
                  marginTop: "10px",
                }}
              >
                <div
                  className="accordian_header"
                  onClick={() => setPaymentMethod("C-D-CARD")}
                >
                  <div>
                    <input type="radio" checked={paymentMethod == "C-D-CARD"} />
                  </div>
                  <div className="d_flex align_items_center gap_4">
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <img
                        src={atm_card}
                        alt="atm_card"
                        height={25}
                        width={35}
                      />
                    </div>
                    <div
                      style={{
                        fontSize: "14px",
                        fontWeight: 600,
                      }}
                    >
                      Credit or Debit Card
                    </div>
                  </div>
                </div>
                <div>
                  <div
                    className="d_flex align_items_center"
                    style={{
                      width: "calc(100% - 10px)",
                    }}
                  >
                    <div
                      style={{
                        height: "3.3rem",
                        width: "3rem",
                        borderRadius: "5px",
                        paddingLeft: "10px",
                        outline: "none",
                        marginTop: "10px",
                        background: "#fff7ee",
                        border: "0.1rem solid #80808036",
                        borderRight: "unset",
                        borderBottomRightRadius: "0px",
                        borderTopRightRadius: "0px",
                        display: "flex",
                        alignItems: "center",
                        color: "#888888",
                      }}
                    >
                      <BsCreditCard2Front size={30} />
                    </div>
                    <input
                      className="font_lato"
                      placeholder="Card Number"
                      style={{
                        height: "3.3rem",
                        width: "-webkit-fill-available",
                        borderRadius: "5px",
                        paddingLeft: "10px",
                        outline: "none",
                        marginTop: "10px",
                        background: "#fff7ee",
                        border: "0.1rem solid #80808036",
                        borderLeft: "unset",
                        borderBottomLeftRadius: "0px",
                        borderTopLeftRadius: "0px",
                      }}
                    />
                  </div>
                  <div
                    className="d_flex align_items_center gap_2"
                    style={{
                      width: "calc(100% - 10px)",
                    }}
                  >
                    <Input
                      className="font_lato"
                      placeholder="Expiration (MM/YY)"
                      containerStyle={{
                        width: "-webkit-fill-available",
                      }}
                      style={{
                        width: "-webkit-fill-available",
                        marginTop: "10px",
                        background: "#fff7ee",
                      }}
                    />
                    <Input
                      className="font_lato"
                      placeholder="CVV"
                      containerStyle={{
                        width: "-webkit-fill-available",
                      }}
                      style={{
                        width: "-webkit-fill-available",
                        marginTop: "10px",
                        background: "#fff7ee",
                      }}
                    />
                  </div>
                  <div
                    className="d_flex align_items_center gap_2"
                    style={{
                      width: "calc(100% - 10px)",
                    }}
                  >
                    <Input
                      className="font_lato"
                      placeholder="Zip Code"
                      containerStyle={{
                        width: "-webkit-fill-available",
                      }}
                      style={{
                        width: "-webkit-fill-available",
                        marginTop: "10px",
                        background: "#fff7ee",
                      }}
                    />
                  </div>
                </div>
              </div> */}
            </div>
            <div className="d_flex align_items_center justify_content_flex_end">
              <div className="w_fill d_flex align_items_center justify_content_center">
                <button
                  className="mt_2 mb_2 font_lato"
                  style={{
                    background: "var(--cmac-primary-color)",
                    height: "50px",
                    width: "-webkit-fill-available",
                    fontSize: "18px",
                    color: "#fff",
                    outline: "none",
                    border: "none",
                    borderRadius: "5px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "8px",
                    cursor: "pointer",
                  }}
                  onClick={handleSubmitOrder}
                  disabled={disableSubmitButton}
                >
                  {(cartLoading || loading) && (
                    <InfinityLoader
                      height={50}
                      width={50}
                      stroke="var(--cmac-default-fill)"
                    />
                  )}
                  {!cartLoading && !loading && <RiLock2Fill />}
                  {!cartLoading && !loading && <div>Pay</div>}
                  {!cartLoading && !loading && (
                    <div
                      className="d_flex align_items_center price"
                      style={{
                        color: "#fff",
                        gap: "1px",
                        scale: "0.95",
                      }}
                    >
                      <div
                        style={{
                          fontSize: "18px",
                        }}
                      >
                        Rs.{" "}
                        {currencyFormatter(
                          Number(order?.orderSummary?.totalAmountToPay),
                          "INR"
                        )}
                      </div>
                    </div>
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CmacModal
        active={addressModal}
        onClickOutsider={() => setAddressModal(false)}
        portalUsage={true}
        blurStrength="stronger"
        style={{
          width: "500px",
          backgroundColor: "var(--cmac-default-fill)",
          color: "var(--cmac-primary-color)",
        }}
        className={"address_inputs_container checkout_input_address font_lato"}
      >
        <div
          className="d_flex align_items_center gap_3"
          style={{
            fontSize: "18px",
            fontWeight: 600,
            paddingBottom: "10px",
            borderBottom: "2px solid #00000011",
            marginBottom: "10px",
          }}
        >
          <div
            className="d_flex align_items_center justify_content_center cursor_pointer"
            style={{
              height: "20px",
              width: "20px",
              border: "1px solid #0000001e",
              borderRadius: "5px",
            }}
            onClick={() => setAddressModal(false)}
          >
            <IoIosArrowBack size={14} />
          </div>
          Address
        </div>
        <div className="checkout_page_address_container">
          {addresses?.map((address) => {
            return (
              <div
                className="checkout_page_address_content"
                key={address?._id}
                onClick={() => handleAddressData(address)}
                style={{
                  borderColor:
                    selectedAddressId === address?._id && "#a2877fb0",
                }}
              >
                <div className="radio_button_con">
                  <input
                    type="radio"
                    checked={selectedAddressId === address?._id}
                  />
                </div>
                <div className="address_name">{address?.addressName}</div>
                <div className="address_recipient_name">
                  {address?.firstName} {address?.lastName}
                </div>
                <div className="address_mobile_number">
                  {address?.mobileNumber}
                </div>
                <div className="address_value">{address?.addressLine1}</div>
              </div>
            );
          })}
        </div>
        <div className="address_actions">
          <div onClick={handleClearSelectedAddress}>Cancel</div>
          <Button
            onClick={() => {
              handleChangeAddress();
            }}
          >
            Continue
          </Button>
        </div>
      </CmacModal>
      <CmacModal
        active={verifingPayment}
        onClickOutsider={() => setVerifingPayment(true)}
        portalUsage={true}
        blurStrength="stronger"
        style={{
          height: "300px",
          width: "400px",
          backgroundColor: "var(--cmac-default-fill)",
          color: "var(--cmac-dark-fill)",
        }}
        className={
          "address_inputs_container font_lato d_flex align_items_center justify_content_center flex_direction_col"
        }
      >
        <div className="d_flex align_items_center justify_content_center flex_direction_col gap_2">
          <div>
            <DotLoader height={100}/>
          </div>
          <div className="f_18 f_weight_2 opacity_0_6 mt_2">Please Wait...</div>
          <div className="f_20 f_weight_2 opacity_0_8">Verifing Payment Status</div>
        </div>
      </CmacModal>
    </div>
  );
};

export default CheckoutInputs;
