import React, { useEffect, useState } from "react";
import { Button, Input } from "../../../utils/Elements";
import brand_src from "../../../../assets/imgs/brand/revive.png";
import {
  AUTH_SECRET_CODE_PAGE,
  LANDING_PAGE,
  PRIVACY_POLICIES_PAGE,
  TERMS_POLICIES_PAGE,
} from "../../../../helpers/paths";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { sendSecretCode } from "../../../../redux/actions/userAction";
import { useToast } from "../../../plugins/toaster/useToast";
import { PiWarningCircleFill } from "react-icons/pi";
import { clearSecretCodeActionStatus } from "../../../../redux/slices/secretCodeSlice";
import { IoCheckmarkCircle } from "react-icons/io5";
import InfinityLoader from "../../../../assets/imgs/loaders/InfinityLoader";

const AuthMobileNumberInput = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const { message, status, loading } = useSelector(
    (state) => state.secretCodeState
  );
  const mobileRegex = /^\d{10}$/;
  const dispatch = useDispatch();
  const [mobileNumber, setMobileNumber] = useState("");
  const handleSendSecretCode = (e) => {
    e.preventDefault();
    const payload = {
      mobileNumber,
    };
    if (mobileNumber) {
      dispatch(sendSecretCode(payload, true));
    }
  };
  const displayToast = (icon) => {
    return (
      <div className="add_cart_toast height-30 d_flex">
        <div className="d_flex align_items_center">{icon}</div>
        <div className="toast_message">{message}</div>
      </div>
    );
  };

  useEffect(() => {
    switch (status) {
      case "success": {
        toast.open(
          displayToast(<IoCheckmarkCircle color="#37cd00" size={30} />),
          {
            toastWidth: "fit-content",
            toastMsgWidth: "fit-content",
          }
        );
        dispatch(clearSecretCodeActionStatus());
        navigate(AUTH_SECRET_CODE_PAGE);
        break;
      }
      case "fail": {
        toast.open(
          displayToast(<PiWarningCircleFill color="#f00" size={30} />),
          {
            toastWidth: "fit-content",
            toastMsgWidth: "fit-content",
          }
        );
        dispatch(clearSecretCodeActionStatus());
        break;
      }
      default: {
        dispatch(clearSecretCodeActionStatus());
      }
    }
  }, [status]);
  return (
    <div className="auth_input">
      <a
        href={LANDING_PAGE}
        className="d_flex align_items_center justify_content_center cursor_pointer _brand_container"
      >
        <img src={brand_src} alt="brand_src" height={"60px"} />
        {/* LOGO */}
      </a>
      <div className="email_input_title">
        To proceed, please enter your mobile number
        <span
          style={{
            fontSize: "14px",
          }}
        >
          , we’ll send you a 6-digit OTP right away to verify it.
        </span>
      </div>
      <form onSubmit={(e) => handleSendSecretCode(e)}>
        <Input
          className="email_input_field"
          placeholder="Please enter your Mobile Number"
          containerStyle={{
            width: "400px",
          }}
          type="number"
          value={mobileNumber}
          onChange={(e) => {
            setMobileNumber(e.target.value);
          }}
        />
        <Button
          className="email_continue_btn mt_2"
          type={"submit"}
          disabled={
            !mobileNumber || !mobileRegex.test(mobileNumber) || loading
              ? true
              : false
          }
        >
          {loading ? (
            <div className="d_flex align_items_center justify_content_center gap_2">
              <InfinityLoader height={40} width={40} />
              <span
                style={{
                  fontSize: "14px",
                }}
              >
                Generating Secret Code
              </span>
            </div>
          ) : (
            "Continue"
          )}
        </Button>
      </form>
      <div className="dash_line"></div>
      <div className="alert_text">
        By proceeding, you agree to our
        <Link
          to={`${TERMS_POLICIES_PAGE}?is_auth=true`}
          style={{
            textDecoration: "underline",
            cursor: "pointer",
            color: "var(--cmac-primary-color)",
          }}
        >
          {" "}
          Terms and Conditions{" "}
        </Link>
        and{" "}
        <Link
          style={{
            textDecoration: "underline",
            cursor: "pointer",
            color: "var(--cmac-primary-color)",
          }}
          to={`${PRIVACY_POLICIES_PAGE}?is_auth=true`}
        >
          {" "}
          Privacy Policy.{" "}
        </Link>
        Please review them carefully before continuing. Your continued use of
        our services implies acceptance.
      </div>
      <div className="rights_template">
        ReviveRoots - © All Rights Reserved - 2024
      </div>
    </div>
  );
};

export default AuthMobileNumberInput;
