import axios from "axios";
import { performCrypto } from "../../helpers/performCrypto";
import {
  addTemporaryTestQuestionsFail,
  addTemporaryTestQuestionsRequest,
  addTemporaryTestQuestionsSuccess,
  addTestQuestionsFail,
  addTestQuestionsRequest,
  addTestQuestionsSuccess,
  getTestQuestionsDataFail,
  getTestQuestionsDataRequest,
  getTestQuestionsDataSuccess,
  getTestQuestionsFail,
  getTestQuestionsRequest,
  getTestQuestionsSuccess,
} from "../slices/testQuestionsSlice";
import { endPoints } from "../../api/endPoints";
import { BASE_URL } from "../../api/apiPath";
import { getVerificationToken } from "../../helpers/getVerificationToken";

export const createTemporaryTestQuestions = (payload) => async (dispatch) => {
  try {
    dispatch(addTemporaryTestQuestionsRequest());
    dispatch(addTemporaryTestQuestionsSuccess(payload));
  } catch (error) {
    dispatch(addTemporaryTestQuestionsFail({}));
  }
};

export const sendDBTemporaryTestQuestions = (payload) => async (dispatch) => {
  try {
    dispatch(addTestQuestionsRequest());
    const formData = new FormData();
    formData.append("userName", payload.userName);
    formData.append("email", payload.email);
    formData.append("mobileNumber", payload.mobileNumber);
    formData.append("age", payload.age);
    formData.append("gender", payload.gender);
    formData.append("testQuestions", JSON.stringify(payload.testQuestions));
    formData.append("testscalpimage", payload?.scalpImage?.value?.file);
    formData.append("testscalpQuestion", payload?.scalpImage?.q);
    const response = await axios.post(
      `${BASE_URL}/${endPoints.test_questions.create}`,
      formData,
      {
        withCredentials: true,
        headers: {
          "x-verification-token": getVerificationToken().X_VERIFY_TOKEN,
        },
      }
    );
    const decryptedData = performCrypto().decode(response.data.encryptedData);
    dispatch(addTestQuestionsSuccess(decryptedData));
  } catch (error) {
    const decryptedData = performCrypto().decode(
      error?.response?.data?.encryptedData
    );
    console.error("[ERROR]: ", decryptedData?.error?.message);
    dispatch(addTestQuestionsFail(decryptedData?.error));
  }
};
export const getTestQuestions = () => async (dispatch) => {
  try {
    dispatch(getTestQuestionsRequest());
    const response = await axios.get(
      `${BASE_URL}/${endPoints.test_questions.get}`,
      {
        withCredentials: true,
        headers: {
          Authorization: getVerificationToken().authToken,
          "x-verification-token": getVerificationToken().X_VERIFY_TOKEN,
        },
      }
    );
    const decryptedData = performCrypto().decode(response.data.encryptedData);
    console.log("decryptedData: ", decryptedData);

    dispatch(getTestQuestionsSuccess(decryptedData));
  } catch (error) {
    dispatch(getTestQuestionsFail({}));
  }
};
export const getTestQuestionsData = (reportId) => async (dispatch) => {
  try {
    dispatch(getTestQuestionsDataRequest());
    const response = await axios.get(
      `${BASE_URL}/${endPoints.test_questions.get_data}?question_data_id=${reportId}`,
      {
        withCredentials: true,
        headers: {
          Authorization: getVerificationToken().authToken,
          "x-verification-token": getVerificationToken().X_VERIFY_TOKEN,
        },
      }
    );
    const decryptedData = performCrypto().decode(response.data.encryptedData);
    console.log("decryptedData: ", decryptedData);

    dispatch(getTestQuestionsDataSuccess(decryptedData));
  } catch (error) {
    dispatch(getTestQuestionsDataFail({}));
  }
};
