import React, { useRef, useState, useEffect } from "react";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { useSelector } from "react-redux";

const CmacCardSlider = ({ dragStatus, cardGap, style, children }) => {
  const carouselRef = useRef(null);
  const { product } = useSelector((state) => state.productState);
  const [hasHorizontalOverflow, setHasHorizontalOverflow] = useState(false);
  const [cardWidth, setCardWidth] = useState(0);
  let isDragging = false,
    startX,
    startScrollLeft;

  const scrollCarousel = (direction) => {
    if (carouselRef.current) {
      const scrollAmount = direction === "left" ? -cardWidth : cardWidth;
      carouselRef.current.scrollLeft += scrollAmount;
    }
  };

  const dragStart = (e) => {
    const carousel = carouselRef.current;
    isDragging = true;
    carousel.classList.add("dragging");
    startX = e.pageX;
    startScrollLeft = carousel.scrollLeft;
  };

  const dragging = (e) => {
    const carousel = carouselRef.current;
    if (!isDragging) {
      dragStatus && dragStatus(false);
      return;
    }
    carousel.scrollLeft = startScrollLeft - (e.pageX - startX);
  };

  const dragStop = () => {
    const carousel = carouselRef.current;
    isDragging = false;
    carousel.classList.remove("dragging");
    dragStatus && dragStatus(false);
  };

  const checkOverflow = () => {
    const element = carouselRef.current;
    if (element) {
      const hasOverflow = element.scrollWidth > element.clientWidth;
      setHasHorizontalOverflow(hasOverflow);
    }
  };

  useEffect(() => {
    checkOverflow();
    window.addEventListener("resize", checkOverflow);

    return () => window.removeEventListener("resize", checkOverflow);
  }, [product]);

  useEffect(() => {
    const carousel = carouselRef.current;
    if (carousel) {
      const firstCard = carousel.querySelector(".card");
      if (firstCard) {
        setCardWidth(firstCard.offsetWidth);
      }
    }
  }, [children]);

  return (
    <div className="wrapper" style={{ ...style }}>
      {/* Conditionally render the left button based on overflow */}
      {hasHorizontalOverflow && (
        <span id="left" className="i" onClick={() => scrollCarousel("left")}>
          <IoIosArrowBack />
        </span>
      )}
      <div
        ref={carouselRef}
        onMouseDown={dragStart}
        onMouseMove={dragging}
        onMouseUp={dragStop}
        onMouseLeave={dragStop} // To stop dragging if the mouse leaves the carousel
        className="carousel"
        style={{ gap: cardGap ? cardGap : "16px" }}
      >
        {children}
      </div>
      {/* Conditionally render the right button based on overflow */}
      {hasHorizontalOverflow && (
        <span id="right" className="i" onClick={() => scrollCarousel("right")}>
          <IoIosArrowForward />
        </span>
      )}
    </div>
  );
};

export default CmacCardSlider;
