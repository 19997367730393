import React from "react";
import { currencyFormatter } from "../../../../helpers/currencyFormatter";
import CmacSliderCardContainer from "../../../plugins/cmac-plugins/cmac-card-slider/CmacSliderCard";
import { Link } from "react-router-dom";
import { PRODUCT_DETAILS_PAGE } from "../../../../helpers/paths";
import { addRecentProducts } from "../../../../helpers/addRecentProducts";
import { getRecentProducts } from "../../../../redux/actions/productsAction";
import { useDispatch } from "react-redux";
import { performCrypto } from "../../../../helpers/performCrypto";
import { getProduct } from "../../../../redux/actions/productAction";
import { clearProduct } from "../../../../redux/slices/productSlice";
import { clearRecentProducts } from "../../../../redux/slices/recentProductsSlice";
import { clearSimilarProducts } from "../../../../redux/slices/similarProductsSlice";

const ProductContainer = ({
  product,
  minWidth = 300,
  imgHeight = 300,
  imgWidth = 300,
}) => {
  const dispatch = useDispatch();
  const crypto = performCrypto();
  return (
    <CmacSliderCardContainer className="product_container">
      <Link
        to={`${PRODUCT_DETAILS_PAGE}?product_id=${product?._id}`}
        className="_item"
        style={{
          minWidth: minWidth,
          maxWidth: minWidth,
        }}
        onClick={() => {
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
          dispatch(clearProduct());
          dispatch(clearRecentProducts());
          dispatch(clearSimilarProducts());
          dispatch(getProduct({ productId: product?._id }));
          addRecentProducts(product?._id);
          const productIds = crypto.get("recent_items").decryptedData;
          dispatch(
            getRecentProducts(`product_ids=${JSON.stringify(productIds)}`)
          );
        }}
      >
        {(product?.isDiscountedProduct === "true" ||
          product?.isDiscountedProduct === true ||
          product?.isDiscountedProduct === true) && (
          <div
            style={{
              height: 0,
            }}
          >
            <div
              className="pos_relative z_index_1 d_flex align_items_center justify_content_center flex_direction_col f_weight_2 f_14 border_rad_50_cover cmac_primary_color_25_bg"
              style={{
                height: 50,
                width: 50,
                top: "10px",
                left: "10px",
                color: "#000",
              }}
            >
              <div>{product?.discountPercentage}%</div>
              <div className="f_10">OFF</div>
            </div>
          </div>
        )}
        <div
          className="_img_container mb_2"
          style={{
            height: imgHeight,
            width: imgWidth,
          }}
        >
          <img src={product?.productImages[0]?.url} alt={product._id} />
        </div>
        <div
          style={{
            height: "60px",
          }}
        >
          <p>{product.productName}</p>
          <div
            className="d_flex align_items_center mt_1 price"
            style={{
              color: "var(--cmac-ternary-color-dark)",
            }}
          >
            <div
              className="d_flex align_items_center gap_2"
              style={{
                fontSize: "14px",
                fontWeight: "600",
              }}
            >
              <div>Rs. {currencyFormatter(product.salePrice, "INR")}</div>
              {(product?.isDiscountedProduct === "true" ||
                product?.isDiscountedProduct === true) && (
                <div className="text_decoration_line_through opacity_0_4 f_12">
                  Rs. {currencyFormatter(product.grossPrice, "INR")}
                </div>
              )}
            </div>
          </div>
        </div>
      </Link>
    </CmacSliderCardContainer>
  );
};

export default ProductContainer;
