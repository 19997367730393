import React, { useState } from "react";
import Products from "./Products";
import FilterBar from "./FilterBar";
import { CmacIcFilter, CmacIcSort } from "../../../../assets/icons/CmacIcons";
import CmacSideDragger from "../../../plugins/cmac-plugins/cmac-side-dragger/CmacSideDragger";
import { IoClose } from "react-icons/io5";

const ProductList = ({searching}) => {
  const [resFilterModal, setResFilterModal] = useState(false);
  const [filterValues, setFilterValues] = useState({});
  const setFilterValuesData = (e) => {
    setFilterValues(e);
  };
  return (
    <div className="product_list">
      <div className="element_hug">
        <div className="element_container">
          <div className="d_flex align_items_center justify_content_space_between mt_2">
            <div
              className="d_flex align_items_center gap_2"
              style={{
                padding: "15px",
                background: "var(--cmac-primary-color)",
                color: "var(--cmac-default-fill)",
                fontSize: "14px",
              }}
              onClick={(e) => {
                setResFilterModal(true);
                e.stopPropagation();
              }}
            >
              <CmacIcFilter
                heightVal={20}
                widthVal={20}
                style={{ fill: "var(--cmac-default-fill)" }}
              />
              <div>Filter</div>
            </div>
            <div className="d_flex align_items_center gap_3">
              <div
                className="d_flex align_items_center justify_content_center"
                style={{
                  height: "35px",
                  width: "35px",
                  background: "var(--cmac-primary-color)",
                  borderRadius: "50%",
                }}
              >
                <CmacIcSort
                  heightVal={15}
                  widthVal={15}
                  style={{ fill: "var(--cmac-default-fill)" }}
                />
              </div>
              <select
                style={{
                  height: "40px",
                  width: "130px",
                  background: "var(--cmac-default-fill)",
                  outline: "none",
                  border: "none",
                  borderBottom: "1px solid var(--cmac-primary-color)",
                  color: "var(--cmac-primary-fill)",
                  letterSpacing: "1px",
                }}
                className="font_lato"
              >
                <option>Sort by</option>
              </select>
            </div>
          </div>
          <div className="flex_container">
            <div className="res-max-992px-d-none">
              <FilterBar setFilterValuesData={setFilterValuesData} />
            </div>
            <Products filterValues={filterValues} searching={searching}/>
          </div>
        </div>
      </div>
      <CmacSideDragger
        open={resFilterModal}
        dragPosition={"left"}
        onClose={() => setResFilterModal(false)}
        overlayBackground={"transparent"}
        style={{
          boxShadow: "#0000007d 0px 0px 50px 1px",
        }}
        className={"font_lato"}
        background={"var(--cmac-secondary-color)"}
      >
        <div className="shop_page">
          <div className="product_list">
            <div className="flex_container res_flex_container" style={{}}>
              <div
                style={{
                  color: "var(--cmac-primary-color)",
                  fontSize: "18px",
                  fontWeight: 600,
                  padding: "15px",
                  paddingBottom: "0px",
                }}
                onClick={() => {
                  setResFilterModal(true);
                }}
              >
                <div
                  className="d_flex align_items_center justify_content_space_between"
                  style={{
                    borderBottom: "1px solid #00000031",
                    paddingBottom: "15px",
                  }}
                >
                  <div className="d_flex align_items_center gap_3">
                    <div>Filter</div>
                    <CmacIcFilter
                      heightVal={18}
                      widthVal={18}
                      style={{ fill: "var(--cmac-primary-color)" }}
                    />
                  </div>
                  <div
                    onClick={(e) => {
                      setResFilterModal(false);
                      e.stopPropagation();
                    }}
                  >
                    <IoClose
                      size={25}
                      color="var(--cmac-primary-color)"
                      style={{
                        scale: "1.2",
                      }}
                    />
                  </div>
                </div>
              </div>
              <FilterBar background={"var(--cmac-default-fill)"} setFilterValuesData={setFilterValuesData}/>
            </div>
          </div>
        </div>
      </CmacSideDragger>
    </div>
  );
};

export default ProductList;
