import React, { useEffect, useState } from "react";
import male_avatar from "../../../../assets/imgs/vectors/avatar-design-male.png";
import female_avatar from "../../../../assets/imgs/vectors/avatar-design-female.png";
import { useDispatch, useSelector } from "react-redux";
import { createTemporaryTestQuestions } from "../../../../redux/actions/testQuestionsAction";

const FormEpsilon = ({
  selectedLink,
  selectedSubLink,
  handleSelectChange,
  setFormIndex,
}) => {
  const [gender, setGender] = useState("");
  const { testQuestions } = useSelector((state) => state.testQuestionsState);
  const dispatch = useDispatch();
  const onNext = () => {
    const payload = {
      ...testQuestions,
      15: { link: 1, sublink: 5, value: gender, key: "gender" },
    };
    dispatch(createTemporaryTestQuestions(payload));
  };
  useEffect(() => {
    if (testQuestions[15] && testQuestions[15]["value"]) {
      setGender(testQuestions[15]["value"]);
    }
  }, [testQuestions]);
  return (
    <div className="form_alpha">
      <div className="heading f_weight_1">Gender</div>
      <div className="interactions_container">
        <div className="input_container">
          <div className="label">Select your gender</div>
          <div className="list_containers mt_2">
            <div
              className={`gender_value ${gender === "male" && "active"}`}
              onClick={() => {
                setGender("male");
                setFormIndex(0);
              }}
            >
              <img src={male_avatar} alt="male_avatar" height={30} />
              <div>Male</div>
            </div>
            <div
              className={`gender_value ${gender === "female" && "active"}`}
              onClick={() => {
                setGender("female");
                setFormIndex(1);
              }}
            >
              <img src={female_avatar} alt="female_avatar" height={30} />
              <div>Female</div>
            </div>
          </div>
        </div>
        <div className="mt_2 interactions_btn_container">
          <button
            className="interaction_btn_alpha"
            onClick={() =>
              handleSelectChange(selectedLink, selectedSubLink - 1)
            }
          >
            Previous
          </button>
          <button
            className="interaction_btn_beta"
            onClick={() => {
              gender && handleSelectChange(2, 1);
              gender && onNext();
            }}
            style={{
              transition: "all 0.5s ease-in-out",
              opacity: !gender && "0.2",
            }}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default FormEpsilon;
