import React from "react";

const SideBanner = () => {
  return (
    <div className="side_banner">
      <div className="img_container"></div>
    </div>
  );
};

export default SideBanner;
