import React, { useEffect } from "react";
import { getQueryParam } from "../../helpers/getQueryParams";
import { Link } from "react-router-dom";
import { AUTH_PAGE } from "../../helpers/paths";

const PrivacyPolicies = () => {
  const isAuth = getQueryParam("is_auth");
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <div className="policies_container">
      {isAuth && (
        <div className="d_flex align_items_center justify_content_center mb_2">
          <Link
            style={{
              color: "#fff",
              background: "var(--cmac-primary-color)",
              padding: "10px",
              borderRadius: "5px",
            }}
            to={AUTH_PAGE}
          >
            Back to Login Page
          </Link>
        </div>
      )}
      <div>
        <h1 className="policies_title">Privacy Policy</h1>
        <p>
          The online website “Revive Roots Rituals” is operated by Revive Roots
          Inspirations Private Limited “Revive Roots” having its registered
          office located in Hyderabad. By using the website and related
          products, you signify your agreement to be bound by Revive Roots's
          Conditions of Use.
        </p>
        <p className="mt_1">
          Revive Roots is committed to keeping confidential, secure, and private
          any and all personal information collected from individuals that visit
          our website and purchase our products.
        </p>
        <p className="mt_1">
          Our privacy policy has been designed and created to ensure those
          affiliated with Revive Roots of our commitment and realization of our
          obligation not only to meet but to exceed most existing privacy
          standards.
        </p>
        <p className="mt_1">
          Therefore, this Privacy Policy Agreement shall apply to Revive Roots
          and shall govern any and all data collection and usage thereof.
        </p>
        <p className="mt_1">
          As mentioned, we at Revive Roots set a high priority on protecting
          your privacy and personal information. Our commitment is to uphold the
          highest standards for secure transactions and data security.
        </p>
        <p className="mt_1">
          Please read the following statement carefully to understand how we
          collect and utilize your personal information.
        </p>
        <p className="mt_1">
          Kindly be advised that we reserve the right to change our privacy
          statement at any time and without prior notice. Review this policy
          from time to time to stay informed/updated of any revisions.
        </p>
        <p className="mt_1">
          You may, at your discretion, read through our detailed privacy policy
          to learn how we gather, use, and disclose personal information on our
          website, as well as with whom we might share it. Learn about the
          security measures we take to protect your data, as well as how simple
          it is to access, edit, or remove your personal information whenever
          you choose. Understand how to object to the processing of your
          personal data or choose not to receive communications about our
          products and services.
        </p>
        <p className="mt_1">
          By using our website, you voluntarily consent to our privacy policy,
          which controls the use and dissemination of your personal information.
          If you disagree, we kindly request that you refrain from using or
          gaining access to our website. Our terms of use include our privacy
          policy in detail.
        </p>
        <p className="mt_1">
          At Revive Roots, we value your privacy and are committed to providing
          the highest level of security for your personal information.
        </p>

        <h2 class="section_title">1. Terms and Conditions</h2>
        <h3>
          i. Collection of Personally Identifiable Information (PII) and Other
          Information
        </h3>
        <p class="section_content">
          Revive Roots only collects data required to provide accurate service.
          Usually, only the most basic details are needed to complete a
          transaction or issue a refund. Name, address, phone number, date of
          birth, email address, product descriptions or purchases, language
          preference, IP address, operating system of your computer, and the
          type and version of your browser are a few examples of the kind of
          customer information we may collect.
        </p>
        <p class="section_content">
          Your personal information won't be leased, sold, or given away to
          third parties.
        </p>
        <p class="section_content">
          We may gather certain information about you automatically enabling us
          to understand better, protect, and serve our users by enabling us to
          undertake internal research on the demographics, interests, and
          behavior of our users. This data is gathered and examined on an
          aggregate level. It might also contain information about your
          computer's browser and IP address, as well as the URLs you just came
          from and went to (whether they are on our site).
        </p>
        <h3>ii. Banking information, debit card, and credit card details</h3>
        <p class="section_content">
          Since credit card, debit card, and other banking information is sent
          to the payment network or bank directly through the payment gateway
          provider, Revive Roots does not collect or retain this data.
        </p>

        <h2 class="section_title">2. Registration</h2>
        <p class="section_content">
          To access certain features on the website, you may need to register
          and create an account, which is completely free. During the
          registration process, you will be asked to provide your name, email
          address, and create a password.
        </p>
        <p class="section_content">
          Your password is an essential part of our security measures, and it is
          your responsibility to keep it safe. Please do not share your password
          with anyone. If you suspect that your password has been compromised,
          please change it immediately.
        </p>

        <h2 class="section_title">
          3. Technologies That Enhance Your Experience on Revive Roots
        </h2>
        <p class="section_content">
          We use various technologies to collect information regarding your
          visit to our website, such as the Uniform Resource Locator (URL) that
          referred you to our website, Internet Protocol (IP) address, browser
          type, browser language, date and time of your request, and more. These
          technologies enable us to enhance and personalize your experience on
          our website. For example, we may collect your IP address to identify
          your geographic location for statistical purposes. In some cases, we
          may use these technologies in combination with the personally
          identifiable information you provide on the website. They may also
          help you save your preferences and show you whether you have visited
          Revive Roots before. Each technology is explained below.
        </p>

        <h2 class="section_title">4. Web Beacons And Tracking Links</h2>
        <p class="section_content">
          Web Beacons, tracking links, and similar technologies are small pieces
          of code embedded on our website pages that are often used in
          combination with cookies. They may not be visible to website users but
          can relay information to third parties, including our service
          providers. These technologies may be used to track customer response
          to advertising, improve targeted advertising, and enhance customer
          support and usability. If you reject our cookies, you may limit your
          use of some of our website features that rely on these technologies.
        </p>

        <h3>Use of Cookies</h3>
        <p class="section_content">
          We may use "cookies" on certain pages of our website to help us
          analyze the flow of our web pages, measure the effectiveness of our
          promotions, and enhance security. Cookies are small files that are
          placed on your computer's hard drive that help us provide our
          services. Some of our website's features are only accessible through
          the use of cookies. Cookies also enable you to enter your password
          less often during a browsing session, and help us provide information
          that is tailored to your interests. Most cookies are "session cookies"
          that are automatically deleted from your hard drive at the end of a
          browsing session. You have the option to decline our cookies if your
          web browser allows it, but this may prevent you from using certain
          features of our website and require you to enter your password more
          frequently during a browsing session.
        </p>
        <p class="section_content">
          Here are the different types of cookies used on our website:
        </p>
        <h3>i. Necessary Cookies</h3>
        <p class="section_content">
          These cookies are essential for the basic functioning of the website
          and ensure compliance with privacy laws and regulations.
        </p>
        <h3>ii. Functional Cookies</h3>
        <p class="section_content">
          These cookies are used to improve website access for users. They
          remember and store information such as user ID, location, font size,
          and language preferences.
        </p>
        <h3>iii. Performance Cookies</h3>
        <p class="section_content">
          These cookies are used to enhance website performance by analyzing
          user preferences and shortlisted products.
        </p>
        <p class="section_content">
          Please note that our website does not offer the option to disable
          online tracking as it is not uniform across all websites. Our website
          includes third-party software development kits (SDKs) that may collect
          personally identifiable information, such as your name, email address,
          and physical address. This information is used to identify potential
          customers and provide relevant services.
        </p>

        <h2 class="section_title">6. Use of Personal Information</h2>
        <p class="section_content">
          At Revive Roots, we use your personal information to provide you with
          the services you request, as well as to improve and customize your
          experience on our website. We may also use your personal information
          to inform you about our products, services, and updates, and to
          measure consumer interest in our offerings.
        </p>
        <p class="section_content">
          We value your privacy and offer you the ability to opt-out of any
          marketing communications. In addition, we collect and analyze
          demographic and profile data to better understand our users' activity
          on our website, and to improve our product and service offerings.
        </p>
        <p class="section_content">
          We also use your IP address to help diagnose any technical issues with
          our server, as well as to gather broad demographic information. From
          time to time, we may request that you complete optional online surveys
          that ask for your feedback on our products and services. These surveys
          help us enhance your experience and better understand our customers.
        </p>

        <h2 class="section_title">7. Security of Your Information</h2>
        <p class="section_content">
          We are committed to protecting your personal information and have
          implemented a variety of security measures to ensure its safety. We
          use SSL (Secure Socket Layer) technology to encrypt sensitive
          information transmitted online, as well as firewalls and intrusion
          detection systems to protect our servers from unauthorized access.
        </p>
        <p class="section_content">
          However, no method of transmission over the Internet or method of
          electronic storage is 100% secure. While we strive to protect your
          personal information, we cannot guarantee its absolute security.
        </p>

        <h2 class="section_title">8. Accessing Your Personal Information</h2>
        <p class="section_content">
          You have the right to access, update, and correct your personal
          information at any time. You can do this by logging into your account
          on our website or by contacting us directly. We will respond to your
          request within a reasonable timeframe.
        </p>

        <h2 class="section_title">9. Third-Party Links</h2>
        <p class="section_content">
          Our website may contain links to third-party websites. We are not
          responsible for the privacy practices of these websites, and we
          encourage you to read their privacy policies before providing any
          personal information.
        </p>

        <h2 class="section_title">10. Children's Privacy</h2>
        <p class="section_content">
          Our services are not intended for children under the age of 13. We do
          not knowingly collect personal information from children under 13. If
          we become aware that we have collected personal information from a
          child under 13, we will take steps to delete that information.
        </p>

        <h2 class="section_title">11. Your Consent</h2>
        <p class="section_content">
          By using our website, you consent to our privacy policy and the
          collection and use of your personal information as described in this
          policy.
        </p>

        <h2 class="section_title">12. Changes to Our Privacy Policy</h2>
        <p class="section_content">
          We may update this privacy policy from time to time. Any changes will
          be posted on this page with an updated effective date. Your continued
          use of the website following the posting of changes constitutes your
          acceptance of such changes.
        </p>

        <h2 class="section_title">13. Contact Us</h2>
        <p class="section_content">
          If you have any questions or concerns about our privacy policy, please
          contact us at:
        </p>
        <p class="section_content">
          <div>
            Email:{" "}
            <a href="mailto:reviverootsindia@gmail.com">
              reviverootsindia@gmail.com
            </a>
          </div>
          <div>Phone: +91 96003 90148</div>
        </p>

        <h2 class="section_title">14. Limitation of Liability</h2>
        <p class="section_content">
          Under no circumstances shall Revive Roots be liable for any direct,
          indirect, incidental, special, or consequential damages that result
          from the use of or the inability to use the website, including but not
          limited to reliance by a user on any information obtained from Old
          School, or that result from mistakes, omissions, interruptions,
          deletion of files, or errors on the website.
        </p>

        <h2 class="section_title">15. Indemnification</h2>
        <p class="section_content">
          You agree to defend, indemnify, and hold Revive Roots and its
          affiliates harmless from any loss, damages, or costs, including
          reasonable attorneys' fees, resulting from any third-party claim
          arising out of your use of our website or violation of these terms.
        </p>
      </div>
      {isAuth && (
        <div className="d_flex align_items_center justify_content_center mt_3">
          <Link
            style={{
              color: "#fff",
              background: "var(--cmac-primary-color)",
              padding: "10px",
              borderRadius: "5px",
            }}
            to={AUTH_PAGE}
          >
            Back to Login Page
          </Link>
        </div>
      )}
    </div>
  );
};

export default PrivacyPolicies;
