import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { TEST_OVERVIEW_PAGE } from "../../../../helpers/paths";
import DataTable from "react-data-table-component";
import moment from "moment";
import EmptyDataState from "../../../plugins/EmptyDataState";
import { getTestQuestions } from "../../../../redux/actions/testQuestionsAction";
import { FiDownload } from "react-icons/fi";

const TestManagement = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { testQuestionsList } = useSelector(
    (state) => state.testQuestionsState
  );
  const columns = [
    {
      name: "Report ID",
      selector: (row) => (
        <div
          onClick={() =>
            navigate(`${TEST_OVERVIEW_PAGE}?test_report_id=${row?._id}`)
          }
        >
          {row?._id}
        </div>
      ),
      width: "250px"
    },
    {
      name: "Patient Name",
      selector: (row) => (
        <div
          onClick={(e) =>
            navigate(`${TEST_OVERVIEW_PAGE}?test_report_id=${row?._id}`)
          }
        >
          {row?.userName}
        </div>
      ),
      width: "200px"
    },
    {
      name: "Mobile Number",
      selector: (row) => (
        <div
          onClick={(e) =>
            navigate(`${TEST_OVERVIEW_PAGE}?test_report_id=${row?._id}`)
          }
        >
          {row?.email}
        </div>
      ),
      width: "150px"
    },
    {
      name: "Email",
      selector: (row) => (
        <div
          onClick={(e) =>
            navigate(`${TEST_OVERVIEW_PAGE}?test_report_id=${row?._id}`)
          }
        >
          {row?.mobileNumber}
        </div>
      ),
      width: "250px"
    },
    {
      name: "Age",
      selector: (row) => (
        <div
          onClick={(e) =>
            navigate(`${TEST_OVERVIEW_PAGE}?test_report_id=${row?._id}`)
          }
        >
          {row?.age}
        </div>
      ),
      width: "80px"
    },
    {
      name: "Gender",
      selector: (row) => (
        <div
          onClick={(e) =>
            navigate(`${TEST_OVERVIEW_PAGE}?test_report_id=${row?._id}`)
          }
        >
          {row?.gender}
        </div>
      ),
      width: "80px"
    },
    {
      name: "Created On",
      selector: (row) => (
        <div
          onClick={(e) =>
            navigate(`${TEST_OVERVIEW_PAGE}?test_report_id=${row?._id}`)
          }
        >
          {moment(row?.createdAt).format("DD, MMM YYYY HH:MM A")}
        </div>
      ),
      width: "200px"
    },
    {
      name: "Report",
      selector: (row) => (
        <div
          onClick={(e) =>
            navigate(`${TEST_OVERVIEW_PAGE}?test_report_id=${row?._id}`)
          }
        >
          <button
            className="d_flex align_items_center justify_content_center gap_2 cursor_pointer"
            style={{
              backgroundColor: "var(--cmac-management-outer-color)",
              outline: "none",
              border: "none",
              padding: "10px 20px",
              color: "var(--cmac-management-font-color-2)",
              borderRadius: "5px",
              fontSize: "12px"
            }}
          >
            <FiDownload size={"16px"}/>
            Download Report
          </button>
        </div>
      ),
      width: "200px"
    },
  ];
  console.log("testQuestionsList: ", testQuestionsList);

  const data = testQuestionsList.filter(
    (row) => row !== null && row !== undefined
  );
  useEffect(() => {
    dispatch(getTestQuestions());
  }, []);
  return (
    <div className="product_management">
      <div className="product_management_header">
        <div className="heading">TEST REPORT</div>
      </div>
      <div className="mt_4 data_table_comp">
        <DataTable
          columns={columns}
          data={data}
          onRowClicked={(e) =>
            navigate(`${TEST_OVERVIEW_PAGE}?test_report_id=${e._id}`)
          }
          noDataComponent={
            <EmptyDataState message={"There are no records to display"} />
          }
          fixedHeader={true}
          fixedHeaderScrollHeight="70vh"
          className="data_table_comp"
        />
      </div>
    </div>
  );
};

export default TestManagement;
