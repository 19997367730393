import PortalContainer from "./portal/PortalContainer";
import CmacContainer from "./cmac-container/CmacContainer";
import { useEffect, useState } from "react";

const CmacModal = ({
  portalUsage,
  active,
  onClickOutsider,
  onClickInsider,
  style,
  className,
  id,
  children,
}) => {
  const [modalActive, setModalActive] = useState(active);
  useEffect(() => {
    setTimeout(() => {
      setModalActive(active);
    }, 1);
  }, [active]);
  return (
    <PortalContainer>
      <CmacContainer
        active={active}
        portalUsage={portalUsage}
        onClickOutsider={(e) => onClickOutsider && onClickOutsider(e)}
      >
        <div className={`${active ? 'control_modal' : "unhandled_modal"}`}>
          <div
            className={`cmac_modal ${modalActive ? "modal_active" : "default"}`}
          >
            <div
              style={{ ...style }}
              className={`${className} content`}
              id={id}
              onClick={(e) => {
                onClickInsider && onClickInsider(e);
                e.stopPropagation();
              }}
            >
              {children}
            </div>
          </div>
        </div>
      </CmacContainer>
    </PortalContainer>
  );
};

export default CmacModal;
