import { createSlice } from "@reduxjs/toolkit";

export const productSlice = createSlice({
  name: "product",
  initialState: {
    loading: false,
    product: {},
    message: null,
    status: null,
    methodStatus: null,
  },
  reducers: {
    productRequest(state, payload) {
      return {
        ...state,
        loading: true,
      };
    },
    productSuccess(state, action) {
      return {
        ...state,
        loading: false,
        product: action.payload.data.product,
        message: action.payload.message,
        status: "success",
        methodStatus: "get-success",
      };
    },
    productFail(state, action) {
      return {
        ...state,
        loading: false,
        message: action.payload.message,
        status: "fail",
        methodStatus: "get-success",
      };
    },
    productDeleteRequest(state, payload) {
      return {
        ...state,
        loading: true,
      };
    },
    productDeleteSuccess(state, action) {
      return {
        ...state,
        loading: false,
        message: action.payload.message,
        status: "success",
        methodStatus: "delete-success",
      };
    },
    productDeleteFail(state, action) {
      return {
        ...state,
        loading: false,
        message: action.payload.message,
        status: "fail",
        methodStatus: "delete-fail",
      };
    },
    productCreateRequest(state) {
      return {
        ...state,
        loading: true,
      };
    },
    productCreateSuccess(state, action) {
      return {
        ...state,
        loading: false,
        product: action.payload.data.product,
        message: action.payload.message,
        status: "success",
        methodStatus: "create-success",
      };
    },
    productCreateFail(state, action) {
      return {
        ...state,
        loading: false,
        message: action.payload.message,
        status: "fail",
        methodStatus: "create-fail",
      };
    },
    productUpdateRequest(state) {
      return {
        ...state,
        loading: true,
      };
    },
    productUpdateSuccess(state, action) {
      return {
        ...state,
        loading: false,
        product: action.payload.data.product,
        message: action.payload.message,
        status: "success",
        methodStatus: "update-success",
      };
    },
    productUpdateFail(state, action) {
      return {
        ...state,
        loading: false,
        message: action.payload.message,
        status: "fail",
        methodStatus: "update-success",
      };
    },
    clearProduct(state) {
      return {
        ...state,
        loading: false,
        product: {},
        message: null,
        status: null,
      };
    },
    clearProductStatus(state) {
      return {
        ...state,
        loading: false,
        status: null,
        methodStatus: null,
      };
    },
  },
});

const { actions, reducer } = productSlice;

export const {
  productRequest,
  productFail,
  productSuccess,
  productDeleteRequest,
  productDeleteFail,
  productDeleteSuccess,
  productCreateRequest,
  productCreateFail,
  productCreateSuccess,
  productUpdateRequest,
  productUpdateFail,
  productUpdateSuccess,
  clearProduct,
  clearProductStatus
} = actions;

export default reducer;
