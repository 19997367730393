import React from "react";

const TermsAndConditions = () => {
  return (
    <div>
      <h1 className="policies_title">Terms and Conditions</h1>
      <p className="terms_intro">
        This document is an electronic record and is governed by the Information
        Technology Act, 2000, and its applicable rules and amended provisions
        related to electronic records in various statutes as amended by the
        Information Technology Act, 2000. The document is generated by a
        computer system and does not require any physical or digital signatures.
      </p>

      <h2 className="section_title">1. Welcome to the Reviveroots Online</h2>
      <p className="section_content">
        Reviveroots provides the content and services available on this website
        to you subject to the following Terms and Conditions, our Privacy
        Policy, Payment Policy and other terms and conditions and policies which
        you may find throughout our website in connection with certain
        functionality, features or promotions as well as customer service, all
        of which are deemed a part of and included within these terms and
        conditions (collectively, "Terms and Conditions"). By accessing or using
        this website, you are acknowledging that you have read, understood, and
        you agree, without limitation or qualification, to be bound by these
        Terms and Conditions in this Agreement, so please read this carefully
        before proceeding.
      </p>

      <h2 className="section_title">2. Privacy Policy</h2>
      <p className="section_content">
        You may as per your discretion also take a moment to review our privacy
        policy as has been specified in a separate document for ease of
        reference and in order to familiarise yourself with our privacy
        practices.
      </p>

      <h2 className="section_title">3. Payment Policy</h2>
      <p className="section_content">
        You may as per your discretion also discretion refer to our Payment
        Policy as has been specified in a separate document for ease of
        reference and for information on how to make purchases on our website.
        Please note that for international shipping orders, local duties and
        taxes may apply to the destination country. The customer will be
        responsible for paying these fees.
      </p>
      <h2 className="section_title">4. Delivery Policy</h2>
      <p className="section_content">
        For deliveries within Tamil Nadu, you can expect your package to arrive
        within 2 business days. For orders shipped to other locations across
        India, delivery will take up to 7 business days. All products will be
        shipped within 3 to 4 business days after your order is confirmed. Once
        shipped, you will receive a tracking number to monitor your package's
        journey to you.
      </p>

      <h2 className="section_title">
        5. Products and Services for Personal Use Only
      </h2>
      <p className="section_content">
        The products and services offered on this website, along with any
        samples provided to you, are intended for personal use only. You are
        prohibited from selling or reselling any of the products, services, or
        samples received from us. We reserve the right, with or without prior
        notice, to cancel or decrease the quantity of any products or services
        that we believe, in our sole discretion, may result in a violation of
        our Terms and Conditions.
      </p>

      <div class="section">
        <h2 class="section_title">6. Intellectual Property</h2>
        <p class="section_content">
          All information and content available on the website and its "look and
          feel," including but not limited to trademarks, logos, service marks,
          text, graphics, button icons, images, audio clips, data compilations,
          and software, and the compilation and organization thereof
          (collectively, the "Content") are the exclusive property of
          Reviveroots, our affiliates, partners, or licensors, and are protected
          by the laws of India, including laws governing all applicable forms of
          intellectual property. Without our express, prior written consent,
          except as set forth in the limited licenses in Section 6, or as
          required under applicable law, neither the Content nor any portion of
          this website may be used, reproduced, duplicated, copied, sold,
          resold, accessed, modified, or otherwise exploited, in whole or in
          part, for any purpose.
        </p>
      </div>

      <div class="section">
        <h2 class="section_title">7. Limited Licenses</h2>
        <p class="section_content">
          We grant you a limited, non-exclusive, and revocable license to access
          and use Reviveroots's website for personal use only. This license does
          not include the right to:
        </p>
        <ul>
          <li>
            (a) frame or use framing techniques to enclose the website or any
            part of it;
          </li>
          <li>
            (b) republish, redistribute, transmit, sell, license, or download
            the website or any Content, except for caching or as necessary to
            view the website;
          </li>
          <li>
            (c) make any use of the website or any Content other than personal
            use;
          </li>
          <li>
            (d) modify, reverse engineer, or create any derivative works based
            on the website or any Content;
          </li>
          <li>
            (e) collect account information for yourself or another party;
          </li>
          <li>(f) use any meta tags or "hidden text" using any Content; or</li>
          <li>
            (g) use software robots, spiders, crawlers, or similar data
            gathering and extraction tools, or take any other action that may
            impose an unreasonable burden or load on our infrastructure.
          </li>
        </ul>
        <p class="section_content">
          You must retain all proprietary notices on the website without
          modification.
        </p>
        <p class="section_content">
          We also grant you a limited, non-exclusive, and revocable license to
          create a hyperlink to the home page of the website for personal,
          non-commercial use only. A website that links to the website may not:
        </p>
        <ul>
          <li>(i) replicate any of our Content;</li>
          <li>
            (ii) imply that we endorse such website or its products or services;
          </li>
          <li>(iii) misrepresent its relationship with us;</li>
          <li>
            (iv) contain content that could be considered distasteful, obscene,
            offensive, controversial, or illegal or inappropriate for any age
            group;
          </li>
          <li>
            (v) portray us or our products or services in a false, misleading,
            derogatory, or otherwise offensive or objectionable manner, or
            associate us with undesirable products, services, or opinions; or
          </li>
          <li>
            (vi) link to any page of the website other than the home page.
          </li>
        </ul>
        <p class="section_content">
          We may request that you remove any link to the website, and you must
          comply immediately unless we expressly authorize you to resume linking
          in writing.
        </p>
        <p class="section_content">
          Any unauthorized use of Reviveroots's website or any of our Content
          automatically terminates the limited licenses granted in this Section,
          without prejudice to any other remedy provided by applicable law or
          these Terms and Conditions.
        </p>
      </div>

      <div class="section">
        <h2 class="section_title">8. Your Obligations And Responsibilities</h2>
        <p class="section_content">
          In accessing or using the Reviveroots website, you must comply with
          these Terms and Conditions and any special warnings or instructions
          for access or use posted on the website. You must act according to all
          applicable laws, customs, statutory provisions/enactments and in good
          faith. You are not permitted to make any changes or alterations of any
          nature/type whatsoever to the website or any content or services that
          appear on the website that changes/modifies the original coding and
          design of the website. If you default negligently or willfully in any
          of the obligations set forth in these Terms and Conditions, you shall
          be liable for all losses and damages caused to Reviveroots, our
          Affiliates, partners or licensors.
        </p>
      </div>

      <div class="section">
        <h2 class="section_title">9. Third Party Links</h2>
        <p class="section_content">
          We are not accountable for the contents of any external web pages or
          websites linked to or from the Reviveroots website. Such links are
          provided for your convenience and do not indicate our endorsement of
          the referenced content, product, service, or supplier. You assume all
          risks associated with linking to or from any external web pages or
          other websites. We are not responsible for examining or evaluating the
          content, products, or services of such pages and websites, nor do we
          warrant their offerings. We assume no liability or responsibility for
          the actions, contents, terms and conditions, and privacy policies of
          such pages and websites. We recommend that you review the terms and
          conditions and privacy policies of all external web pages and other
          websites that you visit.
        </p>
      </div>

      <div class="section">
        <h2 class="section_title">
          10. Special Features, Functionality And Events
        </h2>
        <p class="section_content">
          Reviveroots may offer certain special features and functionalities or
          events (such as contests, promotions, or other offerings) which may
          (a) be subject to terms of use, rules, and/or policies in addition to
          or instead of these Terms and Conditions; and (b) be offered by us or
          by third parties. If so, we will inform you of this and if you choose
          to use these offerings, you agree that your use of those offerings
          will be subject to such additional or separate terms of use, rules,
          and/or policies.
        </p>
      </div>

      <div class="section">
        <h2 class="section_title">11. Submissions</h2>
        <p class="section_content">
          We do not accept or consider unsolicited suggestions and ideas.
          Nonetheless, any inquiries, feedback, suggestions, ideas or other
          information you provide to us (collectively, "Submissions") will be
          treated as non-confidential and non-proprietary. By submitting or
          posting any Submission, you hereby grant us the right to copy, use,
          reproduce, modify, adapt, translate, publish, license, distribute,
          sell, or assign the Submission as we deem appropriate, including, but
          not limited to, copying in whole or in part, creating derivative works
          from, distributing, and displaying any Submission in any form, media,
          or technology, whether now known or hereafter developed, alone or as
          part of other works, or using the Submission within or in connection
          with our products or services. You acknowledge that we may use your
          Submission, and any ideas, concepts, or know-how contained therein,
          for any purpose, including, without limitation, developing,
          manufacturing, distributing, and marketing products, without any
          obligation to compensate you.
        </p>
        <p class="section_content">
          When you make a Submission, you represent and warrant that you own or
          otherwise have the necessary rights to your Submission. You further
          represent and warrant that your Submission does not contain any
          software viruses, commercial solicitation, chain letters, mass
          mailings, or any form of "spam." You may not use a false email
          address, impersonate any person or entity, or otherwise mislead us as
          to the origin of any Submission. You agree to indemnify us for all
          claims arising from or in connection with any claims to any rights in
          any Submission or any damages arising from any Submission.
        </p>
      </div>

      <div class="section">
        <h2 class="section_title">12. User Content</h2>
        <p class="section_content">
          When you transmit, upload, post, email, or otherwise make available
          any data, text, software, music, sound, photographs, graphics, images,
          videos, messages, or other materials ("User Content") on the
          Reviveroots website, you are solely responsible for such User Content.
          This means that all third parties, and not us, are solely responsible
          for all User Content that they post on the website. You agree not to
          engage in or assist or encourage others to engage in transmitting,
          uploading, posting, emailing, or otherwise making available on the
          website User Content that (a) is unlawful, harmful, threatening,
          abusive, harassing, tortious, defamatory, vulgar, obscene,
          pornographic, libellous, invasive of another's privacy, hateful, or
          otherwise objectionable; (b) infringes any patent, trademark, trade
          secret, copyright or other intellectual property rights or rights of
          publicity or privacy of any third party; (c) contains software viruses
          or any other computer code, files, or programs designed to interrupt,
          destroy, or limit the functionality of any computer software or
          hardware or telecommunications equipment; (d) contains any unsolicited
          or unauthorized advertising, promotional materials, "junk mail,"
          "spam," "chain letters," "pyramid schemes," or any other form of
          solicitation; (e) is not suitable for minors; or (f) includes personal
          information of any third party without the express permission of such
          third party.
        </p>
        <p class="section_content">
          We do not endorse any User Content, and we are not responsible or
          liable for any User Content or any losses or damages of any kind
          incurred as a result of your use of any User Content. You understand
          that you may be exposed to User Content that is inaccurate,
          objectionable, inappropriate for children, or otherwise unsuited to
          your purpose. In addition, we do not guarantee the accuracy,
          integrity, or quality of any User Content.
        </p>
      </div>

      <h2 className="section_title">15. Indemnification</h2>
      <p className="section_content">
        You agree to defend, indemnify, and hold Reviveroots harmless from any
        loss, damages, or costs, including reasonable attorneys' fees, resulting
        from any third-party claim, action, or demand resulting from your use of
        the Reviveroots website or breach of these Terms and Conditions. You
        also agree to indemnify us for any loss, damages, or costs, including
        reasonable attorneys' fees, resulting from your use of software robots,
        spiders, crawlers, or similar data gathering and extraction tools, or
        any other action you take that imposes an unreasonable burden or load on
        our infrastructure.
      </p>

      <h2 className="section_title">16. Disputes</h2>
      <p className="section_content">
        In the event of any dispute regarding the website, all rights and
        obligations and all actions contemplated by these Terms and Conditions
        shall be governed by the laws of India, and any legal action shall be
        subject to the exclusive jurisdiction of the courts of Hyderabad,
        Telangana, India...
      </p>

      <h2 className="section_title">17. General</h2>
      <p className="section_content">
        You acknowledge and agree that these Terms and Conditions constitute the
        complete and exclusive agreement between us concerning your use of the
        website and subsequent usage of any of our products as made available in
        the website, and supersede and govern all prior proposals, agreements,
        or other communications.
      </p>

      <p className="terms_contact">
        If you have any questions regarding these Terms and Conditions, please
        email us at:{" "}
        <a href="mailto:reviverootsindia@gmail.com">
          reviverootsindia@gmail.com
        </a>
      </p>
    </div>
  );
};

export default TermsAndConditions;
