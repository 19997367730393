import master_card from "../../assets/imgs/vectors/cmac_vector_id_5.png";
import visa_card from "../../assets/imgs/vectors/cmac_vector_id_6.png";
import upi from "../../assets/imgs/vectors/cmac_vector_id_7.png";
import { isNoShowRoute } from "../../helpers/isNoShowRoute";
import {
  ACCOUNT_PAGE,
  ACCOUNT_TABS_PAGE,
  ADDRESS_PAGE,
  ORDERS_PAGE,
  PRIVACY_POLICIES_PAGE,
  PROFILE_PAGE,
  SHOP_PAGE,
  TERMS_POLICIES_PAGE,
} from "../../helpers/paths";
import { Link, useLocation } from "react-router-dom";
import brand from "../../assets/imgs/brand/revive.png";

const Footer = () => {
  console.log('API URL:', process.env.REACT_APP_API_URL);
  const noShowRoutesRes = [
    SHOP_PAGE,
    ACCOUNT_PAGE,
    ACCOUNT_TABS_PAGE,
    ADDRESS_PAGE,
    PROFILE_PAGE,
    ORDERS_PAGE,
  ];
  const { pathname } = useLocation();
  const isNoShowRouteRes = () => {
    if (noShowRoutesRes.some((data) => data === pathname)) {
      return true;
    } else {
      return false;
    }
  };
  if (isNoShowRoute() || isNoShowRouteRes()) {
    return <></>;
  }
  return (
    <div
      className={`footer mt_1 ${
        isNoShowRouteRes() ? "remove_footer" : "default"
      }`}
    >
      <div className="element_hug">
        <div className="element_container d_flex align_items_center justify_content_center flex_direction_col gap_8">
          <img src={brand} alt="brand" height={50} />
          <div className="d_flex align_items_center gap_6 f_weight_2 letter_spacing_1_5 res-max-768px-f-direction-c">
            {/* <div>|</div> */}
            <Link className="text_decoration_unset cmac_dark_fill_text opacity_0_8">
              Shop
            </Link>
            <Link className="text_decoration_unset cmac_dark_fill_text opacity_0_8">
              Account
            </Link>
            <Link
              className="text_decoration_unset cmac_dark_fill_text opacity_0_8"
              onClick={() => {
                window.scrollTo({
                  top: 0,
                  behavior: "smooth",
                });
              }}
              to={TERMS_POLICIES_PAGE}
            >
              Terms And Conditions
            </Link>
            <Link
              className="text_decoration_unset cmac_dark_fill_text opacity_0_8"
              onClick={() => {
                window.scrollTo({
                  top: 0,
                  behavior: "smooth",
                });
              }}
              to={PRIVACY_POLICIES_PAGE}
            >
              Privacy Policies
            </Link>
          </div>
        </div>
      </div>
      <div
        className="d_flex align_items_center gap_3 mt_3"
        style={{
          scale: "0.9",
        }}
      >
        <img src={master_card} alt="master_card" height={"40"} width={"60"} />
        <img src={visa_card} alt="visa_card" height={"40"} width={"60"} />
        <img src={upi} alt="upi" height={"40"} width={"60"} />
      </div>
      <div className="f_14 f_weight_2 mt_2">
        Reviveroots - © All Rights Reserved - 2024
      </div>
    </div>
  );
};

export default Footer;
