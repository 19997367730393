import React, { useEffect, useState } from "react";
import SideBanner from "./layouts/SideBanner";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import {
  AUTH_PAGE,
  AUTH_SERVICE_PAGE,
  LANDING_PAGE,
} from "../../../helpers/paths";
import { useSelector } from "react-redux";
import { performCrypto } from "../../../helpers/performCrypto";

const Auth = () => {
  const navigate = useNavigate();
  const crypto = performCrypto();
  const { isAuthenticated, dataFetchInitiated } = useSelector(
    (state) => state.userState
  );
  const [showRoute, setShowRoute] = useState(false);
  const { pathname } = useLocation();
  useEffect(() => {
    if (!dataFetchInitiated) {
      setShowRoute(true);
    }
  }, [dataFetchInitiated]);
  useEffect(() => {
    const preceding_route = crypto.get("preceding_route").decryptedData || null;
    if (isAuthenticated && preceding_route?.length === 0) {
      window.location.replace(LANDING_PAGE);
    } else if (pathname === AUTH_PAGE && showRoute) {
      navigate(AUTH_SERVICE_PAGE);
    }
  }, [showRoute, isAuthenticated]);
  if (showRoute)
    return (
      <div className="auth">
        <SideBanner />
        <Outlet />
      </div>
    );
};

export default Auth;
