import CmacSideDragger from "../../plugins/cmac-plugins/cmac-side-dragger/CmacSideDragger";
import { PiHandbagSimpleBold } from "react-icons/pi";
import { currencyFormatter } from "../../../helpers/currencyFormatter";
import { CmacIcCurrencyINR } from "../../../assets/icons/CmacIcons";
import {
  IoIosArrowRoundUp,
  IoIosArrowForward,
  IoIosClose,
} from "react-icons/io";
import { FaMinus, FaPlus } from "react-icons/fa6";
import {
  AUTH_PAGE,
  CHECKOUT_PAGE,
  PRODUCT_DETAILS_PAGE,
} from "../../../helpers/paths";
import { useDispatch, useSelector } from "react-redux";
import { addCart, addTempCart } from "../../../redux/actions/cartAction";
import { CmacVectorEmptyBag } from "../../../assets/icons/CmacVectors";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { getRecommendedProducts } from "../../../redux/actions/productsAction";
import { performCrypto } from "../../../helpers/performCrypto";

const CartList = ({ cartDraggerOpen, onCloseCartDragger }) => {
  const dispatch = useDispatch();
  const { cart } = useSelector((state) => state.cartState);
  const { isAuthenticated } = useSelector((state) => state.userState);
  const { recommendedProducts } = useSelector(
    (state) => state.recommendedProductsState
  );
  const {
    loading: cartLoading,
    reqFrom,
    status: cartStatus,
  } = useSelector((state) => state.cartState);
  const crypto = performCrypto();
  const [suggestedProduct, setSuggestedProduct] = useState({});
  const getSuggestedProduct = () => {
    // Filter out recommended products that are already in the cart
    const filteredProduct = recommendedProducts.filter((data) => {
      return !cart.productsIdsInCart.some(
        (productInCart) => productInCart?.productId === data?._id
      );
    });
    // Get the first suggested product or an empty object
    const product = filteredProduct?.reverse()[0] || {};

    // Set the suggested product
    setSuggestedProduct(product);
  };

  const handleCartCreationSuggested = (product) => {
    const payload = {
      productId: product._id,
      quantity: 1,
      selectedSize: product?.availableSizes[0],
    };
    if (isAuthenticated) {
      dispatch(addCart(payload, false, "cartPage"));
    } else {
      dispatch(addTempCart(payload, true, "productInfoPage"));
    }
  };
  const handleCartCreation = (productId, action) => {
    const payload = {
      productId,
      action,
    };
    if (isAuthenticated) {
      dispatch(addCart(payload, false, "cartPage"));
    } else {
      dispatch(addTempCart(payload, false, "cartPage"));
    }
  };
  const handleCheckout = () => {
    crypto.set("preceding_route", CHECKOUT_PAGE);
  };
  useEffect(() => {
    dispatch(getRecommendedProducts());
    getSuggestedProduct();
  }, [cart]);
  return (
    <CmacSideDragger
      open={cartDraggerOpen}
      dragPosition={"right"}
      onClose={() => onCloseCartDragger && onCloseCartDragger(false)}
      overlayBackground={"transparent"}
      style={{
        boxShadow: "#00000052 0px 0px 50px 1px",
      }}
      className={"font_lato"}
      background={"var(--cmac-default-shade-fill)"}
    >
      <div
        style={{
          height: "100%",
          padding: "20px",
        }}
      >
        <div
          className="d_flex align_items_center justify_content_space_between"
          style={{
            height: "50px",
            // background: 'red',
            borderBottom: "2px solid #80808038",
          }}
        >
          <div className="d_flex align_items_center justify_content_center gap_5">
            <div className="d_flex align_items_center justify_content_center">
              <PiHandbagSimpleBold size={26} />
            </div>
            <div
              style={{
                fontSize: "18px",
                textTransform: "uppercase",
                fontWeight: "900",
              }}
            >
              Cart
            </div>
          </div>
          <div onClick={() => onCloseCartDragger && onCloseCartDragger()}>
            <IoIosClose
              size={30}
              style={{
                scale: "1.3",
                cursor: "pointer",
              }}
            />
          </div>
        </div>
        <div
          className="cart_list_scroll"
          style={{
            minHeight: "calc(var(--cmac-doc-height) - 390px)",
            maxHeight:
              Object.keys(suggestedProduct)?.length == 0
                ? "calc(var(--cmac-doc-height) - 246px)"
                : "calc(var(--cmac-doc-height) - 500px)",
            overflow: "auto",
            borderBottom: "2px solid #80808038",
          }}
        >
          {/* Items Listing */}

          {/* Empty Items */}

          {!cart?.productsIdsInCart || cart?.productsIdsInCart?.length === 0 ? (
            <div
              className="d_flex align_items_center justify_content_center"
              style={{
                minHeight: "calc(var(--cmac-doc-height) - 390px)",
                maxHeight: "calc(var(--cmac-doc-height) - 500px)",
                flexDirection: "column",
                height: "100%",
              }}
            >
              <div>
                <CmacVectorEmptyBag heightVal={"120"} widthVal={"120"} />
              </div>
              <div
                style={{
                  fontWeight: "600",
                  opacity: "0.3",
                }}
              >
                Cart Is Empty
              </div>
            </div>
          ) : (
            cart?.productsIdsInCart?.map((item, i) => {
              return (
                <div
                  className="d_flex gap_6 mt_2 pb_2"
                  key={item?._id}
                  style={{
                    borderBottom:
                      i < cart?.productsIdsInCart?.length - 1
                        ? "2px dashed #80808038"
                        : "unset",
                  }}
                >
                  <div
                    style={{
                      height: "101px",
                      width: "101px",
                      background: "#eaeaea",
                    }}
                  >
                    <img
                      src={
                        item?.productImages?.length > 0 &&
                        item?.productImages[0]?.url
                      }
                      alt={item?._id}
                      height={"100px"}
                      width={"100px"}
                      style={{
                        borderRadius: "5px",
                        border: "1px solid #8686863c",
                        objectFit: "contain",
                      }}
                    />
                  </div>
                  <div>
                    <div
                      style={{
                        fontSize: "13px",
                        textTransform: "capitalize",
                        fontWeight: 600,
                      }}
                    >
                      {item?.productName}
                    </div>
                    <div
                      className="d_flex align_items_center gap_2 mt_1"
                      style={{
                        fontSize: "12px",
                        fontWeight: "600",
                      }}
                    >
                      <div
                        className="d_flex align_items_center justify_content_center"
                        style={{
                          height: "20px",
                          width: "20px",
                          border: "1px solid gray",
                          borderRadius: "5px",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          handleCartCreation(item?.productId, "remove")
                        }
                      >
                        <FaMinus />
                      </div>
                      <div
                        className="d_flex align_items_center justify_content_center"
                        style={{
                          height: "20px",
                          minWidth: "15px",
                        }}
                      >
                        {item?.quantity}
                      </div>
                      <div
                        className="d_flex align_items_center justify_content_center"
                        style={{
                          height: "20px",
                          width: "20px",
                          border: "1px solid gray",
                          borderRadius: "5px",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          handleCartCreation(item?.productId, "add")
                        }
                      >
                        <FaPlus />
                      </div>
                    </div>
                    <div className="d_flex align_items_center gap_1 mt_1">
                      <div
                        style={{
                          fontSize: "14px",
                          fontWeight: "600",
                        }}
                      >
                        Rs.{" "}
                        {currencyFormatter(
                          parseInt(item?.currentSalePrice),
                          "INR"
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })
          )}
        </div>

        <div className="mt_3">
          {Object.keys(suggestedProduct)?.length > 0 && (
            <div
              className="d_flex align_items_center justify_content_space_between"
              style={{
                fontWeight: "900",
              }}
            >
              <div>Suggested</div>
              <div>You Might Also Like</div>
            </div>
          )}
          <div className="filter_item suggested_items mt_2">
            {Object.keys(suggestedProduct)?.length > 0 && (
              <Link
                to={`${PRODUCT_DETAILS_PAGE}?product_id=${suggestedProduct._id}`}
                className="d_flex gap_2 mb_2 filter_item_link"
                style={{
                  color: "var(--cmac-dark-fill)",
                }}
              >
                <div>
                  <img
                    src={suggestedProduct.productImages[0]?.url}
                    alt={suggestedProduct._id}
                    height={90}
                    width={90}
                  />
                </div>
                <div>
                  <h5>{suggestedProduct.productName}</h5>
                  <div
                    className="d_flex align_items_center price mb_1"
                    style={{
                      color: "var(--cmac-dark-fill)",
                      marginTop: "5px",
                    }}
                  >
                    <div
                      style={{
                        fontSize: "12px",
                        fontWeight: "600",
                      }}
                    >
                      Rs. {currencyFormatter(suggestedProduct.salePrice, "INR")}
                    </div>
                  </div>
                  <div
                    className="cursor-pointer d_flex align_items_center f_weight_2"
                    style={{
                      borderBottom: "1px solid var(--cmac-primary-color)",
                      fontSize: "12px",
                      width: "fit-content",
                      color: "var(--cmac-primary-color)",
                      opacity:
                        cartLoading && reqFrom === "productInfoPage"
                          ? "0.5"
                          : "1",
                      cursor:
                        cartLoading && reqFrom === "productInfoPage"
                          ? "default"
                          : "pointer",
                    }}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      handleCartCreationSuggested(suggestedProduct);
                    }}
                    disabled={cartLoading}
                  >
                    Add Item <IoIosArrowRoundUp size={18} />
                  </div>
                </div>
              </Link>
            )}
          </div>
          <div
            className="d_flex align_items_center justify_content_space_between"
            style={{
              height: "70px",
              // background: 'red',
              borderBottom: "2px solid #80808038",
            }}
          >
            <div
              style={{
                fontSize: "20px",
                fontWeight: "900",
              }}
            >
              Total
            </div>
            <div className="d_flex align_items_center gap_1 mt_1">
              <div
                style={{
                  fontSize: "18px",
                  fontWeight: "600",
                }}
              >
                Rs. {currencyFormatter(cart?.checkoutPrice || 0, "INR")}
              </div>
            </div>
          </div>
          <div className="d_flex align_items_center justify_content_space_between mt_3">
            <button
              className="d_flex align_items_center justify_content_center gap_2 cursor_pointer font_lato"
              style={{
                height: "35px",
                width: "160px",
                background: "#faeee6",
                borderRadius: "50px",
                border: "1px solid transparent",
                fontWeight: "900",
                textTransform: "uppercase",
                fontSize: "11px",
              }}
              onClick={() => onCloseCartDragger()}
            >
              Continue Shopping
              <IoIosArrowForward
                style={{
                  scale: "1.3",
                }}
              />
            </button>
            {!cart?.productsIdsInCart ||
            cart?.productsIdsInCart?.length === 0 ? (
              <button
                className="d_flex align_items_center justify_content_center gap_2"
                style={{
                  height: "35px",
                  width: "110px",
                  background: "var(--cmac-primary-color)",
                  borderRadius: "50px",
                  border: "1px solid transparent",
                  fontWeight: "900",
                  textTransform: "uppercase",
                  fontSize: "11px",
                  textDecoration: "none",
                  color: "var(--cmac-default-fill)",
                  outline: "none",
                  opacity: "0.5",
                  cursor: "default",
                }}
              >
                Checkout <IoIosArrowForward />
              </button>
            ) : (
              <a
                href={CHECKOUT_PAGE}
                className="d_flex align_items_center justify_content_center gap_2 cursor_pointer"
                style={{
                  height: "35px",
                  width: "110px",
                  background: "var(--cmac-primary-color)",
                  borderRadius: "50px",
                  border: "1px solid transparent",
                  fontWeight: "900",
                  textTransform: "uppercase",
                  fontSize: "11px",
                  textDecoration: "none",
                  color: "var(--cmac-default-fill)",
                }}
                onClick={() => handleCheckout()}
              >
                Checkout <IoIosArrowForward />
              </a>
            )}
          </div>
        </div>
      </div>
    </CmacSideDragger>
  );
};

export default CartList;
