import React, { useEffect } from "react";
import { IoClose } from "react-icons/io5";
import { useSelector } from "react-redux";
import { useToast } from "./useToast";

export const Toaster = () => {
  const toastMessage = useToast();
  const { toasts } = useSelector((state) => state.toastsState);
  useEffect(() => {
    if (toasts?.length > 0) {
      const interval = setInterval(() => {
        toastMessage.close(toasts[0].id);
      }, toasts[0]?.timeoutSeconds);

      return () => clearInterval(interval);
    }
  }, [toasts]);
  return (
    <div className="toast_container">
      {toasts?.length > 0 &&
        toasts?.map((toast) => {
          return (
            <div
              className="toast"
              style={{
                width: toast?.toastWidth,
                backgroundColor: toast?.backgroundColor,
              }}
            >
              <div
                className="message"
                style={{
                  width: toast?.toastMsgWidth,
                }}
              >
                {toast.message}
              </div>
              <button onClick={() => toastMessage.close(toast?.id)}>
                <IoClose />
              </button>
            </div>
          );
        })}
    </div>
  );
};
