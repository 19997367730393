import {
  MANAGEMENT_DASHBOARD_PAGE,
  ORDER_MANAGEMENT_PAGE,
  ORDER_OVERVIEW_PAGE,
  PRODUCT_MANAGEMENT_PAGE,
  PRODUCT_OVERVIEW_PAGE,
  TEST_MANAGEMENT_PAGE,
  TEST_OVERVIEW_PAGE,
} from "../../../../../helpers/paths";
import { ReactComponent as DashboardIcon } from "../../../../../assets/icons/svg/apps.svg";
import { ReactComponent as ProductIcon } from "../../../../../assets/icons/svg/marketplace-store.svg";
import { ReactComponent as ShippingIcon } from "../../../../../assets/icons/svg/shipping-fast.svg";
import { ReactComponent as MedicalDoc } from "../../../../../assets/icons/svg/file-medical-alt.svg";

import { Link, useLocation } from "react-router-dom";

const Sidebar = () => {
  const { pathname } = useLocation();

  const Links = [
    {
      id: 1,
      route: MANAGEMENT_DASHBOARD_PAGE,
      linkName: "Dashboard",
      icon: (size) => <DashboardIcon height={size} width={size} />,
      top: "5px",
      controlableRoutes: [MANAGEMENT_DASHBOARD_PAGE],
    },
    {
      id: 2,
      route: PRODUCT_MANAGEMENT_PAGE,
      linkName: "Products",
      icon: (size) => <ProductIcon height={size} width={size} />,
      top: "60px",
      controlableRoutes: [PRODUCT_MANAGEMENT_PAGE, PRODUCT_OVERVIEW_PAGE],
    },
    {
      id: 3,
      route: ORDER_MANAGEMENT_PAGE,
      linkName: "Shipping",
      icon: (size) => <ShippingIcon height={size} width={size} />,
      top: "115px",
      controlableRoutes: [ORDER_MANAGEMENT_PAGE, ORDER_OVERVIEW_PAGE],
    },
    {
      id: 3,
      route: TEST_MANAGEMENT_PAGE,
      linkName: "Test Reports",
      icon: (size) => <MedicalDoc height={size} width={size} />,
      top: "170px",
      controlableRoutes: [TEST_MANAGEMENT_PAGE, TEST_OVERVIEW_PAGE],
    },
  ];
  return (
    <div className="sidebar font_merriweather">
      <div className="sidebar_contents">
        <div className="logo_container">
          <div className="logo_content">
            <div className="brand_name">
              <div>Revive Roots</div>
              <div className="border_line" />
            </div>
            <div className="sub_heading">Admin Panel</div>
          </div>
        </div>
        <div className="links_container">
          <div className={`active_link`}>
            <div
              className="span"
              style={{
                top: Links.find((data) =>
                  data.controlableRoutes.includes(pathname)
                )?.top,
              }}
            ></div>
          </div>
          {Links.map((data) => {
            return (
              <Link to={data.route} className="link_container" key={data.id}>
                <div className={`link`}>
                  <div
                    className="d_flex align_items_center"
                    style={{
                      scale: "1.1",
                    }}
                  >
                    {data.icon(18)}
                  </div>
                  <div className="f_14">{data.linkName}</div>
                </div>
              </Link>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
