import React, { useState } from "react";
import ProductImage from "./ProductImage";
import ProductInfo from "./ProductInfo";
import ProductPurchaseBox from "./ProductPurchaseBox";

const ProductInfoContainer = ({documentDimensions}) => {
  const [selectedSize, setSelectedSize] = useState("");
  const handleSelectedSize = (e) => {
    setSelectedSize(e);
  };
  return (
    <div className="product_info_container">
      <div className="element_hug">
        <div className="element_container">
          <div className="product_info_grids">
            <ProductImage documentDimensions={documentDimensions}/>
            <ProductInfo handleSelectedSize={handleSelectedSize} />
            <ProductPurchaseBox selectedSize={selectedSize} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductInfoContainer;
