import { createSlice } from "@reduxjs/toolkit";

export const addressSlice = createSlice({
  name: "address",
  initialState: {
    loading: false,
    address: {},
    addressList: [],
    message: null,
    status: null,
    allowToast: false,
    reqFrom: null,
    statusAction: null,
  },
  reducers: {
    addressesRequest(state) {
      return {
        ...state,
        loading: true,
        statusAction: "get-address-req",
      };
    },
    addressesSuccess(state, action) {
      return {
        ...state,
        loading: false,
        address: action.payload.address,
        addressList: action.payload.address?.addressList,
        message: action.payload.message,
        status: "success",
        statusAction: "get-address-success",
      };
    },
    addressesFail(state, action) {
      return {
        ...state,
        loading: false,
        message: action.payload.message,
        status: "fail",
        statusAction: "get-address-fail",
      };
    },
    addAddressRequest(state) {
      return {
        ...state,
        loading: true,
      };
    },
    addAddressSuccess(state, action) {
      return {
        ...state,
        loading: false,
        address: action.payload.address,
        addressList: action.payload.address?.addressList,
        message: action.payload.message,
        allowToast: action.payload.allowToast,
        status: "success",
      };
    },
    addAddressFail(state, action) {
      return {
        ...state,
        loading: false,
        message: action.payload.message,
        allowToast: action.payload.allowToast,
        status: "fail",
      };
    },
    clearAddress(state) {
      return {
        ...state,
        loading: false,
        address: {},
        addressList: [],
        message: null,
        status: null,
        reqFrom: null,
        statusAction: null,
      };
    },
    clearAddressActionStatus(state) {
      return {
        ...state,
        loading: false,
        status: null,
        allowToast: false,
        reqFrom: null,
        statusAction: null,
      };
    },
  },
});

const { actions, reducer } = addressSlice;

export const {
  addressesRequest,
  addressesFail,
  addressesSuccess,
  addAddressRequest,
  addAddressFail,
  addAddressSuccess,
  clearAddress,
  clearAddressActionStatus,
} = actions;

export default reducer;
