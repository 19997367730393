import axios from "axios";
import { BASE_URL } from "../../api/apiPath";
import { endPoints } from "../../api/endPoints";
import {
  productCreateFail,
  productCreateRequest,
  productCreateSuccess,
  productDeleteFail,
  productDeleteRequest,
  productDeleteSuccess,
  productFail,
  productRequest,
  productSuccess,
  productUpdateFail,
  productUpdateRequest,
  productUpdateSuccess,
} from "../slices/productSlice";
import { getProducts, getSimilarProducts } from "./productsAction";
import { getVerificationToken } from "../../helpers/getVerificationToken";
import { performCrypto } from "../../helpers/performCrypto";
import {
  addReviewFail,
  addReviewRequest,
  addReviewSuccess,
  reviewsFail,
  reviewsRequest,
  reviewsSuccess,
} from "../slices/reviewSlice";

export const createProduct = (payload) => async (dispatch) => {
  try {
    dispatch(productCreateRequest());
    const formData = new FormData();
    formData.append("productName", payload.productName);
    formData.append("alternateName", payload.alternateName);
    formData.append("description", payload.description);
    formData.append("subDescription", payload.subDescription);
    payload?.productImages.forEach((image) => {
      formData.append("productImages", image?.file);
    });
    formData.append("availableSizes", JSON.stringify(payload.availableSizes));
    formData.append("productTags", JSON.stringify(payload.productTags));
    formData.append("productCategory", payload.productCategory);
    formData.append("availableQuantity", payload.availableQuantity);
    formData.append("minimumQuantity", payload.minimumQuantity);
    formData.append("maximumQuantity", payload.maximumQuantity);
    if (payload.manufacturer)
      formData.append("manufacturer", payload.manufacturer);
    if (payload.countryOfMade)
      formData.append("countryOfMade", payload.countryOfMade);
    formData.append("unitPrice", payload.unitPrice);
    formData.append("profitPercentage", payload.profitPercentage);
    formData.append("isDiscountedProduct", payload.isDiscountedProduct);
    if (payload.discountPercentage)
      formData.append("discountPercentage", payload.discountPercentage);
    if (payload.discountStartDate)
      formData.append("discountStartDate", payload.discountStartDate);
    if (payload.discountEndDate)
      formData.append("discountEndDate", payload.discountEndDate);
    formData.append("deliveryWithinLocal", payload.deliveryWithinLocal);
    formData.append("deliveryWithinDistrict", payload.deliveryWithinDistrict);
    formData.append("deliveryWithinState", payload.deliveryWithinState);
    formData.append("deliveryInterState", payload.deliveryInterState);
    const response = await axios.post(
      `${BASE_URL}/${endPoints.product.create}`,
      formData,
      {
        headers: {
          Authorization: getVerificationToken().authToken,
          "x-verification-token": getVerificationToken().X_VERIFY_TOKEN,
        },
      }
    );
    const decryptedData = performCrypto().decode(response.data.encryptedData);
    dispatch(getProducts("limit=10"));
    dispatch(productCreateSuccess(decryptedData));
  } catch (error) {
    const decryptedData = performCrypto().decode(
      error?.response?.data?.encryptedData
    );
    dispatch(productCreateFail(decryptedData?.error));
  }
};
export const updateProduct = (payload) => async (dispatch) => {
  try {
    dispatch(productUpdateRequest());
    const imgUrls = payload?.productImages
      .map((image) => {
        if (image?._id) return image;
        return null;
      })
      .filter((url) => url != null);
    const formData = new FormData();
    formData.append("productId", payload.productId);
    formData.append("productName", payload.productName);
    formData.append("alternateName", payload.alternateName);
    formData.append("description", payload.description);
    formData.append("subDescription", payload.subDescription);
    payload?.productImages.forEach((image) => {
      if (image?.file) {
        formData.append("updateProductImages", image?.file);
      }
    });
    formData.append("productImages", JSON.stringify(imgUrls));
    formData.append("productTags", JSON.stringify(payload.productTags));
    formData.append("productCategory", payload.productCategory);
    formData.append("productStatus", payload.productStatus);
    formData.append("availableQuantity", payload.availableQuantity);
    formData.append("availableSizes", JSON.stringify(payload.availableSizes));
    formData.append("minimumQuantity", payload.minimumQuantity);
    formData.append("maximumQuantity", payload.maximumQuantity);
    if (payload.manufacturer)
      formData.append("manufacturer", payload.manufacturer);
    if (payload.countryOfMade)
      formData.append("countryOfMade", payload.countryOfMade);
    formData.append("unitPrice", payload.unitPrice);
    formData.append("profitPercentage", payload.profitPercentage);
    formData.append("isDiscountedProduct", payload.isDiscountedProduct);
    if (payload.discountPercentage)
      formData.append("discountPercentage", payload.discountPercentage);
    if (payload.discountStartDate)
      formData.append("discountStartDate", payload.discountStartDate);
    if (payload.discountEndDate)
      formData.append("discountEndDate", payload.discountEndDate);
    formData.append("deliveryWithinLocal", payload.deliveryWithinLocal);
    formData.append("deliveryWithinDistrict", payload.deliveryWithinDistrict);
    formData.append("deliveryWithinState", payload.deliveryWithinState);
    formData.append("deliveryInterState", payload.deliveryInterState);

    const response = await axios.put(
      `${BASE_URL}/${endPoints.product.update}`,
      formData,
      {
        headers: {
          Authorization: getVerificationToken().authToken,
          "x-verification-token": getVerificationToken().X_VERIFY_TOKEN,
        },
      }
    );
    const decryptedData = performCrypto().decode(response.data.encryptedData);
    dispatch(getProducts("limit=10"));
    dispatch(productUpdateSuccess(decryptedData));
  } catch (error) {
    const decryptedData = performCrypto().decode(
      error?.response?.data?.encryptedData
    );
    dispatch(productUpdateFail(decryptedData?.error));
  }
};

export const getProduct = (payload) => async (dispatch) => {
  try {
    dispatch(getReviews(payload.productId));
    dispatch(productRequest());
    const response = await axios.get(
      `${BASE_URL}/${endPoints.product.get}?product_id=${payload.productId}`,
      {
        withCredentials: true,
        headers: {
          "x-verification-token": getVerificationToken().X_VERIFY_TOKEN,
        },
      }
    );
    const decryptedData = performCrypto().decode(response.data.encryptedData);
    dispatch(productSuccess(decryptedData));
    dispatch(
      getSimilarProducts(
        `productTags=${JSON.stringify(
          decryptedData?.data?.product?.productTags
        )}`
      )
    );
  } catch (error) {
    const decryptedData = performCrypto().decode(
      error?.response?.data?.encryptedData
    );
    dispatch(productFail(decryptedData?.error));
  }
};
export const deleteProduct = (payload) => async (dispatch) => {
  try {
    dispatch(productDeleteRequest());
    const response = await axios.delete(
      `${BASE_URL}/${endPoints.product.delete}?product_id=${payload.productId}`,
      {
        withCredentials: true,
        headers: {
          Authorization: getVerificationToken().authToken,
          "x-verification-token": getVerificationToken().X_VERIFY_TOKEN,
        },
      }
    );
    const decryptedData = performCrypto().decode(response.data.encryptedData);
    dispatch(productDeleteSuccess(decryptedData));
  } catch (error) {
    const decryptedData = performCrypto().decode(
      error?.response?.data?.encryptedData
    );
    dispatch(productDeleteFail(decryptedData?.error));
  }
};

export const createReview = (payload) => async (dispatch) => {
  try {
    dispatch(addReviewRequest());
    const formData = new FormData();
    formData.append("productId", payload.productId);
    formData.append("reviewTitle", payload.reviewTitle);
    formData.append("reviewDetails", payload.reviewDetails);
    formData.append("ratingValue", payload.ratingValue);
    payload?.reviewImages.forEach((image) => {
      formData.append("reviewImages", image?.file);
    });
    if (payload?.reviewUserName) {
      formData.append("reviewUserName", payload.reviewUserName);
    }
    if (payload?.isVerifiedUser) {
      formData.append("isVerifiedUser", payload.isVerifiedUser);
    }
    if (payload.purchasedSize) {
      formData.append("purchasedSize", payload.purchasedSize);
    }
    const response = await axios.post(
      `${BASE_URL}/${endPoints.review.create}`,
      formData,
      {
        withCredentials: true,
        headers: {
          "x-verification-token": getVerificationToken().X_VERIFY_TOKEN,
          Authorization: getVerificationToken().authToken,
        },
      }
    );
    const decryptedData = performCrypto().decode(response.data.encryptedData);
    dispatch(
      addReviewSuccess({
        message: decryptedData.message,
        review: decryptedData.data?.review,
        allowToast: true,
      })
    );
  } catch (error) {
    const decryptedData = performCrypto().decode(
      error?.response?.data?.encryptedData
    );
    dispatch(
      addReviewFail({
        message: decryptedData?.error?.message,
        allowToast: true,
      })
    );
  }
};

export const updateReview = (payload) => async (dispatch) => {
  try {
    dispatch(addReviewRequest());
    const imgUrls = payload?.reviewImages
      .map((image) => {
        if (image?._id) return image;
        return null;
      })
      .filter((url) => url != null);
    const formData = new FormData();
    formData.append("productId", payload.productId);
    formData.append("reviewId", payload.reviewId);
    formData.append("reviewTitle", payload.reviewTitle);
    formData.append("reviewDetails", payload.reviewDetails);
    formData.append("ratingValue", payload.ratingValue);
    if (payload?.purchasedSize) {
      formData.append("purchasedSize", payload.purchasedSize);
    }
    if (payload?.reviewUserName) {
      formData.append("reviewUserName", payload.reviewUserName);
    }
    if (payload?.isVerifiedUser) {
      formData.append("isVerifiedUser", payload.isVerifiedUser);
    }
    payload?.reviewImages.forEach((image) => {
      formData.append("updateReviewImages", image?.file);
    });
    formData.append("reviewImages", JSON.stringify(imgUrls));
    const response = await axios.put(
      `${BASE_URL}/${endPoints.review.update}`,
      formData,
      {
        withCredentials: true,
        headers: {
          "x-verification-token": getVerificationToken().X_VERIFY_TOKEN,
          Authorization: getVerificationToken().authToken,
        },
      }
    );
    const decryptedData = performCrypto().decode(response.data.encryptedData);
    dispatch(
      addReviewSuccess({
        message: decryptedData.message,
        address: decryptedData?.data?.address,
        allowToast: true,
      })
    );
  } catch (error) {
    const decryptedData = performCrypto().decode(
      error?.response?.data?.encryptedData
    );
    dispatch(
      addReviewFail({
        message: decryptedData?.error?.message,
        allowToast: true,
      })
    );
  }
};

export const deleteReview = (productId) => async (dispatch) => {
  try {
    dispatch(addReviewRequest());
    const response = await axios.delete(
      `${BASE_URL}/${endPoints.review.delete}?product_id=${productId}`,
      {
        withCredentials: true,
        headers: {
          "x-verification-token": getVerificationToken().X_VERIFY_TOKEN,
          Authorization: getVerificationToken().authToken,
        },
      }
    );
    const decryptedData = performCrypto().decode(response.data.encryptedData);
    dispatch(
      addReviewSuccess({
        message: decryptedData.message,
        review: decryptedData.data?.review,
        allowToast: true,
      })
    );
  } catch (error) {
    const decryptedData = performCrypto().decode(
      error?.response?.data?.encryptedData
    );
    dispatch(
      addReviewFail({
        message: decryptedData?.error?.message,
        allowToast: true,
      })
    );
  }
};

export const getReviews = (productId) => async (dispatch) => {
  try {
    dispatch(reviewsRequest());
    const response = await axios.get(
      `${BASE_URL}/${endPoints.review.get}?product_id=${productId}`,
      {
        withCredentials: true,
        headers: {
          "x-verification-token": getVerificationToken().X_VERIFY_TOKEN,
          Authorization: getVerificationToken().authToken,
        },
      }
    );
    const decryptedData = performCrypto().decode(response.data.encryptedData);
    dispatch(
      reviewsSuccess({
        message: decryptedData.message,
        review: decryptedData.data?.review,
        allowToast: false,
      })
    );
  } catch (error) {
    const decryptedData = performCrypto().decode(
      error?.response?.data?.encryptedData
    );
    console.error("[ERROR]: ", decryptedData?.error?.message);
    dispatch(
      reviewsFail({
        message: decryptedData?.error?.message,
        allowToast: true,
      })
    );
  }
};
