import React, { useEffect } from "react";
import ProductList from "./layouts/ProductList";
import SearchInputResponsiveScreen from "../../common/SearchInputResponsiveScreen";

const ShopPage = ({ searching, isSearching }) => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <div className="shop_page">
      <SearchInputResponsiveScreen isSearching={isSearching} />
      <ProductList searching={searching} />
    </div>
  );
};

export default ShopPage;
