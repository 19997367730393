import React, { useEffect, useState } from "react";
import { BsArrowLeft } from "react-icons/bs";
import { FaHome, FaLocationArrow } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import cart_box from "../../../../assets/imgs/vectors/box.png";
import cart_boxes from "../../../../assets/imgs/vectors/boxes.png";
import {
  ORDER_TRACKING_PAGE,
  PRODUCT_OVERVIEW_PAGE,
  SHOP_PAGE,
} from "../../../../helpers/paths";
import { useDispatch, useSelector } from "react-redux";
import { getOrders } from "../../../../redux/actions/orderAction";
import { Input } from "../../../utils/Elements";
import moment from "moment";
import { currencyFormatter } from "../../../../helpers/currencyFormatter";
import DataTable from "react-data-table-component";
import appConstatnts from "../../../../helpers/appConstants";

const Orders = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { orders, ordersCount } = useSelector((state) => state.ordersState);
  const [orderStatusFilterValue, setOrderStatusFilterValue] =
    useState("All Orders");
  const [orderSearchInput, setOrderSearchInput] = useState("");
  const order_status_values = [
    {
      status: "processing",
      label: "Order Placed",
      bg: "#fe2d5a29",
      color: "#fe2d5a",
    },
    {
      status: "shipped",
      label: "In Transist",
      bg: "#feb70126",
      color: "#feb701",
    },
    {
      status: "delivered",
      label: "Out For Delivery",
      bg: "#00a86b26",
      color: "#00A86B",
    },
  ];
  const handleOrderStatusFilterValue = (e) => {
    setOrderStatusFilterValue(e);
    if (e) {
      const statusFilter =
        e === "Processing"
          ? appConstatnts["SHIPMENT_STATUS"]["PROCESSING"]
          : e === "Confirmed"
          ? appConstatnts["SHIPMENT_STATUS"]["SHIPPED"]
          : e === "In Transist"
          ? appConstatnts["SHIPMENT_STATUS"]["SHIPPED"]
          : e === "Delivered"
          ? appConstatnts["SHIPMENT_STATUS"]["DELIVERED"]
          : "";
      const query = `?keyword=${orderSearchInput}&orderStatusFilter=${statusFilter}`;
      dispatch(getOrders(query));
    }
  };
  useEffect(() => {
    const statusFilter =
      orderStatusFilterValue === "Processing"
        ? appConstatnts["SHIPMENT_STATUS"]["PROCESSING"]
        : orderStatusFilterValue === "Confirmed"
        ? appConstatnts["SHIPMENT_STATUS"]["SHIPPED"]
        : orderStatusFilterValue === "In Transist"
        ? appConstatnts["SHIPMENT_STATUS"]["SHIPPED"]
        : orderStatusFilterValue === "Delivered"
        ? appConstatnts["SHIPMENT_STATUS"]["DELIVERED"]
        : "";
    const query = `?keyword=${orderSearchInput}&orderStatusFilter=${statusFilter}`;
    dispatch(getOrders(query));
  }, [orderSearchInput]);
  useEffect(() => {
    dispatch(getOrders());
  }, []);
  return (
    <div className="order_list">
      <div className="element_hug font_lato">
        <div className="element_container top_bottom_margin">
          <div className="d_flex align_items_center gap_2 f_14 f_weight_2">
            <div
              className="d_flex align_items_center justify_content_center ic_arrow_back"
              onClick={() => navigate(-1)}
            >
              <BsArrowLeft size={18} />
            </div>
            <div className="opacity_0_6">Back</div>
          </div>
          <div
            className={`align_items_center justify_content_center gap_2 flex_direction_col ${
              ordersCount > 0 ? "d_block" : "d_flex"
            }`}
          >
            <div
              className="mt_2 pb_2 f_size_3 f_weight_2 res-max-768px-pb_0 "
              style={{
                borderBottom: "1px solid #ececec",
                height: "40px",
              }}
            >
              My Orders
            </div>
            <div className="order_list_container">
              {ordersCount > 0 ? (
                <div className="order_list_content">
                  <div className="side_options">
                    <Input
                      placeholder={"Search by Order Id"}
                      mode={"search"}
                      className={"font_lato f_14"}
                      containerStyle={{
                        width: "-webkit-fill-available",
                      }}
                      style={{
                        width: "-webkit-fill-available",
                        height: "16px",
                        borderRadius: "3px",
                        marginBottom: "15px",
                      }}
                      onChange={(e) => setOrderSearchInput(e.target.value)}
                    />
                    <div className="option_values">
                      <div className="option_flex">
                        <div
                          className={`option ${
                            orderStatusFilterValue === "All Orders" &&
                            "selected"
                          }`}
                          onClick={() =>
                            handleOrderStatusFilterValue("All Orders")
                          }
                        >
                          All Orders
                        </div>
                        <div
                          className={`option ${
                            orderStatusFilterValue === "Processing" &&
                            "selected"
                          }`}
                          onClick={() =>
                            handleOrderStatusFilterValue("Processing")
                          }
                        >
                          Processing
                        </div>
                        <div
                          className={`option ${
                            orderStatusFilterValue === "Confirmed" && "selected"
                          }`}
                          onClick={() =>
                            handleOrderStatusFilterValue("Confirmed")
                          }
                        >
                          Confirmed
                        </div>
                      </div>
                      <div className="option_flex">
                        <div
                          className={`option ${
                            orderStatusFilterValue === "In Transist" &&
                            "selected"
                          }`}
                          onClick={() =>
                            handleOrderStatusFilterValue("In Transist")
                          }
                        >
                          In Transist
                        </div>
                        <div
                          className={`option ${
                            orderStatusFilterValue === "Delivered" && "selected"
                          }`}
                          onClick={() =>
                            handleOrderStatusFilterValue("Delivered")
                          }
                        >
                          Delivered
                        </div>
                        <div
                          className={`option ${
                            orderStatusFilterValue === "Cancelled" && "selected"
                          }`}
                          onClick={() =>
                            handleOrderStatusFilterValue("Cancelled")
                          }
                        >
                          Cancelled
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="list_container_data">
                    {orders?.length > 0 ? (
                      orders?.map((data) => {
                        const foundStatusColors = order_status_values.find(
                          (statusData) =>
                            statusData?.status ===
                            data?.shippingAddress?.shipmentStatus
                        );
                        const columns = [
                          {
                            name: "Product",
                            cell: (row) => {
                              return (
                                <div
                                  className="d_flex align_items_center gap_3"
                                  onClick={() =>
                                    navigate(
                                      `${PRODUCT_OVERVIEW_PAGE}?product_id=${row.productId}`
                                    )
                                  }
                                >
                                  <img
                                    src={row?.productImages[0]?.url}
                                    alt={row?.productId}
                                    height={50}
                                    width={50}
                                    style={{
                                      backgroundColor:
                                        "var(--cmac-default-shade-fill)",
                                      padding: "5px",
                                      objectFit: "contain",
                                      borderRadius: "8px",
                                    }}
                                  />
                                  <div className="f_12 f_weight_1">
                                    <div className="multi_line_2">
                                      {row?.productName}
                                    </div>
                                    <div
                                      className="multi_line_2 f_10 opacity_0_8"
                                      style={{
                                        marginTop: "2px",
                                      }}
                                    >
                                      {row?.productCategory}
                                    </div>
                                  </div>
                                </div>
                              );
                            },
                            minWidth: "350px",
                          },
                          {
                            name: "Size",
                            selector: (row) => (
                              <div className="f_12 f_weight_1">
                                {row?.selectedSize}
                              </div>
                            ),
                          },
                          {
                            name: "Price",
                            selector: (row) => (
                              <div
                                onClick={() =>
                                  navigate(
                                    `${PRODUCT_OVERVIEW_PAGE}?product_id=${row.productId}`
                                  )
                                }
                                className="f_12 f_weight_1"
                              >
                                {currencyFormatter(row?.onAddSalePrice, "INR")}{" "}
                                INR
                              </div>
                            ),
                          },
                          {
                            name: "Qty",
                            selector: (row) => (
                              <div className="f_12 f_weight_1">
                                {row?.quantity} Item(s)
                              </div>
                            ),
                          },
                        ];
                        return (
                          <div className="data_container">
                            <div className="d_flex align_items_center justify_content_space_between">
                              <div>
                                <div className="d_flex align_items_center gap_2">
                                  <div className="f_weight_1 f_14">
                                    Order Id: <span>#{data?._id}</span>
                                  </div>
                                  <div
                                    className="text_transform_capitalize f_10 f_weight_1 d_flex align_items_center justify_content_center"
                                    style={{
                                      height: "20px",
                                      paddingLeft: "10px",
                                      paddingRight: "10px",
                                      background: foundStatusColors?.bg,
                                      color: foundStatusColors?.color,
                                      borderRadius: "50px",
                                    }}
                                  >
                                    {data?.shippingAddress?.shipmentStatus}
                                  </div>
                                </div>
                                <div className="d_flex align_items_center gap_2 res-max-490px-f-direction-c res-max-490px-align_items_unset res-max-490px-gap_unset">
                                  <div className="f_10 mt_1 d_flex align_items_center gap_1">
                                    <div className="f_weight_1 opacity_0_6">
                                      Placed On:
                                    </div>
                                    <div className="f_weight_1 f_12">
                                      {moment(data?.createdAt).format(
                                        "DD MMM YYYY"
                                      )}
                                    </div>
                                  </div>
                                  <div className="f_10 mt_1 d_flex align_items_center gap_1">
                                    <div className="f_weight_1 opacity_0_6">
                                      | Ariving On:
                                    </div>
                                    <div className="f_weight_1 f_12">
                                      {moment(data?.estimatedDeliveryOn).format(
                                        "DD MMM YYYY"
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div>
                                <Link
                                  to={`${ORDER_TRACKING_PAGE}?order_id=${data?._id}`}
                                  className="track_package_btn"
                                >
                                  <FaLocationArrow
                                    style={{
                                      position: "relative",
                                      top: "1px",
                                    }}
                                  />
                                  <div className="track_font">
                                    Track Package
                                  </div>
                                </Link>
                              </div>
                            </div>
                            <div>
                              <DataTable
                                columns={columns}
                                data={data?.productsInOrder}
                                onRowClicked={(e) =>
                                  navigate(
                                    `${PRODUCT_OVERVIEW_PAGE}?product_id=${e._id}`
                                  )
                                }
                                fixedHeader={true}
                                fixedHeaderScrollHeight="70vh"
                              />
                            </div>
                            <div className="mt_3 d_flex align_items_end flex_direction_col">
                              <div>
                                <div className="d_flex align_items_center gap_3">
                                  <div
                                    className="f_12 f_weight_1 opacity_0_6"
                                    style={{
                                      width: "85px",
                                    }}
                                  >
                                    Items Price:{" "}
                                  </div>
                                  <div className="f_weight_1 f_14">
                                    Rs.{" "}
                                    {currencyFormatter(
                                      data?.orderSummary?.totalPrice,
                                      "INR"
                                    )}
                                  </div>
                                </div>
                                <div
                                  className="d_flex align_items_center gap_3"
                                  style={{
                                    marginTop: "4px",
                                  }}
                                >
                                  <div
                                    className="f_12 f_weight_1 opacity_0_6"
                                    style={{
                                      width: "85px",
                                    }}
                                  >
                                    Gst:{" "}
                                  </div>
                                  <div className="f_weight_1 f_14">
                                    Rs.{" "}
                                    {currencyFormatter(
                                      data?.orderSummary?.gstPrice,
                                      "INR"
                                    )}
                                  </div>
                                </div>
                                <div
                                  className="d_flex align_items_center gap_3"
                                  style={{
                                    marginTop: "4px",
                                  }}
                                >
                                  <div
                                    className="f_12 f_weight_1 opacity_0_6"
                                    style={{
                                      width: "85px",
                                    }}
                                  >
                                    Total Price
                                  </div>
                                  <div className="f_weight_1 f_14">
                                    Rs.{" "}
                                    {currencyFormatter(
                                      data?.orderSummary?.totalAmountToPay,
                                      "INR"
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <div className="list_container">
                        <div className="d_flex align_items_center justify_content_center mt_4 flex_direction_col gap_5 f_weight_2 f_18">
                          <div>
                            <img
                              src={cart_boxes}
                              alt="cart_boxes"
                              height={180}
                            />
                          </div>
                          <div className="mt_2 text_align_center">
                            No Matching Order Id found for "{orderSearchInput}"
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              ) : (
                <div className="list_container">
                  <div className="d_flex align_items_center justify_content_center mt_4 flex_direction_col gap_5 f_weight_2 f_18">
                    <div>
                      <img src={cart_box} alt="cart_box" height={180} />
                    </div>
                    <div className="mt_2">No Orders Placed Yet!</div>
                    <div
                      className="opacity_0_4 text_align_center f_14"
                      style={{
                        width: "500px",
                      }}
                    >
                      Make your first purchase now and transform your beauty
                      routine. Discover products that deliver real, lasting
                      results!
                    </div>
                    <button
                      className="d_flex align_items_center justify_content_center gap_2 new_address_btn cursor_pointer"
                      onClick={() => {
                        navigate(SHOP_PAGE);
                      }}
                      style={{
                        scale: "0.9",
                        background: "var(--cmac-dark-fill)",
                        color: "var(--cmac-default-fill)",
                      }}
                    >
                      Continue Shopping
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Orders;
