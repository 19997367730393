import React from "react";
import brand_src from "../../../../assets/imgs/brand/brand.png";
import { LANDING_PAGE } from "../../../../helpers/paths";
const CheckoutHeader = () => {
  return (
    <div className="checkout_header font_lato">
      <div
        className="d_flex"
        style={{
          height: "100%",
        }}
      >
        <div className="element_hug end">
          <div className="element_container">
            <a
              href={LANDING_PAGE}
              className="d_flex align_items_center cursor_pointer _brand_container"
              style={{
                width: "fit-content",
                textDecoration: "none",
                color: "var(--cmac-primary-color)",
                fontWeight: 600,
                display:'flex', 
                alignItems: 'center', gap: '13px'
              }}
            >
              {/* <img
                src={brand_src}
                alt="brand_src"
                height={"60px"}
                width={"60px"}
                style={{
                  scale: '0.9'
                }}
              /> */}
              <div>REVIVE ROOTS</div>
              <div>-</div>
              <div>CHECKOUT</div>
              {/* LOGO */}
            </a>
          </div>
        </div>
        <div className="element_hug start">
          <div className="element_container"></div>
        </div>
      </div>
    </div>
  );
};

export default CheckoutHeader;
