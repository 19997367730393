import axios from "axios";
import { BASE_URL } from "../../api/apiPath";
import { endPoints } from "../../api/endPoints";
import {
  productsFail,
  productsRequest,
  productsSuccess,
} from "../slices/productsSlice";
import { getVerificationToken } from "../../helpers/getVerificationToken";
import {
  quickBuyProductsFail,
  quickBuyProductsRequest,
  quickBuyProductsSuccess,
} from "../slices/quickBuyProductsSlice";
import { performCrypto } from "../../helpers/performCrypto";
import {
  categoryProductsFail,
  categoryProductsRequest,
  categoryProductsSuccess,
} from "../slices/categoryProductsSlice";
import {
  recentProductsFail,
  recentProductsRequest,
  recentProductsSuccess,
} from "../slices/recentProductsSlice";
import {
  similarProductsFail,
  similarProductsRequest,
  similarProductsSuccess,
} from "../slices/similarProductsSlice";
import {
  popularProductsFail,
  popularProductsRequest,
  popularProductsSuccess,
} from "../slices/popularProductsSlice";
import {
  recommendedProductsFail,
  recommendedProductsRequest,
  recommendedProductsSuccess,
} from "../slices/recommendedProductsSlice";

export const getProducts = (query) => async (dispatch) => {
  try {
    dispatch(productsRequest());
    const response = await axios.get(
      `${BASE_URL}/${endPoints.products.get}${query && `?${query}`}`,
      {
        withCredentials: true,
        headers: {
          "x-verification-token": getVerificationToken().X_VERIFY_TOKEN,
        },
      }
    );
    const decryptedData = performCrypto().decode(response.data.encryptedData);
    
    dispatch(productsSuccess(decryptedData));
  } catch (error) {
    const decryptedData = performCrypto().decode(
      error?.response?.data?.encryptedData
    );
    dispatch(productsFail(decryptedData?.error));
  }
};
export const getCategoryProducts = () => async (dispatch) => {
  try {
    dispatch(categoryProductsRequest());
    const response = await axios.get(
      `${BASE_URL}/${endPoints.category_products.get}`,
      {
        withCredentials: true,
        headers: {
          "x-verification-token": getVerificationToken().X_VERIFY_TOKEN,
        },
      }
    );
    const decryptedData = performCrypto().decode(response.data.encryptedData);
    dispatch(categoryProductsSuccess(decryptedData));
  } catch (error) {

    const decryptedData = performCrypto().decode(
      error?.response?.data?.encryptedData
    );
    dispatch(categoryProductsFail(decryptedData?.error));
  }
};
export const getRecentProducts = (payload) => async (dispatch) => {
  try {
    dispatch(recentProductsRequest());
    const response = await axios.get(
      `${BASE_URL}/${endPoints.recent_products.get}?${payload}`,
      {
        withCredentials: true,
        headers: {
          "x-verification-token": getVerificationToken().X_VERIFY_TOKEN,
        },
      }
    );
    const decryptedData = performCrypto().decode(response.data.encryptedData);
    dispatch(recentProductsSuccess(decryptedData));
  } catch (error) {
    const decryptedData = performCrypto().decode(
      error?.response?.data?.encryptedData
    );
    dispatch(recentProductsFail(decryptedData?.error));
  }
};
export const getSimilarProducts = (payload) => async (dispatch) => {
  try {
    dispatch(similarProductsRequest());
    const response = await axios.get(
      `${BASE_URL}/${endPoints.similar_products.get}?${payload}`,
      {
        withCredentials: true,
        headers: {
          "x-verification-token": getVerificationToken().X_VERIFY_TOKEN,
        },
      }
    );
    const decryptedData = performCrypto().decode(response.data.encryptedData);
    dispatch(similarProductsSuccess(decryptedData));
  } catch (error) {
    const decryptedData = performCrypto().decode(
      error?.response?.data?.encryptedData
    );
    dispatch(similarProductsFail(decryptedData?.error));
  }
};
export const getPopularProducts = () => async (dispatch) => {
  try {
    dispatch(popularProductsRequest());
    const response = await axios.get(
      `${BASE_URL}/${endPoints.popular_products.get}`,
      {
        withCredentials: true,
        headers: {
          "x-verification-token": getVerificationToken().X_VERIFY_TOKEN,
        },
      }
    );
    const decryptedData = performCrypto().decode(response.data.encryptedData);
    dispatch(popularProductsSuccess(decryptedData));
  } catch (error) {
    const decryptedData = performCrypto().decode(
      error?.response?.data?.encryptedData
    );
    dispatch(popularProductsFail(decryptedData?.error));
  }
};
export const getRecommendedProducts = () => async (dispatch) => {
  try {
    dispatch(recommendedProductsRequest());
    const response = await axios.get(
      `${BASE_URL}/${endPoints.recommended_products.get}`,
      {
        withCredentials: true,
        headers: {
          "x-verification-token": getVerificationToken().X_VERIFY_TOKEN,
        },
      }
    );
    const decryptedData = performCrypto().decode(response.data.encryptedData);
    dispatch(recommendedProductsSuccess(decryptedData));
  } catch (error) {
    const decryptedData = performCrypto().decode(
      error?.response?.data?.encryptedData
    );
    console.error("[ERROR]: ", decryptedData?.error?.message);
    dispatch(recommendedProductsFail(decryptedData?.error));
  }
};

export const getQuickBuyProducts = (query) => async (dispatch) => {
  try {
    dispatch(quickBuyProductsRequest());
    const response = await axios.get(
      `${BASE_URL}/${endPoints.products.get}${query && `?${query}`}`,
      {
        withCredentials: true,
        headers: {
          "x-verification-token": getVerificationToken().X_VERIFY_TOKEN,
        },
      }
    );
    const decryptedData = performCrypto().decode(response.data.encryptedData);
    dispatch(quickBuyProductsSuccess(decryptedData));
  } catch (error) {
    const decryptedData = performCrypto().decode(
      error?.response?.data?.encryptedData
    );
    dispatch(quickBuyProductsFail(decryptedData?.error));
  }
};
