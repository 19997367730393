import axios from "axios";
import { BASE_URL } from "../../api/apiPath";
import { endPoints } from "../../api/endPoints";
import {
  addCartFail,
  addCartRequest,
  addCartSuccess,
  cartFail,
  cartRequest,
  cartSuccess,
  clearCartActionStatus,
  quickBuyAddCartFail,
  quickBuyAddCartRequest,
  quickBuyAddCartSuccess,
} from "../slices/cartSlice";
import { getVerificationToken } from "../../helpers/getVerificationToken";
import { getOrderDetails } from "./orderAction";
import { performCrypto } from "../../helpers/performCrypto";

export const getCart = (reqFrom) => async (dispatch) => {
  try {
    dispatch(cartRequest({ reqFrom }));
    const response = await axios.get(`${BASE_URL}/${endPoints.cart.get}`, {
      withCredentials: true,
      headers: {
        Authorization: getVerificationToken().authToken,
        "x-verification-token": getVerificationToken().X_VERIFY_TOKEN,
      },
    });
    const decryptedData = performCrypto().decode(response.data.encryptedData);
    dispatch(cartSuccess(decryptedData));
    dispatch(clearCartActionStatus());
  } catch (error) {
    const decryptedData = performCrypto().decode(
      error?.response?.data?.encryptedData
    );
    dispatch(cartFail(decryptedData?.error));
  }
};
export const getCartTemp = (reqFrom, temp_cart_id) => async (dispatch) => {
  try {
    dispatch(cartRequest({ reqFrom }));
    const response = await axios.get(
      `${BASE_URL}/${endPoints.cart.get_temp}?temp_cart_id=${temp_cart_id}`,
      {
        withCredentials: true,
        headers: {
          Authorization: getVerificationToken().authToken,
          "x-verification-token": getVerificationToken().X_VERIFY_TOKEN,
        },
      }
    );
    const decryptedData = performCrypto().decode(response.data.encryptedData);
    dispatch(cartSuccess(decryptedData));
    dispatch(clearCartActionStatus());
  } catch (error) {
    const decryptedData = performCrypto().decode(
      error?.response?.data?.encryptedData
    );
    dispatch(cartFail(decryptedData?.error));
  }
};
export const updateCart =
  (reqFrom = "/", temp_cart_id) =>
  async (dispatch) => {
    try {
      dispatch(cartRequest({ reqFrom }));
      const response = await axios.get(
        `${BASE_URL}/${endPoints.cart.update}?temp_cart_id=${temp_cart_id}`,
        {
          withCredentials: true,
          headers: {
            Authorization: getVerificationToken().authToken,
            "x-verification-token": getVerificationToken().X_VERIFY_TOKEN,
          },
        }
      );
      const decryptedData = performCrypto().decode(response.data.encryptedData);
      dispatch(cartSuccess(decryptedData));
      localStorage.removeItem("temp_cart_id");
      dispatch(clearCartActionStatus());
    } catch (error) {
      const decryptedData = performCrypto().decode(
        error?.response?.data?.encryptedData
      );
      localStorage.removeItem("temp_cart_id");
      dispatch(cartFail(decryptedData?.error));
    }
  };
export const getCartCheckoutDetails = (reqFrom) => async (dispatch) => {
  try {
    dispatch(cartRequest({ reqFrom }));
    const response = await axios.get(`${BASE_URL}/${endPoints.cart.get}`, {
      withCredentials: true,
      headers: {
        Authorization: getVerificationToken().authToken,
        "x-verification-token": getVerificationToken().X_VERIFY_TOKEN,
      },
    });
    const decryptedData = performCrypto().decode(response.data.encryptedData);
    dispatch(cartSuccess(decryptedData));
    dispatch(getOrderDetails());
  } catch (error) {
    const decryptedData = performCrypto().decode(
      error?.response?.data?.encryptedData
    );
    dispatch(cartFail(decryptedData?.error));
  }
};
export const addCart = (payload, allowToast, reqFrom) => async (dispatch) => {
  try {
    dispatch(addCartRequest({ reqFrom }));
    const response = await axios.post(
      `${BASE_URL}/${endPoints.cart.add}`,
      payload,
      {
        withCredentials: true,
        headers: {
          Authorization: getVerificationToken().authToken,
          "x-verification-token": getVerificationToken().X_VERIFY_TOKEN,
        },
      }
    );
    const decryptedData = performCrypto().decode(response.data.encryptedData);
    dispatch(addCartSuccess({ ...decryptedData, allowToast }));
  } catch (error) {
    const decryptedData = performCrypto().decode(
      error?.response?.data?.encryptedData
    );
    dispatch(
      addCartFail({
        message: decryptedData?.error?.message,
        allowToast,
      })
    );
  }
};
export const addTempCart =
  (payload, allowToast, reqFrom) => async (dispatch) => {
    try {
      dispatch(addCartRequest({ reqFrom }));
      const response = await axios.post(
        `${BASE_URL}/${endPoints.cart.add_temp}`,
        payload,
        {
          withCredentials: true,
          headers: {
            "x-verification-token": getVerificationToken().X_VERIFY_TOKEN,
          },
        }
      );
      const decryptedData = performCrypto().decode(response.data.encryptedData);
      dispatch(addCartSuccess({ ...decryptedData, allowToast }));
    } catch (error) {
      const decryptedData = performCrypto().decode(
        error?.response?.data?.encryptedData
      );
      dispatch(
        addCartFail({
          message: decryptedData?.error?.message,
          allowToast,
        })
      );
    }
  };
export const quickBuyAddCart = (payload, reqFrom) => async (dispatch) => {
  try {
    dispatch(quickBuyAddCartRequest({ reqFrom }));
    await Promise.all(
      payload.map((data) =>
        axios.post(`${BASE_URL}/${endPoints.cart.add}`, data, {
          withCredentials: true,
          headers: {
            Authorization: getVerificationToken().authToken,
            "x-verification-token": getVerificationToken().X_VERIFY_TOKEN,
          },
        })
      )
    );
    dispatch(quickBuyAddCartSuccess());
    setTimeout(() => {
      dispatch(getCart("quickBuy"));
    }, 10);
  } catch (error) {
    dispatch(quickBuyAddCartFail(error));
  }
};
