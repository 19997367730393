import React, { useEffect } from "react";
import "../cmac.plugins.epsilon.styles.css";

const CmacContainer = ({
  portalUsage,
  children,
  onClickOutsider,
  active,
}) => {
  const getDocumentDimensions = () => {
    const doc = document.documentElement;
    doc.style.setProperty("--doc-height", `${window.innerHeight}px`);
    doc.style.setProperty("--doc-width", `${window.innerWidth}px`);
  };

  const updateRootClass = () => {
    const overlayElement = document.querySelector(".hYnPqT");
    let body = document.querySelector("body");
    let root = document.querySelector("#root");
    if (overlayElement) {
      root?.classList.add("iKjQnR");
      body?.classList.add("ipjQnR");
    } else {
      root?.classList.remove("iKjQnR");
      body?.classList.remove("ipjQnR");
    }
  };

  window.addEventListener("resize", getDocumentDimensions);
  getDocumentDimensions();
  useEffect(() => {
    updateRootClass();
  }, [portalUsage, active]);
  return (
    <div className={`jPlMrW ${portalUsage && active ? "hYnPqT" : "__"}`} onClick={onClickOutsider}>{children}</div>
  );
};

export default CmacContainer;
