import { useEffect, useState } from "react";
import CheckoutDetails from "./layouts/CheckoutDetails";
import CheckoutHeader from "./layouts/CheckoutHeader";
import CheckoutInputs from "./layouts/CheckoutInputs";
import { useDispatch } from "react-redux";
import CmacSideDragger from "../../plugins/cmac-plugins/cmac-side-dragger/CmacSideDragger";
import { getCartCheckoutDetails } from "../../../redux/actions/cartAction";
import { getAddresses } from "../../../redux/actions/userAction";
import { IoClose } from "react-icons/io5";
import { getQueryParam } from "../../../helpers/getQueryParams";

const CheckoutPage = () => {
  const [resCheckoutDetailsModal, setResCheckoutDetailsModal] = useState(false);
  const dispatch = useDispatch();
  const encriptedData = getQueryParam("encripted_data");
  const handleCheckoutDetailsModal = () => {
    setResCheckoutDetailsModal(true);
  };
  useEffect(() => {
    if (!encriptedData) {
      dispatch(getAddresses());
      dispatch(getCartCheckoutDetails("checkoutPage"));
    }
  }, []);
  return (
    <div className="checkout_page font_lato">
      <CheckoutHeader />
      <div className="d_flex">
        <CheckoutInputs
          handleCheckoutDetailsModal={handleCheckoutDetailsModal}
        />
        <div
          className="res-max-992px-d-none"
          style={{
            width: "50%",
          }}
        >
          <CheckoutDetails />
        </div>
      </div>
      <CmacSideDragger
        open={resCheckoutDetailsModal}
        dragPosition={"left"}
        onClose={() => setResCheckoutDetailsModal(false)}
        overlayBackground={"transparent"}
        style={{
          boxShadow: "#0000001d 0px 0px 50px 1px",
        }}
        className={"font_lato checkout_page"}
        background={"var(--cmac-default-shade-fill)"}
      >
        <div
          style={{
            padding: "20px",
            paddingLeft: "10px",
            paddingRight: "10px",
            paddingBottom: "0px",
          }}
        >
          <div
            style={{
              fontWeight: "600",
              fontSize: "16px",
              paddingBottom: "20px",
              borderBottom: "1px solid #00000030",
            }}
            className="d_flex align_items_center justify_content_space_between"
          >
            <div>Checkout Details</div>
            <IoClose
              size={22}
              onClick={() => setResCheckoutDetailsModal(false)}
            />
          </div>
        </div>
        <CheckoutDetails background={"var(--cmac-default-shade-fill)"} />
      </CmacSideDragger>
    </div>
  );
};

export default CheckoutPage;
