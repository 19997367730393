import { useEffect, useState } from "react";
import { generateUUID } from "../../../../helpers/generateUUID";
import { RxCross2 } from "react-icons/rx";
import { FaPlus } from "react-icons/fa";
import { FaArrowRightLong } from "react-icons/fa6";
import CustomTagger from "../../../plugins/CustomTagger";
import CustomDropdown from "../../../plugins/CustomDropdown";
import CustomDatePicker from "../../../plugins/CustomDatePicker";
import { FaCheck } from "react-icons/fa6";
import { MdModeEdit } from "react-icons/md";
import { CmacIcDone } from "../../../../assets/icons/CmacIcons";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
  createProduct,
  updateProduct,
} from "../../../../redux/actions/productAction";
import { getQueryParam } from "../../../../helpers/getQueryParams";

const CreateProduct = ({ openNode, setOpenNode, setSheetActive }) => {
  /* Nodes */
  const nodes = [
    {
      id: 1,
      returnData: () => productDetails(),
      label: "Product Details",
      errorHandler: (e) => handleProductDetailsError(e),
    },
    {
      id: 2,
      returnData: () => productConfigurations(),
      label: "Product Configurations",
      errorHandler: () => handleProductDetailsError(),
    },
    {
      id: 3,
      returnData: () => priceDetails(),
      label: "Price Details",
      errorHandler: () => handleProductDetailsError(),
    },
    {
      id: 4,
      returnData: () => reviewingProduct(),
      label: "Reviewing Products",
      errorHandler: () => handleProductDetailsError(),
    },
    {
      id: 5,
      returnData: () => productCreated(),
      label: "",
      linkHide: true,
      errorHandler: () => handleProductDetailsError(),
    },
  ];

  const { products, loading } = useSelector((state) => state.productsState);
  const { product } = useSelector((state) => state.productState);
  const dispatch = useDispatch();
  const isEdit = getQueryParam("edit");

  /* Node Configuration States */
  const [activeNodeId, setActiveNodeId] = useState(1);
  const [tuningNode, setTuningNode] = useState(false);
  const [endNode, setEndNode] = useState(false);

  /********************************************************************************* */

  /*
   *
   * Product Details States
   *
   *  */

  const [productName, setProductName] = useState("");
  const [productAlternateName, setProductAlternateName] = useState("");
  const [productImages, setProductImages] = useState([]);
  const [productDescription, setProductDescription] = useState("");
  const [productSubDescription, setProductSubDescription] = useState("");

  /**
   * Product Details Error States
   */

  const [productNameError, setProductNameError] = useState(false);
  const [productAlternateNameError, setProductAlternateNameError] =
    useState(false);
  const [productImagesError, setProductImagesError] = useState(false);
  const [productDescriptionError, setProductDescriptionError] = useState(false);
  const [productSubDescriptionError, setProductSubDescriptionError] =
    useState(false);

  /**
   * Product Details Other States
   */

  const [focusImage, setFocusImage] = useState(false);

  /********************************************************************************* */

  /*
   *
   * Product Configurations States
   *
   */

  const [productTags, setProductTags] = useState([]);
  const [availableSizes, setAvailableSizes] = useState([]);
  const [productCategory, setProductCategory] = useState("");
  const [productStatus, setProductStatus] = useState("");
  const [availableQuantity, setAvailableQuantity] = useState("");
  const [maximumQuantity, setMaximumQuantity] = useState("");
  const [minimumQuantity, setMinimumQuantity] = useState("");
  const [manufacturer, setManufacturer] = useState("");
  const [countryMade, setCountryMade] = useState("");

  /**
   * Product Configurations Other States
   */

  const productTypeOptions = [
    "Shampoo",
    "Hair Butter",
    "Hair Cream",
    "Skin Care Essentials",
    "Hair Serum",
    "Herbal Hair Oil",
    "Hair Pack",
    "Hair Mist",
    "Herbal Hair Dye",
  ];
  const productStatusOptions = ["Active", "InActive", "Pending"];

  /********************************************************************************* */

  /*
   *
   * Price Details States
   *
   */

  const [unitPrice, setUnitPrice] = useState("");
  const [profitPercentage, setProfitPercentage] = useState("");
  const [grossPrice, setGrossPrice] = useState("");
  const [salePrice, setSalePrice] = useState("");
  const [discountPercentage, setDiscountPercentage] = useState("");
  const [discountPrice, setDiscountPrice] = useState("");
  const [deliveryWithinLocal, setDeliveryWithinLocal] = useState("");
  const [deliveryWithinDistrict, setDeliveryWithinDistrict] = useState("");
  const [deliveryWithinState, setDeliveryWithinState] = useState("");
  const [deliveryInterState, setDeliveryInterState] = useState("");

  /**
   * Common States
   */

  const [emptyStateErrorMsg, setEmptyStateErrorMsg] = useState("");

  const [applyDiscount, setApplyDiscount] = useState(false);
  const [cashOnDeliveryAccepted, setCashOnDeliveryAccepted] = useState(false);
  const [additionalInfo, setAdditionalInfo] = useState(false);

  const [discountRange, setDiscountRange] = useState([]);

  /********************************************************************************* */

  /*
   *Error Handlers
   */

  /*
   * Product Details Error Handler
   */

  const handleProductDetailsError = (targetValue) => {
    let isValid = true;
    if (!productName) {
      setProductNameError(true);
      isValid = false;
    } else {
      setProductNameError(false);
    }
    if (!productAlternateName) {
      setProductAlternateNameError(true);
      isValid = false;
    } else {
      setProductAlternateNameError(false);
    }

    if (productImages?.length === 0) {
      setProductImagesError(true);
      isValid = false;
    } else {
      setProductImagesError(false);
    }
    if (!productDescription) {
      setProductDescriptionError(true);
      isValid = false;
    } else {
      setProductDescriptionError(false);
    }
    if (!productSubDescription) {
      setProductSubDescriptionError(true);
      isValid = false;
    } else {
      setProductSubDescriptionError(false);
    }
    if (!isValid) {
      setEmptyStateErrorMsg("Please Fill all the Inputs!");
    } else {
      setEmptyStateErrorMsg("");
    }
    return isValid;
  };

  /********************************************************************************* */

  const handleProductImage = (e) => {
    const newImages = Array?.from(e.target.files)?.map((image) => {
      return {
        id: generateUUID(),
        file: image,
        url: URL.createObjectURL(image),
      };
    });
    setProductImages([...productImages, ...newImages]);
    activeNode()?.errorHandler({
      productName: [...productImages, ...newImages],
      isOnChange: "productImages",
    });
  };
  const handleRemoveImage = (image_url) => {
    const updated_images = productImages?.filter(
      (image) => image?.url !== image_url
    );
    setProductImages(updated_images);
  };
  const handleProductTagChange = (e) => {
    setProductTags(e);
  };
  const handleAvailableSizesChange = (e) => {
    setAvailableSizes(e);
  };
  const handleProductCategory = (e) => {
    setProductCategory(e);
  };
  const handleProductStatus = (e) => {
    setProductStatus(e);
  };
  const handleDiscountDateRange = (e) => {
    setDiscountRange(e);
  };
  const handleNodes = () => {
    setOpenNode(true);
    const isValid = activeNode()?.errorHandler();
    if (activeNodeId === 4) {
      handleCreateProduct();
      setEndNode(true);
    }
    if (isValid && activeNodeId !== 5 && activeNodeId !== 4) {
      setTuningNode(true);
      setTimeout(() => {
        setActiveNodeId(activeNodeId + 1);
        setTuningNode(false);
      }, 100);
    }
  };
  const handlePreviousNode = () => {
    if (activeNodeId === 1) {
      setOpenNode(false);
    }
    if (activeNodeId > 1) {
      setOpenNode(true);
      setTuningNode(true);
      setTimeout(() => {
        setActiveNodeId(activeNodeId - 1);
        setTuningNode(false);
      }, 100);
    }
  };
  const handleNavigateNode = (data) => {
    setOpenNode(true);
    setTuningNode(true);
    setTimeout(() => {
      setActiveNodeId(data.id);
      setTuningNode(false);
    }, 100);
  };
  const activeNode = () => {
    return nodes.find((node) => node.id === activeNodeId);
  };
  const handleCloseSheet = () => {
    setOpenNode(false);
    setSheetActive(false);
    setProductName("");
    setProductAlternateName("");
    setActiveNodeId(1);
  };
  const handleCreateProduct = () => {
    const discountPayload = {
      discountPercentage,
      discountStartDate: moment(discountRange[0]?.startDate).format(
        "YYYY-MM-DD"
      ),
      discountEndDate: moment(discountRange[0]?.endDate).format("YYYY-MM-DD"),
    };

    const payload = {
      productName,
      alternateName: productAlternateName,
      description: productDescription,
      subDescription: productSubDescription,
      productImages,
      productTags,
      availableSizes,
      productCategory,
      ...(isEdit && { productStatus: productStatus?.toLowerCase() }),
      availableQuantity,
      minimumQuantity,
      maximumQuantity,
      isAdditionalInfo: additionalInfo,
      ...(additionalInfo && { manufacturer: manufacturer }),
      ...(additionalInfo && { countryOfMade: countryMade }),
      unitPrice,
      profitPercentage,
      isCashOnDeliveryAccepted: cashOnDeliveryAccepted,
      isDiscountedProduct: applyDiscount,
      ...(applyDiscount && { ...discountPayload }),
      deliveryWithinLocal,
      deliveryWithinDistrict,
      deliveryWithinState,
      deliveryInterState,
    };

    isEdit
      ? dispatch(updateProduct({ ...payload, productId: product?._id }))
      : dispatch(createProduct(payload));
  };

  /********************************************************************************* */

  /* JSX Elements for Nodes */

  /*
   *Product Details JSX
   */
  const productDetails = () => {
    return (
      <div className="product_details">
        <div className="heading">Product Details</div>
        <div
          className="error_msg"
          style={{
            color: "#f00",
            fontWeight: 600,
          }}
        >
          {emptyStateErrorMsg}
        </div>
        <div>
          <div className="label">Product Name</div>
          <input
            className={`${productNameError && "error_border"}`}
            placeholder="Enter Product Name"
            value={productName}
            onChange={(e) => {
              setProductName(e.target.value);
              if (!e.target.value) {
                setProductNameError(true);
              } else {
                setProductNameError(false);
              }
            }}
          />
        </div>
        <div>
          <div className="label">Product Alternate Name</div>
          <input
            className={`${productAlternateNameError && "error_border"}`}
            placeholder="Enter Product Alternate Name"
            value={productAlternateName}
            onChange={(e) => {
              setProductAlternateName(e.target.value);
              if (!e.target.value) {
                setProductAlternateNameError(true);
              } else {
                setProductAlternateNameError(false);
              }
            }}
          />
        </div>
        <div className="images-box">
          <div className="label">Images</div>
          <input
            className="d_none"
            id="product-imgs"
            type="file"
            onChange={(e) => handleProductImage(e)}
          />
          <div
            className={`images-container ${focusImage ? "focus" : "default"}`}
          >
            {productImages?.length !== 0 &&
              productImages?.map((image) => (
                <div className="image-content">
                  <div className="remove-image-container">
                    <div
                      className="remove-image"
                      onClick={() => handleRemoveImage(image?.url)}
                    >
                      <RxCross2 />
                    </div>
                  </div>
                  <div className="image">
                    <img
                      key={image?.id}
                      src={
                        image?.file instanceof Blob
                          ? URL.createObjectURL(image?.file)
                          : image?.url
                      }
                      alt={`Product Image ${image?.id}`}
                    />
                  </div>
                </div>
              ))}
          </div>
          <div
            className="upload-img-container"
            onClick={() => setFocusImage(true)}
          >
            <label className="upload-img" htmlFor="product-imgs">
              <FaPlus className="d_flex align_items_center justify_content_center" />
            </label>
          </div>
        </div>
        <div>
          <div className="label">Product Description</div>
          <textarea
            className={`${productDescriptionError && "error_border"}`}
            placeholder="Product Description"
            rows={6}
            maxLength={300}
            value={productDescription}
            onChange={(e) => {
              setProductDescription(e.target.value);
              if (!e.target.value) {
                setProductDescriptionError(true);
              } else {
                setProductDescriptionError(false);
              }
            }}
          />
        </div>
        <div>
          <div className="label">Product Sub Description</div>
          <textarea
            className={`${productSubDescriptionError && "error_border"}`}
            placeholder="Product Sub Description"
            rows={6}
            maxLength={300}
            value={productSubDescription}
            onChange={(e) => {
              setProductSubDescription(e.target.value);
              if (!e.target.value) {
                setProductSubDescriptionError(true);
              } else {
                setProductSubDescriptionError(false);
              }
            }}
          />
        </div>
      </div>
    );
  };
  const productConfigurations = () => {
    return (
      <div className="product_configurations">
        <div className="heading">Product Configurations</div>
        <div>
          <div className="label">Product Tags</div>
          <CustomTagger
            value={productTags}
            placeholder={"Product tags"}
            tags={productTags}
            onTagChange={handleProductTagChange}
          />
        </div>
        <div className="d_flex align_items_center gap_2">
          <div className="w_fill">
            <div className="label">Product Category</div>
            <CustomDropdown
              value={productCategory}
              placeholder="Select Product Category"
              onSelect={handleProductCategory}
              options={productTypeOptions}
              addOptionValue={true}
              disabled={false}
            />
          </div>
          <div className="w_fill">
            <div className="label">Available Quantity</div>
            <input
              placeholder="Enter Available Quantity"
              type="number"
              value={availableQuantity}
              onChange={(e) => {
                const value = e.target.value.replace(/e/g, "");
                if (Number(value) <= Number(maximumQuantity)) {
                  setMaximumQuantity(value);
                }
                setAvailableQuantity(value);
              }}
            />
          </div>
        </div>
        <div className="d_flex align_items_center gap_2">
          <div className="w_fill">
            <div className="label">Minimum Quantity</div>
            <input
              placeholder="Enter Minimum Quantity"
              type="number"
              value={minimumQuantity}
              onChange={(e) => {
                const value = e.target.value.replace(/e/g, "");
                setMinimumQuantity(value);
              }}
            />
          </div>
          <div className="w_fill">
            <div className="label">Maximum Quantity</div>
            <input
              placeholder="Enter Maximum Quantity"
              type="number"
              value={maximumQuantity}
              onChange={(e) => {
                const value = e.target.value.replace(/e/g, "");
                console.log("availableQuantity: ", Number(value));
                if (Number(value) >= Number(availableQuantity)) {
                  setMaximumQuantity(availableQuantity);
                } else {
                  setMaximumQuantity(value);
                }
              }}
            />
          </div>
        </div>
        <div className="d_flex gap_2">
          <div className="w_half">
            <div className="label">Available Sizes</div>
            <CustomTagger
              value={availableSizes}
              placeholder={"Available Sizes"}
              tags={availableSizes}
              onTagChange={handleAvailableSizesChange}
            />
          </div>
          {isEdit && (
            <div className="w_fill">
              <div className="label">Product Status</div>
              <CustomDropdown
                value={productStatus}
                placeholder="Select Product Status"
                onSelect={handleProductStatus}
                options={productStatusOptions}
                addOptionValue={true}
                disabled={false}
              />
            </div>
          )}
        </div>
        <div className="w_fill">
          <div className="d_flex align_items_center gap_3 mb_2">
            <input
              type="checkbox"
              checked={additionalInfo}
              onChange={() => setAdditionalInfo(!additionalInfo)}
              className="checkbox"
            />
            <div
              className="label"
              style={{
                marginTop: "15px",
              }}
            >
              Additional Info
            </div>
          </div>
          <div
            className={`additional_info_config ${
              additionalInfo ? "applied" : "default"
            }`}
          >
            <div className="d_flex align_items_center gap_2">
              <div className="w_fill">
                <div className="label">Manufacturer</div>
                <input
                  placeholder="Enter Manufacturer"
                  type="text"
                  value={manufacturer}
                  onChange={(e) => setManufacturer(e.target.value)}
                />
              </div>
              <div className="w_fill">
                <div className="label">Country Of Made</div>
                <input
                  placeholder="Enter Country Of Made"
                  type="text"
                  value={countryMade}
                  onChange={(e) => setCountryMade(e.target.value)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const priceDetails = () => {
    return (
      <div className="pricet_details">
        <div className="heading">Price Details</div>
        <div className="d_flex align_items_center gap_2">
          <div className="w_fill">
            <div className="label">Unit Price</div>
            <input
              placeholder="Enter Unit Price"
              type="number"
              value={unitPrice}
              onChange={(e) => {
                const value = e.target.value.replace(/e/g, "");
                setUnitPrice(value);
              }}
            />
          </div>
          <div className="w_fill">
            <div className="label">Profit Percentage</div>
            <input
              placeholder="Enter Profit Percentage"
              type="number"
              value={profitPercentage}
              onChange={(e) => {
                let enteredProfitPercentage = parseFloat(e.target.value);
                if (enteredProfitPercentage > 100) {
                  enteredProfitPercentage = 100;
                }
                setProfitPercentage(enteredProfitPercentage.toString());
              }}
            />
          </div>
        </div>
        <div className="d_flex align_items_center gap_2">
          <div className="w_fill">
            <div className="label">Gross Price</div>
            <input
              placeholder="Gross Price"
              value={grossPrice}
              type="number"
              disabled
            />
          </div>
          <div className="w_fill">
            <div className="label">Sale Price</div>
            <input
              placeholder="Sale Price"
              type="number"
              value={salePrice}
              disabled
            />
          </div>
        </div>
        <div className="d_flex align_items_center gap_2">
          <div className="w_fill">
            <div className="label">Delivery Within Local</div>
            <input
              placeholder="Delivery within local"
              type="number"
              value={deliveryWithinLocal}
              onChange={(e) => setDeliveryWithinLocal(e.target.value)}
            />
          </div>
          <div className="w_fill">
            <div className="label">Delivery Within District</div>
            <input
              placeholder="Delivery within district"
              type="number"
              value={deliveryWithinDistrict}
              onChange={(e) => setDeliveryWithinDistrict(e.target.value)}
            />
          </div>
        </div>
        <div className="d_flex align_items_center gap_2">
          <div className="w_fill">
            <div className="label">Delivery Within State</div>
            <input
              placeholder="Delivery within state"
              type="number"
              value={deliveryWithinState}
              onChange={(e) => setDeliveryWithinState(e.target.value)}
            />
          </div>
          <div className="w_fill">
            <div className="label">Delivery Inter State</div>
            <input
              placeholder="Delivery inter state"
              type="number"
              value={deliveryInterState}
              onChange={(e) => setDeliveryInterState(e.target.value)}
            />
          </div>
        </div>
        <div className="end_line"></div>
        <div className="w_fill">
          <div className="d_flex align_items_center gap_3 mb_2">
            <input
              type="checkbox"
              checked={applyDiscount}
              onChange={() => setApplyDiscount(!applyDiscount)}
              className="checkbox"
            />
            <div
              className="label"
              style={{
                marginTop: "15px",
              }}
            >
              Add Discount
            </div>
          </div>
          <div
            className={`discount_config ${
              applyDiscount ? "applied" : "default"
            }`}
          >
            <div className="w_fill">
              <div className="label">Discount Range</div>
              <CustomDatePicker
                value={discountRange}
                className="date-picker-input"
                onDateChange={handleDiscountDateRange}
              />
            </div>
            <div className="d_flex align_items_center gap_2">
              <div className="w_fill">
                <div className="label">Discount Percentage</div>
                <input
                  placeholder="Enter Discount Percentage"
                  type="number"
                  value={discountPercentage}
                  onChange={(e) => {
                    let enteredDiscountPercentage = parseFloat(e.target.value);
                    if (enteredDiscountPercentage > 100) {
                      enteredDiscountPercentage = 100;
                    }
                    setDiscountPercentage(enteredDiscountPercentage.toString());
                  }}
                />
              </div>
              <div className="w_fill">
                <div className="label">Discount Price</div>
                <input
                  placeholder="Discount Price"
                  value={discountPrice}
                  type="number"
                  disabled
                />
              </div>
            </div>
          </div>
        </div>
        <div className="d_flex align_items_center gap_3 mb_2">
          <input
            checked={cashOnDeliveryAccepted}
            onChange={() => setCashOnDeliveryAccepted(!cashOnDeliveryAccepted)}
            type="checkbox"
            className="checkbox"
          />
          <div
            className="label"
            style={{
              marginTop: "15px",
            }}
          >
            Cash On Delivery Accepted
          </div>
        </div>
      </div>
    );
  };
  const reviewingProduct = () => {
    return (
      <div className="review_product">
        <div className="heading">Review Product</div>
        <div className="d_flex align_items_center justify_content_center">
          <img
            src={productImages[0].url}
            alt="getty_img"
            height={"200px"}
            width={"250px"}
          />
        </div>
        <div className="title">{productName}</div>
        <div className="vr_line"></div>
        <div>
          <div
            className="d_flex align_items_center justify_content_space_between"
            style={{
              fontWeight: "600",
              fontSize: "14px",
            }}
          >
            <div>Product Details</div>
            <div
              className="d_flex align_items_center gap_1"
              style={{
                color: "#6b9eec",
              }}
            >
              <MdModeEdit /> Edit
            </div>
          </div>
          <div className="content">
            <div className="content_title">Product Name</div>
            <div className="detail">{productName}</div>
          </div>
          <div className="content">
            <div className="content_title">Product Description</div>
            <div className="detail">{productDescription}</div>
          </div>
          <div className="content">
            <div className="content_title">Product Category</div>
            <div className="detail">{productCategory}</div>
          </div>
        </div>
        <div className="vr_line"></div>
        <div>
          <div
            className="d_flex align_items_center justify_content_space_between"
            style={{
              fontWeight: "600",
              fontSize: "14px",
            }}
          >
            <div>Price Details</div>
            <div
              className="d_flex align_items_center gap_1"
              style={{
                color: "#6b9eec",
              }}
            >
              <MdModeEdit /> Edit
            </div>
          </div>
          <table>
            <tr>
              <th>Unit Price</th>
              <th>Gross Price</th>
              <th>Discount Price</th>
              <th>Sale Price</th>
            </tr>
            <tr>
              <td>{unitPrice}</td>
              <td>{grossPrice}</td>
              <td>{discountPrice}</td>
              <td>{salePrice}</td>
            </tr>
          </table>
        </div>
      </div>
    );
  };
  const productCreated = () => {
    return (
      <div
        className="product_created"
        style={{
          textAlign: "center",
        }}
      >
        <div>
          <CmacIcDone heightVal={200} widthVal={200} />
        </div>
        <div className="heading mt_4">
          Product {isEdit ? "Updated" : "Created"} Successfully
        </div>
        <div className="product_name">
          <span>Product </span>
          <span
            style={{
              fontWeight: 600,
            }}
          >
            {productName}
          </span>
          <span> has been {isEdit ? "updated" : "created"}.</span>
        </div>
        <div className="d_flex align_items_center justify_content_center w_fill">
          <button
            className="continue_btn"
            style={{
              width: "50%",
              background: "var(--cmac-management-font-color-2)",
              color: "var(--cmac-management-inner-color)",
            }}
            onClick={() => handleCloseSheet()}
          >
            Continue
          </button>
        </div>
      </div>
    );
  };
  useEffect(() => {
    if (endNode) {
      setTuningNode(true);
      setTimeout(() => {
        setActiveNodeId(activeNodeId + 1);
        setTuningNode(false);
      }, 100);
    }
  }, [products]);
  useEffect(() => {
    const profitMargin = Number(profitPercentage) / 100;
    const sellingPrice = Number(unitPrice) * Number(profitMargin);
    setSalePrice((Number(sellingPrice) + Number(unitPrice)).toString());
    setGrossPrice((Number(sellingPrice) + Number(unitPrice)).toString());
  }, [unitPrice]);
  useEffect(() => {
    const discountMargin = Number(discountPercentage) / 100;
    const discountPriceAmount = Number(grossPrice) * discountMargin;
    setDiscountPrice(discountPriceAmount.toString());
  }, [grossPrice]);
  useEffect(() => {
    const profitMargin = Number(profitPercentage) / 100;
    const grossPrice = Number(unitPrice) * Number(profitMargin);
    setSalePrice((Number(grossPrice) + Number(unitPrice)).toString());
    setGrossPrice((Number(grossPrice) + Number(unitPrice)).toString());
  }, [profitPercentage]);
  useEffect(() => {
    const discountMargin = Number(discountPercentage) / 100;
    const discountPriceAmount = Number(grossPrice) * Number(discountMargin);
    setDiscountPrice(discountPriceAmount.toString());
    setSalePrice((Number(grossPrice) - discountPriceAmount).toString());
  }, [discountPercentage]);
  useEffect(() => {
    if (isEdit) {
      setProductName(product?.productName);
      setProductAlternateName(product?.alternateName);
      setProductImages(product?.productImages);
      setProductDescription(product?.description);
      setProductSubDescription(product?.subDescription);

      setProductTags(product?.productTags?.length > 0 && product?.productTags);
      setAvailableSizes(
        product?.availableSizes?.length > 0 && product?.availableSizes
      );
      setProductCategory(product?.productCategory);
      setProductStatus(product?.productStatus);
      setAvailableQuantity(product?.availableQuantity);
      setMaximumQuantity(product?.maximumQuantity);
      setMinimumQuantity(product?.minimumQuantity);
      setAdditionalInfo(
        product?.isAdditionalInfo && JSON?.parse(product.isAdditionalInfo)
      );
      product?.isAdditionalInfo && setManufacturer(product?.manufacturer);
      product?.isAdditionalInfo && setCountryMade(product?.countryOfMade);

      setUnitPrice(Number(product?.unitPrice));
      setProfitPercentage(Number(product?.profitPercentage));
      setGrossPrice(Number(product?.grossPrice));
      setSalePrice(Number(product?.salePrice));
      setDiscountPercentage(Number(product?.discountPercentage));
      setDiscountPrice(Number(product?.discountPrice));
      setDeliveryWithinLocal(Number(product?.deliveryWithinLocal || 0));
      setDeliveryWithinDistrict(Number(product?.deliveryWithinDistrict));
      setDeliveryWithinState(Number(product?.deliveryWithinState));
      setDeliveryInterState(Number(product?.deliveryInterState));

      (product?.isDiscountedProduct === "true" ||
        product?.isDiscountedProduct === true) &&
        setApplyDiscount(product?.isDiscountedProduct);
      product?.isCashOnDeliveryAccepted &&
        setCashOnDeliveryAccepted(product?.isCashOnDeliveryAccepted);
      product?.discountPercentage &&
        setDiscountRange([
          {
            startDate: new Date(product?.discountStartDate),
            endDate: new Date(product?.discountEndDate),
          },
        ]);
    }
  }, [isEdit, product]);
  return (
    <div
      className={`create_product ${
        openNode ? "node_mode_active" : "node_mode_deactive"
      }`}
    >
      <div
        className={`${tuningNode ? "node_mode_deactive" : "node_mode_active"}`}
      >
        <div className="node">{activeNode()?.returnData()}</div>
        {activeNode()?.linkHide !== true && (
          <div
            className="d_flex align_items_center justify_content_flex_end gap_3"
            style={{
              width: "430px",
            }}
          >
            <button
              className="back_btn"
              onClick={() => {
                activeNodeId === 1 && isEdit
                  ? setSheetActive(false)
                  : handlePreviousNode();
              }}
            >
              {activeNodeId === 1 && isEdit ? "Cancel" : "Back"}
            </button>
            <button className="continue_btn" onClick={() => handleNodes()}>
              <div>
                {activeNodeId === 4 && isEdit ? "Save Changes" : "Continue"}
              </div>
              <FaArrowRightLong />
            </button>
          </div>
        )}
      </div>
      <div className="side_locators">
        {activeNode()?.linkHide !== true &&
          nodes.map((data) => {
            if (data?.linkHide) {
              return;
            }
            return (
              <div>
                <div
                  className="d_flex align_items_center gap_2 mt_1"
                  onClick={() => handleNavigateNode(data)}
                >
                  <div
                    className={`round_ic ${
                      data.id < activeNodeId ? "verified" : "default"
                    }`}
                  >
                    {data.id < activeNodeId && <FaCheck size={8} />}
                  </div>
                  <div
                    className={`${
                      data.id === activeNodeId ? "isActiveNode" : "default"
                    }`}
                  >
                    {data.label}
                    {data.id === activeNodeId && " •••"}
                  </div>
                </div>
                {data.id !== 4 && <div className="horzontal_line mt_1"></div>}
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default CreateProduct;
