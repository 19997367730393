import { performCrypto } from "./performCrypto";

const addRecentProducts = (productId) => {    
  const crypto = performCrypto();
  const localProductIds = crypto.get("recent_items").decryptedData || [];
  if (localProductIds?.length >= 10) {
    if (localProductIds?.some((itemId) => itemId === productId)) {
      const updateItems = localProductIds?.filter(
        (itemId) => itemId !== productId
      );
      const indexRemoved = updateItems?.filter((item, index) => index !== 10);
      crypto.set("recent_items", [productId, ...indexRemoved]);
    } else {
      const updateItems = localProductIds?.filter(
        (item, index) => index !== 10
      );
      crypto.set("recent_items", [productId, ...updateItems]);
    }
  } else if (localProductIds?.some((itemId) => itemId === productId)) {
    const updateItems = localProductIds?.filter(
      (itemId) => itemId !== productId
    );
    crypto.set("recent_items", [productId, ...updateItems]);
  } else {
    crypto.set("recent_items", [productId, ...localProductIds]);
  }
  return {
    productIds: [productId, ...localProductIds],
  };
};

export { addRecentProducts };
