import React from "react";
import { currencyFormatter } from "../../../../helpers/currencyFormatter";
import { useSelector } from "react-redux";
import Skeleton from "react-loading-skeleton";

const CheckoutDetails = ({ background = "var(--cmac-default-shade-fill)" }) => {
  const { order, loading } = useSelector((state) => state.orderState);
  const { cart, loading: cartLoading } = useSelector(
    (state) => state.cartState
  );

  const getSkeleton = () => {
    return Array.from({
      length: cartLoading ? 1 : cart?.productsIdsInCart?.length,
    }).map((data) => {
      return (
        <div className="item">
          <div className="flex_item">
            <Skeleton
              height={80}
              width={80}
              baseColor="#eaeaea"
              highlightColor="var(--cmac-default-fill)"
              duration={0.8}
              style={{
                borderRadius: "8px",
              }}
            />
            <div className="d_flex justify_content_center gap_1 flex_direction_col">
              <div>
                <Skeleton
                  height={20}
                  width={150}
                  baseColor="#eaeaea"
                  highlightColor="var(--cmac-default-fill)"
                  duration={0.8}
                />
              </div>
              <div className="d_flex align_items_center gap_2">
                <div>
                  <Skeleton
                    height={20}
                    width={90}
                    baseColor="#eaeaea"
                    highlightColor="var(--cmac-default-fill)"
                    duration={0.8}
                  />
                </div>
                <div>
                  <Skeleton
                    height={20}
                    width={30}
                    baseColor="#eaeaea"
                    highlightColor="var(--cmac-default-fill)"
                    duration={0.8}
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            className="d_flex align_items_center mt_1 price"
            style={{
              color: "var(--cmac-primary-color)",
            }}
          >
            <Skeleton
              height={20}
              width={80}
              baseColor="#eaeaea"
              highlightColor="var(--cmac-default-fill)"
              duration={0.8}
            />
          </div>
        </div>
      );
    });
  };

  return (
    <div
      className="checkout_details font_lato"
      style={{
        background: background,
      }}
    >
      <div className="element_hug start">
        <div className="element_container">
          <div>
            <div className="item_container">
              {!order?.productsInOrder
                ? getSkeleton()
                : order?.productsInOrder?.map((data) => {
                    return (
                      <div className="item res-max-576px-f-direction-c">
                        <div className="flex_item">
                          <div className="img_container">
                            <div style={{ position: "absolute" }}>
                              <div
                                style={{
                                  height: "23px",
                                  width: "23px",
                                  background: "var(--cmac-primary-color)",
                                  position: "relative",
                                  left: "67px",
                                  bottom: "7px",
                                  borderRadius: "50%",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  color: "#fff",
                                  fontSize: "12px",
                                }}
                              >
                                {data?.quantity}
                              </div>
                            </div>
                            <img
                              src={data?.productImages[0]?.url}
                              alt={data._id}
                            />
                          </div>
                          <div className="d_flex justify_content_center gap_1 flex_direction_col">
                            <div
                              style={{
                                fontSize: "12px",
                                fontWeight: "600",
                                display: "-webkit-box",
                                "-webkit-line-clamp": "2",
                                "-webkit-box-orient": "vertical",
                                overflow: "hidden",
                              }}
                            >
                              {data?.productName}
                            </div>
                            <div
                              className="d_flex align_items_center gap_2"
                              style={{
                                fontSize: "12px",
                              }}
                            >
                              <div>{data?.productCategory}</div>
                              <div>|</div>
                              <div>{data?.selectedSize}</div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="d_flex align_items_center mt_1 price res-max-576px-justify_content_f-e"
                        >
                          <div
                            style={{
                              fontSize: "14px",
                              fontWeight: "600",
                              color: "var(--cmac-dark-color)"
                            }}
                            className="text_wrap_nowrap"
                          >
                            Rs. {currencyFormatter(
                              Number(data?.currentSalePriceWithoutGST),
                              "INR"
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  })}
            </div>
            <div className="d_flex align_items_center justify_content_space_between mt_1">
              <div>
                {!order?.productsInOrder ? (
                  <Skeleton
                    height={20}
                    width={100}
                    baseColor="#eaeaea"
                    highlightColor="var(--cmac-default-fill)"
                    duration={0.8}
                  />
                ) : (
                  "Sub Total"
                )}
              </div>
              <div
                className="d_flex align_items_center mt_1 price"
              >
                {!order?.productsInOrder ? (
                  <Skeleton
                    height={20}
                    width={80}
                    baseColor="#eaeaea"
                    highlightColor="var(--cmac-default-fill)"
                    duration={0.8}
                  />
                ) : (
                  <div
                    style={{
                      fontSize: "14px",
                      fontWeight: "600",
                    }}
                  >
                   Rs. {currencyFormatter(
                      Number(order?.orderSummary?.totalPrice),
                      "INR"
                    )}
                  </div>
                )}
              </div>
            </div>
            <div className="d_flex align_items_center justify_content_space_between mt_1">
              <div>
                {!order?.productsInOrder ? (
                  <Skeleton
                    height={20}
                    width={70}
                    baseColor="#eaeaea"
                    highlightColor="var(--cmac-default-fill)"
                    duration={0.8}
                  />
                ) : (
                  "Shipping"
                )}
              </div>
              <div
                className="d_flex align_items_center mt_1 price"
              >
                {!order?.productsInOrder ? (
                  <Skeleton
                    height={20}
                    width={80}
                    baseColor="#eaeaea"
                    highlightColor="var(--cmac-default-fill)"
                    duration={0.8}
                  />
                ) : (
                  <div
                    style={{
                      fontSize: "14px",
                      fontWeight: "600",
                    }}
                  >
                    Rs. {currencyFormatter(
                      Number(order?.orderSummary?.shippingCharge),
                      "INR"
                    )}
                  </div>
                )}
              </div>
            </div>
            <div className="d_flex align_items_center justify_content_space_between mt_1">
              <div>
                {!order?.productsInOrder ? (
                  <Skeleton
                    height={20}
                    width={40}
                    baseColor="#eaeaea"
                    highlightColor="var(--cmac-default-fill)"
                    duration={0.8}
                  />
                ) : (
                  "GST"
                )}
              </div>
              <div
                className="d_flex align_items_center mt_1 price"
              >
                {!order?.productsInOrder ? (
                  <Skeleton
                    height={20}
                    width={80}
                    baseColor="#eaeaea"
                    highlightColor="var(--cmac-default-fill)"
                    duration={0.8}
                  />
                ) : (
                  <div
                    style={{
                      fontSize: "14px",
                      fontWeight: "600",
                    }}
                  >
                    Rs. {currencyFormatter(
                      Number(order?.orderSummary?.gstPrice),
                      "INR"
                    )}
                  </div>
                )}
              </div>
            </div>
            <div className="d_flex align_items_center justify_content_space_between mt_1">
              <div
                style={{
                  fontSize: "18px",
                  fontWeight: "600",
                }}
              >
                {!order?.productsInOrder ? (
                  <Skeleton
                    height={25}
                    width={60}
                    baseColor="#eaeaea"
                    highlightColor="var(--cmac-default-fill)"
                    duration={0.8}
                  />
                ) : (
                  "Total"
                )}
              </div>
              <div
                className="d_flex align_items_center mt_1 price"
              >
                {!order?.productsInOrder ? (
                  <Skeleton
                    height={25}
                    width={80}
                    baseColor="#eaeaea"
                    highlightColor="var(--cmac-default-fill)"
                    duration={0.8}
                  />
                ) : (
                  <div
                    style={{
                      fontSize: "18px",
                      fontWeight: "600",
                    }}
                  >
                    Rs. {currencyFormatter(
                      Number(order?.orderSummary?.totalAmountToPay),
                      "INR"
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CheckoutDetails;
