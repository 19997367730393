import React from "react";

const EmptyDataState = ({
  message,
  background = "var(--cmac-management-outer-color)",
  color = "var(--cmac-management-font-color-1)",
}) => {
  return (
    <div
      style={{
        background,
        color,
        width: "-webkit-fill-available",
      }}
      className="d_flex align_items_center justify_content_center p_4"
    >
      <div>{message}</div>
    </div>
  );
};

export default EmptyDataState;
