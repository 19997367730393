import React from "react";
import cmac_img_id_04 from "../../../../assets/imgs/landing-page/cmac_img_id_04.png";
import cmac_img_id_05 from "../../../../assets/imgs/landing-page/cmac_img_id_05.png";
import cmac_img_id_18 from "../../../../assets/imgs/landing-page/cmac_img_id_18.jpg";
import { Button } from "../../../utils/Elements";
import { useNavigate } from "react-router-dom";
import { SHOP_PAGE } from "../../../../helpers/paths";

const Topbanner = () => {
  const navigate = useNavigate();
  return (
    <div className="topbaner mb_2">
      <div className="element_hug">
        <div className="element_container ">
          <div className="d_flex align_items_center content gap_4 cmac_primary_color_10_bg res-max-576px-f-direction-c res-max-576px-w_fill">
            <div
              className="d_flex justify_content_center flex_direction_col gap_5 p_4 res-max-576px-d-none"
              style={{
                width: "50%",
              }}
            >
              <div className="f_size_5 f_weight_2">
                Discover the Beauty of Nature: Organic Cosmetic Essentials
              </div>
              <div>
                Elevate your self-care with our premium range of organic
                cosmetics, crafted from nature's finest ingredients
              </div>
              <div>
                <Button
                  className={"font_lato"}
                  style={{
                    height: "50px",
                    width: "150px",
                    background: "var(--cmac-primary-color)",
                  }}
                  onClick={() => {
                    navigate(SHOP_PAGE);
                  }}
                >
                  Explore
                </Button>
              </div>
            </div>
            <div
              className="d_flex align_items_center justify_content_center res-max-576px-w_fill"
              style={{
                width: "50%",
              }}
            >
              <img
                src={cmac_img_id_18}
                alt="cmac_img_id_18"
                height={"100%"}
                width={"100%"}
              />
            </div>
          </div>
          {/* <div className="d_flex gap_3">
            <img
              src={cmac_img_id_05}
              alt="cmac_img_id_05"
              height={"100%"}
              width={"50%"}
            />
            <img
              src={cmac_img_id_04}
              alt="cmac_img_id_04"
              height={"100%"}
              width={"50%"}
            />
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Topbanner;
