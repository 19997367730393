import React, { useEffect, useState } from "react";
import { Input } from "../utils/Elements";
import { SHOP_PAGE } from "../../helpers/paths";
import { useNavigate } from "react-router-dom";
import { getQueryParam } from "../../helpers/getQueryParams";

const SearchInputResponsiveScreen = ({ isSearching }) => {
  const navigate = useNavigate();
  const searchActive = getQueryParam("q") 
  const [searchInputStatusAlpha, setSearchInputStatusAlpha] = useState(false);
  const [searchInputStatusBeta, setSearchInputStatusBeta] = useState(false);
  useEffect(() => {
    if (searchActive === 'search') {
      setSearchInputStatusAlpha(true);
      setTimeout(() => {
        setSearchInputStatusBeta(true);
      }, 300);
    } else {
      setSearchInputStatusBeta(false);
      setTimeout(() => {
        setSearchInputStatusAlpha(false);
      }, 300);
    }
  }, [searchActive]);
  return (
    <div
      className={`search_input_res_screen ${
        searchInputStatusAlpha ? "open_box" : "close_box"
      }`}
    >
      <div
        className={`search_input_container ${
          searchInputStatusBeta ? "drag_input" : "push_input"
        }`}
      >
        <Input
          style={{
            width: "400px",
            borderRadius: "3px",
          }}
          mode={"search"}
          className="search_input_class font_lato"
          placeholder={"Search for Products"}
          onChange={(e) => {
            const searchPayload = e.target.value;
            navigate(`${SHOP_PAGE}?q=search&s=${searchPayload}`);
            isSearching(true);
            setTimeout(() => {
              isSearching(false);
            }, 1000);
          }}
        />
      </div>
    </div>
  );
};

export default SearchInputResponsiveScreen;
