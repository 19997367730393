import React, { useEffect, useState } from "react";
import { Input } from "../../../utils/Elements";
import { useDispatch, useSelector } from "react-redux";
import { createTemporaryTestQuestions } from "../../../../redux/actions/testQuestionsAction";

const FormDelta = ({ selectedLink, selectedSubLink, handleSelectChange }) => {
  const [age, setAge] = useState("");
  const { testQuestions } = useSelector(state => state.testQuestionsState)
  const dispatch = useDispatch();
  const onNext = () => {
    const payload = {
      ...testQuestions,
      14: { link: 1, sublink: 4, value: age, key: "age" }
    }
    dispatch(createTemporaryTestQuestions(payload))
  }
  useEffect(() => {
    if (testQuestions[14] && testQuestions[14]["value"]) {
      setAge(testQuestions[14]["value"])
    }
  }, [testQuestions])
  return (
    <div className="form_alpha">
      <div className="heading f_weight_1">How old are you?</div>
      <div className="interactions_container">
        <div className="input_container">
          <div className="label">Please tell us your Age</div>
          <Input
            className={"font_lato mt_2"}
            placeholder={"Your Age"}
            containerStyle={{
              width: "-webkit-fill-available",
            }}
            style={{
              width: "-webkit-fill-available",
            }}
            value={age}
            onChange={(e) => setAge(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
                age && onNext();
                age && handleSelectChange(selectedLink, selectedSubLink + 1)
              }
            }}
          />
        </div>
        <div className="mt_2 interactions_btn_container">
          <button className="interaction_btn_alpha" onClick={() => handleSelectChange(selectedLink, selectedSubLink - 1)}>Previous</button>
          <button className="interaction_btn_beta" onClick={() => {
            age && onNext();
            age && handleSelectChange(selectedLink, selectedSubLink + 1)
          }} style={{
            transition: "all 0.5s ease-in-out",
            opacity: !age && '0.2'
          }}>Next</button>
        </div>
      </div>
    </div>
  );
};

export default FormDelta;