import { createSlice } from "@reduxjs/toolkit";

export const reviewSlice = createSlice({
  name: "review",
  initialState: {
    loading: false,
    review: {},
    reviewList: [],
    message: null,
    status: null,
    allowToast: false,
    reqFrom: null,
    statusAction: null,
  },
  reducers: {
    reviewsRequest(state) {
      return {
        ...state,
        loading: true,
        statusAction: "get-review-req",
      };
    },
    reviewsSuccess(state, action) {
      return {
        ...state,
        loading: false,
        review: action.payload.review,
        reviewsList: action.payload.review?.reviewsList,
        message: action.payload.message,
        status: "success",
        statusAction: "get-review-success",
        allowToast: false,
      };
    },
    reviewsFail(state, action) {
      return {
        ...state,
        loading: false,
        message: action.payload.message,
        status: "fail",
        statusAction: "get-review-fail",
        review: {},
        allowToast: false,
        reviewsList: [],
      };
    },
    addReviewRequest(state) {
      return {
        ...state,
        loading: true,
      };
    },
    addReviewSuccess(state, action) {
      return {
        ...state,
        loading: false,
        review: action.payload.review,
        reviewList: action.payload.review?.reviewList,
        message: action.payload.message,
        allowToast: action.payload.allowToast,
        status: "success",
        statusAction: "add-review-success",
      };
    },
    addReviewFail(state, action) {
      return {
        ...state,
        loading: false,
        message: action.payload.message,
        allowToast: action.payload.allowToast,
        status: "fail",
      };
    },
    clearReview(state) {
      return {
        ...state,
        loading: false,
        review: {},
        reviewList: [],
        message: null,
        status: null,
        reqFrom: null,
        statusAction: null,
      };
    },
    clearReviewActionStatus(state) {
      return {
        ...state,
        loading: false,
        status: null,
        allowToast: false,
        reqFrom: null,
        statusAction: null,
      };
    },
  },
});

const { actions, reducer } = reviewSlice;

export const {
  reviewsRequest,
  reviewsFail,
  reviewsSuccess,
  addReviewRequest,
  addReviewFail,
  addReviewSuccess,
  clearReview,
  clearReviewActionStatus,
} = actions;

export default reducer;
