import React, { useEffect } from "react";
import TermsAndConditions from "./TermsAndConditions";
import PrivacyPolicies from "./PrivacyPolicies";
import { getQueryParam } from "../../helpers/getQueryParams";
import { Link } from "react-router-dom";
import { AUTH_PAGE } from "../../helpers/paths";
import RefundPolicies from "./RefundPolicies";

const Policies = () => {
  const isAuth = getQueryParam("is_auth");
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <div className="policies_container">
      {isAuth && (
        <div className="d_flex align_items_center justify_content_center mb_2">
          <Link
            style={{
              color: "#fff",
              background: "var(--cmac-primary-color)",
              padding: "10px",
              borderRadius: "5px",
            }}
            to={AUTH_PAGE}
          >
            Back to Login Page
          </Link>
        </div>
      )}
      <TermsAndConditions />
      <div
        class="mt_5 pt_5"
        style={{
          borderTop: "1px dashed #ababab",
        }}
      >
        <h2 class="policies_title">Shipping and Return Policy</h2>
        <ol class="section_content">
          <li>
            If a cancellation request is received before the order is
            processed/approved, we will cancel the order and refund the full
            amount.
          </li>
          <li>
            A ₹50 deduction will be applied for cancellations or returns of
            orders that have left the shipping warehouse.
          </li>
          <li>
            Cash on Delivery (COD) orders will have a specified shipping charge
            limit mentioned during the checkout.
          </li>
          <li>
            In case of any damage, we require customers to record an unboxing
            video of the received order in case any discrepancies arise. This
            will help us address any issues effectively and provide suitable
            solutions.
          </li>
        </ol>
      </div>
      <div
        class="mt_5 pt_5"
        style={{
          borderTop: "1px dashed #ababab",
        }}
      >
        <h2 class="policies_title">Bulk Order Information</h2>
        <ol class="section_content">
          <li>
            For bulk orders, retailing, or corporate gifting, contact us at{" "}
            <a href="mailto:reviverootsindia@gmail.com">
              reviverootsindia@gmail.com
            </a>
            .
          </li>
          <li>
            4 to 8% discounts will be offered for bulk orders, corporate
            gifting, and retailing.
          </li>
          <li>Full payment required before order processing.</li>
        </ol>
      </div>
      <div
        class="mt_5 pt_5"
        style={{
          borderTop: "1px dashed #ababab",
        }}
      >
        <RefundPolicies />
      </div>
      <div
        class="mt_5 pt_5"
        style={{
          borderTop: "1px dashed #ababab",
        }}
      >
        <h1 className="policies_title">Return Policy</h1>
        <p class="section_content">
          If you are not completely satisfied with your order, you may initiate
          a return within 5 days of delivery. To be eligible for a return,
          products must be in their original packaging and remain unused. Please
          allow 5-7 business days for the refund to reflect in your account,
          depending on your bank's processing times.
        </p>
      </div>
      {isAuth && (
        <div className="d_flex align_items_center justify_content_center mt_3">
          <Link
            style={{
              color: "#fff",
              background: "var(--cmac-primary-color)",
              padding: "10px",
              borderRadius: "5px",
            }}
          >
            Back to Login Page
          </Link>
        </div>
      )}
    </div>
  );
};

export default Policies;
