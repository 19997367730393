import styled from "styled-components";

export const StyledInput = styled.input`
  height: 1.2rem;
  width: 18rem;
  border-radius: 0.3rem;
  border: 0.1rem solid #80808036;
  outline: none;
  padding: 1rem 0.8rem;
  font-size: 1rem;
  transition: 0.3s;
  &:focus {
    border: 1px solid var(--cmac-secondary-color);
    box-shadow: 0 0 0 4px #15121624;
  }
`;
