import React, { SetStateAction, useEffect, useState } from "react";
import { FaPlus } from "react-icons/fa";
import { IoClose } from "react-icons/io5";


const CustomTagger = ({
  value,
  placeholder,
  tags,
  onTagChange,
  showTagColor,
}) => {
  const [tagsValue, setTagsValue] = useState([]);
  const [tagInput, setTagInput] = useState("");
  const handleTagInputChange = (e) => {
    setTagInput(e.target.value);
  };

  const handleTagInputKeyDown = (e) => {
    if (e.key === "Enter" || e.key === ",") {
      e.preventDefault();
      addTag();
    }
  };
  const addTag = () => {
    
    const trimmedTag = tagInput.trim();
    if (trimmedTag && !tags?.includes(trimmedTag)) {
      setTagsValue([...tags, trimmedTag]);
      onTagChange && onTagChange([...tags, trimmedTag]);
      setTagInput("");
    }
  };

  const removeTag = (tagToRemove) => {
    setTagsValue(tags?.filter((tag) => tag !== tagToRemove));
    onTagChange && onTagChange(tags?.filter((tag) => tag !== tagToRemove));
  };
  useEffect(() => {
    setTagsValue(value);
  });
  return (
    <div className={`${tagsValue?.length > 0 && "tag-container"}`}>
      <div className="tags">
        {tagsValue?.map((tag, index) => (
          <div
            key={index}
            className="tag"
            onClick={() => removeTag(tag)}
            style={{ color: showTagColor && tag }}
          >
            {tag}
            <IoClose className="ic-close" />
          </div>
        ))}
      </div>
      {tagInput && (
        <div className="res-add-key">
          <div className="icon-container">
            <FaPlus className="ic-plus" onClick={addTag} />
          </div>
        </div>
      )}
      <input
        placeholder={placeholder}
        value={tagInput}
        onChange={handleTagInputChange}
        onKeyDown={handleTagInputKeyDown}
      />
    </div>
  );
};

export default CustomTagger;
