import React, { useEffect, useState } from "react";
import { Button } from "../../../utils/Elements";
import { IoClose } from "react-icons/io5";
import create_product_ic from "../../../../assets/imgs/vectors/cmac_vector_id_10.png";
import schedule_product_ic from "../../../../assets/imgs/vectors/cmac_vector_id_9.png";
import { FaArrowRightLong } from "react-icons/fa6";
import { FaCheck } from "react-icons/fa6";
import CreateProduct from "./CreateProduct";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { getProducts } from "../../../../redux/actions/productsAction";
import { currencyFormatter } from "../../../../helpers/currencyFormatter";
import { useNavigate, useOutletContext } from "react-router-dom";
import { PRODUCT_OVERVIEW_PAGE } from "../../../../helpers/paths";
import EmptyDataState from "../../../plugins/EmptyDataState";
import {
  IoIosAlert,
  IoIosArrowBack,
  IoIosArrowForward,
  IoIosWarning,
} from "react-icons/io";
import { clearProducts } from "../../../../redux/slices/productsSlice";
import ResponsivePagination from "react-responsive-pagination";

const ProductManagement = () => {
  const [theme] = useOutletContext();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { products, totalPages, loading } = useSelector(
    (state) => state.productsState
  );
  const [sheetActive, setSheetActive] = useState(false);
  const [selectedOption, setSelectedOption] = useState(0);
  const [openNode, setOpenNode] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [limit, setLimit] = useState(10);
  const productCreateOptions = [
    {
      id: 1,
      icon: () => (
        <img
          src={create_product_ic}
          alt="create_product_ic"
          height={"40px"}
          width={"40px"}
        />
      ),
      title: "Create Product",
      description:
        "Create a product that will be created instantly. This process ensures immediate availability and functionality, allowing you to quickly offer new products to your customers.",
    },
    {
      id: 2,
      icon: () => (
        <img
          src={schedule_product_ic}
          alt="schedule_product_ic"
          height={"40px"}
          width={"40px"}
        />
      ),
      title: "Schedule Product",
      description:
        "Schedule a product that will be deployed at the scheduled time. This feature allows you to plan releases in advance, ensuring timely availability and better resource management.",
    },
  ];
  const handlePageChange = (e) => {
    const queries = `limit=${limit}&page=${e}${
      searchQuery ? `&keyword=${searchQuery}` : ""
    }`;
    dispatch(clearProducts());
    dispatch(getProducts(queries));
    setActivePage(e);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const dataLimits = [5, 10, 20, 50, 100];
  const getStatusTheme = (status) => {
    switch (status) {
      case "pending": {
        if (theme === 0) {
          return {
            bg: "repeating-linear-gradient(45deg, #f5b1b156 0px, #f5b1b156 10px, #eb575752 10px, #eb575752 20px)",
            color: "#E17678",
          };
        } else if (theme === 1) {
          return {
            bg: "repeating-linear-gradient(45deg, #F5B1B1 0px, #F5B1B1 10px, #EB5757 10px, #EB5757 20px)",
            color: "#390001",
          };
        }
      }
      case "active": {
        if (theme === 0) {
          return {
            bg: "#4dbe6d24",
            color: "#4DBE6D",
          };
        } else if (theme === 1) {
          return {
            bg: "#e5fdef",
            color: "#333333",
          };
        }
      }
      case "inactive": {
        if (theme === 0) {
          return {
            bg: "#ff313126",
            color: "#ff4444ad",
          };
        } else if (theme === 1) {
          return {
            bg: "#ffd8d8",
            color: "#ff1515",
          };
        }
      }
    }
  };
  const getQuantityBadge = (qty) => {

    if (qty <= 10) {
      if (theme === 0) {
        return <IoIosAlert color="#ff4444ad" size={"16"} />;
      } else if (theme === 1) {
        return <IoIosAlert color="#ff1515" size={"16"} />;
      }
    } else if (qty <= 25) {
      if (theme === 0) {
        return <IoIosWarning color="#feb601c1" size={"16"} />;
      } else if (theme === 1) {
        return <IoIosWarning color="#feb701" size={"16"} />;
      }
    } else {
      return "";
    }
  };
  const columns = [
    {
      name: "Product ID",
      cell: (row) => {
        return (
          <div
            onClick={() =>
              navigate(`${PRODUCT_OVERVIEW_PAGE}?product_id=${row._id}`)
            }
          >
            {row?._id}
          </div>
        );
      },
      width: "250px",
    },
    {
      name: "Product Details",
      cell: (row) => {
        return (
          <div
            className="product_cell"
            onClick={() =>
              navigate(`${PRODUCT_OVERVIEW_PAGE}?product_id=${row._id}`)
            }
          >
            <img
              src={row?.productImages[0]?.url}
              alt={row?._id}
              height={50}
              width={50}
              style={{
                backgroundColor: "var(--cmac-management-outer-color)",
                padding: "5px",
                objectFit: "contain",
                borderRadius: "8px",
              }}
            />
            <div className="product_info">
              <div className="product_name">{row?.productName}</div>
              <div className="product_category">{row?.productCategory}</div>
            </div>
          </div>
        );
      },
      width: "400px",
    },
    {
      name: "Available Qty",
      selector: (row) => (
        <div
          onClick={() =>
            navigate(`${PRODUCT_OVERVIEW_PAGE}?product_id=${row._id}`)
          }
          className="d_flex align_items_center gap_1"
        >
          <div>{row?.availableQuantity}</div>
          {getQuantityBadge(row?.availableQuantity)}
        </div>
      ),
      width: "140px",
    },
    {
      name: "Price",
      selector: (row) => (
        <div
          onClick={() =>
            navigate(`${PRODUCT_OVERVIEW_PAGE}?product_id=${row._id}`)
          }
        >
          {currencyFormatter(row?.salePrice, "INR")} INR
        </div>
      ),
      width: "140px",
    },
    {
      name: "Status",
      cell: (row) => {
        return (
          <div
            className="product_status"
            style={{
              background: getStatusTheme(row?.productStatus)?.bg,
              color: getStatusTheme(row?.productStatus)?.color,
            }}
            onClick={() =>
              navigate(`${PRODUCT_OVERVIEW_PAGE}?product_id=${row._id}`)
            }
          >
            {row?.productStatus}
          </div>
        );
      },
      width: "140px",
    },
    {
      name: "Total Sales",
      selector: (row) => row?.verifiedPurchaseUsers?.length | 0,
      width: "140px",
    },
    {
      name: "Total Revenue",
      selector: (row) => `${currencyFormatter(0, "INR")} INR`,
      width: "140px",
    },
    {
      name: "Total Views",
      selector: (row) => row?.productViews,
      width: "140px",
    },
    {
      name: "Created On",
      selector: (row) => moment(row?.createdAt).format("MMM DD, YYYY hh:mm A"),
      width: "200px",
    },
  ];
  const data = products.filter((row) => row !== null && row !== undefined);

  useEffect(() => {
    const query = `limit=${limit}`;
    dispatch(getProducts(query));
  }, []);
  return (
    <div className="product_management">
      <div className="product_management_header">
        <div className="d_flex align_items_center gap_5">
          <div className="heading">PRODUCTS</div>
          <input
            placeholder="Search by Product Name"
            style={{
              marginTop: "10px",
              width: "260px",
              height: "45px",
            }}
            onChange={(e) => {
              if (e.target.value) {
                setSearchQuery(e.target.value);
                setActivePage(1);
                const query = `limit=${limit}&keyword=${e.target.value}&page=1`;
                dispatch(getProducts(query));
              } else {
                setActivePage(1);
                setSearchQuery("");
                const query = `limit=${limit}&page=1`;
                dispatch(getProducts(query));
              }
            }}
          />
        </div>
        <Button
          className="add_product_btn"
          onClick={() => setSheetActive(true)}
        >
          Add Product
        </Button>
      </div>
      <div className="mt_4 data_table_comp">
        <DataTable
          columns={columns}
          data={data}
          onRowClicked={(e) =>
            navigate(`${PRODUCT_OVERVIEW_PAGE}?product_id=${e._id}`)
          }
          noDataComponent={
            <EmptyDataState message={"There are no records to display"} />
          }
          fixedHeader={true}
          fixedHeaderScrollHeight="calc(var(--cmac-doc-height) - 300px)"
          className="data_table_comp"
        />
        <div className="mt_4 mb_4 d_flex align_items_center justify_content_flex_end gap_5">
          <ResponsivePagination
            current={activePage}
            total={totalPages}
            onPageChange={handlePageChange}
            pageItemClassName="management_page_item_class"
            previousLabel={
              <IoIosArrowBack
                size={20}
                color="var(--cmac-management-font-color-2)"
                style={{ position: "relative", top: "2px" }}
              />
            }
            nextLabel={
              <IoIosArrowForward
                size={20}
                color="var(--cmac-management-font-color-2)"
                style={{ position: "relative", top: "2px" }}
              />
            }
          />
          <select
            className="page_limit_select_input"
            value={limit}
            onChange={(e) => {
              if (e.target.value) {
                setLimit(e.target.value);
                const query = `limit=${e.target.value}&page=1${
                  searchQuery ? `&keyword=${searchQuery}` : ""
                }`;
                dispatch(getProducts(query));
              }
            }}
          >
            {dataLimits?.map((data) => {
              return (
                <option value={data} key={data}>
                  {data}
                </option>
              );
            })}
          </select>
        </div>
      </div>
      {/* Product Select Sheet */}
      <div
        className={`product_select_sheet ${sheetActive ? "active" : "default"}`}
      >
        <div>
          <div className="header_sec">
            <div
              className="ic_container cursor_pointer"
              onClick={() => {
                setSheetActive(false);
                setSelectedOption(0);
                setOpenNode(false);
              }}
            >
              <IoClose />
            </div>
          </div>
          <div
            className={`options_container ${
              openNode ? "node_mode_deactive" : "node_mode_active"
            }`}
          >
            <div
              style={{
                width: "400px",
                textAlign: "center",
              }}
            >
              <h3>Please Select an Option to Continue</h3>
            </div>
            <div className="d_flex align_items_center gap_8 mt_4">
              {productCreateOptions.map((data) => {
                const isSelectedOption = data.id === selectedOption;

                return (
                  <div
                    className={`option ${
                      isSelectedOption ? "active" : "default"
                    }`}
                    onClick={() => setSelectedOption(data.id)}
                  >
                    <div
                      className={`check_container ${
                        isSelectedOption ? "check" : "default"
                      }`}
                    >
                      <div className="ic_container">
                        <FaCheck className="ic" />
                      </div>
                    </div>
                    <div className="img_container">{data.icon()}</div>
                    <div className="details_container">
                      <div className="mode">{data.title}</div>
                      <div className="desc">{data.description}</div>
                    </div>
                  </div>
                );
              })}
            </div>
            <button
              className="continue_btn"
              disabled={
                !productCreateOptions.some((data) => data.id === selectedOption)
              }
              onClick={() => setOpenNode(true)}
            >
              <div>Continue</div>
              <FaArrowRightLong />
            </button>
          </div>
          <CreateProduct
            openNode={openNode}
            setOpenNode={(e) => setOpenNode(e)}
            setSheetActive={(e) => setSheetActive(e)}
          />
        </div>
      </div>
    </div>
  );
};

export default ProductManagement;
