import { useEffect, useState } from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import Header from "../common/Header";
import { useDispatch, useSelector } from "react-redux";
import {
  ACCOUNT_PAGE,
  CHECKOUT_PAGE,
  LANDING_PAGE,
  MANAGEMENT_PAGE,
  PRODUCT_DETAILS_PAGE,
  PRODUCT_MANAGEMENT_PAGE,
  PROFILE_PAGE,
  SHOP_PAGE,
  ADDRESS_PAGE,
  PRODUCT_OVERVIEW_PAGE,
  ORDER_TRACKING_PAGE,
  AUTH_PAGE,
  AUTH_SERVICE_PAGE,
  AUTH_SECRET_CODE_PAGE,
  PRODUCT_REVIEW_PAGE,
  ACCOUNT_TABS_PAGE,
  ORDERS_PAGE,
  TEST_QUESTIONS_PAGE,
  ORDER_MANAGEMENT_PAGE,
  ORDER_OVERVIEW_PAGE,
  PRINT_ORDER_INVOICE_PAGE,
  TEST_MANAGEMENT_PAGE,
  TEST_OVERVIEW_PAGE,
  TERMS_POLICIES_PAGE,
  PRIVACY_POLICIES_PAGE,
} from "../../helpers/paths";
import LandingPage from "../pages/landing-page/LandingPage";
import Footer from "../common/Footer";
import ShopPage from "../pages/shop-page/ShopPage";
import CheckoutPage from "../pages/checkout-page/CheckoutPage";
import Management from "../pages/management/nested-page/Management";
import ProductManagement from "../pages/management/product-management/ProductManagement";
import ProductDetailsPage from "../pages/product-details-page/ProductDetailsPage";
import Account from "../pages/account/Account";
import Profile from "../pages/account/layouts/Profile";
import AddressBook from "../pages/account/layouts/AddressBook";
import ProductOverview from "../pages/management/product-management/ProductOverview";
import OrderTrackingPage from "../pages/account/OrderTrackingPage";
import Auth from "../pages/auth-page/Auth";
import AuthSecretCodeInput from "../pages/auth-page/layouts/AuthSecretCodeInput";
import { getProfile } from "../../redux/actions/userAction";
import ProtectedRoute from "./ProtectedRoute";
import Topbar from "../common/Topbar";
import Categorybar from "../common/Categorybar";
import {
  getCart,
  getCartTemp,
  updateCart,
} from "../../redux/actions/cartAction";
import ProductReviewPage from "../pages/product-details-page/layouts/ProductReviewPage";
import AccountTabs from "../pages/account/layouts/AccountTabs";
import Orders from "../pages/account/layouts/Orders";
import TestQuestionsPage from "../pages/test-questions-page/TestQuestionsPage";
import OrderManagement from "../pages/management/order-management/OrderManagement";
import OrderOverview from "../pages/management/order-management/OrderOverview";
import AuthMobileNumberInput from "../pages/auth-page/layouts/AuthMobileNumberInput";
import OrderInvoicePrintPage from "../pages/account/OrderInvoicePrintPage";
import TermsAndConditions from "../common/TermsAndConditions";
import Policies from "../common/Policies";
import TestManagement from "../pages/management/test-management/TestManagement";
import TestOverview from "../pages/management/test-management/TestOverview";
import SearchInputResponsiveScreen from "../common/SearchInputResponsiveScreen";
import PrivacyPolicies from "../common/PrivacyPolicies";
import { performCrypto } from "../../helpers/performCrypto";

const AppRouter = () => {
  const crypto = performCrypto();
  const dispatch = useDispatch();
  const [searching, setSearching] = useState(false);
  const [docDimensions, setDocDimensions] = useState({});
  const { isAuthenticated } = useSelector((state) => state.userState);
  /* Setting default screen height property */
  const documentDimensions = () => {
    const docWidth = window.innerWidth;
    const docHeight = window.innerHeight;

    document.documentElement.style.setProperty(
      "--cmac-doc-width",
      `${docWidth}px`
    );
    document.documentElement.style.setProperty(
      "--cmac-doc-height",
      `${docHeight}px`
    );

    setDocDimensions({ width: docWidth, height: docHeight });
  };

  useEffect(() => {
    window.addEventListener("resize", documentDimensions);

    documentDimensions();

    return () => {
      window.removeEventListener("resize", documentDimensions);
    };
  }, []);
  const isSearching = (e) => {
    setSearching(e);
  };
  useEffect(() => {
    dispatch(getProfile());
    const tempCartId = crypto.get("temp_cart_id")?.decryptedData || null;
    if (tempCartId?.length > 0) {
      if (!isAuthenticated) {
        dispatch(getCartTemp("/", tempCartId));
      } else {
        dispatch(updateCart("/", tempCartId));
      }
    } else if (isAuthenticated) {
      dispatch(getCart());
    }
  }, [isAuthenticated]);
  useEffect(() => {
    /* Setting theme for the application */
    document.documentElement.style.setProperty(
      "--cmac-primary-color",
      "#7eb241"
    );
    document.documentElement.style.setProperty(
      "--cmac-primary-color-75",
      "#7eb241"
    );
    document.documentElement.style.setProperty(
      "--cmac-primary-color-50",
      "#7eb241"
    );
    document.documentElement.style.setProperty(
      "--cmac-primary-color-25",
      "#7eb24140"
    );
    document.documentElement.style.setProperty(
      "--cmac-primary-color-10",
      "#f2f7ec"
    );
    document.documentElement.style.setProperty(
      "--cmac-secondary-color",
      "#623c29"
    );
    document.documentElement.style.setProperty(
      "--cmac-default-fill",
      "#ffffff"
    );
    document.documentElement.style.setProperty(
      "--cmac-default-shade-fill",
      "#f6f7f6"
    );
    document.documentElement.style.setProperty("--cmac-dark-fill", "#000000");
  }, []);
  return (
    <Router>
      {/* Header */}
      <Topbar />
      <Header isSearching={isSearching} />
      <Categorybar />
      <Routes>
        <Route path={TERMS_POLICIES_PAGE} element={<Policies />} />
        <Route path={PRIVACY_POLICIES_PAGE} element={<PrivacyPolicies />} />
        <Route path={LANDING_PAGE} element={<LandingPage />} />
        <Route
          path={SHOP_PAGE}
          element={<ShopPage isSearching={isSearching} searching={searching} />}
        />
        <Route
          path={PRODUCT_DETAILS_PAGE}
          element={<ProductDetailsPage documentDimensions={docDimensions} />}
        />
        <Route path={PRODUCT_REVIEW_PAGE} element={<ProductReviewPage />} />
        <Route path={TEST_QUESTIONS_PAGE} element={<TestQuestionsPage />} />
        <Route
          path={CHECKOUT_PAGE}
          element={
            <ProtectedRoute>
              <CheckoutPage />
            </ProtectedRoute>
          }
        />
        {/* Protected Pages */}
        <Route
          path={ACCOUNT_PAGE}
          element={
            <ProtectedRoute>
              <Account />
            </ProtectedRoute>
          }
        >
          <Route
            path={ACCOUNT_TABS_PAGE}
            element={
              <ProtectedRoute>
                <AccountTabs />
              </ProtectedRoute>
            }
          />
          <Route
            path={PROFILE_PAGE}
            element={
              <ProtectedRoute>
                <Profile />
              </ProtectedRoute>
            }
          />
          <Route
            path={ADDRESS_PAGE}
            element={
              <ProtectedRoute>
                <AddressBook />
              </ProtectedRoute>
            }
          />
          <Route
            path={ORDERS_PAGE}
            element={
              <ProtectedRoute>
                <Orders />
              </ProtectedRoute>
            }
          />
        </Route>
        <Route
          path={ORDER_TRACKING_PAGE}
          element={
            <ProtectedRoute>
              <OrderTrackingPage />
            </ProtectedRoute>
          }
        />
        <Route
          path={PRINT_ORDER_INVOICE_PAGE}
          element={
            <ProtectedRoute>
              <OrderInvoicePrintPage documentDimensions={docDimensions} />
            </ProtectedRoute>
          }
        />
        {/* Auth Page */}
        <Route path={AUTH_PAGE} element={<Auth />}>
          <Route path={AUTH_SERVICE_PAGE} element={<AuthMobileNumberInput />} />
          <Route
            path={AUTH_SECRET_CODE_PAGE}
            element={<AuthSecretCodeInput />}
          />
        </Route>
        {/* </Routes> */}
        {/* Management Pages */}
        {/* <Routes> */}
        <Route
          path={MANAGEMENT_PAGE}
          element={
            <ProtectedRoute roles={["supreme_admin"]}>
              <Management />
            </ProtectedRoute>
          }
        >
          <Route
            path={PRODUCT_MANAGEMENT_PAGE}
            element={<ProductManagement />}
          />
          <Route path={PRODUCT_OVERVIEW_PAGE} element={<ProductOverview />} />
          <Route path={ORDER_MANAGEMENT_PAGE} element={<OrderManagement />} />
          <Route path={ORDER_OVERVIEW_PAGE} element={<OrderOverview />} />
          <Route path={TEST_MANAGEMENT_PAGE} element={<TestManagement />} />
          <Route path={TEST_OVERVIEW_PAGE} element={<TestOverview />} />
        </Route>
      </Routes>
      {/* Footer */}
      <Footer />
    </Router>
  );
};

export default AppRouter;
