import React, { useEffect } from "react";
import ProductsMultiCarousal from "./ProductsMultiCarousal";
import { useDispatch, useSelector } from "react-redux";
import { getRecentProducts } from "../../../../redux/actions/productsAction";
import { performCrypto } from "../../../../helpers/performCrypto";
import { addRecentProducts } from "../../../../helpers/addRecentProducts";

const ProductConnections = () => {
  const dispatch = useDispatch();
  const crypto = performCrypto();
  const { product } = useSelector((state) => state.productState);
  const { recentProducts } = useSelector((state) => state.recentProductsState);
  const { similarProducts } = useSelector(
    (state) => state.similarProductsState
  );
  useEffect(() => {
    addRecentProducts(product?._id);
    const productIds = crypto.get("recent_items").decryptedData;
    dispatch(getRecentProducts(`product_ids=${JSON.stringify(productIds)}`));
  }, []);
  return (
    <div className="product_connections mb_3">
      <div className="element_hug">
        <div className="element_container">
          {recentProducts?.length > 0 && (
            <div className="mt_3">
              <ProductsMultiCarousal
                heading={"Recently Viewed"}
                productsFeed={recentProducts}
              />
            </div>
          )}
          {similarProducts?.length > 0 && (
            <div className="mt_3">
              <ProductsMultiCarousal
                heading={"Similar Products"}
                productsFeed={similarProducts}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProductConnections;
