import React, { useState } from "react";
import Sidebar from "./layouts/Sidebar";
import { Outlet } from "react-router-dom";
import Header from "./layouts/Header";

const Management = () => {
  const [theme, setTheme] = useState(0);
  const currentTheme = (e) => {
    setTheme(e);
  };
  return (
    <div className="management font_merriweather">
      <Sidebar />
      <div className="flex_item">
        <Header currentTheme={currentTheme} />
        <div className="outlet">
          <Outlet context={[theme]} />
        </div>
      </div>
    </div>
  );
};

export default Management;
