import React, { useEffect, useState } from "react";
import { getQueryParam } from "../../../../helpers/getQueryParams";
import {
  clearProduct,
  clearProductStatus,
} from "../../../../redux/slices/productSlice";
import {
  deleteProduct,
  getProduct,
} from "../../../../redux/actions/productAction";
import { useDispatch, useSelector } from "react-redux";
import { currencyFormatter } from "../../../../helpers/currencyFormatter";
import { MdDelete, MdModeEdit, MdOutlineCopyAll } from "react-icons/md";
import moment from "moment";
import CreateProduct from "./CreateProduct";
import { IoClose } from "react-icons/io5";
import { useNavigate, useOutletContext } from "react-router-dom";
import {
  PRODUCT_MANAGEMENT_PAGE,
  PRODUCT_OVERVIEW_PAGE,
} from "../../../../helpers/paths";
import CmacModal from "../../../plugins/cmac-plugins/CmacModal";
import { IoMdAlert } from "react-icons/io";

const ProductOverview = () => {
  const [theme] = useOutletContext()
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const productId = getQueryParam("product_id");
  const { product, loading, methodStatus } = useSelector(
    (state) => state.productState
  );
  const [openNode, setOpenNode] = useState(true);
  const [sheetActive, setSheetActive] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const getStatusTheme = (status) => {
    switch (status) {
      case "pending": {
        if (theme === 0) {
          return {
            bg: "repeating-linear-gradient(45deg, #f5b1b156 0px, #f5b1b156 10px, #eb575752 10px, #eb575752 20px)",
            color: "#E17678",
          };
        } else if (theme === 1) {
          return {
            bg: "repeating-linear-gradient(45deg, #F5B1B1 0px, #F5B1B1 10px, #EB5757 10px, #EB5757 20px)",
            color: "#390001",
          };
        }
      }
      case "active": {
        if (theme === 0) {
          return {
            bg: "#4dbe6d24",
            color: "#4DBE6D",
          };
        } else if (theme === 1) {
          return {
            bg: "#e5fdef",
            color: "#333333",
          };
        }
      }
      case "inactive": {
        if (theme === 0) {
          return {
            bg: "#ff313126",
            color: "#ff4444ad",
          };
        } else if (theme === 1) {
          return {
            bg: "#ffd8d8",
            color: "#ff1515",
          };
        }
      }
    }
  };
  useEffect(() => {
    const payload = {
      productId,
    };
    if (!productId) {
      navigate(PRODUCT_MANAGEMENT_PAGE);
    }
    dispatch(clearProduct());
    dispatch(getProduct(payload));
  }, []);
  useEffect(() => {
    if (methodStatus === "delete-success") {
      setDeleteModal(false);
      setTimeout(() => {
        navigate(PRODUCT_MANAGEMENT_PAGE);
        dispatch(clearProductStatus());
      }, 100)
    }
  }, [methodStatus]);
  return (
    <div className="product_overview">
      <div className="product_overview_header">
        <div className="heading">PRODUCT OVERVIEW</div>
        {/* <Button
          className="add_product_btn"
          onClick={() => setSheetActive(true)}
        >
          Add Product
        </Button> */}
      </div>
      <div className="product_header_details">
        <div className="product_header_content">
          <div className="img_container">
            <img
              src={
                product?.productImages?.length > 0 &&
                product?.productImages[0]?.url
              }
              alt={product?._id}
            />
          </div>
          <div className="d_flex flex_direction_col">
            <div className="d_flex align_items_center gap_4">
              <div className="product_name">{product?.productName}</div>
              <div
                className="product_status"
                style={{
                  background: getStatusTheme(product?.productStatus)?.bg,
                  color: getStatusTheme(product?.productStatus)?.color,
                }}
              >
                {product?.productStatus}
              </div>
              <button
                className="action_btn d_flex align_items_center justify_content_center gap_1"
                style={{
                  color: "#fff",
                  background: "#5050ff",
                }}
                onClick={() => {
                  setSheetActive(true);
                  setOpenNode(true);
                  navigate(
                    `${PRODUCT_OVERVIEW_PAGE}?product_id=${
                      product._id
                    }&edit=${true}`
                  );
                }}
              >
                <div>Edit</div>
                <MdModeEdit size={14} />
              </button>
              <button
                className="action_btn d_flex align_items_center justify_content_center gap_1"
                style={{
                  background: "#950000",
                  color: "#fff",
                }}
                onClick={() => setDeleteModal(true)}
              >
                <div>Delete</div>
                <MdDelete size={16} />
              </button>
            </div>
            <div className="d_flex align_items_center gap_3">
              <div className="price">
                {currencyFormatter(product?.salePrice, "INR")} INR
              </div>
              <div className="custom_dot"></div>
              <div className="product_category">{product?.productCategory}</div>
              <div className="custom_dot"></div>
              <div className="d_flex align_items_center gap_2 product_id">
                <div>{product?._id}</div>
                <MdOutlineCopyAll
                  size={18}
                  style={{
                    cursor: "pointer",
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="short_analysis">
        <div className="analysis_data">
          <div className="analysis_content">
            <div className="data">
              <div className="analysis_title">
                Current Month Recurring Revenue
              </div>
              <div className="price">{currencyFormatter(0, "INR")} INR</div>
              <div className="sales">0 Total Sales on this month</div>
            </div>
            <div className="data">
              <div className="analysis_title">Overall Revenue</div>
              <div className="price">{currencyFormatter(0, "INR")} INR</div>
              <div className="sales">0 Total Sales</div>
            </div>
          </div>
          <div className="details_container">
            <div className="title">Details</div>
            <div className="details_content">
              <div className="d_flex">
                <div className="key">Name</div>
                <div className="value">{product?.productName}</div>
              </div>
              <div className="d_flex">
                <div className="key">Description</div>
                <div className="value">{product?.description}</div>
              </div>
              <div className="d_flex">
                <div className="key">Created At</div>
                <div className="value">
                  {moment(product?.createdAt).format("MMM DD, YYYY hh:mm A")}
                </div>
              </div>
              <div className="d_flex">
                <div className="key">Updated At</div>
                <div className="value">
                  {moment(product?.updatedAt).format("MMM DD, YYYY hh:mm A")}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="images_container">
          <div className="parent_img_container">
            <img
              src={
                product?.productImages?.length > 0 &&
                product?.productImages[0]?.url
              }
              alt={product?._id}
            />
          </div>
        </div>
      </div>
      {/* Product Select Sheet */}
      <div
        className={`product_select_sheet ${sheetActive ? "active" : "default"}`}
      >
        <div>
          <div className="header_sec">
            <div
              className="ic_container cursor-pointer"
              onClick={() => {
                setSheetActive(false);
                setOpenNode(false);
              }}
            >
              <IoClose />
            </div>
          </div>
          <CreateProduct
            openNode={openNode}
            setOpenNode={(e) => setOpenNode(true)}
            setSheetActive={(e) => setSheetActive(e)}
          />
        </div>
      </div>
      <CmacModal
        active={deleteModal}
        onClickOutsider={() => setDeleteModal(false)}
        className="auth-modal"
        portalUsage={true}
        blurStrength="stronger"
        style={{
          width: "450px",
        }}
      >
        <div className="font_lato">
          <div className="d_flex gap_3">
            <div>
              <IoMdAlert color="#f00" size={60} />
            </div>
            <div>
              <div
                style={{
                  fontSize: "20px",
                  fontWeight: 600,
                }}
              >
                Delete Product!
              </div>
              <div
                className="mt_1"
                style={{
                  fontSize: "14px",
                }}
              >
                Are you sure you want to delete the product? This action cannot
                be undone!
              </div>
            </div>
          </div>
          <div className="mt_2 d_flex justify_content_flex_end gap_2">
            <button
              className="alert_btn_1"
              onClick={() => setDeleteModal(false)}
            >
              Cancel
            </button>
            <button
              className="alert_btn_2"
              onClick={() => dispatch(deleteProduct({ productId }))}
            >
              Yes! Delete!
            </button>
          </div>
        </div>
      </CmacModal>
    </div>
  );
};

export default ProductOverview;
