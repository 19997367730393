import React, { useEffect, useState } from "react";
import { Input } from "../../../utils/Elements";
import { useDispatch, useSelector } from "react-redux";
import { createTemporaryTestQuestions } from "../../../../redux/actions/testQuestionsAction";

const FormBeta = ({ selectedLink, selectedSubLink, handleSelectChange }) => {
  const [mobileNumber, setMobileNumber] = useState("");
  const { testQuestions } = useSelector(state => state.testQuestionsState)
  const dispatch = useDispatch();
  const onNext = () => {
    const payload = {
      ...testQuestions,
      12: { link: 1, sublink: 2, value: mobileNumber, key: "mobileNumber" }
    }
    dispatch(createTemporaryTestQuestions(payload))
  }
  useEffect(() => {
    if (testQuestions[12] && testQuestions[12]["value"]) {
      setMobileNumber(testQuestions[12]["value"])
    }
  }, [testQuestions])
  return (
    <div className="form_alpha">
      <div className="heading f_weight_1">Mobile Number</div>
      <div className="interactions_container">
        <div className="input_container">
          <div className="label">Could you share your Mobile Number?</div>
          <Input
            className={"font_lato mt_2"}
            placeholder={"Your Mobile Number"}
            containerStyle={{
              width: "-webkit-fill-available",
            }}
            style={{
              width: "-webkit-fill-available",
            }}
            value={mobileNumber}
            onChange={(e) => setMobileNumber(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
                mobileNumber && handleSelectChange(selectedLink, selectedSubLink + 1);
                mobileNumber && onNext();
              }
            }}
          />
        </div>
        <div className="mt_2 interactions_btn_container">
          <button className="interaction_btn_alpha" onClick={() => handleSelectChange(selectedLink, selectedSubLink - 1)}>Previous</button>
          <button className="interaction_btn_beta" onClick={() => {
            mobileNumber && handleSelectChange(selectedLink, selectedSubLink + 1);
            mobileNumber && onNext();
          }} style={{
            transition: "all 0.5s ease-in-out",
            opacity: !mobileNumber && '0.2'
          }}>Next</button>
        </div>
      </div>
    </div>
  );
};

export default FormBeta;
