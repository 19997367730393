import React, { useEffect } from "react";
import ProductInfoContainer from "./layouts/ProductInfoContainer";
import ProductConnections from "./layouts/ProductConnections";
import { useDispatch, useSelector } from "react-redux";
import { getProduct } from "../../../redux/actions/productAction";
import { getQueryParam } from "../../../helpers/getQueryParams";
import { clearProduct } from "../../../redux/slices/productSlice";
import { dummyProducts } from "../landing-page/layouts/productsData";
import { IoIosArrowForward } from "react-icons/io";
import { Link } from "react-router-dom";
import { SHOP_PAGE } from "../../../helpers/paths";
import Skeleton from "react-loading-skeleton";

const ProductDetails = ({documentDimensions}) => {
  const { product, loading } = useSelector((state) => state.productState);
  const dispatch = useDispatch();
  const productId = getQueryParam("product_id");
  useEffect(() => {
    const payload = {
      productId,
    };
    dispatch(clearProduct());
    dispatch(getProduct(payload));
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <div className="product_details_page">
      <div className="element_hug">
        <div className="element_container d_flex align_items_center gap_3 mt_3 f_14 text_transform_uppercase f_weight_2">
          {loading ? (
            <Skeleton
              height={"20px"}
              width={"100px"}
              baseColor="var(--cmac-default-shade-fill)"
              highlightColor="var(--cmac-default-fill)"
              duration={0.8}
              className="mt_1"
            />
          ) : (
            <Link
              to={SHOP_PAGE}
              className="cmac_primary_color_text text_decoration_unset"
            >
              Shop
            </Link>
          )}
          {!loading && (
            <IoIosArrowForward
              size={20}
              className="pos_relative opacity_0_4"
              style={{
                top: "0px",
              }}
            />
          )}
          {loading ? (
            <Skeleton
              height={"20px"}
              width={"350px"}
              baseColor="var(--cmac-default-shade-fill)"
              highlightColor="var(--cmac-default-fill)"
              duration={0.8}
              className="mt_1"
            />
          ) : (
            <div className="overflow_hidden text_overflow_ellipsis text_wrap_nowrap opacity_0_4">
              {product?.productName}
            </div>
          )}
        </div>
      </div>
      <ProductInfoContainer documentDimensions={documentDimensions}/>
      {!loading && <ProductConnections />}
    </div>
  );
};

export default ProductDetails;
