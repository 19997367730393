import { createSlice } from "@reduxjs/toolkit";

export const toastsSlice = createSlice({
  name: "toasts",
  initialState: {
    loading: false,
    toasts: [],
  },
  reducers: {
    toastsRequest(state, payload) {
      return {
        ...state,
        loading: true,
      };
    },
    toastsSuccess(state, action) {
      return {
        ...state,
        loading: false,
        toasts: action.payload,
      };
    },
    toastsFail(state, action) {
      return {
        ...state,
        loading: false,
      };
    },
    clearToasts(state) {
      return {
        ...state,
        loading: false,
        toasts: [],
      };
    },
  },
});

const { actions, reducer } = toastsSlice;

export const { toastsRequest, toastsFail, toastsSuccess, clearToasts } = actions;

export default reducer;
